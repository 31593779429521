import * as React from 'react';
import { Box, List, ListItem, ListItemButton, SxProps, Typography, useMediaQuery, useTheme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import Grid, { GridSize } from '@mui/material/Grid';
import PersonIcon from '@mui/icons-material/Person';
import PersonsIcon from '@mui/icons-material/PeopleAlt';
import * as Backend from '../util/firebase';
import {
    Competition, ScoringData, Event, Contact, GolferGroup, Score, ReportedScore, ContactPayoutState, Team,
    isIndividualScoring, PayoutSettings, isNetMode, isGrossMode, isTeamFormat, isSkinsScoring, isTeamScoring, Units,
    getTee, getTotalHoles, teamsOfFlight, contactsOfFlight, teamsOf, golfersOfCompetition, isStablefordScoringOrMode,
    Tee, getFlightOfContact, ContactScoringState, isNetPayouts, isGrossPayouts, CalculatedScores, isTeamScoringExceptBB,
    ScoringFormatTeams, getFlightOfTeam, isCompatibleCompetitionSkins, getEventMainCompetition, isTeamFormatExceptBB,
    getHolesRange, ScoringFormatSkins, HOLES_9, HOLES_9_9, getHoleLabel, isMainScoring, isDistanceScoring, Distance,
    ScoringFormatDistance
} from '../types/EventTypes';
import {
    HolesScoringState, getSkinsScores, SkinsScoringState, eventPayoutsStates, getDistanceScores, formatDistance, hasTees,
    genderFromEvent, golferMainCompetitionAward, getPayouts, isFullScoresCompetition, isFullScoresCompetitions,
    getFullCompetitionPurse, processSkins, getSplitCompetitionsWithPayouts, getActualSkin, skinCompetitionAward
} from '../event/Event';
import { getFlightName } from '../event/TeeTimes';
import { ScorecardRow, Edit, scoresOf, reportedScoresOf } from '../event/tabs/scores/EditScoreDialog';
import * as Scoring from '../scoring/scoring';
import { fullName, formatHandicap, getSameNameGolfersIds, golfersOfTeam, golferShortTeamNameArray } from '../contact/Contact';
import { PortalEditScoreDialog } from '../event/tabs/scores/EditScoreDialog';
import AppButton from '../common/components/AppButton';
import { FirebaseDataComponent, FirebaseUserPubDataComponent } from '../common/WithData';
import { Container, Item, ItemS, NoWrapOverflow, ListTitle, Spacing, FlexGrid, Label } from '../common/Misc';
import { getHoleHandicap, getGolferRangeHandicap, hardestTee, getPlayingHandicap, getHandicapsAllowance, setTeamHandicapIndexes } from '../scoring/handicap';
import { randomString, range } from '../util/utility';
import { styles } from '../styles';
import { round, formatCurrency } from '../util/utility';
import { getEventInfo } from "./About";
import { Urls } from "../util/config";
import { showAlert } from "../redux/ReduxConfig";
import useDidUpdateEffect from "../hooks/useDidUpdateEffect";
import styled, { keyframes } from "styled-components";
import { AppColors } from "../main/Theme";
import { Theme } from "@mui/material/styles";

const linkIconImage = '/img/link_icon_transparent14x14.png';
const skinsTieImage = '/img/skinsTiePic24x24.png';
const skinsWinImage = '/img/skinsWinPic24x24.png';

const holeByHoleWidth = 1000;
const holeByHoleNamesWidth18 = 200;
const holeByHoleNamesWidth9 = 525;

enum ScoreCompetitionInfoState {
    SCORING_NAME,
    LL_INFO,
    NONE
}

interface HeaderProps {
    event: Event;
    scoring: ScoringData;
    holeView?: boolean;
    withWinnings?: boolean;
    holeByHoleNamesWidth: number;
    fullHoleByHoleWidth: number;
}

const Header = withStyles(styles)((props: WithStyles<typeof styles> & HeaderProps) => {
    const { classes, event, scoring, withWinnings, holeView, holeByHoleNamesWidth, fullHoleByHoleWidth } = props;
    const isTeam = isTeamScoring(scoring);
    const isNet = isNetMode(scoring.mode);
    const isGross = isGrossMode(scoring.mode);
    const isStableford = isStablefordScoringOrMode(scoring);
    const isSkins = isSkinsScoring(scoring);
    const tightMode = window.screen.width < 500;
    const columnStyle: React.CSSProperties = { fontSize: tightMode ? 9 : "inherit" };
    if (holeView) {
        const holesRange = getHolesRange(event.holesType);
        const holeLabelsGen = (hole: number) => {
            if (hole === -1) {
                return isNet ? <span>Net<br />OUT</span> : 'OUT';
            }
            if (hole === -2) {
                return isNet ? <span>Net<br />IN</span> : 'IN';
            }
            if (hole === -3) {
                return isNet ? 'Total Net' : 'Total';
            }
            if (hole === -4 && isNet) {
                return 'Total Gross';
            }
            if (hole < 0) {
                return '';
            }
            return getHoleLabel(hole, holesRange);
        };
        return (
            <ListItem className={classes.portalListItemHeader} style={{ width: fullHoleByHoleWidth }}>
                <Container wrap="nowrap">
                    <Item width={30}>Pos</Item>
                    <Item width={holeByHoleNamesWidth}>{isTeam ? 'Team' : 'Golfer'}</Item>
                    <ItemS>
                        <div className={classes.portalScorecardPanel}>
                            <ScorecardRow
                                label=""
                                inOutForm={2}
                                holesType={event.holesType}
                                gen={holeLabelsGen}
                                classes={{
                                    cellsInOut: classes.portalCellsInOut,
                                    cellsRoot: classes.portalCellsRoot,
                                    cells: classes.portalCells,
                                    cell: classes.portalCell,
                                    row: classes.portalHeader
                                }}
                            />
                        </div>
                    </ItemS>
                </Container>
            </ListItem>
        );
    }
    const namesXs = (((isNet && withWinnings) ? 6 : (isNet || withWinnings) ? 7 : 8) + ((isSkins && isNet) ? 1 : 0)) as 6 | 7 | 8 | 9;
    return (
        <ListItem className={classes.listItemHeader}>
            <Container wrap="nowrap">
                <Item xs={1} lg={1}><span style={columnStyle}>Pos</span></Item>
                <Item xs={namesXs} lg={namesXs}><span style={columnStyle}>{isTeam ? 'Team' : 'Golfer'}</span></Item>
                <Item xs={1} lg={1}><span style={columnStyle}>{isSkins ? 'Skins' : isNet ? 'Net' : 'Score'}</span></Item>
                <Item xs={1} lg={1}><span style={columnStyle}>Thru</span></Item>
                {!(isSkins && isNet) && <Item xs={1} lg={1}><span style={columnStyle}>Total<br /> {(isNet || isSkins) ? ' Gross' : ''}</span></Item>}
                {!isStableford && isNet && <Item xs={1} lg={1}><span style={columnStyle}>Total<br /> Net</span></Item>}
                {isStableford && isGross && <Item xs={1} lg={1}><span style={columnStyle}>Pts</span></Item>}
                {isStableford && isNet && <Item xs={1} lg={1}><span style={columnStyle}>Pts<br /> Net</span></Item>}
                {withWinnings && <Item xs={1} lg={1}><span style={columnStyle}>Purse</span></Item>}
            </Container>
        </ListItem>
    );
});

interface RowProps {
    onClick?: () => void;
    withWinnings?: boolean;
    winning?: number;
    event: Event;
    competition: Competition;
    reportedScores: Map<string, ReportedScore>;
    scores: Map<string, Score>;
    golfers: Map<string, Contact>;
    teams: Map<string, Team>;
    sameNameGolfersIdsSet: Set<string>;
    mainCompetition: Competition;
    roundScorecardLink?: string;
    index: number;
    skinScore?: number;
    skins?: SkinsScoringState[];
    holeByHoleNamesWidth: number;
    fullHoleByHoleWidth: number;
}

const getRoundScorecardLink = (contact?: Contact) => {
    if (contact && contact.roundToken) {
        return `${Urls.baseServerUrl}/round/${contact.roundToken}?puid=${randomString(4)}`;
    }
    return undefined;
}
export const getAnimation = (defaultColour: string) => {
    return keyframes`
      0% {
        background-color: ${defaultColour};
        opacity: 1;
      }
      15% {
        background-color: ${AppColors.reportedColor};
        opacity: .95;
      }
      85% {
        background-color: ${AppColors.reportedColor};
        opacity: .95;
      }
      100% {
          background-color: ${defaultColour};
          opacity: 1;
      }
    `;
};

const ContainerEven = styled.div`
        animation-name: ${getAnimation('#fff')};
        animation-duration: 4s;
    `;

export const ContainerOdd = styled.div`
        animation-name: ${getAnimation('#eeeeee')};
        animation-duration: 4s;
    `;

type RowScoreProps = ContactScoringState & WithStyles<typeof styles> & RowProps;

const RowScoreHoles = withStyles(styles)((props: RowScoreProps) => {
    const { classes, onClick, sameNameGolfersIdsSet, player, holeByHoleNamesWidth } = props;
    const { pos, contactInfo, courseHandicap, finalGrossScore, tee, nets } = props;
    const { event, competition, scores, reportedScores, golfers, teams, mainCompetition, skins, fullHoleByHoleWidth } = props;
    const scoring = competition.scoring;
    const holesRange = getHolesRange(event.holesType);
    const totalHoles = holesRange.last - holesRange.first;
    const isNet = isNetMode(scoring.mode);
    const bestBall = scoring.format === ScoringFormatTeams.best_ball;
    const isSkins = isSkinsScoring(scoring);
    const courseHcp = courseHandicap == null || isNet == null ? '' : ` (${formatHandicap(courseHandicap)})`;
    let withHomeCourseOrCity: boolean = false;
    const setHomeCourseOrCity = (homeCourseOrCity: string | undefined) => {
        withHomeCourseOrCity = true;
        return homeCourseOrCity;
    };
    const golferNames = (golfer: Contact) => {
        return (
            <Container>
                <Item>
                    <NoWrapOverflow>
                        {fullName(golfer, 15, 25)}
                        <span className={classes.homeCourseOrCity}>
                            {golfer.homeCourseOrCity && sameNameGolfersIdsSet.has(golfer.id) ? ` (${golfer.homeCourseOrCity})` : ''}
                        </span>
                        {courseHcp}
                    </NoWrapOverflow>
                </Item>
            </Container>
        );
    };
    const teamNames = (team: Team) => {
        const names = golferShortTeamNameArray(team, golfers, false);
        const contactIds = golfersOfTeam(team, golfers).map(c => c.id);
        const playingHandicaps = contactInfo.playingHandicaps.map(ci => ci == null || isNet == null ? '' : ` (${formatHandicap(ci)})`);
        return (
            <Container>
                {names.map((name, idx) =>
                    <Item key={idx} style={{ marginRight: 8 }}>
                        <NoWrapOverflow>
                            {name}
                            <span className={classes.homeCourseOrCity}>
                                {sameNameGolfersIdsSet.has(contactIds[idx]) && golfers.get(contactIds[idx])?.homeCourseOrCity ? ` (${setHomeCourseOrCity(golfers.get(contactIds[idx])?.homeCourseOrCity)})` : ''}
                            </span>
                            {playingHandicaps[idx]}
                        </NoWrapOverflow>
                    </Item>)}
            </Container>
        );
    };
    const namesOnly = 'contactIds' in player ? teamNames(player) : golferNames(player);
    let totGross1 = 0;
    let totGross2 = 0;
    let totNet1 = 0;
    let totNet2 = 0;
    const courseHoleCount = event.holesType === HOLES_9 || event.holesType === HOLES_9_9 ? 9 : 18;
    const tees = contactInfo.contacts.map(c => getTee(competition, c.gender, c, getFlightOfContact(competition, golfers, teams, golfers.get(c.id)!)));
    const usedTee = hardestTee(tees);
    const { par } = tee || usedTee || {} as Tee;
    const rawScore = scoresOf(contactInfo.id, event, contactInfo, competition, contactInfo.contacts, scores);
    const reportedScore = reportedScoresOf(contactInfo.id, event, contactInfo, competition, contactInfo.contacts, reportedScores);
    const score: Score = finalGrossScore;
    const hs = event.handicapSystem ? event.handicapSystem : (usedTee ? usedTee.handicapSystem : 'WHS');
    let playingHandicap = 0;
    if (isTeamScoringExceptBB(scoring) || (scoring.format === ScoringFormatSkins.skins_team && mainCompetition?.scoring?.format && mainCompetition.scoring.format !== ScoringFormatTeams.best_ball)) {
        playingHandicap = contactInfo.teamPlayingHandicap ?? 0;
    } else if (!bestBall && isNet) {
        playingHandicap = getPlayingHandicap(event.holesType, usedTee, event.handicapSystem, getGolferRangeHandicap(event.holesType, event.handicapSystem, contactInfo.handicapIndex), getHandicapsAllowance(scoring, 0));
    }
    for (let hole = holesRange.first; hole < 9; hole++) {
        totGross1 += score.gross[hole];
        if (isNet) {
            totNet1 += (nets && nets[hole] != null) ? nets[hole]! : Scoring.scoreNet(hs, hole, playingHandicap, rawScore, reportedScore, event.holesType, usedTee) || 0;
        }
    }
    for (let hole = 9; hole < holesRange.last; hole++) {
        totGross2 += score.gross[hole];
        if (isNet) {
            totNet2 += (nets && nets[hole] != null) ? nets[hole]! : Scoring.scoreNet(hs, hole, playingHandicap, rawScore, reportedScore, event.holesType, usedTee) || 0;
        }
    }
    let bestBallNetScores: Array<number | undefined> = [];
    if (bestBall) {
        if (nets) {
            bestBallNetScores = nets;
        } else {
            const playingHandicaps = contactInfo.contacts.map((teamContact, teamIndex) => {
                return getPlayingHandicap(event.holesType, getTee(competition, teamContact.gender, teamContact, getFlightOfTeam(competition, golfers, teams, teams.get(contactInfo.id)!)), event.handicapSystem, getGolferRangeHandicap(event.holesType, event.handicapSystem, teamContact.handicapIndex), scoring!.handicaps![teamIndex]);
            });
            bestBallNetScores = Scoring.bestBallNet(hs, contactInfo.contacts, playingHandicaps, scores, reportedScores, tees, event.holesType);
        }
    }
    const grossScoresGen = (hole: number) => {
        if (hole === -1) {
            return totGross1 || ' ';
        }
        if (hole === -2) {
            return totGross2 || ' ';
        }
        if (hole === -3) {
            return (totGross1 + totGross2) || ' ';
        }
        if (hole < 0) {
            return '';
        }
        const holeScore = score.gross[hole];
        return (<Edit
            type={'SCORE'}
            value={holeScore}
            onKey={() => { }}
            onValue={() => { }}
            diff={Scoring.getScoreDiffName(holeScore, par && par[hole % courseHoleCount])}
            classes={{ decor: classes.portalDecor }}
            readonly={true}
            withPickUp={score.pickUps && score.pickUps[hole]} />);
    };
    const netScoresGen = (hole: number) => {
        if (hole === -1) {
            return totNet1 || ' ';
        }
        if (hole === -2) {
            return totNet2 || ' ';
        }
        if (hole === -3) {
            return (totNet1 + totNet2) || ' ';
        }
        if (hole === -4) {
            return (totGross1 + totGross2) || ' ';
        }
        if (hole < 0) {
            return '';
        }
        const holeScore = score.gross[hole];
        const netScore = bestBall ? bestBallNetScores[hole] : nets ? nets[hole] : Scoring.scoreNet(hs, hole, playingHandicap, rawScore, reportedScore, event.holesType, usedTee);
        return (<Edit
            type={'SCORE'}
            value={holeScore}
            onKey={() => { }}
            onValue={() => { }}
            handicap={(!bestBall && getHoleHandicap(hole, event.holesType, usedTee, event.handicapSystem, getGolferRangeHandicap(event.holesType, event.handicapSystem, contactInfo.handicapIndex), getHandicapsAllowance(scoring, 0), playingHandicap)) || 0}
            diff={Scoring.getScoreDiffName(bestBall ? bestBallNetScores[hole] : netScore, par && par[hole % courseHoleCount])}
            classes={{ decor: classes.portalDecor }}
            readonly={true}
            additionalDotStyles={{ marginTop: -5 }}
            withPickUp={score.pickUps && score.pickUps[hole]} />);
    };
    const getSkinsCellClassNameGen = (tieColorClassName: string, winColorClassName: string, skins?: SkinsScoringState[]) =>
        (hole: number): string => {
            if (!skins || !skins[hole % totalHoles]?.contacts?.length) {
                return '';
            }
            if (skins[hole % totalHoles].contacts.length === 1 && skins[hole % totalHoles].contacts[0].golferOrTeamId === contactInfo.id) {
                return ` ${winColorClassName}`;
            }
            if (skins[hole % totalHoles].contacts.findIndex(contactHcp => contactHcp.golferOrTeamId === contactInfo.id) > -1) {
                return ` ${tieColorClassName}`;
            }
            return '';
        }
    const elemClassName = (event.teamSize < (totalHoles === 9 ? 4 : 3) && !withHomeCourseOrCity) ?
        `${classes.portalListItemZebra} ${classes.height40}` : classes.portalListItemZebra;
    const scorecardClasses = {
        cellsInOut: classes.portalCellsInOut,
        cellsRoot: classes.portalCellsRoot,
        cells: classes.portalCells,
        cell: classes.portalCell,
        row: classes.portalRow
    };
    return (
        <ListItemButton className={elemClassName} style={{ width: fullHoleByHoleWidth }} onClick={onClick}>
            <Container wrap="nowrap">
                <Item width={30}>{pos}</Item>
                <Item width={holeByHoleNamesWidth}>{namesOnly}</Item>
                <ItemS>
                    <div className={classes.portalScorecardPanel}>
                        <ScorecardRow
                            label=""
                            inOutForm={2}
                            holesType={event.holesType}
                            gen={isNet ? netScoresGen : grossScoresGen}
                            classes={scorecardClasses}
                            skinsClassNameGen={(isSkins && skins) ? getSkinsCellClassNameGen(classes.skinTieBackground, classes.skinWinBackground, skins) : undefined}
                        />
                    </div>
                </ItemS>
            </Container>
        </ListItemButton>
    );
});

interface NoParticipantsRowProps {
    individual: boolean;
    width?: string | number;
    holeView?: boolean;
}

const NoParticipantsRow = ({ individual, width, holeView }: NoParticipantsRowProps) => {
    const isXs = useMediaQuery(useTheme().breakpoints.down('sm'));
    return (<div style={{
        minHeight: 40, width: width, backgroundColor: AppColors.webGrayLight, display: 'flex',
        justifyContent: 'center', alignContent: 'center', flexDirection: 'column'
    }}>
        <span style={{
            color: AppColors.webBlack, fontSize: 14, lineHeight: '21px', fontFamily: 'poppins, sans-serif',
            margin: '6px 10px', width: isXs && holeView ? window.innerWidth - 20 : 'auto'
        }}>
            {individual ? 'Golfers' : 'Teams'} will be visible here, once players are added or registered for the event.
        </span>
    </div>);
};

type StandingsViewModeButtonsProps = {
    holeView?: boolean;
    setHoleView: (value: boolean) => void;
    alignRight: boolean;
} & WithStyles<typeof styles>;

const commonSxProps: SxProps<Theme> | undefined = {
    width: '140px',
    height: '32px',
    padding: '6px 16px',
    gap: 10,
    '&:hover': { backgroundColor: AppColors.blue100 }
};

const StandingsViewModeButtons = withStyles(styles)((props: StandingsViewModeButtonsProps) => {
    const { holeView, classes, alignRight, setHoleView } = props;
    return (<div className={alignRight ? classes.marginLeftAuto : classes.marginBottom16}>
        <AppButton color={'info'} sx={{ ...commonSxProps, borderRadius: '8px 0px 0px 8px', backgroundColor: holeView ? AppColors.white : AppColors.blue100 }}
            onClick={() => setHoleView(false)}>Total scores</AppButton>
        <AppButton color={'info'} sx={{ ...commonSxProps, borderRadius: '0px 8px 8px 0px', backgroundColor: holeView ? AppColors.blue100 : AppColors.white }}
            onClick={() => setHoleView(true)}>Hole-by-hole</AppButton>
    </div>);
});

type SkinsCompetitionHeaderProps = {
    event: Event;
    competition: Competition;
    holeView?: boolean;
    setHoleView: (value: boolean) => void;
    compCounter: number;
    mainCompetition: Competition;
    skinsMixed?: boolean;
    infoState: ScoreCompetitionInfoState;
} & WithStyles<typeof styles>;

const CompetitionHeader = withStyles(styles)((props: SkinsCompetitionHeaderProps) => {
    const isXs = useMediaQuery(useTheme().breakpoints.down('sm'));
    const { classes, event, holeView, competition, setHoleView, mainCompetition, compCounter, infoState, skinsMixed } = props;
    const infoComponent = infoState === ScoreCompetitionInfoState.SCORING_NAME ? <ListTitle text={Scoring.scoringName(competition, event.eventGender, competition.competitionGender, skinsMixed)} />
        : infoState === ScoreCompetitionInfoState.LL_INFO ? <LeaderBoardInfo classes={classes} event={event} mainCompetition={mainCompetition} /> : '';
    return (isXs ? <Grid container direction="column">
        {compCounter === 1 && <StandingsViewModeButtons holeView={holeView} setHoleView={setHoleView} alignRight={false} />}
        <Box sx={{ paddingBottom: 1 }}>{infoComponent}</Box>
    </Grid> : <FlexGrid gridPadding={4}>
        {infoComponent}
        {compCounter === 1 && <StandingsViewModeButtons holeView={holeView} setHoleView={setHoleView} alignRight={true} />}
    </FlexGrid>)
});

const RowScoreSum = withStyles(styles)((props: RowScoreProps) => {
    const { classes, onClick, winning, sameNameGolfersIdsSet, player, roundScorecardLink, index } = props;
    const { pos, contactInfo, total, net, courseHandicap, relativeTotal, relativeNet, holes, stableford, stablefordNet } = props;
    const { event, competition, reportedScores, golfers, skinScore } = props;
    const scoring = competition.scoring;
    const holesRange = getHolesRange(event.holesType);
    const totalHoles = holesRange.last - holesRange.first;
    const disOrWit = contactInfo.withdrawn ? 'WD' : contactInfo.disqualified ? 'DQ' : undefined;
    const isNet = isNetMode(scoring.mode);
    const isGross = isGrossMode(scoring.mode);
    const bestBall = scoring.format === ScoringFormatTeams.best_ball;
    const isStableford = isStablefordScoringOrMode(scoring);
    const isSkins = isSkinsScoring(scoring);
    const relScore = !disOrWit ? Scoring.formatRelativeScore(isNet ? relativeNet : relativeTotal) : disOrWit;
    const thru = holes === totalHoles ? 'F' : holes;
    const courseHcp = courseHandicap == null || isNet == null ? '' : ` (${formatHandicap(courseHandicap)})`;
    const golferNames = (golfer: Contact) => {
        return (
            <Container>
                <ItemS>
                    <PersonIcon className={classes.textIcon} />
                    <Label paddingLeft={0}>
                        {fullName(golfer, 15, 25)}
                        <span className={classes.homeCourseOrCity}>
                            {golfer.homeCourseOrCity && sameNameGolfersIdsSet.has(golfer.id) ? ` (${golfer.homeCourseOrCity})` : ''}
                        </span>
                        {courseHcp}
                    </Label>
                </ItemS>
            </Container>
        );
    };
    const teamNames = (team: Team) => {
        const names = golferShortTeamNameArray(team, golfers, false);
        const contactIds = golfersOfTeam(team, golfers).map(c => c.id);
        const playingHandicaps = contactInfo.playingHandicaps.map(ci => ci == null || isNet == null ? '' : ` (${formatHandicap(ci)})`);
        const home = (idx: number) => sameNameGolfersIdsSet.has(contactIds[idx]) && golfers.get(contactIds[idx])?.homeCourseOrCity ? ` (${golfers.get(contactIds[idx])?.homeCourseOrCity})` : '';
        return !bestBall ?
            <Container>
                {names.map((name, idx) =>
                    <ItemS key={idx} style={{ marginRight: 16 }}>
                        {idx === 0 && <PersonsIcon className={classes.textIcon} />}
                        <Label paddingLeft={idx === 0 ? 0 : undefined}>
                            {name}
                            <span className={classes.homeCourseOrCity}>{home(idx)}</span>
                            {idx === names.length - 1 ? courseHcp : ''}
                        </Label>
                    </ItemS>)}
            </Container> :
            <Container>
                {names.map((name, idx) =>
                    <ItemS key={idx} style={{ marginRight: 16 }}>
                        {idx === 0 && <PersonsIcon className={classes.textIcon} />}
                        <Label paddingLeft={idx === 0 ? 0 : undefined}>
                            {name}
                            <span className={classes.homeCourseOrCity}>{home(idx)}</span>
                            {playingHandicaps[idx]}
                        </Label>
                    </ItemS>)}
            </Container>
    };
    const namesIcons = 'contactIds' in player ? teamNames(player) : golferNames(player);
    const minHeightFunc: (instance: HTMLDivElement | null) => void = listItemRef => {
        if (listItemRef?.className) {
            if (listItemRef.clientWidth === 1000 && !listItemRef.className.includes(classes.minHeight40)) {
                listItemRef.className = listItemRef.className.concat(' ', classes.minHeight40);
            }
        }
    };
    let imageClickFixed = false;
    const onLinkIconImageClicked = () => {
        imageClickFixed = true;
        if (roundScorecardLink) {
            window.open(roundScorecardLink, '_blank');
        }
    };
    const onListItemClicked = () => {
        if (!imageClickFixed && onClick) {
            onClick();
        }
    };
    const withWinnings = !!winning || winning === 0;
    const namesXs = (((isNet && withWinnings) ? 6 : (isNet || withWinnings) ? 7 : 8) + ((isSkins && isNet) ? 1 : 0)) as 6 | 7 | 8 | 9;
    const gridComponent = (
        <Container wrap="nowrap">
            <Item wide={Boolean(roundScorecardLink)} className={classes.flexRowContainer} xs={1} lg={1}>
                <div style={{ width: '100%', paddingRight: 2 }}>
                    {pos}{roundScorecardLink &&
                        <img onClick={onLinkIconImageClicked} className={classes.linkIconImage} src={linkIconImage}
                            alt='' />}
                </div>
            </Item>
            <Item xs={namesXs} lg={namesXs}>{namesIcons}</Item>
            <Item xs={1} lg={1}>{isSkins ? skinScore ?? 0 : relScore}</Item>
            <Item xs={1} lg={1}>{thru}</Item>
            {!(isSkins && isNet) && <Item xs={1} lg={1}>{!disOrWit ? total : disOrWit}</Item>}
            {!isStableford && isNet && <Item xs={1} lg={1}>{!disOrWit ? net : disOrWit}</Item>}
            {isStableford && isGross && <Item xs={1} lg={1}>{!disOrWit ? stableford : disOrWit}</Item>}
            {isStableford && isNet && <Item xs={1} lg={1}>{!disOrWit ? stablefordNet : disOrWit}</Item>}
            {(!!winning || winning === 0) && <Item xs={1}>{!disOrWit ? '$' + winning : disOrWit}</Item>}
        </Container>
    );
    const getListItem = (zebra: boolean = false) => (
        <ListItemButton
            key={contactInfo.id + zebra}
            ref={minHeightFunc}
            className={zebra ? classes.listItemZebra : undefined}
            onClick={onListItemClicked}>
            {gridComponent}
        </ListItemButton>
    );
    const [scoresUpdated, setScoresUpdated] = React.useState<boolean>(false);
    const onAnimationEnd = () => {
        if (scoresUpdated) {
            setScoresUpdated(false);
        }
    };
    const styledComponent = index % 2 ?
        <ContainerOdd onAnimationEnd={onAnimationEnd} className={classes.listItemZebra}>{getListItem()}</ContainerOdd> :
        <ContainerEven onAnimationEnd={onAnimationEnd} className={classes.listItemZebra}>{getListItem()}</ContainerEven>;
    const scoresToCheck = Scoring.getMonitoringReportedScoresArray(reportedScores, totalHoles, bestBall, contactInfo);
    useDidUpdateEffect(() => {
        if (!scoresUpdated) {
            setScoresUpdated(true);
        }
    }, scoresToCheck);
    return scoresUpdated ? styledComponent : getListItem(true);
});

const RowScore = withStyles(styles)((props: RowScoreProps & { holeView?: boolean }) => {
    const { holeView } = props;
    if (holeView) {
        return <RowScoreHoles {...props} />
    } else {
        return <RowScoreSum {...props} />
    }
});

const LDHeader = withStyles(styles)((props: WithStyles<typeof styles> & { withWinnings: boolean }) => {
    const { classes, withWinnings } = props;
    return (
        <ListItem className={classes.listItemHeader}>
            <Container wrap="nowrap">
                <Item xs={1}>Hole</Item>
                <Item xs={withWinnings ? 10 : 11}>Winner</Item>
                {withWinnings && <Item xs={1}>Purse</Item>}
            </Container>
        </ListItem>
    );
});

const LDRow = withStyles(styles)((props: HolesScoringState & WithStyles<typeof styles> & { winning?: number, sameNameGolfersIdsSet: Set<string> }) => {
    const { hole, contacts, classes, winning, sameNameGolfersIdsSet } = props;
    const contact: Contact | undefined = contacts[0];
    return (
        <ListItem className={classes.listItemZebra}>
            <Container wrap="nowrap">
                <Item xs={1}>{hole + 1}</Item>
                <ItemS xs={(!!winning || winning === 0) ? 10 : 11}>
                    {contact ?
                        <React.Fragment>
                            <PersonIcon className={classes.textIcon} />
                            <Label>
                                {fullName(contact)}
                            </Label>
                            <span className={classes.homeCourseOrCity}>
                                {contact.homeCourseOrCity && sameNameGolfersIdsSet.has(contact.id) ? ` (${contact.homeCourseOrCity})` : ''}
                            </span>
                        </React.Fragment> :
                        <Label className={classes.notSelected}>
                            {'Not selected'}
                        </Label>}
                </ItemS>
                {(!!winning || winning === 0) && <Item xs={1}>{'$' + winning}</Item>}
            </Container>
        </ListItem>
    );
});

const KPHeader = withStyles(styles)((props: WithStyles<typeof styles> & { withWinnings: boolean }) => {
    const { classes, withWinnings } = props;
    return (
        <ListItem className={classes.listItemHeader}>
            <Container wrap="nowrap">
                <Item xs={1}>Hole</Item>
                <Item xs={withWinnings ? 8 : 9}>Winner</Item>
                <Item xs={2}>Distance</Item>
                {withWinnings && <Item xs={1}>Purse</Item>}
            </Container>
        </ListItem>
    );
});

const KPRow = withStyles(styles)((props: { units?: Units } & HolesScoringState & WithStyles<typeof styles> & { winning?: number, sameNameGolfersIdsSet: Set<string> }) => {
    const { units, hole, score, contacts, classes, winning, sameNameGolfersIdsSet } = props;
    const contact: Contact | undefined = contacts[0];
    return (
        <ListItem className={classes.listItemZebra}>
            <Container wrap="nowrap">
                <Item xs={1}>{hole + 1}</Item>
                <ItemS xs={(!!winning || winning === 0) ? 8 : 9}>
                    {contact ?
                        <React.Fragment>
                            <PersonIcon className={classes.textIcon} />
                            <Label>
                                {fullName(contact)}
                            </Label>
                            <span className={classes.homeCourseOrCity}>
                                {contact.homeCourseOrCity && sameNameGolfersIdsSet.has(contact.id) ? ` (${contact.homeCourseOrCity})` : ''}
                            </span>
                        </React.Fragment> :
                        <Label className={classes.notSelected}>
                            {'Not selected'}
                        </Label>}
                </ItemS>
                <Item xs={2}>{formatDistance(units, score)}</Item>
                {(!!winning || winning === 0) && <Item xs={1}>{'$' + winning}</Item>}
            </Container>
        </ListItem>
    );
});

export const SkinsHeader = withStyles(styles)((props: { scoring: ScoringData } & WithStyles<typeof styles> & { withWinnings: boolean }) => {
    const { classes, scoring, withWinnings } = props;
    const isNet = isNetMode(scoring.mode);
    return (
        <ListItem className={classes.listItemHeader}>
            <Container wrap="nowrap">
                <ItemX xs={1}>Hole</ItemX>
                <ItemX xs={1}>Par</ItemX>
                <ItemX xs={1}>Lowest<br /> {isNet ? 'net' : 'gross'}</ItemX>
                <ItemX xs={withWinnings ? 8 : 9}>Golfer(s)</ItemX>
                {withWinnings && <ItemX xs={1}>Purse</ItemX>}
            </Container>
        </ListItem>
    );
});

const ItemX = withStyles(styles)((props: WithStyles<typeof styles> & { xs?: GridSize, children?: string | React.ReactNode }) => {
    const { xs, children } = props;
    return (
        <Item xs={xs}>
            <div style={{ display: 'flex', flexWrap: 'wrap', whiteSpace: 'break-spaces' }}>{children}</div>
        </Item>
    );
});

type SkinsRowProps = {
    scoring: ScoringData,
    teamSize: number,
    sameNameGolfersIdsSet: Set<string>,
    winning?: number
}

export const SkinsRow = withStyles(styles)((props: SkinsRowProps & SkinsScoringState & WithStyles<typeof styles>) => {
    const { classes, scoring, hole, par, score, contacts, teamSize, winning, sameNameGolfersIdsSet } = props;
    const isNet = isNetMode(scoring.mode);
    const oneWinner = contacts.length === 1;
    const namesIcons = teamSize > 1 ? (
        <Container>
            {contacts.map((contact, idx) =>
                <ItemS key={idx}>
                    <PersonsIcon className={classes.textIcon} />
                    <Label>
                        {contact.contacts.map((c, idx) => <span key={idx}>
                            {fullName(c)}
                            <span className={classes.homeCourseOrCity}>
                                {c?.homeCourseOrCity && sameNameGolfersIdsSet.has(c.id) ? ` (${c.homeCourseOrCity})` : ''}
                            </span>
                            {contact.contacts.length - 1 !== idx ? ', ' : ''}
                        </span>)}
                        {contact.courseHandicap == null || isNet == null ? '' : ` (${formatHandicap(contact.courseHandicap)})`}
                    </Label>
                </ItemS>)}
        </Container>
    ) : (
        <Container>
            {contacts.map((contact, idx) =>
                <ItemS key={idx}>
                    <PersonIcon className={classes.textIcon} />
                    <Label>
                        {fullName(contact.contacts[0])}
                        <span className={classes.homeCourseOrCity}>
                            {sameNameGolfersIdsSet.has(contact.contacts[0].id) && contact.contacts[0].homeCourseOrCity ? ` (${contact.contacts[0].homeCourseOrCity})` : ''}
                        </span>
                        {contact.courseHandicap == null || isNet == null ? '' : ` (${formatHandicap(contact.courseHandicap)})`}
                    </Label>
                </ItemS>)}
        </Container>
    );
    return (
        <ListItem className={oneWinner ? classes.winnerItem : classes.listItem} style={{ color: 'black' }}>
            <Container wrap="nowrap">
                <ItemX xs={1}>{hole + 1}</ItemX>
                <ItemX xs={1}>{par}</ItemX>
                <ItemX xs={1}>{score === null ? '' : score}</ItemX>
                <ItemX xs={(!!winning || winning === 0) ? 8 : 9}>{namesIcons}</ItemX>
                {(!!winning || winning === 0) && <ItemX xs={1}>{'$' + winning}</ItemX>}
            </Container>
        </ListItem>
    );
});

type HeaderPayoutProps = {
    splitMainCompetitions: Array<Competition>,
    splitSideCompetitions: Array<Competition>,
    event: Event,
    nameWidth: NameWidth,
    competitionWidth: 1 | 2,
    skinsMixed: boolean
};

const HeaderPayout = withStyles(styles)((props: HeaderPayoutProps & WithStyles<typeof styles>) => {
    const { classes, splitMainCompetitions, splitSideCompetitions, event, nameWidth, competitionWidth, skinsMixed } = props;
    let individualCompetitionPresent = false;
    let teamCompetitionPresent = false;
    splitMainCompetitions.forEach(comp => {
        individualCompetitionPresent = individualCompetitionPresent || isIndividualScoring(comp.scoring);
        teamCompetitionPresent = teamCompetitionPresent || isTeamFormat(comp.scoring);
    });
    const teamOrGolfer = (individualCompetitionPresent && teamCompetitionPresent) ? 'Team/Golfer' :
        teamCompetitionPresent ? 'Team' : 'Golfer';
    return (
        <ListItem className={classes.listItemHeader} style={{ height: 52 }}>
            <Container>
                <ItemX xs={nameWidth}>{teamOrGolfer}</ItemX>
                {splitMainCompetitions.map((comp, index) => <ItemX xs={competitionWidth} key={index}>
                    {Scoring.scoringName(comp, event.eventGender, comp.competitionGender, skinsMixed)}
                </ItemX>)}
                {splitSideCompetitions.map((comp, index) => <ItemX xs={competitionWidth} key={index}>
                    {Scoring.scoringName(comp, event.eventGender, comp.competitionGender, skinsMixed)}
                </ItemX>)}
                <ItemX xs={1}>Total</ItemX>
            </Container>
        </ListItem>
    );
});

type NameWidth = 8 | 1 | 2 | 5 | 4 | 3 | 9 | 6 | 7 | 10;

type RowPayoutProps = {
    splitMainCompetitions: Array<Competition>,
    splitSideCompetitions: Array<Competition>,
    sameNameGolfersIdsSet: Set<string>,
    golfers: Map<string, Contact>,
    nameWidth: NameWidth,
    competitionWidth: 1 | 2
};

const RowPayout = withStyles(styles)((props: ContactPayoutState & WithStyles<typeof styles> & RowPayoutProps) => {
    const { names, total, classes, awards, player, sameNameGolfersIdsSet, golfers, splitMainCompetitions, splitSideCompetitions, nameWidth, competitionWidth } = props;
    const namesIcons: JSX.Element | string = names.length > 0 && 'contactIds' in player ?
        (<Container>
            {names.map((name, idx) =>
                <ItemS key={idx}>
                    {idx === 0 && <PersonsIcon className={classes.textIcon} />}
                    <Label>
                        {name}
                        <span className={classes.homeCourseOrCity}>
                            {sameNameGolfersIdsSet.has(player.contactIds[idx]) && golfers.get(player.contactIds[idx])?.homeCourseOrCity ? ` (${golfers.get(player.contactIds[idx])?.homeCourseOrCity})` : ''}
                        </span>
                    </Label>
                </ItemS>)}
        </Container>)
        : 'lastName' in player ?
            (<Container>
                {names.map((name, idx) =>
                    <ItemS key={idx}>
                        <PersonIcon className={classes.textIcon} />
                        <Label>
                            {name}
                            <span className={classes.homeCourseOrCity}>
                                {player.homeCourseOrCity && sameNameGolfersIdsSet.has(player.id) ? ` (${player.homeCourseOrCity})` : ''}
                            </span>
                        </Label>
                    </ItemS>)}
            </Container>)
            : '';
    return (
        <ListItemButton className={classes.listItemZebra} >
            <Container wrap="nowrap">
                <ItemX xs={nameWidth}>{namesIcons}</ItemX>
                {splitMainCompetitions.map((comp, index) => <ItemX xs={competitionWidth} key={index}>
                    {'$' + (awards.has(comp.id + comp.scoring.mode) ? formatCurrency(awards.get(comp.id + comp.scoring.mode)!) : '0')}
                </ItemX>)}
                {splitSideCompetitions.map((comp, index) => <ItemX xs={competitionWidth} key={index}>
                    {'$' + (awards.has(comp.id + (isSkinsScoring(comp.scoring) ? comp.scoring.mode : '')) ? formatCurrency(awards.get(comp.id + (isSkinsScoring(comp.scoring) ? comp.scoring.mode : ''))!) : '0')}
                </ItemX>)}
                <ItemX xs={1}>{'$' + formatCurrency(total)}</ItemX>
            </Container>
        </ListItemButton>
    );
});



type PaidOutRowProps = {
    payoutStates: ContactPayoutState[],
    titleWidth: NameWidth,
    competitionWidth: 1 | 2,
    competitions: Competition[],
    golfers: Map<string, Contact>,
    teams: Map<string, Team>,
    event: Event,
    golferScores: Map<string, Score>,
    teamScores: Map<string, Score>,
    reportedScores: Map<string, ReportedScore>,
    reportedTeamScores: Map<string, ReportedScore>,
    groups: Array<GolferGroup>
};

const PaidOutRow = withStyles(styles)((props: WithStyles<typeof styles> & PaidOutRowProps) => {
    const { classes, titleWidth, competitionWidth, payoutStates, competitions, golfers, teams, event, golferScores, teamScores, reportedScores, reportedTeamScores, groups } = props;
    if (!payoutStates || payoutStates.length < 1 || payoutStates[0]?.awards?.size < 1) {
        return null;
    }
    const competitionIds = competitions.map(comp => isMainScoring(comp.scoring) ? (comp.id + comp.scoring.mode) : comp.id);
    let wasRed = false;
    let paidOutTotal: number = 0;
    const paidOutComponent = competitionIds.map(id => {
        let competitionPaidOutSum: number = 0;
        for (const payoutState of payoutStates) {
            competitionPaidOutSum += payoutState.awards?.get(id) ?? 0;
        }
        const competition = competitions.find(comp => id === (isMainScoring(comp.scoring) ? (comp.id + comp.scoring.mode) : comp.id))!;
        const purse = getFullCompetitionPurse(competition, golfers, teams, event, competitions, golferScores, teamScores, reportedScores, reportedTeamScores, groups);
        competitionPaidOutSum = round(competitionPaidOutSum, 2);
        const significantPayoutAndPurseDifference = (Math.abs(purse - competitionPaidOutSum) > (0.005 * purse)); // to avoid difference as a result of rounding, (0.5%)
        wasRed = wasRed || significantPayoutAndPurseDifference;
        paidOutTotal += competitionPaidOutSum;
        return <ItemX key={id} xs={competitionWidth} children={<Typography className={significantPayoutAndPurseDifference ? classes.redText : undefined}>${competitionPaidOutSum}</Typography>} />;
    });
    return (
        <ListItemButton className={classes.listItemZebra}>
            <Container wrap="nowrap">
                <ItemX xs={titleWidth}><Typography style={{ margin: 'auto' }}>Paid out</Typography></ItemX>
                {paidOutComponent}
                <ItemX xs={1}><Typography className={wasRed ? classes.redText : undefined}>${round(paidOutTotal, 2)}</Typography></ItemX>
            </Container>
        </ListItemButton>
    );
});

type PurseRowProps = {
    competitions: Competition[],
    titleWidth: NameWidth,
    competitionWidth: 1 | 2,
    golfers: Map<string, Contact>,
    teams: Map<string, Team>,
    event: Event,
    golferScores: Map<string, Score>,
    teamScores: Map<string, Score>,
    reportedScores: Map<string, ReportedScore>,
    reportedTeamScores: Map<string, ReportedScore>,
    groups: Array<GolferGroup>
};

const PurseRow = withStyles(styles)((props: WithStyles<typeof styles> & PurseRowProps) => {
    const { classes, titleWidth, competitions, competitionWidth, golfers, teams, event, golferScores, teamScores, reportedScores, reportedTeamScores, groups } = props;
    let totalPurse: number = 0;
    const purseComponent = competitions.map((competition, index) => {
        const competitionPurse: number = getFullCompetitionPurse(competition, golfers, teams, event, competitions, golferScores, teamScores, reportedScores, reportedTeamScores, groups);
        totalPurse += competitionPurse;
        return <ItemX key={index} xs={competitionWidth}><Typography>${competitionPurse}</Typography></ItemX>;
    });
    return (
        <ListItemButton className={classes.listItemZebra}>
            <Container wrap="nowrap">
                <ItemX xs={titleWidth}>Purse</ItemX>
                {purseComponent}
                <ItemX xs={1}>${round(totalPurse, 2)}</ItemX>
            </Container>
        </ListItemButton>
    );
});

type LeaderBoardInfoProps = {
    classes: Record<string, string>,
    event: Event,
    mainCompetition?: Competition
}

const LeaderBoardInfo = ({ classes, event, mainCompetition }: LeaderBoardInfoProps) => {
    const { courseName, eventDate, teeTimeMode, competitionName } = getEventInfo(event, mainCompetition);
    const dateTimeAndCompetitionClassName = classes.infoBaseLL + ' ' + classes.dateTimeAndCompetitionLL;
    return (<div>
        <div className={classes.infoBaseLL + ' ' + classes.courseNameLL}>{courseName}</div>
        <div className={classes.paddingBottom4}>
            <span className={dateTimeAndCompetitionClassName + ' ' + classes.darkGrey}>{`${eventDate}${(teeTimeMode ? ` ${teeTimeMode}` : '')}`}</span>
            <span className={`${dateTimeAndCompetitionClassName} ${classes.grey} ${classes.paddingLeft6}`}>{competitionName}</span>
        </div>
    </div>);
}

type DetailedSkinsInfoProps = {
    competition: Competition,
    golfers: Map<string, Contact>,
    teams: Map<string, Team>,
    skins: SkinsScoringState[],
    withWinnings: boolean,
    sameNameGolfersIdsSet: Set<string>,
    skinsMixed: boolean,
    event: Event
}

export const SkinsDetailedInfo = withStyles(styles)((props: DetailedSkinsInfoProps & WithStyles<typeof styles>) => {
    const { competition, withWinnings, golfers, teams, skins, sameNameGolfersIdsSet, event, skinsMixed, classes } = props;
    return (
        <List disablePadding>
            <ListTitle text={<Typography className={classes.modeListTitle}>{Scoring.scoringName(competition, event.eventGender, competition.competitionGender, skinsMixed)}</Typography>} />
            <SkinsHeader scoring={competition.scoring} withWinnings={withWinnings} />
            {skins.map(skin => <SkinsRow teamSize={event.teamSize} scoring={competition.scoring}
                key={skin.hole} {...skin}
                winning={withWinnings ? skinCompetitionAward(skin, competition, skins, golfers, teams) : undefined}
                sameNameGolfersIdsSet={sameNameGolfersIdsSet} />)}
        </List>
    );
});

interface StandingsProps {
    event: Event;
}

interface State {
    competitions: Array<Competition>;
    golferScores: Map<string, Score>;
    teamScores: Map<string, Score>;
    reportedScores?: Map<string, ReportedScore>;
    reportedTeamScores?: Map<string, ReportedScore>;
    calculatedScores: Map<string, CalculatedScores>;
    golfers: Map<string, Contact>;
    teams: Map<string, Team>;
    groups: Array<GolferGroup>;
    distances: Map<string, Distance>;
    openedScore?: ContactScoringState & { competition: Competition, skinsGross?: SkinsScoringState[], skinsNet?: SkinsScoringState[] };
    units?: Units;
    holeView?: boolean;
    mainCompetition?: Competition;
    roundScorecardLink?: string;
    holeByHoleNamesWidth: number;
    fullHoleByHoleWidth: number;
    loads: boolean[]
}

type Props = StandingsProps & WithStyles<typeof styles>;

class Standings extends React.Component<Props, State> {
    state: State;
    private compCounter = 0;
    private readonly totalHoles: number;

    constructor(props: Props) {
        super(props);
        this.totalHoles = getTotalHoles(props.event.holesType);
        this.state = {
            golferScores: new Map<string, Score>(),
            teamScores: new Map<string, Score>(),
            calculatedScores: new Map<string, CalculatedScores>(),
            golfers: new Map<string, Contact>(),
            groups: [],
            teams: new Map<string, Team>(),
            competitions: [],
            distances: new Map<string, Distance>(),
            holeByHoleNamesWidth: this.totalHoles === 18 ? holeByHoleNamesWidth18 : holeByHoleNamesWidth9,
            fullHoleByHoleWidth: holeByHoleWidth,
            loads: new Array(8).fill(false)
        };
    }

    private setPropertyLoaded = (ind: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7) => {
        const { loads } = this.state;
        loads[ind] = true;
        return loads;
    }

    private onGroups = (groups: Array<GolferGroup>) => this.setState({ groups, loads: this.setPropertyLoaded(0) }, this.getGolferScores);
    private onGolfers = (golfers: Map<string, Contact>) => this.setState({ golfers, loads: this.setPropertyLoaded(1) }, this.getGolferScores);
    private onDistances = (distances: Map<string, Distance>) => this.setState({ distances });
    private onTeamScores = (teamScores: Map<string, Score>) => this.setState({ teamScores, loads: this.setPropertyLoaded(2) }, this.getGolferScores);
    private onGolferScores = (golferScores: Map<string, Score>) => this.setState({ golferScores, loads: this.setPropertyLoaded(3) }, this.getGolferScores);
    private onCompetitions = (competitions: Array<Competition>) => this.setState({ competitions, loads: this.setPropertyLoaded(6), mainCompetition: getEventMainCompetition(competitions) }, this.getGolferScores);
    private setHoleView = (value: boolean) => {
        const { holeView } = this.state;
        if (value === holeView) return;
        if (value) {
            const holeByHoleNamesWidth = this.totalHoles === 18 ? holeByHoleNamesWidth18 :
                window.innerWidth >= holeByHoleWidth ? holeByHoleNamesWidth9 :
                    Math.max(holeByHoleNamesWidth18, holeByHoleNamesWidth9 - (holeByHoleWidth - window.innerWidth));
            const fullHoleByHoleWidth = (this.totalHoles === 9 && window.innerWidth < holeByHoleWidth) ?
                (holeByHoleWidth - holeByHoleNamesWidth9 + holeByHoleNamesWidth) : holeByHoleWidth;
            this.setState({ holeView: true, holeByHoleNamesWidth, fullHoleByHoleWidth });
        } else {
            this.setState({ holeView: false });
        }
    }

    private onTeams = (allTeams: Map<string, Team>) => {
        const { golfers } = this.state;
        const teams = new Map(
            Array.from(allTeams.entries())
                .filter(entry => entry[1].contactIds.some(golferId => golfers.has(golferId)))
        );
        setTeamHandicapIndexes(golfers, teams);
        this.setState({ teams, loads: this.setPropertyLoaded(7) }, this.getGolferScores);
    }
    
    private onReportedGolferScores = (reportedScores: Map<string, ReportedScore>) => {
        const { event } = this.props;
        if (!event.hideLiveScores || event.hideLiveScores === 'ON' || !this.state.reportedScores) {
            this.setState({ reportedScores, loads: this.setPropertyLoaded(4) }, this.getGolferScores);
        }
    }
    
    private onReportedTeamScores = (reportedTeamScores: Map<string, ReportedScore>) => {
        const { event } = this.props;
        if (!event.hideLiveScores || event.hideLiveScores === 'ON' || !this.state.reportedTeamScores) { 
            this.setState({ reportedTeamScores, loads: this.setPropertyLoaded(5) }, this.getGolferScores);
        } 
    }

    private getGolferScores = () => {
        const { event } = this.props;
        const { competitions, reportedScores, reportedTeamScores, golferScores, teamScores, groups, golfers, teams, mainCompetition, loads } = this.state;
        if (loads.every(val => val)) {
            const calculatedScores = Scoring.getGolferScores(event, competitions, golfers, teams, groups, golferScores, teamScores, reportedScores || new Map<string, ReportedScore>(), reportedTeamScores || new Map<string, ReportedScore>(), mainCompetition);
            this.setState({ calculatedScores });
        }
    }
    
    Competition = (params: { competition: Competition }) => {
        const { competition } = params;
        return (
            <React.Fragment>
                {isDistanceScoring(competition.scoring) ? this.DistanceCompetition(competition) :
                    isSkinsScoring(competition.scoring) ? this.Skins(competition) : this.Scores(competition)}
            </React.Fragment>
        );
    }

    Skins = (competition: Competition) => {
        const { mode } = competition.scoring;
        return (
            <React.Fragment>
                {isNetMode(mode) && this.SkinsCompetition(Scoring.netCompetition(competition))}
                {isGrossMode(mode) && this.SkinsCompetition(Scoring.grossCompetition(competition))}
            </React.Fragment>
        );
    }

    Scores = (competition: Competition) => {
        const { mode } = competition.scoring;
        return (
            <React.Fragment>
                {isNetMode(mode) && this.ScoreCompetition(Scoring.netCompetition(competition))}
                {isGrossMode(mode) && this.ScoreCompetition(Scoring.grossCompetition(competition))}
            </React.Fragment>
        );
    }

    DistanceCompetition = (competition: Competition) => {
        if (competition.scoring.format === ScoringFormatDistance.longest_drive) {
            return this.LDCompetition(competition);
        } else {
            return this.KPCompetition(competition);
        }
    }

    LDCompetition = (competition: Competition) => {
        const { event } = this.props;
        const { distances, golfers, mainCompetition } = this.state;
        if (!mainCompetition) {
            return null;
        }
        const gender = genderFromEvent(event);
        const distanceInfo = getDistanceScores(event, competition, golfers, distances, getTee(mainCompetition, gender));
        const isNet = isNetMode(competition.scoring.mode);
        const payoutSettings: PayoutSettings | undefined | null = isNet && competition.payoutsNet ? competition.payoutsNet[0] : competition.payoutsGross ? competition.payoutsGross[0] : undefined;
        const sameNameGolfersIdsSet: Set<string> = getSameNameGolfersIds(Array.from(golfers.values()));
        const payoutPerHole = payoutSettings?.payoutPerHole;
        const payoutsEligible = Boolean(payoutSettings?.enabled) && payoutPerHole != null && payoutPerHole >= 0;
        return (
            <React.Fragment>
                <ListTitle text={Scoring.scoringName(competition)} />
                <List disablePadding>
                    <LDHeader withWinnings={payoutsEligible && distanceInfo.findIndex(info => info.contacts && info.contacts.length > 0) > -1} />
                    {distanceInfo.map(info => <LDRow key={info.hole} {...info} winning={(payoutsEligible && info.contacts && info.contacts.length > 0) ? payoutPerHole : undefined} sameNameGolfersIdsSet={sameNameGolfersIdsSet} />)}
                </List >
                <Spacing />
            </React.Fragment>
        );
    }

    KPCompetition = (competition: Competition) => {
        const { event } = this.props;
        const { units, distances, golfers, mainCompetition } = this.state;
        if (!mainCompetition) {
            return null;
        }
        const gender = genderFromEvent(event);
        const distanceInfo = getDistanceScores(event, competition, golfers, distances, getTee(mainCompetition, gender));
        const isNet = isNetMode(competition.scoring.mode);
        const payoutSettings: PayoutSettings | undefined | null = isNet && competition.payoutsNet ? competition.payoutsNet[0] : competition.payoutsGross ? competition.payoutsGross[0] : undefined;
        const payoutPerHole = payoutSettings?.payoutPerHole;
        const sameNameGolfersIdsSet: Set<string> = getSameNameGolfersIds(Array.from(golfers.values()));
        const payoutsEligible = Boolean(payoutSettings?.enabled) && payoutPerHole != null && payoutPerHole >= 0;
        return (
            <React.Fragment>
                <ListTitle text={Scoring.scoringName(competition)} />
                <List disablePadding>
                    <KPHeader withWinnings={payoutsEligible && distanceInfo.findIndex(info => info.contacts && info.contacts.length > 0) > -1} />
                    {distanceInfo.map(info => <KPRow key={info.hole} {...info} units={units} winning={(payoutsEligible && info.contacts && info.contacts.length > 0) ? payoutPerHole : undefined} sameNameGolfersIdsSet={sameNameGolfersIdsSet} />)}
                </List >
                <Spacing />
            </React.Fragment>
        );
    }

    SkinsCompetition = (competition: Competition) => {
        const { event, classes } = this.props;
        const { golferScores, teamScores, reportedScores, reportedTeamScores, groups, golfers, teams, competitions,
            mainCompetition, holeView, holeByHoleNamesWidth, fullHoleByHoleWidth, loads } = this.state;
        if (!mainCompetition || !isCompatibleCompetitionSkins(competition, mainCompetition) || !loads.every(val => val)) {
            return null;
        }
        const competitionScores = Scoring.getGolferScores(event, [competition], golfers, teams, groups, golferScores, teamScores, reportedScores || new Map<string, ReportedScore>(), reportedTeamScores || new Map<string, ReportedScore>(), mainCompetition)?.get(competition.id);
        if (!competitionScores) {
            return null;
        }
        const isNet = isNetMode(competition.scoring.mode);
        const scores: ContactScoringState[] | undefined = isNet ? competitionScores.competitionScoresNet.get(0) : competitionScores.competitionScoresGross.get(0);
        if (!scores) {
            return null;
        }
        this.compCounter++;
        const isGross = isGrossMode(competition.scoring.mode);
        const gender = genderFromEvent(event);
        const skinsMixed = !!competitions.find(comp => comp.scoring.format === ScoringFormatTeams.best_ball);
        const tee = getTee(mainCompetition, gender);
        const skins: SkinsScoringState[] = getSkinsScores(event, competition, scores, tee);
        const noTees = !hasTees(event, mainCompetition);
        const withWinnings = (isNetPayouts(competition) || isGrossPayouts(competition))
            && isFullScoresCompetition(event, competition, golferScores, teamScores, reportedScores || new Map<string, ReportedScore>(), golfers, teams);
        const sameNameGolfersIdsSet: Set<string> = getSameNameGolfersIds(Array.from(golfers.values()));
        const withLLInfo = event.leaderboard && competition.id === mainCompetition.id;
        const infoState: ScoreCompetitionInfoState = withLLInfo ? ScoreCompetitionInfoState.LL_INFO : ScoreCompetitionInfoState.SCORING_NAME;
        const skinsScoresMap: Map<string, number> = new Map<string, number>();
        const skinsAwardsMap: Map<string, number> = new Map<string, number>();
        const addPlayersAward = (playerId: string, hole: number) => {
            const skin = getActualSkin(hole, getHolesRange(event.holesType), skins);
            if (skin) {
                skinsAwardsMap.set(playerId, (skinsAwardsMap.get(playerId) ?? 0) + skinCompetitionAward(skin,
                    competition, skins, golfers, teams)
                );
            }
        };
        processSkins(skins, skinsScoresMap, competition, true, scores, undefined, withWinnings ? addPlayersAward : undefined);
        const isTeam = isTeamFormatExceptBB(competition.scoring);
        return (
            <React.Fragment>
                <CompetitionHeader infoState={infoState} competition={competition} mainCompetition={mainCompetition}
                    event={event} holeView={holeView} setHoleView={this.setHoleView}
                    compCounter={this.compCounter} skinsMixed={skinsMixed} />
                {noTees ?
                    <Typography color="error">
                        This competition has not been set up by event administrator yet
                    </Typography> :
                    <List disablePadding>
                        <Header
                            scoring={competition.scoring}
                            withWinnings={withWinnings}
                            holeView={holeView}
                            event={event}
                            holeByHoleNamesWidth={holeByHoleNamesWidth}
                            fullHoleByHoleWidth={fullHoleByHoleWidth}
                        />
                        {scores.length > 0 ? scores.map((score, index) => <RowScore
                            key={score.contactInfo.id}
                            {...score}
                            winning={withWinnings ? (round(skinsAwardsMap.get(score.contactInfo.id) ?? 0, 2)) : undefined}
                            holeView={holeView}
                            event={event}
                            teams={teams}
                            golfers={golfers}
                            competition={competition}
                            reportedScores={isTeam ? reportedTeamScores || new Map<string, ReportedScore>(): reportedScores || new Map<string, ReportedScore>()}
                            scores={isTeam ? teamScores : golferScores}
                            onClick={() => this.setState({
                                openedScore: {
                                    competition, ...score, skinsGross: isGross ? skins : undefined,
                                    skinsNet: isNet ? skins : undefined
                                }, roundScorecardLink: getRoundScorecardLink(golfers.get(score.contactInfo.id))
                            })}
                            sameNameGolfersIdsSet={sameNameGolfersIdsSet}
                            mainCompetition={mainCompetition}
                            index={index}
                            roundScorecardLink={getRoundScorecardLink(golfers.get(score.contactInfo.id))}
                            skinScore={skinsScoresMap.get(score.contactInfo.id)}
                            skins={skins}
                            holeByHoleNamesWidth={holeByHoleNamesWidth}
                            fullHoleByHoleWidth={fullHoleByHoleWidth} />) :
                            <NoParticipantsRow holeView={holeView}
                                width={holeView ? fullHoleByHoleWidth : 'auto'}
                                individual={!isTeam} />}
                    </List>}
                {(isTeam && teams.size > 0) || golfers.size > 0 && <div className={classes.flexRowContainer} style={{ marginTop: 8 }}>
                    {holeView && <div className={`${classes.flexRowContainer} ${classes.skinColorsExplanation}`}>
                        <img className={classes.size26x26} src={skinsTieImage} alt='' />
                        <Typography className={`${classes.shiftedExplanations} ${classes.width100}`}>Tied scores</Typography>
                        <img className={classes.size26x26} src={skinsWinImage} alt='' />
                        <Typography className={`${classes.shiftedExplanations} ${classes.width160}`}>Skin winning scores</Typography>
                    </div>}
                    <AppButton color='info' className={classes.skinsSummaryBtn} onClick={() => showAlert(
                        <div className={classes.minWidth640}>
                            <SkinsDetailedInfo competition={competition} event={event} golfers={golfers}
                                teams={teams} skins={getSkinsScores(event, competition, scores, tee)}
                                sameNameGolfersIdsSet={getSameNameGolfersIds(Array.from(golfers.values()))}
                                skinsMixed={skinsMixed} withWinnings={withWinnings} />
                            <Spacing backgroundColor={'white'} />
                        </div>,
                        [{ title: 'Close', }], undefined, undefined, false, 'Skins summary'
                    )}>Skins summary</AppButton>
                </div>}
                <Spacing />
            </React.Fragment>
        );
    }

    ScoreCompetition = (competition: Competition) => {
        const { mainCompetition } = this.state;
        if (!mainCompetition) {
            return null;
        }
        if (!competition.flights || competition.flights === 0) {
            return this.ScoreCompetitionFlight(competition, 0, mainCompetition);
        } else {
            return <React.Fragment>{range(1, competition.flights + 1).map(flight => this.ScoreCompetitionFlight(competition, flight, mainCompetition))}</React.Fragment>;
        }
    }

    ScoreCompetitionFlight = (competition: Competition, flight: number, mainCompetition: Competition) => {
        const { calculatedScores } = this.state;
        const competitionScores = calculatedScores.get(competition.id);
        if (!competitionScores) {
            return null;
        }
        const isNet = isNetMode(competition.scoring.mode);
        const scores: ContactScoringState[] | undefined = isNet ? competitionScores.competitionScoresNet.get(flight) : competitionScores.competitionScoresGross.get(flight);
        if (!scores) {
            return null;
        }
        const { event, classes } = this.props;
        const { golferScores, teamScores, reportedScores, reportedTeamScores, golfers, teams, holeView, holeByHoleNamesWidth, fullHoleByHoleWidth } = this.state;
        const noTees = !hasTees(event, competition) && !event.leaderboard;
        const winnings = this.getWinnings(competition, flight);
        const payouts = getPayouts(competition, competition.scoring.mode, flight);
        const flightName = flight > 0 ? <Typography noWrap gutterBottom={true} variant="subtitle2">{getFlightName(flight, competition.flightsNaming).toUpperCase()}</Typography> : null;
        const withWinnings = !!payouts && payouts.enabled && isFullScoresCompetition(event, competition, golferScores, teamScores, reportedScores || new Map<string, ReportedScore>(), golfers, teams);
        this.compCounter++;
        const sameNameGolfersIdsSet: Set<string> = getSameNameGolfersIds(Array.from(golfers.values()));
        const withLLInfo = event.leaderboard && competition.id === mainCompetition.id;
        const infoState: ScoreCompetitionInfoState = withLLInfo ? (flight < 2 ? ScoreCompetitionInfoState.LL_INFO : ScoreCompetitionInfoState.NONE) : ScoreCompetitionInfoState.SCORING_NAME;
        const isTeamFormatExceptBB = isTeamScoringExceptBB(competition.scoring);
        return (
            <React.Fragment key={competition.id + competition.scoring.mode + flight}>
                <CompetitionHeader infoState={infoState} competition={competition} mainCompetition={mainCompetition}
                    event={event} holeView={holeView} setHoleView={this.setHoleView}
                    compCounter={this.compCounter} />
                {flightName}
                {noTees ?
                    <Typography color="error">This competition has not been set up by event administrator yet</Typography> :
                    <div className={classes.editscoreScrollPanel}>
                        <List disablePadding>
                            <Header
                                scoring={competition.scoring}
                                withWinnings={withWinnings}
                                holeView={holeView}
                                event={event}
                                holeByHoleNamesWidth={holeByHoleNamesWidth}
                                fullHoleByHoleWidth={fullHoleByHoleWidth}
                            />
                            {scores.length > 0 ? scores.map((score, index) => <RowScore
                                key={score.contactInfo.id}
                                {...score}
                                winning={withWinnings ? winnings.get(score.contactInfo.id) : undefined}
                                holeView={holeView}
                                event={event}
                                teams={teams}
                                golfers={golfers}
                                competition={competition}
                                reportedScores={isTeamFormatExceptBB ? reportedTeamScores || new Map<string, ReportedScore>() : reportedScores || new Map<string, ReportedScore>()}
                                scores={isTeamFormatExceptBB ? teamScores : golferScores}
                                onClick={() => this.setState({
                                    openedScore: { competition, ...score },
                                    roundScorecardLink: getRoundScorecardLink(golfers.get(score.contactInfo.id))
                                })}
                                sameNameGolfersIdsSet={sameNameGolfersIdsSet}
                                mainCompetition={mainCompetition}
                                index={index}
                                roundScorecardLink={getRoundScorecardLink(golfers.get(score.contactInfo.id))}
                                holeByHoleNamesWidth={holeByHoleNamesWidth}
                                fullHoleByHoleWidth={fullHoleByHoleWidth} />) :
                                <NoParticipantsRow holeView={holeView}
                                    width={holeView ? fullHoleByHoleWidth : 'auto'}
                                    individual={!isTeamFormatExceptBB} />}
                        </List>
                    </div>}
                <Spacing />
            </React.Fragment>
        );
    }

    PayoutsStates = () => {
        const { golferScores, teamScores, reportedScores, reportedTeamScores, groups, golfers, teams, competitions, distances } = this.state;
        const { event } = this.props;
        const payoutStates: ContactPayoutState[] = eventPayoutsStates(event, competitions, golferScores, teamScores, reportedScores || new Map<string, ReportedScore>(), reportedTeamScores || new Map<string, ReportedScore>(), golfers, teams, groups, distances);
        const showPayoutsExplanation = !isFullScoresCompetitions(event, competitions, golferScores, teamScores, reportedScores || new Map<string, ReportedScore>(), golfers, teams);
        const sameNameGolfersIdsSet: Set<string> = getSameNameGolfersIds(Array.from(golfers.values()));
        const skinsMixed: boolean = competitions.some(competition => competition.scoring.format === ScoringFormatTeams.best_ball);
        const [splitMainCompetitions, splitSideCompetitions] = getSplitCompetitionsWithPayouts(competitions);
        const competitionWidth = (splitMainCompetitions.length + splitSideCompetitions.length) > 3 ? 1 : 2;
        const nameWidth = (11 - (splitMainCompetitions.length + splitSideCompetitions.length) * competitionWidth) as NameWidth;
        const allCompetitions = splitMainCompetitions.concat(splitSideCompetitions);
        return (
            <React.Fragment>
                <ListTitle text={'Money list'} />
                <List disablePadding>
                    <HeaderPayout
                        event={event}
                        splitMainCompetitions={splitMainCompetitions}
                        splitSideCompetitions={splitSideCompetitions}
                        nameWidth={nameWidth}
                        competitionWidth={competitionWidth}
                        skinsMixed={skinsMixed} />
                    {payoutStates.map(payout => <RowPayout
                        key={payout.player.id}
                        {...payout}
                        splitMainCompetitions={splitMainCompetitions}
                        splitSideCompetitions={splitSideCompetitions}
                        sameNameGolfersIdsSet={sameNameGolfersIdsSet}
                        golfers={golfers}
                        nameWidth={nameWidth}
                        competitionWidth={competitionWidth} />)}
                    <PaidOutRow
                        payoutStates={payoutStates}
                        titleWidth={nameWidth}
                        competitionWidth={competitionWidth}
                        competitions={allCompetitions}
                        golfers={golfers}
                        teams={teams}
                        event={event}
                        golferScores={golferScores}
                        teamScores={teamScores}
                        reportedScores={reportedScores || new Map<string, ReportedScore>()}
                        reportedTeamScores={reportedTeamScores || new Map<string, ReportedScore>()}
                        groups={groups} />
                    <PurseRow
                        competitions={allCompetitions}
                        titleWidth={nameWidth}
                        competitionWidth={competitionWidth}
                        golfers={golfers}
                        teams={teams}
                        event={event}
                        golferScores={golferScores}
                        teamScores={teamScores}
                        reportedScores={reportedScores || new Map<string, ReportedScore>()}
                        reportedTeamScores={reportedTeamScores || new Map<string, ReportedScore>()}
                        groups={groups} />
                </List>
                {showPayoutsExplanation && event.teamSize === 1 && <Typography>
                    Payouts will be calculated for individual competitions after all scores are posted.
                </Typography>}
                {showPayoutsExplanation && event.teamSize > 1 && <Typography>
                    Payouts will be calculated for team competitions after all scores are posted.
                </Typography>}
                <Spacing />
            </React.Fragment>
        );
    }

    HideLiveScores = () => {
        return (
            <React.Fragment>
                <ListTitle text={'Live scoring is currently disabled by event administrator'} wrap />
            </React.Fragment>
        );
    }

    getWinnings = (competition: Competition, flight: number) => {
        const { event } = this.props;
        const { golferScores, teamScores, reportedScores, reportedTeamScores, groups, golfers, teams, mainCompetition } = this.state;
        const winnings = new Map<string, number>();
        if (!flight || flight == 0) {
            if (isTeamFormat(competition.scoring)) {
                if (competition.scoring.format !== ScoringFormatTeams.best_ball) {
                    teamsOf(competition, golfers, teams).forEach(team => winnings.set(team.id, teamScores.has(team.id) ? golferMainCompetitionAward(team.id, event, competition, golferScores, teamScores, reportedScores || new Map<string, ReportedScore>(), reportedTeamScores || new Map<string, ReportedScore>(), golfers, teams, groups, mainCompetition) : 0));
                } else {
                    teamsOf(competition, golfers, teams).forEach(team => winnings.set(team.id, team.contactIds.filter(cid => golfers.has(cid)).reduce((arr, curr) => arr.length > 0 || !golferScores.has(curr) ? curr : '', '').length === 0 ? golferMainCompetitionAward(team.id, event, competition, golferScores, teamScores, reportedScores || new Map<string, ReportedScore>(), reportedTeamScores || new Map<string, ReportedScore>(), golfers, teams, groups, mainCompetition) : 0));
                }
            } else {
                golfersOfCompetition(competition, golfers, teams).forEach(contact => winnings.set(contact.id, golferScores.has(contact.id) || reportedScores?.has(contact.id) ? golferMainCompetitionAward(contact.id, event, competition, golferScores, teamScores, reportedScores || new Map<string, ReportedScore>(), reportedTeamScores || new Map<string, ReportedScore>(), golfers, teams, groups, mainCompetition) : 0));
            }
        } else {
            if (isTeamFormat(competition.scoring)) {
                if (competition.scoring.format !== ScoringFormatTeams.best_ball) {
                    teamsOfFlight(competition, golfers, teams, flight).forEach(team => winnings.set(team.id, teamScores.has(team.id) && teamScores.get(team.id) ? golferMainCompetitionAward(team.id, event, competition, golferScores, teamScores, reportedScores || new Map<string, ReportedScore>(), reportedTeamScores || new Map<string, ReportedScore>(), golfers, teams, groups, mainCompetition) : 0));
                } else {
                    teamsOfFlight(competition, golfers, teams, flight).forEach(team => winnings.set(team.id, team.contactIds.filter(cid => golfers.has(cid)).reduce((arr, curr) => arr.length > 0 || !golferScores.has(curr) ? curr : '', '').length === 0 ? golferMainCompetitionAward(team.id, event, competition, golferScores, teamScores, reportedScores || new Map<string, ReportedScore>(), reportedTeamScores || new Map<string, ReportedScore>(), golfers, teams, groups, mainCompetition) : 0));
                }
            } else {
                contactsOfFlight(competition, golfers, teams, flight).forEach(contact => winnings.set(contact.id, golferScores.has(contact.id) ? golferMainCompetitionAward(contact.id, event, competition, golferScores, teamScores, reportedScores || new Map<string, ReportedScore>(), reportedTeamScores || new Map<string, ReportedScore>(), golfers, teams, groups, mainCompetition) : 0));
            }
        }
        return winnings;
    }

    render() {
        const { event, classes } = this.props;
        const { openedScore, competitions, golfers, teams, roundScorecardLink, loads } = this.state;
        const competitionsMain = competitions.filter(competition => isMainScoring(competition.scoring));
        const competitionsSideGames = competitions.filter(competition => !isMainScoring(competition.scoring));
        const withMoneyList: boolean = competitions.some(competition => isNetPayouts(competition) || isGrossPayouts(competition));
        const hideLiveScores = event.hideLiveScores === true || event.hideLiveScores === 'OFF';
        this.compCounter = 0;
        const mobileMode = document.documentElement.clientWidth < 500;
        return (
            <Box style={mobileMode ? { padding: '0px 10px' } : undefined}>
                {hideLiveScores && <this.HideLiveScores />}
                {!hideLiveScores && competitionsMain.map(competition => <this.Competition key={competition.id} competition={competition} />)}
                {!hideLiveScores && competitionsSideGames.map(competition => <this.Competition key={competition.id} competition={competition} />)}
                {!hideLiveScores && withMoneyList && <this.PayoutsStates />}
                {!hideLiveScores && !competitions.length && loads.every(val => val) && <Typography className={classes.portalInfoNotSet}>
                    Golfers will be visible here, once the scoring format has been selected.
                </Typography>}
                {!!openedScore && <PortalEditScoreDialog
                    open={true}
                    teams={teams}
                    readonly={true}
                    golfers={golfers}
                    roundScorecardLink={roundScorecardLink}
                    {...openedScore}
                    close={() => this.setState({ openedScore: undefined })} />}
                <FirebaseDataComponent query={Backend.query(Backend.golferDb(event.id), Backend.where('hidden', '==', false))} onMap={this.onGolfers} />
                <FirebaseDataComponent query={Backend.golferTeamDb(event.id)} onMap={this.onTeams} />
                <FirebaseDataComponent query={Backend.golferTeamScoresDb(event.id)} onMap={this.onTeamScores} />
                <FirebaseDataComponent query={Backend.golferScoresDb(event.id)} onMap={this.onGolferScores} />
                <FirebaseDataComponent query={Backend.reportedGolferScoresDb(event.id)} onMap={this.onReportedGolferScores} />
                <FirebaseDataComponent query={Backend.reportedTeamScoresDb(event.id)} onMap={this.onReportedTeamScores} />
                <FirebaseDataComponent query={Backend.golferDistancesDb(event.id)} onMap={this.onDistances} />
                <FirebaseDataComponent query={Backend.query(Backend.competitionsDb(event.id), Backend.orderBy('order'))} onData={this.onCompetitions} />
                <FirebaseDataComponent query={Backend.query(Backend.golferGroupDb(event.id), Backend.orderBy('order'))} onData={this.onGroups} />
                <FirebaseUserPubDataComponent uid={event.userId} onData={data => this.setState({ units: data.units })} />
            </Box>
        );
    }
}

export default withStyles(styles)(Standings);
