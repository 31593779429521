import * as React from 'react';
import { ReactNode, SyntheticEvent } from 'react';
import { FormControl } from '@mui/material';
import { InputLabel } from '@mui/material';
import { showAlert } from '../../redux/ReduxConfig';

const styles = {
    link: {
        outline: 'none'
    }
};

interface FormLinkWithDialogProps {
    text: string;
    msg: string | ReactNode;
}

class FormLinkWithDialog extends React.Component<FormLinkWithDialogProps> {

    handleClick = (e: SyntheticEvent<any>) => {
        e.preventDefault();
        e.stopPropagation();
        const msg = (this.props.msg);
        showAlert(msg);
    }

    render() {
        const { text } = this.props;
        return (
            <FormControl variant="standard" fullWidth={true}>
                <InputLabel shrink={true}>
                    <a href="/" onClick={this.handleClick} style={styles.link}>{text}</a>
                </InputLabel>
            </FormControl>
        );
    }
}

export default FormLinkWithDialog;