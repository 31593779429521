import { AnyAction, Reducer } from 'redux';
import { Action, withKey } from './ReduxCommon';
import { isOfType } from './ReduxCommon';
import * as React from 'react';
import { DialogContent, CircularProgress } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import { HandledDialog } from '../common/dialog/BackHandler';

interface ProgressState {
    requests: Set<number>;
}

const DEFAULT_STATE: ProgressState = {
    requests: new Set()
};

const PROGRESS_TYPE = 'progress';

export interface ProgressAction extends Action {
    visible: boolean;
    token: number;
    all?: boolean;
}

export function showProgressAction(token: number): ProgressAction {
    return { type: PROGRESS_TYPE, visible: true, token: token };
}

export function hideProgressAction(token: number): ProgressAction {
    return { type: PROGRESS_TYPE, visible: false, token: token };
}

export function hideAllProgressAction(): ProgressAction {
    return { type: PROGRESS_TYPE, visible: false, token: 0, all: true };
}

export const progressReducer: Reducer<ProgressState> = (state: ProgressState | undefined, action: AnyAction) => {
    if (state && isOfType<ProgressAction>(action, PROGRESS_TYPE)) {
        const copy = { requests: new Set(state.requests) };
        if (action.visible) {
            copy.requests.add(action.token);
        } else if (action.all) {
            copy.requests.clear();
        } else {
            copy.requests.delete(action.token);
        }
        return copy;
    }
    return state || DEFAULT_STATE;
};

const styles = {
    paper: {
        background: 'none',
        boxShadow: 'none'
    },
    progress: {
        display: 'flex',
        '& > * + *': {
            marginLeft: 6,
        },
    },
};

class ProgressDialogInner extends React.Component<ProgressState & WithStyles<'paper' | 'progress'>, { visible: boolean }> {
    state = { visible: false };
    private timeoutId?: any;

    shouldComponentUpdate(newProps: ProgressState) {
        let update = false;
        if (this.props.requests.size === 0 && newProps.requests.size > 0) {
            this.setState({ visible: false });
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }
            this.timeoutId = setTimeout(() => this.setState({ visible: true }), 2000);
            update = true;
        } else if (this.props.requests.size > 0 && newProps.requests.size === 0 && this.timeoutId) {
            clearTimeout(this.timeoutId);
            this.timeoutId = undefined;
            update = true;
        }
        return update;
    }

    render() {
        const { classes, requests } = this.props;
        return (
            <HandledDialog
                onClose={() => false} open={requests.size > 0}
                slotProps={{
                    backdrop: { invisible: !this.state.visible }
                }}
                classes={{ paper: classes.paper }}>
                <DialogContent>
                    <div className={classes.progress}>
                        <CircularProgress color="secondary" />
                    </div>
                </DialogContent>
            </HandledDialog>
        );
    }
}

export const ProgressDialog = withStyles(styles)(connect(withKey<ProgressState>('progressReducer'))(ProgressDialogInner));
