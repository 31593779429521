import * as React from 'react';
import { DialogContent, Typography, FormControl, Grid } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import DoneIcon from '@mui/icons-material/CheckCircle';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { InfoIcon } from '../../common/Icons';
import {
    Event, Team, Contact, Competition, Tee, PayoutSettings, isCompoundCourse, getEventMainCompetition,
    isTeamScoring, isSkinsScoring, getParticipantsCount, getPayoutSettings
} from '../../types/EventTypes';
import { SMMobileDialog } from '../../common/dialog/MobileDialog';
import AppButton from '../../common/components/AppButton';
import ListGridItem from '../../common/components/ListGridItem';
import DialogAppBar from '../../common/dialog/DialogAppBar';
import { AppColors } from '../../main/Theme';
import { showAlert, showProgress } from '../../redux/ReduxConfig';
import EditTeeDialog from '../../event/tabs/common/EditTeeDialog';
import { loadCourseTees } from '../Courses';
import { createSideGame, saveCompetitions } from '../Event';
import CompetitionSettingsDialog from '../../event/tabs/scores/CompetitionSettingsDialog';
import CompetitionPayoutSettingsDialog from '../../event/tabs/scores/CompetitionPayoutSettingsDialog';
import * as Backend from '../../util/firebase';

const dlgStyles = (_theme: Theme) => {
    return {
        title: {
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        titleSmall: {
            paddingLeft: 16,
            paddingRight: 16,
            paddingBottom: 20
        }
    }
};

type Props = {
    event: Event;
    participantsOk: boolean;
    scheduleOk: boolean;
    teesOk: boolean;
    customize: boolean;
    handleClose: (target?: string) => void;
} & WithStyles<typeof dlgStyles>;

interface State {
    golfers: Map<string, Contact>;
    teams: Map<string, Team>;
    competitions: Array<Competition>;
    mainCompetition?: Competition;
    tees: Array<Tee>;
    editingTee?: Tee;
    sideGame?: Competition;
    editPayout?: PayoutSettings;
}

class CompleteEventSetupDialog extends React.Component<Props, State> {
    state: State = {
        golfers: new Map<string, Contact>(),
        teams: new Map<string, Team>(),
        competitions: [],
        tees: []
    };

    private mounted: boolean = false;
    private controller = new AbortController();

    componentDidMount() {
        this.mounted = true;
        this.loadData();
    }

    componentWillUnmount() {
        this.mounted = false;
        this.controller.abort();
    }

    public loadData = async () => {
        if (!this.mounted) {
            return;
        }
        const { event } = this.props;
        const hideProgress = showProgress();
        try {
            const [competitions, golfers, teams] = await Promise.all([
                Backend.getEntities<Competition>(Backend.competitionsDb(event.id)),
                Backend.mapEntities<Contact>(Backend.query(Backend.golferDb(event.id), Backend.where('hidden', '==', false))),
                Backend.mapEntities<Team>(Backend.golferTeamDb(event.id))
            ]);
            const mainCompetition = getEventMainCompetition(competitions);
            this.setState({ golfers, teams, competitions, mainCompetition });
            if (event.course) {
                const tees = await loadCourseTees(event.userId, event.course, false, false, undefined, this.controller);
                this.setState({ tees });
            }
        } finally {
            hideProgress();
        }
    }

    private handleAddSideGame = () => {
        const { event } = this.props;
        if (event.course) {
            this.setState({ sideGame: createSideGame(this.state.competitions) })
        } else {
            showAlert('Course not selected');
        }
    }

    private onSideGameAdded = async (competition: Competition, _competitionOld: Competition, _resetScores: boolean, _resetDistance: boolean, changes: Map<string, string>) => {
        const { event } = this.props;
        const { competitions } = this.state;
        this.setState({ sideGame: undefined });
        const changedCompetitions = [competition];
        await saveCompetitions(changedCompetitions, competitions, changes, event, false);
    }

    private handleAddPayout = () => {
        const { mainCompetition } = this.state;
        if (mainCompetition) {
            const { payoutSettings } = getPayoutSettings(mainCompetition);
            this.setState({ editPayout: payoutSettings });
        }
    }

    private onPayoutsChanged = async (payoutSettings: PayoutSettings) => {
        const { event } = this.props;
        const { competitions, mainCompetition } = this.state;
        if (mainCompetition) {
            payoutSettings.enabled = true;
            mainCompetition.payoutsNet[0] = payoutSettings;
            mainCompetition.payoutsGross[0] = payoutSettings;
            const changes = new Map<string, string>();
            changes.set('payouts', 'changed');
            const changedCompetitions = [mainCompetition];
            await saveCompetitions(changedCompetitions, competitions, changes, event, false);
        }
        this.setState({ editPayout: undefined });
    }

    render() {
        const { classes, event, participantsOk, scheduleOk, teesOk, handleClose, customize } = this.props;
        const { golfers, teams, competitions, mainCompetition, tees, editingTee, sideGame, editPayout } = this.state;
        const title1 = customize ? 'Customize event' : 'Complete Event setup';
        const title2 = customize ? 'Steps to customize event setup:' : 'Steps to complete event setup:';
        const label = <div className={classes.title}>{title1}</div>;
        const facility = event.course && !isCompoundCourse(event.course) ? event.course : undefined;
        const statusIcon = (ok: boolean) => ok ? <DoneIcon color="primary" /> : <InfoIcon htmlColor={AppColors.webWarning} />;
        return (
            <SMMobileDialog open={true} onClose={() => handleClose()} maxWidth={'md'} fullWidth>
                <DialogAppBar bar close={handleClose} label={label} toolbarBarStyle={{ padding: 0, marginLeft: 16, marginRight: 16 }} />
                <DialogAppBar>
                    <span className={classes.titleSmall}>{event.name}</span>
                </DialogAppBar>
                <DialogContent>
                    <FormControl variant="standard" margin="dense" fullWidth>
                        <Grid container>
                            <Grid item>
                                <Typography variant="h6" gutterBottom>{title2}</Typography>
                            </Grid>
                            <ListGridItem icon={statusIcon(participantsOk)}
                                text1="Participants"
                                text2="Add golfers: manual entry, import roster or set up registration."
                                button={<AppButton color="secondary" onClick={() => handleClose(`/events/${event.id}/golfers/list`)}>Add golfers <ArrowForwardIcon /></AppButton>} />
                            <ListGridItem icon={statusIcon(scheduleOk)}
                                text1="Schedule"
                                text2="Set tee times, hole assignments, group size."
                                button={<AppButton color="secondary" onClick={() => handleClose(`/events/${event.id}/golfers/schedule`)}>Set up schedule <ArrowForwardIcon /></AppButton>} />
                            <ListGridItem icon={statusIcon(teesOk)}
                                text1="Default tees"
                                text2="Set tees for the course. You can always override tees for individual golfers from the golfers list."
                                button={<AppButton color="secondary" onClick={() => this.setState({ editingTee: { name: '' } as Tee })} disabled={!facility}>Add tees</AppButton>} />
                            <Grid item>
                                <Typography variant="h6" gutterBottom>Optional settings to enhance your Event:</Typography>
                            </Grid>
                            <Grid item>
                                <AppButton onClick={() => handleClose(`/events/${event.id}/settings/portal`)} color="info">Edit event portal <ArrowForwardIcon /></AppButton> &nbsp;
                                <AppButton onClick={this.handleAddPayout} color="info" disabled={!mainCompetition}>Add a payout</AppButton> &nbsp;
                                <AppButton onClick={this.handleAddSideGame} color="info">Add side game</AppButton>
                            </Grid>
                        </Grid>
                    </FormControl>
                </DialogContent>
                {editingTee && facility && <EditTeeDialog
                    event={event}
                    facility={facility}
                    tee={editingTee}
                    tees={tees}
                    close={() => this.setState({ editingTee: undefined })} />}
                {sideGame && <CompetitionSettingsDialog
                    open={true}
                    teams={teams}
                    golfers={golfers}
                    hasScores={false}
                    competitions={competitions}
                    mainCompetition={mainCompetition}
                    competition={sideGame}
                    isNewCompetition={true}
                    handleSave={this.onSideGameAdded}
                    handleClose={() => this.setState({ sideGame: undefined })}
                    handleDelete={() => { }}
                    event={event} />}
                {editPayout && mainCompetition && <CompetitionPayoutSettingsDialog
                    event={event}
                    open={true}
                    participantCount={getParticipantsCount(mainCompetition, golfers, teams)}
                    isTeam={isTeamScoring(mainCompetition.scoring)}
                    isSkins={isSkinsScoring(mainCompetition.scoring)}
                    payoutSettings={editPayout}
                    competition={mainCompetition}
                    handleSave={this.onPayoutsChanged}
                    onClose={() => this.setState({ editPayout: undefined })} />}
            </SMMobileDialog>
        );
    }
}

export default withStyles(dlgStyles)(CompleteEventSetupDialog);
