import React from 'react';
import {
    Grow, Paper, Divider, Checkbox, Typography, MenuItem, MenuList, List, ListItem, Popper, Tooltip, useMediaQuery,
    useTheme, ListItemButton, Box
} from '@mui/material';
import { CSVLink } from 'react-csv';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import DropDownArrowIcon from '@mui/icons-material/ArrowDropDown';
import { Theme } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import * as Utils from '../../../../util/utility';
import * as Backend from '../../../../util/firebase';
import { WithUserId, withUserId } from '../../../../auth/Auth';
import {
    Event, EventData, Contact, ContactDetails, Tee, isMainScoring, isNetMode, ResultStatus, PaymentInfo,
    USGAGolferInfo, Distance, Score, ReportedScore
} from '../../../../types/EventTypes';
import {
    ACTION_GOLFER_MODIFIED, ACTION_GOLFER_ADDED, ACTION_ADD_FROM_ROSTER, MENS_TEE, WOMENS_TEE
} from '../../../../types/EventTypes';
import {
    formatHandicap, fullLastName, compareContactsBy, equalContactData, newContact, contactFrom, contactFromRoaster,
    getSameNameGolfersIds
} from '../../../../contact/Contact';
import {
    deleteGolfersFromEvent, updateGolfersPlayingHandicap, saveContact, saveContacts, saveContactDetails,
    updateGolferFeePaidStatus
} from '../../../Event';
import AddGolfersFromRosterDialog from '../../common/AddGolfersFromRosterDialog';
import GolfersEmailDialog from '../../common/GolfersEmailDialog';
import EditTeamsDialog from './EditTeamsDialog';
import EditContactDialog from '../../../../contact/EditContactDialog';
import ImportContactDialog from '../../../../contact/import/ImportContactDialog';
import { Result } from '../../../../contact/import/Importer';
import ImportReviewDialog from '../../../../contact/import/ImportReviewDialog';
import ButtonBar from '../../../../common/components/ButtonBar';
import AppButton from '../../../../common/components/AppButton';
import SelectTeesComponent from '../../common/SelectTeesComponent';
import { Container, Item, EditIcon } from '../../../../common/Misc';
import { styles } from '../../../../styles';
import { InfoIcon } from '../../../../common/Icons';
import { errMsg } from "../../../../util/firebase";
import axios from "axios";
import { Urls } from "../../../../util/config";
import { isValidHCPIndex } from "../../../../scoring/handicap";
import { makeParameterizedGetRequestUrl } from "../../../../util/utility";
import { showReportedGolfersDeletionAlert } from "../../../../util/common_alerts";
import { defaultAxiosRequestConfig } from "../../../../util/axios_utils";
import { InfoElement } from '../../../../redux/ReduxConfig';
import { AppColors } from '../../../../main/Theme';
import TeesProvider from "../../common/TeesProvider";
import { FirebaseDataComponent } from "../../../../common/WithData";
import { SendPaymentMessageDialog } from "../../../../payments/paypal/SendPaymentMessageDialog";
import { EmailVariant, sendPaymentMessage } from "../../../../util/email_utils";
import CurrencyList from "currency-list";
import { PaidStatusSelectionComponent } from "../../../../payments/PaidStatusSelectionComponent";

const USGALabelVertical = '/img/USGAWordMarkVertical.svg';

export async function refreshGHINHandicaps(event: Event, contacts: ContactDetails[]) {
    const filteredContacts = contacts.filter(contact => contact.handicapId?.length);
    const contactsWithHandicapIdMap = new Map<string, ContactDetails>(
        filteredContacts.map(contact => [contact.handicapId!, contact])
    ); // assuming handicap ids are individual for golfers
    const url = makeParameterizedGetRequestUrl<string>(
        Urls.handicapGHINRequestUrl,
        'handicapId',
        Array.from<string>(contactsWithHandicapIdMap.keys())
    );
    try {
        const response = await axios.get(url, defaultAxiosRequestConfig);
        if (response.status === 200 && response.data.golfersInfo) {
            const golfersInfo = response.data.golfersInfo;
            for (const handicapId in golfersInfo) {
                const contact = contactsWithHandicapIdMap.get(handicapId);
                const golferInfo = golfersInfo[handicapId] as USGAGolferInfo;
                if (golferInfo && isValidHCPIndex(golferInfo.handicapIndex) && contact) {
                    contact.handicapIndex = golferInfo.handicapIndex;
                }
            }
        }
        await saveContacts(event, filteredContacts, ACTION_GOLFER_MODIFIED);
    } catch (err) {
        console.log(errMsg((err)));
    }
}

interface ContactItemProps {
    contact: ContactDetails;
    clickHandler: (onRevokeSuccess: () => void, contact: ContactDetails) => void;
    cbClickHandler: (contact: ContactDetails) => void;
    selected: boolean;
    withTee?: boolean;
    withHomeCourseOrCity?: boolean;
    zeroHandicapIconAllowed: boolean;
    withGHINImg: boolean;
    linkedFromApp: boolean;
    paymentInfo?: PaymentInfo;
    onRevokeSuccess: () => void;
    showPaymentInfo?: boolean;
    feePaid?: boolean;
    handleSendMessage: (contact: Contact) => void;
    handleFeePaidStatusUpdate: (feePaid: boolean) => Promise<void>;
}

type ContactItemHeaderProps = {
    selectedAll: boolean;
    withTee?: boolean;
    showPaymentInfo?: boolean;
    paymentColumnEnabled?: boolean;
    sortCol?: string;
    asc?: boolean;
    selectAll: (checked: boolean) => void;
    onRequestClick: () => void;
    onSortColumn: (column: string) => void;
} & WithStyles<typeof styles>;

const ContactItemHeader = withStyles(styles)((props: ContactItemHeaderProps) => {
    const { withTee, selectedAll, showPaymentInfo, paymentColumnEnabled, classes, onRequestClick, sortCol, asc } = props;
    const checkBox = <Checkbox color="secondary" checked={selectedAll} onChange={(e: any, v: any) => props.selectAll(v)} disableRipple />;
    const rightIcon = <EditIcon invisible />;
    const nameXs = 6 - ((withTee ? 1 : 0) + (showPaymentInfo ? 2 : 0));
    const sortIcon = <DropDownArrowIcon className={classes.textIcon + ' ' + (asc !== false ? classes.rotate180 : '')} />;
    return (
        <div>
            <ListItem className={classes.listItemHeaderWhite}>
                <Container wrap="nowrap">
                    <Item xs={nameXs} noWrap variant="body2" onClick={() => { props.onSortColumn('name'); }}>{checkBox} Name {sortCol === 'name' || sortCol === undefined ? sortIcon : undefined}</Item>
                    <Item xs={3} noWrap variant="body2" onClick={() => { props.onSortColumn('email'); }}>Email {sortCol === 'email' ? sortIcon : undefined}</Item>
                    <Item xs={2} noWrap variant="body2" onClick={() => { props.onSortColumn('index'); }}>Index {sortCol === 'index' ? sortIcon : undefined}</Item>
                    {showPaymentInfo && <Item noWrap variant="body2">
                        Entry fee&nbsp;<span onClick={onRequestClick} style={{
                            color: paymentColumnEnabled ? AppColors.blue500 : AppColors.webGray200, fontWeight: 400,
                            cursor: paymentColumnEnabled ? 'pointer' : 'unset'
                        }}>(Request)</span>
                    </Item>}
                    {withTee && <Item xs={1} noWrap variant="body2" onClick={() => { props.onSortColumn('tee'); }}>Tee {sortCol === 'tee' ? sortIcon : undefined}</Item>}
                    <Item xs={1} noWrap variant="body2" placeRight className={classes.colorWhite}>{rightIcon}</Item>
                </Container>
            </ListItem>
            <Divider />
            <Divider />
        </div>
    );
});

const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 10,
        lineHeight: '150%',
        color: '#343434',
        boxShadow: theme.shadows[1],
        borderRadius: 12
    },
}))(Tooltip);

const ContactItem = withStyles(styles)((props: ContactItemProps & WithStyles<typeof styles>) => {
    const { selected, contact, classes, withTee, withHomeCourseOrCity, zeroHandicapIconAllowed, paymentInfo,
        linkedFromApp, withGHINImg, showPaymentInfo, feePaid, cbClickHandler, clickHandler, onRevokeSuccess,
        handleSendMessage, handleFeePaidStatusUpdate
    } = props;
    const rightIcon = <EditIcon />;
    const decorations = classes.listItem + (selected ? (' ' + classes.selected) : '');
    const checkBox = (
        <Checkbox color="secondary" checked={selected} style={{ backgroundColor: 'inherit', color: 'inherit', }}
            onClick={e => { e.preventDefault(); e.stopPropagation(); cbClickHandler(contact); }} disableRipple />
    );
    let email = contact.email;
    const handicapItem = formatHandicap(contact.handicapIndex) ?? (zeroHandicapIconAllowed && <div className={classes.flexCenteredRow21px}>0<InfoIcon className={classes.smallerShiftedIcon} htmlColor={AppColors.webWarning} /></div>);
    const teeName = contact.tee?.name ?? 'Default';
    const isXs = useMediaQuery(useTheme().breakpoints.down('sm'));
    const nameXs = 6 - ((withTee ? 1 : 0) + (showPaymentInfo ? 2 : 0));
    const currencySymbol = paymentInfo?.currencyCode ? (CurrencyList.get(paymentInfo.currencyCode).symbol ?? '$') : '$';
    const currencyString = currencySymbol.length > 1 ? currencySymbol + ' ' : currencySymbol;
    return (
        <div>
            <ListItemButton className={decorations} onClick={() => clickHandler(onRevokeSuccess, contact)}>
                <Container wrap="nowrap">
                    <Item xs={nameXs} paddingRight={4}>
                        <div className={classes.flexCenteredRow21px}>{checkBox}{fullLastName(contact)}
                            <span className={classes.homeCourseOrCity}>
                                {(withHomeCourseOrCity && contact.homeCourseOrCity) ? ` (${contact.homeCourseOrCity})` : ''}
                            </span>
                            {linkedFromApp &&
                                <LightTooltip disableInteractive placement={'right'} title="Connected with the golfers Golf Pad app">
                                    <img className={classes.connectedPhoneIcon} src={Urls.connectedPhoneIcon} alt={''} />
                                </LightTooltip>}
                        </div>
                    </Item>
                    <Item xs={3} paddingRight={4}>{email}</Item>
                    <Item xs={2}>{withGHINImg ? <div className={classes.flexCenteredRow21px}>
                        <span>{handicapItem}</span>
                        <LightTooltip disableInteractive placement="bottom"
                            title="Received from USGA information network">
                            <img style={{ marginLeft: isXs ? 4 : 8, width: 16, height: 16 }}
                                 src={USGALabelVertical} alt="" />
                        </LightTooltip>
                    </div> : handicapItem}</Item>
                    {showPaymentInfo && <Item xs={2}>{paymentInfo?.platform === 'PayPal' ?
                        <Box display="flex" alignItems="center" height={20}>
                            <span style={{ fontWeight: 500 }}>Paid</span>
                            <LightTooltip placement="right" title={`Paid ${currencyString}${paymentInfo.feeCost} with PayPal${paymentInfo.platformFee ? ` (including ${currencyString}${paymentInfo.platformFee} PayPal fee)` : ''}`}>
                                <img className={classes.connectedPhoneIcon} src={Urls.iconPayPalLogo} alt="" />
                            </LightTooltip>
                        </Box> :
                        <PaidStatusSelectionComponent feePaid={feePaid} contactEmail={contact.email}
                                                      styles={{ fontSize: '14px', lineHeight: '21px', fontWeight: 500 }}
                                                      disableUnderline
                                                      handleSendMessage={() => handleSendMessage(contact)}
                                                      handleFeePaidStatusUpdate={handleFeePaidStatusUpdate} />}
                    </Item>}
                    {withTee && <Item xs={1}>{teeName}</Item>}
                    <Item xs={1} placeRight className={classes.colorWhite}>{rightIcon}</Item>
                </Container>
            </ListItemButton>
            <Divider />
        </div>
    );
});

interface State {
    importingFromRoster: boolean;
    editingTeam: number;
    importingFromSpreadsheet: boolean;
    actionChangeTeeOpen: boolean;
    actionMenuOpen: boolean;
    actionNewEmailOpen: boolean;
    selectMenuOpen: boolean;
    importMenuOpen: boolean;
    editedContact?: ContactDetails;
    selectedGolfersMap: Map<string, ContactDetails>;
    selectedAll: boolean;
    importResult?: Result;
    handleTeamExport: boolean;
    handleGolferExport: boolean;
    anchorElSelect: (EventTarget & HTMLElement) | undefined;
    anchorElAction: (EventTarget & HTMLElement) | undefined;
    anchorElImport: (EventTarget & HTMLElement) | undefined;
    onRevokeSuccess: () => void;
    tees?: Tee[];
    payments?: Map<string, PaymentInfo>;
    golferScores: Map<string, Score>;
    teamScores: Map<string, Score>;
    reportedScores: Map<string, ReportedScore>;
    reportedTeamScores: Map<string, ReportedScore>;
    distances: Map<string, Distance>;
    sortCol?: string;
    asc?: boolean;
}

type Props = { event: Event; eventData: EventData; } & WithStyles<typeof styles> & WithUserId;

class GolfersList extends React.Component<Props, State> {
    private readonly teesLoader: React.RefObject<TeesProvider> = React.createRef();
    state: State = {
        importingFromRoster: false,
        editingTeam: -1,
        importingFromSpreadsheet: false,
        actionMenuOpen: false,
        actionNewEmailOpen: false,
        selectMenuOpen: false,
        importMenuOpen: false,
        actionChangeTeeOpen: false,
        selectedGolfersMap: new Map<string, Contact>(),
        selectedAll: false,
        handleGolferExport: false,
        handleTeamExport: false,
        anchorElSelect: undefined,
        anchorElAction: undefined,
        anchorElImport: undefined,
        onRevokeSuccess: () => { },
        golferScores: new Map<string, Score>(),
        teamScores: new Map<string, Score>(),
        reportedScores: new Map<string, ReportedScore>(),
        reportedTeamScores: new Map<string, ReportedScore>(),
        distances: new Map<string, Distance>()
    };

    componentDidMount() {
        Backend.trackEvent('view_golfers');
    }

    private onDistances = (distances: Map<string, Distance>) => this.setState({ distances });
    private onTeamScores = (scores: Map<string, Score>) => this.setState({ teamScores: scores });
    private onGolferScores = (scores: Map<string, Score>) => this.setState({ golferScores: scores });
    private onReportedGolferScores = (reportedScores: Map<string, ReportedScore>) => this.setState({ reportedScores });
    private onReportedTeamScores = (reportedTeamScores: Map<string, ReportedScore>) => this.setState({ reportedTeamScores });
    private handleGolferExport = () => this.setState({ handleGolferExport: true });
    private handleAddClick = () => this.setState({ editedContact: newContact(this.props.event) });
    private handleAddFromRosterClick = () => this.setState({ importMenuOpen: false, importingFromRoster: true });
    private handleAddFromSpreadsheet = () => this.setState({ importMenuOpen: false, importingFromSpreadsheet: true });
    private handleSelectionClose = () => this.setState({ selectMenuOpen: false });
    private handleImportClose = () => this.setState({ importMenuOpen: false });
    private handleImportClick = (mouseEvent: React.MouseEvent<HTMLButtonElement, MouseEvent>) => this.setState({ importMenuOpen: !this.state.importMenuOpen, anchorElImport: mouseEvent.currentTarget });
    private handleSelectClick = (mouseEvent: React.MouseEvent<HTMLButtonElement, MouseEvent>) => this.setState({ selectMenuOpen: !this.state.selectMenuOpen, anchorElSelect: mouseEvent.currentTarget });
    private handleActionsClick = (mouseEvent: React.MouseEvent<HTMLButtonElement, MouseEvent>) => this.setState({ actionMenuOpen: true, anchorElAction: mouseEvent.currentTarget });
    private handleGolferRowClick = (onRevokeSuccess: () => void, contact?: ContactDetails) => contact && this.setState({ editedContact: { ...contact }, onRevokeSuccess });
    private handleActionNewEmail = () => this.setState({ actionMenuOpen: false, actionNewEmailOpen: true, handleGolferExport: false });
    private handleActionChangeTee = () => this.setState({ actionMenuOpen: false, actionChangeTeeOpen: true, handleGolferExport: false });
    private handleActionNewEmailSent = () => this.setState({ actionNewEmailOpen: false });
    private handleActionNewEmailCanceled = () => this.setState({ actionNewEmailOpen: false });
    private handleCloseEditDialog = () => this.setState({ editedContact: undefined });
    private handleCancelFromRoster = () => this.setState({ importingFromRoster: false, importingFromSpreadsheet: false });
    private handleContactDeleted = (golfers: Array<Contact>) => this.deleteGolfersFromEvent(golfers);
    private handleActionsClose = () => this.setState({ actionMenuOpen: false, handleGolferExport: false });
    private handleSelectAll = () => this.selectAllGolfers(true);
    private handleSelectNone = () => this.selectAllGolfers(false);
    private teesLoaded = (loadStatus: ResultStatus, tees?: Tee[]) => {
        if (loadStatus === 'ok') {
            this.setState({ tees });
        }
    };
    private onEventsPaymentsInfo = (payments: Map<string, PaymentInfo>) => this.setState({ payments });

    private onSelectTees = async (tees: Array<Tee | null>, selectedGolfers: Array<Contact>) => {
        const { event } = this.props;
        const menTee = tees[MENS_TEE] ?? undefined;
        const womenTee = tees[WOMENS_TEE] ?? undefined;
        selectedGolfers.filter(g => g.gender === 'male').forEach(g => g.tee = menTee);
        selectedGolfers.filter(g => g.gender === 'female').forEach(g => g.tee = womenTee);
        await Backend.updateOrAddBatch(Backend.golferDb(event.id), selectedGolfers, true);
        this.setState({ actionChangeTeeOpen: false });
    }

    private handleGolferCbChange = (contact: ContactDetails) => {
        const { golfers } = this.props.eventData;
        let { selectedAll } = this.state;
        const selectedGolfersMap = !this.state.selectedGolfersMap ? new Map<string, ContactDetails>() : this.state.selectedGolfersMap;
        if (selectedGolfersMap.has(contact.id)) {
            selectedGolfersMap.delete(contact.id);
            if (selectedGolfersMap.size === 0) {
                this.handleActionsClose();
                selectedAll = false;
            }
        } else {
            selectedGolfersMap.set(contact.id, contact);
            if (selectedGolfersMap.size === golfers.size) {
                selectedAll = true;
            }
        }
        this.setState({ selectedGolfersMap, selectedAll });
    }

    private selectAllGolfers = (selectedAll: boolean) => {
        const selectedGolfersMap = new Map<string, ContactDetails>();
        if (selectedAll) {
            const { golfers, roster } = this.props.eventData;
            golfers.forEach(golfer => selectedGolfersMap.set(golfer.id, contactFrom(golfer, roster.get(golfer.id))));
        }
        this.setState({ selectedGolfersMap, selectedAll }, this.handleSelectionClose);
    }

    private handleSortGolfers = (column: string) => {
        let { sortCol, asc } = this.state;
        if (sortCol === column || (sortCol === undefined && column === 'name')) {
            asc = asc === undefined ? false : !asc;
        } else {
            sortCol = column;
            asc = true
        }
        this.setState({ sortCol, asc });
    }

    private handleActionsDelete = () => {
        if (this.state.actionMenuOpen) {
            const golfers = this.state.selectedGolfersMap;
            const selectedGolfers = !!golfers ? Array.from(golfers.values()) : [];
            const reportedGolfers = selectedGolfers.filter(golfer => !!golfer.reportedBy);
            if (reportedGolfers.length === 0) {
                this.deleteGolfersFromEvent(selectedGolfers);
            } else {
                showReportedGolfersDeletionAlert(reportedGolfers, () => this.deleteGolfersFromEvent(selectedGolfers));
            }
        }
    }

    private deleteGolfersFromEvent = async (golfersToDelete: Array<Contact>) => {
        const { event } = this.props;
        const { mainCompetition } = this.props.eventData;
        try {
            await deleteGolfersFromEvent(event, golfersToDelete, undefined);
            if (mainCompetition?.flights) {
                await updateGolfersPlayingHandicap(event);
            }
            this.setState({ actionMenuOpen: false, selectedGolfersMap: new Map<string, Contact>(), handleGolferExport: false });
        } catch {
            this.setState({ actionMenuOpen: false });
        }
    }

    private handleContactChanged = async (contactDetail: Contact, notificationLess: boolean, initialContact?: Contact) => {
        const { event } = this.props;
        const { mainCompetition, golfers } = this.props.eventData;
        if (initialContact && equalContactData(contactDetail, initialContact)) {
            const golfer = golfers.get(contactDetail.id);
            if (golfer && contactDetail.feePaid !== golfer.feePaid) {
                golfer.feePaid = contactDetail.feePaid;
                golfers.set(contactDetail.id, golfer);
            }
            await saveContactDetails(event, contactDetail, ACTION_GOLFER_MODIFIED);
        } else {
            await saveContact(event, contactDetail, contactDetail.id ? ACTION_GOLFER_MODIFIED : ACTION_GOLFER_ADDED, notificationLess);
            if (mainCompetition?.flights) {
                await updateGolfersPlayingHandicap(event);
            }
        }
        this.setState({ editedContact: undefined });
    }

    private handleAddFromRoster = (contacts: Array<ContactDetails>) => {
        const { event } = this.props;
        if (contacts && contacts.length) {
            const newGolfers = contacts.map(contactFromRoaster);
            saveContacts(event, newGolfers, ACTION_ADD_FROM_ROSTER)
                .then(() => this.setState({ importingFromRoster: false }));
        } else {
            this.setState({ importingFromRoster: false });
        }
    }

    private handleImportResults = (result?: Result) => {
        if (result && result.contacts && result.contacts.length) {
            this.setState({ importResult: result });
        } else {
            const error = 'Unable to process the import. Please verify data format.';
            this.setState({ importResult: { added: 0, updated: 0, rejected: 0, contacts: [], error: error, statuses: new Map() } });
        }
        this.setState({ importingFromSpreadsheet: false });
    }

    private handleFinishImportResults = () => {
        this.setState({ importResult: undefined });
    }

    private contactsComparator = (c1: Contact, c2: Contact) => {
        const { sortCol, asc } = this.state;
        return compareContactsBy(c1, c2, sortCol || 'name', asc === undefined ? true : asc)
    };

    private SelectMenu = () => {
        return (
            <Popper anchorEl={this.state.anchorElSelect} transition open={this.state.selectMenuOpen && Boolean(this.state.anchorElSelect)}>
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                        <Paper>
                            <ClickAwayListener onClickAway={this.handleSelectionClose}>
                                <MenuList role="menu">
                                    <MenuItem onClick={this.handleSelectAll}>All</MenuItem>
                                    <MenuItem onClick={this.handleSelectNone}>None</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        );
    }

    private exportGolferData() {
        const { golfers, roster } = this.props.eventData;
        const { selectedGolfersMap } = this.state;
        const withTee = Array.from(golfers.values()).findIndex(c => !!c.tee) >= 0;
        const exportHeaders = ['Email', 'FirstName', 'LastName', 'Gender', 'Handicap'];
        if (withTee) {
            exportHeaders.push('Tee');
        }

        const exportData: string[][] = [];
        exportData.push(exportHeaders);
        Array.from(selectedGolfersMap.values()).sort((c1, c2) => this.contactsComparator(c1, c2)).forEach((golfer) => {
            const exportRow: string[] = [];
            const teeName = golfer!.tee === null || golfer!.tee === undefined ? 'Default' : golfer!.tee.name;
            exportRow.push(roster.get(golfer.id)?.email || '');
            exportRow.push(golfer?.firstName || '');
            exportRow.push(golfer?.lastName || '');
            exportRow.push(golfer?.gender || '');
            exportRow.push(!!golfer?.handicapIndex ? String(golfer!.handicapIndex) : '0.0');
            if (withTee) {
                exportRow.push(teeName);
            }
            exportData.push(exportRow);
        });
        return exportData;
    }

    private ActionMenu = () => {
        const { event } = this.props;
        const { handleGolferExport } = this.state;
        const fileName = event.name.replace(' ', '-') + '-' + Utils.formatDate2(event.date);
        const exportFile = `${fileName}-golfers.csv`;
        const exportData = handleGolferExport ? this.exportGolferData() : '';
        return (
            <Popper anchorEl={this.state.anchorElAction} transition open={this.state.actionMenuOpen && Boolean(this.state.anchorElAction)}>
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                        <Paper>
                            <ClickAwayListener onClickAway={this.handleActionsClose}>
                                <MenuList role="menu">
                                    {<CSVLink data={exportData} filename={exportFile} style={{ textDecoration: 'none' }}><MenuItem color="default" onClick={this.handleGolferExport}>
                                        Export
                                    </MenuItem></CSVLink>}
                                    <MenuItem onClick={this.handleActionNewEmail}>Send email</MenuItem>
                                    <MenuItem onClick={this.handleActionChangeTee}>Change tee</MenuItem>
                                    <MenuItem onClick={this.handleActionsDelete}>Delete</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        );
    }

    private ImportMenu = () => {
        return (
            <Popper anchorEl={this.state.anchorElImport} transition open={this.state.importMenuOpen && Boolean(this.state.anchorElImport)}>
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                        <Paper>
                            <ClickAwayListener onClickAway={this.handleImportClose}>
                                <MenuList role="menu">
                                    <MenuItem onClick={this.handleAddFromRosterClick}>Add from past events</MenuItem>
                                    <MenuItem onClick={this.handleAddFromSpreadsheet}>Import from spreadsheet</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        );
    };

    private handleSendMessage = async (contactsToSend: Contact[], variant: EmailVariant) => {
        const { event, eventData } = this.props;
        const { golferScores, teamScores, reportedScores, reportedTeamScores, distances } = this.state;
        await sendPaymentMessage(contactsToSend, event, eventData, golferScores, teamScores, reportedScores,
            reportedTeamScores, distances, variant);
    };

    private Golfers = () => {
        const { event, classes, eventData } = this.props;
        const { golfers, roster, acceptedInvites, competitions } = eventData;
        const { selectedGolfersMap, selectedAll, payments, sortCol, asc } = this.state;
        const golfersCount =
            (golfers.size > 0 ? Utils.withS(golfers.size, 'golfer') : '') +
            (selectedGolfersMap.size > 0 ? ', ' + selectedGolfersMap.size + ' selected' : '');
        const contacts: Contact[] = Array.from(golfers.values());
        const sameNameGolfersIdsSet: Set<string> = getSameNameGolfersIds(contacts);
        const isNetCompetitionPresent = competitions.some(competition => isMainScoring(competition.scoring) && isNetMode(competition.scoring.mode));
        let notAllHandicapsPresent = false;
        if (isNetCompetitionPresent) {
            notAllHandicapsPresent = contacts.some(golfer => golfer.handicapIndex === undefined);
        }
        const zeroHandicapNotificationNeeded = notAllHandicapsPresent && isNetCompetitionPresent;
        const withHandicapId = contacts.some(golfer => Boolean(golfer.handicapId) && selectedGolfersMap.has(golfer.id));
        const paymentColumnEnabled = contacts.some(c => !(c.feePaid || (c.paymentId && payments?.get(c.paymentId))));
        const [sendPaymentDialogOpened, setSendPaymentDialogOpened] = React.useState(false);
        const unpaidContacts = payments ? contacts.filter(c => (!c.paymentId || !payments?.get(c.paymentId))
            && !c.feePaid).map(c => contactFrom(c, roster.get(c.id))) : undefined;
        const [contactsToEmail, setContactsToEmail] = React.useState<ContactDetails[] | undefined>(undefined);
        const emailVariant = EmailVariant.eventUpcomingPayment;
        const sendMessages = async (contacts: Array<Contact>) => {
            setSendPaymentDialogOpened(false);
            await this.handleSendMessage(contacts, emailVariant);
        };
        return (
            <div className={classes.listRoot2}>
                <List disablePadding className={classes.listRoot2}>
                    <ListItem className={classes.listItem}>
                        <ButtonBar>
                            <AppButton color="secondary" onClick={this.handleAddClick}>+Add</AppButton>
                            <AppButton color="info" onClick={this.handleImportClick}>
                                Import<DropDownArrowIcon className={classes.rightButtonIcon} />
                            </AppButton>
                            {this.ImportMenu()}
                            <AppButton color="info" onClick={this.handleSelectClick}>
                                Select<DropDownArrowIcon className={classes.rightButtonIcon} />
                            </AppButton>
                            {withHandicapId && <AppButton color="info" onClick={() => refreshGHINHandicaps(event, contacts)}>
                                Handicap Refresh
                            </AppButton>}
                            {this.SelectMenu()}
                            {selectedGolfersMap.size > 0 &&
                                <AppButton color="info" onClick={this.handleActionsClick}>
                                    Actions<DropDownArrowIcon className={classes.rightButtonIcon} />
                                </AppButton>}
                            {selectedGolfersMap.size > 0 && this.ActionMenu()}
                            <Typography noWrap color="textSecondary">
                                {golfersCount}
                            </Typography>
                            {zeroHandicapNotificationNeeded &&
                                <InfoElement iconColor={AppColors.webWarning} contentStyle={{ fontSize: 12, lineHeight: '150%', color: '#6D6D71' }}>
                                    Some golfers do not have a Handicap index. 0 handicap will be used for net scoring calculation.
                                </InfoElement>}
                        </ButtonBar>
                    </ListItem>
                    <ContactItemHeader selectedAll={selectedAll}
                                       selectAll={this.selectAllGolfers}
                                       showPaymentInfo={event.paymentSettings?.enabled}
                                       paymentColumnEnabled={paymentColumnEnabled}
                                       onRequestClick={unpaidContacts?.length ? () => setSendPaymentDialogOpened(true) : () => {}}
                                       withTee={Array.from(golfers.values()).findIndex(c => !!c.tee) >= 0}
                                       sortCol={sortCol}
                                       asc={asc}
                                       onSortColumn={this.handleSortGolfers} />
                    {contacts.sort(this.contactsComparator).map((golfer: Contact) =>
                        <ContactItem key={golfer.id}
                            withTee={Array.from(golfers.values()).findIndex(c => !!c.tee) >= 0}
                            contact={contactFrom(golfer, roster.get(golfer.id))}
                            clickHandler={this.handleGolferRowClick}
                            cbClickHandler={this.handleGolferCbChange}
                            selected={selectedGolfersMap.has(golfer.id)}
                            withHomeCourseOrCity={sameNameGolfersIdsSet.has(golfer.id)}
                            zeroHandicapIconAllowed={zeroHandicapNotificationNeeded}
                            withGHINImg={Boolean(golfer.handicapId)}
                            linkedFromApp={acceptedInvites.has(golfer.id)}
                            onRevokeSuccess={() => acceptedInvites.delete(golfer.id)}
                            showPaymentInfo={event.paymentSettings?.enabled}
                            handleFeePaidStatusUpdate={updateGolferFeePaidStatus(event, golfer)}
                            feePaid={golfer.feePaid}
                            handleSendMessage={contact => this.handleSendMessage([contact], emailVariant)}
                            paymentInfo={golfer.paymentId ? payments?.get(golfer.paymentId) : undefined} />
                    )}
                </List>
                {sendPaymentDialogOpened && <SendPaymentMessageDialog
                    unpaidParticipants={unpaidContacts}
                    notification={false}
                    paymentSettings={event.paymentSettings}
                    showEmail={setContactsToEmail}
                    handleSend={sendMessages}
                    handleClose={() => setSendPaymentDialogOpened(false)} />}
                {contactsToEmail?.length && <GolfersEmailDialog
                    open={true}
                    event={event}
                    eventData={eventData}
                    emailVariant={emailVariant}
                    handleCancel={() => setContactsToEmail(undefined)}
                    handleSent={() => {
                        this.handleActionNewEmailSent();
                        setSendPaymentDialogOpened(false);
                    }}
                    initialGolfers={contactsToEmail!} />}
            </div>
        );
    }

    render() {
        const { event, eventData } = this.props;
        const { acceptedInvites } = eventData;
        const { editedContact, importResult, selectedGolfersMap, importingFromRoster, actionNewEmailOpen, actionChangeTeeOpen, onRevokeSuccess, editingTeam, importingFromSpreadsheet } = this.state;
        const selectedGolfers = selectedGolfersMap ? Array.from(selectedGolfersMap.values()) : [];
        selectedGolfers.sort(this.contactsComparator);
        const paymentsEnabled = Boolean(event.paymentSettings?.enabled);
        return (
            <React.Fragment>
                {!!editedContact && <EditContactDialog
                    open
                    event={event}
                    actionMode={editedContact.id ? 'edit' : 'add'}
                    initialContact={editedContact}
                    linkedFromApp={acceptedInvites.has(editedContact.id)}
                    saveToEvent={this.handleContactChanged}
                    handleClose={this.handleCloseEditDialog}
                    deleteFromEvent={this.handleContactDeleted}
                    sendEmail={this.handleSendMessage}
                    onRevokeSuccess={onRevokeSuccess} />}
                {actionNewEmailOpen && <GolfersEmailDialog
                    open
                    event={event}
                    eventData={eventData}
                    emailVariant={EmailVariant.default}
                    handleCancel={this.handleActionNewEmailCanceled}
                    handleSent={this.handleActionNewEmailSent}
                    initialGolfers={selectedGolfers} />}
                {actionChangeTeeOpen && <SelectTeesComponent open={true} variant="dialog"
                    onClose={() => this.setState({ actionChangeTeeOpen: false })}
                    onSelectTees={tees => this.onSelectTees(tees, selectedGolfers)}
                    selectedGolfers={selectedGolfers}
                    event={event} />}
                {importingFromRoster && <AddGolfersFromRosterDialog
                    open
                    event={event}
                    eventData={eventData}
                    hiddenGolfersMode={false}
                    handleAddGolfers={this.handleAddFromRoster}
                    handleCancel={this.handleCancelFromRoster} />}
                {editingTeam >= 0 && <EditTeamsDialog
                    event={event}
                    eventData={eventData}
                    editingTeam={editingTeam}
                    handleClose={() => this.setState({ editingTeam: -1 })} />}
                {importingFromSpreadsheet && <ImportContactDialog
                    open={importingFromSpreadsheet}
                    importResult={this.handleImportResults}
                    handleCancel={() => this.setState({ importingFromSpreadsheet: false })} />}
                {!!importResult && <ImportReviewDialog loadedData={importResult!} event={event} open={!!importResult} handleClose={this.handleFinishImportResults} />}
                <this.Golfers />
                <TeesProvider event={event} course={event.course} onResult={this.teesLoaded} ref={this.teesLoader} />
                <FirebaseDataComponent query={Backend.eventPaymentsDb(event.id)} onMap={this.onEventsPaymentsInfo} />
                {paymentsEnabled && <>
                    <FirebaseDataComponent query={Backend.golferTeamScoresDb(event.id)} onMap={this.onTeamScores} />
                    <FirebaseDataComponent query={Backend.golferScoresDb(event.id)} onMap={this.onGolferScores} />
                    <FirebaseDataComponent query={Backend.reportedGolferScoresDb(event.id)} onMap={this.onReportedGolferScores} />
                    <FirebaseDataComponent query={Backend.reportedTeamScoresDb(event.id)} onMap={this.onReportedTeamScores} />
                    <FirebaseDataComponent query={Backend.golferDistancesDb(event.id)} onMap={this.onDistances} />
                </>}
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(withUserId(GolfersList));
