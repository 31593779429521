import * as React from 'react';
import { Grid, GridSpacing, ListItem, SxProps, Typography } from '@mui/material';

export type OneToEleven = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

interface ListGridItemProps {
    icon: React.ReactNode;
    text1: string;
    sx1?: SxProps;
    text2: string;
    sx2?: SxProps;
    spacing?: GridSpacing;
    button?: React.ReactNode;
    maxContentSize?: OneToEleven;
    iconXs?: 1 | 2 | 3;
}

const ListGridItem = (props: ListGridItemProps) => {
    let { icon, text1, text2, button, spacing, sx1, sx2, maxContentSize, iconXs } = props;
    if (iconXs && maxContentSize && iconXs + maxContentSize > 12) {
        maxContentSize = (12 - iconXs) as OneToEleven;
    }
    return (
        <ListItem disableGutters>
            <Grid container spacing={spacing}>
                <Grid item xs={iconXs ?? 1}>{icon}</Grid>
                <Grid item xs={maxContentSize ?? 7}>
                    <Typography sx={sx1}>{text1}</Typography>
                    {text2?.length > 0 && <Typography sx={sx2} variant="caption">{text2}</Typography>}
                </Grid>
                <span style={{ flex: '1 1 0%' }} />
                {button && <Grid item xs>{button}</Grid>}
            </Grid>
        </ListItem>
    );
};

export default ListGridItem;
