import * as React from 'react';

import { Event, HANDICAP_SYSTEM_NAMES, HANDICAP_SYSTEM_IDS } from '../../../../../types/EventTypes';
import HandicapSystemSelectionDialog from './HandicapSystemSelectionDialog';
import LabeledField from '../../../../../common/form/LabeledField';
import { ListElem } from '../../../../../common/Misc';

interface Props {
    event: Event;
}

interface State {
    dialogOpened?: boolean;
}

const HCP_ELEM = 'hcp_select_elem';

class HandicapSystemSelectionField extends React.Component<Props, State> {
    state: State = {};

    private handleOpen = () => this.setState({ dialogOpened: true });
    private handleClose = () => this.setState({ dialogOpened: false });

    render() {
        const { event } = this.props;
        const { dialogOpened } = this.state;
        const title = (event.handicapSystem && HANDICAP_SYSTEM_NAMES[HANDICAP_SYSTEM_IDS.indexOf(event.handicapSystem)]) || 'Not selected';
        return (
            <React.Fragment>
                <LabeledField label="Handicap System" value={(<ListElem id={HCP_ELEM} title={title} subtitle=" " />)} edit={this.handleOpen} />
                {dialogOpened && <HandicapSystemSelectionDialog open={true} onClose={this.handleClose} event={event} />}
            </React.Fragment>
        );
    }
}

export default HandicapSystemSelectionField;
