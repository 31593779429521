import * as React from 'react';
import Divider from '@mui/material/Divider';
import { Grid, List, ListItem, ListItemButton, MenuItem, MenuList, IconButton, Typography, Popper, Grow, Paper, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import PersonIcon from '@mui/icons-material/Person';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import DropDownArrowIcon from '@mui/icons-material/ArrowDropDown';
import { CSVLink } from 'react-csv';
import { array, range, makeFriendlyString, Func, formatCurrency, formatDate2 } from '../../../util/utility';
import * as Backend from '../../../util/firebase';
import {
    Competition, Event, Contact, Team, GolferGroup, ScoringFormatDistance, ReportedScore, Score, Distance,
    DistanceValue, Units, HolesType, MAX_HOLES, teeTimeName, getTee, isNetMode, isGrossMode, isMainScoring,
    isDistanceScoring, isSkinsScoring, isStablefordScoringOrMode, getHolesRange, getTotalHoles, isGrossPayouts,
    isNetPayouts, isSideScoringWithPayouts, CalculatedScores, WinnerInfo, ContactScoringState, ContactPayoutState,
    isTeamFormat, ScoringFormatSkins, ScoringFormatTeams, getEventMainCompetition, ScoringData, isIndividualScoringOrBB,
    isTeamScoringExceptBB, appScoringStarted, isTeamFormatExceptBB, EventData
} from '../../../types/EventTypes';
import { fullName, golfersOfTeam, shortName, getSameNameGolfersIds, golferShortTeamNameArray } from '../../../contact/Contact';
import { FirebaseDataComponent, FirebaseUserPubDataComponent } from '../../../common/WithData';
import EditScoreDialog from './EditScoreDialog';
import AddResultDialog from './AddResultDialog';
import {
    liveScoresName, saveCompetitions, updateCompetitionPos, isPayoutsExceedGolfers, getDistanceScores, getSkinsScores, HolesScoringState, createCompetition, createSideGame,
    formatDistance, updateGolferDistance, eventPayoutsStates, hasTees, hasScores, clearScores, clearDistance, genderFromEvent, elog, getScoresWinners, isFullScoresCompetitions,
    ContactHcp, processSkins, isFullScoresCompetition, SkinsScoringState, getSplitCompetitionsWithPayouts, updateEventAppCompetition, competitionSummary
} from '../../Event';
import { getFlightName } from '../../TeeTimes';
import * as Scoring from '../../../scoring/scoring';
import LabeledField from '../../../common/form/LabeledField';
import ButtonBar from '../../../common/components/ButtonBar';
import AppButton from '../../../common/components/AppButton';
import ConfirmDialog from '../../../common/dialog/ConfirmDialog';
import MessageDialog from '../../../common/dialog/MessageDialog';
import { showAlert, InfoElement } from '../../../redux/ReduxConfig';
import CompetitionSettingsDialog from './CompetitionSettingsDialog';
import { Container, Item, ListTitle, EditIcon, Spacing, ItemS, Label } from '../../../common/Misc';
import SelectLiveScoresDialog from '../common/SelectLiveScoresDialog';
import SelectGolfersDialog from '../common/SelectGolfersDialog';
import SelectWinnersDialog from '../common/SelectWinnersDialog';
import { styles } from '../../../styles';
import { withProgress } from '../../../util/ProgressPromise';
import { XSMobileDialog } from "../../../common/dialog/MobileDialog";
import ReorderCompetitionsDialog from "./ReorderCompetitionsDialog";
import { SkinsDetailedInfo } from "../../../public/Standings";
import { SettingsIcon, InfoIcon } from '../../../common/Icons';
import { setTeamHandicapIndexes } from "../../../scoring/handicap";
import { AppColors } from '../../../main/Theme';

type HeaderProps = { scoring: ScoringData, isStableford?: boolean };
type RowProps = { onClick: Func<void>, isStableford: boolean, holesType?: HolesType, skinsGrossScore?: number, skinsNetScore?: number };

const HeaderScore = withStyles(styles)((props: HeaderProps & WithStyles<typeof styles>) => {
    const { classes, scoring, isStableford } = props;
    const isNet = isNetMode(scoring.mode);
    const isGross = isGrossMode(scoring.mode);
    const rightIcon = <EditIcon invisible />;
    const skins = isSkinsScoring(scoring);
    return (
        <div>
            <ListItem className={classes.listItemHeaderWhite}>
                <Container wrap="nowrap">
                    <Item xs={2} md={1}>Tee</Item>
                    <Item xs={isNet ? (skins ? isGross ? 4 : 5 : 6) : skins ? 6 : 7} md={isNet ? (skins ? isGross ? 5 : 6 : 7) : skins ? 7 : 8}>Name</Item>
                    <Item xs={1}>Thru</Item>
                    <Item xs={1} md={1}>Gross</Item>
                    {!isStableford && isNet && <Item xs={1} md={1}>Net</Item>}
                    {isStableford && isGross && <Item xs={1} md={1}>Pts</Item>}
                    {isStableford && isNet && <Item xs={1} md={1}>Pts Net</Item>}
                    {skins && <Item xs={1} md={1}>Skins{isNet && isGross ? ' Gross' : ''}</Item>}
                    {skins && isNet && isGross && <Item xs={1} md={1}>Skins Net</Item>}
                    <Item xs={1} placeRight noWrap>{rightIcon}</Item>
                </Container>
            </ListItem>
            <Divider />
            <Divider />
        </div>
    );
});

const GolferNames = withStyles(styles)((props: { names: Array<string>; winnerIn?: Array<string>, sameNameGolfersIdsSet: Set<string>, golfer: Contact } & WithStyles<typeof styles>) => {
    const { names, winnerIn, classes, sameNameGolfersIdsSet, golfer } = props;
    return (
        <Container>
            {names.map((name, idx) =>
                <ItemS key={idx}>
                    <PersonIcon className={classes.textIcon} />
                    <Label>
                        {name}
                        <span className={classes.homeCourseOrCity}>
                            {golfer.homeCourseOrCity && sameNameGolfersIdsSet.has(golfer.id) ? ` (${golfer.homeCourseOrCity})` : ''}
                        </span>
                    </Label>
                </ItemS>)}
            {winnerIn && <Grid item>&nbsp;*Winner by tie-breaker ({winnerIn.join(',')})</Grid>}
        </Container>
    );
});

const TeamNames = withStyles(styles)((props: { names: Array<string>; winnerIn?: Array<string>, sameNameGolfersIdsSet: Set<string>, golfers: Map<string, Contact>, team: Team } & WithStyles<typeof styles>) => {
    const { names, winnerIn, classes, sameNameGolfersIdsSet, golfers, team } = props;
    const contactIds = golfersOfTeam(team, golfers).map(c => c.id);
    return (
        <Container>
            {names.map((name, idx) =>
                <ItemS key={idx}>
                    <PersonIcon className={classes.textIcon} />
                    <Label>
                        {name}
                        <span className={classes.homeCourseOrCity}>
                            {sameNameGolfersIdsSet.has(contactIds[idx]) && golfers.get(contactIds[idx])?.homeCourseOrCity ? ` (${golfers.get(contactIds[idx])?.homeCourseOrCity})` : ''}
                        </span>
                    </Label>
                </ItemS>)}
            {winnerIn && <Grid item>&nbsp;*Winner by tie-breaker ({winnerIn.join(',')})</Grid>}
        </Container>
    );
});

const RowGolferScore = withStyles(styles)((props: ContactScoringState & RowProps & WithStyles<typeof styles> & { sameNameGolfersIdsSet: Set<string>, golfers: Map<string, Contact> }) => {
    const { teeTime, winnerIn, total, relativeTotal, net, relativeNet, holes, classes, onClick, mode, isStableford, isReported,
        stableford, stablefordNet, holesType, contactInfo, player, sameNameGolfersIdsSet, skinsGrossScore, skinsNetScore } = props;
    if ('contactIds' in player) {
        return null;
    }
    const isNet = isNetMode(mode);
    const isGross = isGrossMode(mode);
    const rightIcon = <EditIcon />;
    const totalHoles = getTotalHoles(holesType);
    const Tee = teeTimeName(teeTime);
    const golferStatus = contactInfo.withdrawn ? 'WD' : contactInfo.disqualified ? 'DQ' : undefined;
    const Gross = !golferStatus ? (total + (total ? ' (' + Scoring.formatRelativeScore(relativeTotal) + ')' : '')) : golferStatus;
    const Thru = holes === totalHoles ? 'F' : holes;
    const Net = !golferStatus ? (net + (net ? ' (' + Scoring.formatRelativeScore(relativeNet) + ')' : '')) : golferStatus;
    const Pts = !golferStatus ? stableford : golferStatus;
    const NetPts = !golferStatus ? stablefordNet : golferStatus;
    const Name = (
        <div className={classes.flexCenteredRow21px}>
            <GolferNames names={[fullName(player, 15, 25)]} winnerIn={winnerIn} sameNameGolfersIdsSet={sameNameGolfersIdsSet} golfer={player} />
            {contactInfo.handicapIndex == null && isNet && <InfoIcon className={classes.smallerShiftedIcon} htmlColor={AppColors.webWarning} />}
        </div>
    );
    const skins = skinsGrossScore != null || skinsNetScore != null;
    return (
        <div>
            <ListItemButton className={isReported ? classes.listItemGreen : classes.listItem} onClick={onClick}>
                <Container wrap="nowrap">
                    <Item xs={2} md={1} noWrap>{Tee}</Item>
                    <Item xs={isNet ? (skins ? isGross ? 4 : 5 : 6) : skins ? 6 : 7} md={isNet ? (skins ? isGross ? 5 : 6 : 7) : skins ? 7 : 8} noWrap>{Name}</Item>
                    <Item xs={1} noWrap>{Thru}</Item>
                    <Item xs={1} md={1} noWrap>{Gross}</Item>
                    {!isStableford && isNet && <Item xs={1} md={1} noWrap>{Net}</Item>}
                    {isStableford && isGross && <Item xs={1} md={1} noWrap>{Pts}</Item>}
                    {isStableford && isNet && <Item xs={1} md={1} noWrap>{NetPts}</Item>}
                    {skins && isGross && <Item xs={1} noWrap>{skinsGrossScore ?? 0}</Item>}
                    {skins && isNet && <Item xs={1} noWrap>{skinsNetScore ?? 0}</Item>}
                    <Item xs={1} placeRight noWrap>{rightIcon}</Item>
                </Container>
            </ListItemButton>
            <Divider />
        </div>
    );
});

export const RowTeamScore = withStyles(styles)((props: ContactScoringState & RowProps & WithStyles<typeof styles> & { sameNameGolfersIdsSet: Set<string>, golfers: Map<string, Contact> }) => {
    const { teeTime, winnerIn, total, relativeTotal, net, relativeNet, holes, classes, onClick, mode, holesType, stableford,
        sameNameGolfersIdsSet, player, golfers, skinsGrossScore, skinsNetScore, contactInfo, isStableford, stablefordNet, isReported } = props;
    if ('lastName' in player) {
        return null;
    }
    const isNet = isNetMode(mode);
    const isGross = isGrossMode(mode);
    const rightIcon = <EditIcon />;
    const totalHoles = getTotalHoles(holesType);
    const Tee = teeTimeName(teeTime);
    const teamStatus = contactInfo.withdrawn ? 'WD' : contactInfo.disqualified ? 'DQ' : undefined;
    const Gross = total + (total ? ' (' + Scoring.formatRelativeScore(relativeTotal) + ')' : '');
    const Thru = holes === totalHoles ? 'F' : holes;
    const Net = !teamStatus ? (net + (net ? ' (' + Scoring.formatRelativeScore(relativeNet) + ')' : '')) : teamStatus;
    const Name = <TeamNames names={golferShortTeamNameArray(player, golfers, false)} winnerIn={winnerIn} sameNameGolfersIdsSet={sameNameGolfersIdsSet} golfers={golfers} team={player} />;
    const NetPts = !teamStatus ? stablefordNet : teamStatus;
    const Pts = !teamStatus ? stableford : teamStatus;
    const skins = skinsGrossScore != null || skinsNetScore != null;
    return (
        <div>
            <ListItemButton className={isReported ? classes.listItemGreen : classes.listItem} onClick={onClick}>
                <Grid container wrap="nowrap">
                    <Item xs={2} md={1} noWrap variant="body2">{Tee}</Item>
                    <Item xs={isNet ? (skins ? isGross ? 4 : 5 : 6) : skins ? 6 : 7} md={isNet ? (skins ? isGross ? 5 : 6 : 7) : skins ? 7 : 8} noWrap>{Name}</Item>
                    <Item xs={1} noWrap>{Thru}</Item>
                    <Item xs={1} noWrap>{Gross}</Item>
                    {!isStableford && isNet && <Item xs={1} md={1} noWrap>{Net}</Item>}
                    {isStableford && isGross && <Item xs={1} md={1} noWrap>{Pts}</Item>}
                    {isStableford && isNet && <Item xs={1} md={1} noWrap>{NetPts}</Item>}
                    {skins && isGross && <Item xs={1} noWrap>{skinsGrossScore ?? 0}</Item>}
                    {skins && isNet && <Item xs={1} noWrap>{skinsNetScore ?? 0}</Item>}
                    <Item xs={1} placeRight noWrap>{rightIcon}</Item>
                </Grid>
            </ListItemButton>
            <Divider />
        </div>
    );
});

const KPHeader = withStyles(styles)((props: WithStyles<typeof styles>) => {
    const { classes } = props;
    const rightIcon = <EditIcon invisible />;
    return (
        <div>
            <ListItem className={classes.listItemHeaderWhite}>
                <Grid container wrap="nowrap">
                    <Item xs={1} variant="body2" noWrap>Hole</Item>
                    <Item xs={8} variant="body2" noWrap>Winner</Item>
                    <Item xs={2} variant="body2" noWrap>Distance</Item>
                    <Item xs={1} placeRight noWrap>{rightIcon}</Item>
                </Grid>
            </ListItem>
            <Divider />
            <Divider />
        </div>
    );
});

const KPRow = withStyles(styles)((props: { onClick: Func<void>, units?: Units, sameNameGolfersIdsSet: Set<string> } & HolesScoringState & WithStyles<typeof styles>) => {
    const { units, hole, contacts, score, classes, onClick, sameNameGolfersIdsSet } = props;
    const rightIcon = <EditIcon />;
    const decorations = classes.listItem;
    const contact: Contact | undefined = contacts[0];
    return (
        <div>
            <ListItemButton className={decorations} onClick={onClick}>
                <Grid container wrap="nowrap">
                    <Item xs={1} noWrap>{hole + 1}</Item>
                    <ItemS xs={8} noWrap>
                        {contact ?
                            <React.Fragment>
                                <PersonIcon className={classes.textIcon} />
                                <Label>
                                    {fullName(contact)}
                                </Label>
                                <span className={classes.homeCourseOrCity}>
                                    {contact?.homeCourseOrCity && sameNameGolfersIdsSet.has(contact.id) ? ` (${contact.homeCourseOrCity})` : ''}
                                </span>
                            </React.Fragment> :
                            <Label className={classes.notSelected}>
                                {'Not selected'}
                            </Label>}
                    </ItemS>
                    <Item xs={2} noWrap>{formatDistance(units, score)}</Item>
                    <Item xs={1} placeRight noWrap>{rightIcon}</Item>
                </Grid>
            </ListItemButton>
            <Divider />
        </div>
    );
});

const LDHeader = withStyles(styles)((props: WithStyles<typeof styles>) => {
    const { classes } = props;
    const rightIcon = <EditIcon invisible />;
    return (
        <div>
            <ListItem className={classes.listItemHeaderWhite}>
                <Grid container wrap="nowrap">
                    <Item xs={1} variant="body2" noWrap>Hole</Item>
                    <Item xs={10} variant="body2" noWrap>Winner</Item>
                    <Item xs={1} placeRight noWrap>{rightIcon}</Item>
                </Grid>
            </ListItem>
            <Divider />
            <Divider />
        </div>
    );
});

const LDRow = withStyles(styles)((props: { onClick: Func<void>, sameNameGolfersIdsSet: Set<string> } & HolesScoringState & WithStyles<typeof styles>) => {
    const { onClick, hole, contacts, classes, sameNameGolfersIdsSet } = props;
    const rightIcon = <EditIcon />;
    const decorations = classes.listItem;
    const contact: Contact | undefined = contacts[0];
    return (
        <div>
            <ListItemButton className={decorations} onClick={onClick}>
                <Grid container wrap="nowrap">
                    <Item xs={1} noWrap>{hole + 1}</Item>
                    <ItemS xs={10} noWrap>
                        {contact ?
                            <React.Fragment>
                                <PersonIcon className={classes.textIcon} />
                                <Label>
                                    {fullName(contact)}
                                </Label>
                                <span className={classes.homeCourseOrCity}>
                                    {contact?.homeCourseOrCity && sameNameGolfersIdsSet.has(contact.id) ? ` (${contact.homeCourseOrCity})` : ''}
                                </span>
                            </React.Fragment> :
                            <Label className={classes.notSelected}>
                                {'Not selected'}
                            </Label>}
                    </ItemS>
                    <Item xs={1} placeRight noWrap>{rightIcon}</Item>
                </Grid>
            </ListItemButton>
            <Divider />
        </div>
    );
});

interface State {
    golferScores: Map<string, Score>;
    teamScores: Map<string, Score>;
    reportedScores: Map<string, ReportedScore>;
    reportedTeamScores: Map<string, ReportedScore>;
    distances: Map<string, Distance>;
    golfers: Map<string, Contact>;
    teams: Map<string, Team>;
    groups: Array<GolferGroup>;
    competitions: Array<Competition>;
    competitionsLoaded?: boolean;
    calculatedScores: Map<string, CalculatedScores>;
    openedScore?: ContactScoringState & { competition: Competition, skinsGross?: SkinsScoringState[], skinsNet?: SkinsScoringState[] };
    editingLD?: { competition: Competition, hole: number, golfer?: Contact };
    editingKP?: { competition: Competition, hole: number, golfer?: Contact, distance?: DistanceValue };
    teamScoring: boolean;
    hasNet: boolean;
    hasStableford: boolean;
    editingCompetition?: Competition;
    editingCompetitionIsNew?: boolean;
    confirmingDeleteCompetition?: () => void;
    openLiveScoresDialog?: boolean;
    openSelectWinnersDialog?: boolean;
    courseNotSelectedMessage?: boolean;
    units?: Units;
    exportMenuOpen: boolean;
    exportCompetitions: boolean;
    exportSideGames: boolean;
    exportMoneyList: boolean;
    alertDialogOpen: boolean;
    anchorExport: (EventTarget & HTMLElement) | undefined;
    openCompetitionsReordering?: boolean;
    mainCompetition?: Competition;
}

type IncompleteScoresAlertDialogProps = { exportCompFile: string; };

type Props = { event: Event } & WithStyles<typeof styles>;

class ScoreList extends React.Component<Props, State> {
    state: State = {
        golferScores: new Map<string, Score>(),
        teamScores: new Map<string, Score>(),
        reportedScores: new Map<string, ReportedScore>(),
        reportedTeamScores: new Map<string, ReportedScore>(),
        groups: [],
        competitions: [],
        calculatedScores: new Map<string, CalculatedScores>(),
        teamScoring: false,
        hasNet: false,
        hasStableford: false,
        teams: new Map<string, Team>(),
        golfers: new Map<string, Contact>(),
        distances: new Map<string, Distance>(),
        exportMenuOpen: false,
        exportCompetitions: false,
        exportSideGames: false,
        exportMoneyList: false,
        alertDialogOpen: false,
        anchorExport: undefined
    };

    componentDidMount() {
        Backend.trackEvent('view_scoring');
    }

    private exportCompetitions = () => {
        const { event } = this.props;
        const { golferScores, teamScores, golfers, teams, mainCompetition, reportedScores, reportedTeamScores, competitions, groups } = this.state;
        const holesRange = getHolesRange(event.holesType);
        const compData: Array<Array<string | number>> = [];
        const compHeader: Array<string> = golferScores.size > 0 ? ['Golfer'] : ['Team'];
        for (let i = holesRange.first; i < holesRange.last; i++) {
            compHeader.push('Hole#' + (i + 1));
        }
        const hasIndividualCompetition = Boolean(competitions.find(c => isIndividualScoringOrBB(c.scoring)
            || c.scoring.format === ScoringFormatSkins.skins_individual));
        const hasTeamCompetition = Boolean(competitions.find(c => isTeamScoringExceptBB(c.scoring) ||
            (c.scoring.format === ScoringFormatSkins.skins_team && mainCompetition?.scoring.format !== ScoringFormatTeams.best_ball)));
        if (teams.size > 0 && hasTeamCompetition) {
            teams.forEach((team: Team, key: string) => {
                if (!mainCompetition) {
                    return;
                }
                const compRow: Array<string | number> = [];
                compRow.push('Team ' + (team.order + 1));
                const contacts = golfersOfTeam(team, golfers);
                const tees = contacts.map(c => getTee(mainCompetition, c.gender, c));
                const score = team.contactIds.findIndex(id => !golferScores.has(id)) < 0 ? Scoring.bestBallGross(team.contactIds, golferScores, tees, event.holesType) : teamScores.get(key);
                for (let i = holesRange.first; i < holesRange.last; i++) {
                    if (!!score) {
                        compRow.push(score.gross[i]);
                    } else {
                        compRow.push(0);
                    }
                }
                compData.push(compRow);
            });
        } else if (golfers.size > 0 && hasIndividualCompetition) {
            const sameNameGolfersIdsSet: Set<string> = getSameNameGolfersIds(Array.from(golfers.values()));
            golfers.forEach((contact: Contact, key: string) => {
                const compRow: Array<string | number> = [];
                compRow.push(fullName(contact) + (sameNameGolfersIdsSet.has(contact.id) && contact.homeCourseOrCity ? ` (${contact.homeCourseOrCity})` : ''));
                const score = golferScores.get(key);
                for (let i = holesRange.first; i < holesRange.last; i++) {
                    if (!!score) {
                        compRow.push(score.gross[i]);
                    } else {
                        compRow.push(0);
                    }
                }
                compData.push(compRow);
            });
        }
        const competitionsSkinsGames = competitions.filter(competition => isSkinsScoring(competition.scoring));
        const gender = genderFromEvent(event);
        const sameNameGolfersIdsSet: Set<string> = getSameNameGolfersIds(Array.from(golfers.values()));
        competitionsSkinsGames.forEach(competition => {
            if (!mainCompetition) {
                return;
            }
            const isNet = isNetMode(competition.scoring.mode);
            const compGender = competition.competitionGender;
            const scores = Scoring.golferHoleScores(event, competition, 0, golferScores, teamScores, reportedScores, reportedTeamScores, golfers, teams, groups, mainCompetition);
            const tee = getTee(mainCompetition, gender, undefined);
            const skinsInfo = getSkinsScores(event, competition, scores, tee);
            skinsInfo.forEach(skin => {
                const firstContactHcp: ContactHcp = skin.contacts[0];
                const winners = event.teamSize > 1 ?
                    (skin.contacts.length === 1 && firstContactHcp ? firstContactHcp.contacts.map(c => shortName(c).concat(sameNameGolfersIdsSet.has(c.id) && c.homeCourseOrCity ? `(${c.homeCourseOrCity})` : '')).join('+') : '')
                    :
                    (skin.contacts.length === 1 ? shortName(firstContactHcp.contacts[0]) : '');
                const skinsRow: Array<string> = [String(skin.hole + 1), 'Skins ' + (isNet ? 'net' : 'gross') + (!!compGender && compGender !== 'both' ? (' - ' + compGender) : ''), winners];
                compData.push(skinsRow);
            });
        });
        if (!competitionsSkinsGames.length) {
            compData.sort((row1, row2) => String(row1[0]).localeCompare(String(row2[0])));
        }
        compData.unshift(compHeader);
        return compData;
    }

    private exportSideGames = () => {
        const { event } = this.props;
        const { competitions, golfers, distances, mainCompetition } = this.state;
        const sideData: Array<Array<string | number>> = [];
        const competitionsDistanceGames = competitions.filter(competition => isDistanceScoring(competition.scoring));
        const gender = genderFromEvent(event);
        const sameNameGolfersIdsSet: Set<string> = getSameNameGolfersIds(Array.from(golfers.values()));
        competitionsDistanceGames.forEach(comp => {
            if (!mainCompetition) {
                return;
            }
            const distanceInfo = getDistanceScores(event, comp, golfers, distances, getTee(mainCompetition, gender, undefined));
            distanceInfo.forEach(dist => {
                const firstContact: Contact = dist.contacts[0];
                const sideRow: Array<string> = [String(dist.hole + 1), comp.scoring.format === ScoringFormatDistance.closest_to_the_pin ? 'Closest to the pin' : 'Longest drive',
                dist.contacts.length === 1 ? fullName(firstContact).concat(sameNameGolfersIdsSet.has(firstContact.id) && firstContact.homeCourseOrCity ? `(${firstContact.homeCourseOrCity})` : '') : ''];
                sideData.push(sideRow);
            });
        });
        sideData.sort((a1: Array<string | number>, a2: Array<string | number>) => { return Number(a1[0]) - Number(a2[0]); });
        sideData.unshift(['Hole', 'Game', 'Winner(s)']);
        return sideData;
    }

    private exportMoneyList = () => {
        const { event } = this.props;
        const { golferScores, teamScores, reportedScores, reportedTeamScores,
            competitions, golfers, teams, distances, groups } = this.state;
        const [splitMainCompetitions, splitSideCompetitions] = getSplitCompetitionsWithPayouts(competitions);
        const data: Array<Array<string>> = [];
        const skinsMixed: boolean = competitions.some(comp => comp.scoring.format === ScoringFormatTeams.best_ball);
        const row: Array<string> = [event.teamSize > 1 ? 'Team' : 'Golfer'];
        splitMainCompetitions.forEach(comp => {
            if (isNetPayouts(comp)) {
                row.push(makeFriendlyString(comp.scoring.format + ', Net', true));
            }
            if (isGrossPayouts(comp)) {
                row.push(makeFriendlyString(comp.scoring.format + ', Gross', true));
            }
        });
        splitSideCompetitions.forEach(comp => {
            if (isSideScoringWithPayouts(comp)) {
                row.push(Scoring.scoringName(comp, event.eventGender, comp.competitionGender, skinsMixed));
            }
        });
        row.push('Total');
        data.push(row);
        const moneyList: ContactPayoutState[] = eventPayoutsStates(event, competitions, golferScores, teamScores, reportedScores, reportedTeamScores, golfers, teams, groups, distances, getSameNameGolfersIds(Array.from(golfers.values())));
        moneyList.filter(ps => ps.total > 0).forEach(ps => {
            const golferName = ps.names.join(' + ');
            const row: Array<string> = [golferName];
            splitMainCompetitions.forEach(comp => {
                if (isNetPayouts(comp)) {
                    row.push('$' + (ps.awards.has(comp.id + 'net') ? formatCurrency(ps.awards.get(comp.id + 'net')!) : '0'));
                }
                if (isGrossPayouts(comp)) {
                    row.push('$' + (ps.awards.has(comp.id + 'gross') ? formatCurrency(ps.awards.get(comp.id + 'gross')!) : '0'));
                }
            });
            splitSideCompetitions.forEach(comp => {
                if (isSideScoringWithPayouts(comp)) {
                    row.push('$' + (ps.awards.has(comp.id) ? formatCurrency(ps.awards.get(comp.id)!) : '0'));
                }
            });
            row.push('$' + formatCurrency(ps.total));
            data.push(row);
        });
        return data;
    }

    private IncompleteScoresAlertDialog = ({ exportCompFile }: IncompleteScoresAlertDialogProps) => {
        return (
            <XSMobileDialog open={this.state.alertDialogOpen}>
                <DialogContent>
                    <DialogContentText>
                        The event is still in progress. Some information may be incomplete. Export anyways?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <AppButton onClick={this.handleAlertDialogCancel}>
                        Cancel
                    </AppButton>
                    <CSVLink data={this.exportCompetitions()} filename={exportCompFile} style={{ textDecoration: 'none' }}>
                        <AppButton color='primary' onClick={this.handleAlertDialogCancel}>
                            Export
                        </AppButton>
                    </CSVLink>
                </DialogActions>
            </XSMobileDialog>
        );
    }

    private ExportMenu = () => {
        const { event } = this.props;
        const { exportCompetitions, exportSideGames, exportMoneyList, competitions,
            golferScores, teamScores, reportedScores, golfers, teams, alertDialogOpen } = this.state;
        const fileName = event.name.replace(' ', '-') + '-' + formatDate2(event.date);
        const exportCompFile = `${fileName}-comp-scores.csv`;
        const exportSideFile = `${fileName}-side-scores.csv`;
        const moneyListFile = `${fileName}-money-list.csv`;
        const competitionsData: (string | number)[][] | '' = exportCompetitions ? this.exportCompetitions() : '';
        const sideData = exportSideGames ? this.exportSideGames() : '';
        const moneyList = exportMoneyList ? this.exportMoneyList() : '';
        const isFullScores = isFullScoresCompetitions(event, competitions, golferScores, teamScores, reportedScores, golfers, teams);
        return (
            <Popper anchorEl={this.state.anchorExport} transition open={this.state.exportMenuOpen && Boolean(this.state.anchorExport)}>
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                        <Paper>
                            <ClickAwayListener onClickAway={this.handleExportClose}>
                                <MenuList role="menu">
                                    {isFullScores && <CSVLink data={competitionsData} filename={exportCompFile} style={{ textDecoration: 'none' }}><MenuItem onClick={this.handleExportCompetitions}>Competitions scores</MenuItem></CSVLink>}
                                    {!isFullScores && <MenuItem onClick={this.handleAlertDialogOpen}>Competitions scores</MenuItem>}
                                    {alertDialogOpen && this.IncompleteScoresAlertDialog({ exportCompFile })}
                                    <CSVLink data={sideData} filename={exportSideFile} style={{ textDecoration: 'none' }}><MenuItem onClick={this.handleExportSideGames}>Side games scores</MenuItem></CSVLink>
                                    <CSVLink data={moneyList} filename={moneyListFile} style={{ textDecoration: 'none' }}><MenuItem onClick={this.handleExportMoneyList}>Money list</MenuItem></CSVLink>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        );
    }

    private handleExportMoneyList = () => { this.setState({ exportMenuOpen: false, exportMoneyList: true }) }
    private handleExportCompetitions = () => { this.setState({ exportMenuOpen: false, exportCompetitions: true }) }
    private handleExportSideGames = () => { this.setState({ exportMenuOpen: false, exportSideGames: true }) }
    private handleExportClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => this.setState({ exportMenuOpen: !this.state.exportMenuOpen, anchorExport: event.currentTarget });
    private handleExportClose = () => this.setState({ exportMenuOpen: false });
    private handleAlertDialogOpen = () => this.setState({ alertDialogOpen: true })
    private handleAlertDialogCancel = () => this.setState({ exportMenuOpen: false, alertDialogOpen: false })
    private handleCloseCompetitionEditDialog = () => this.setState({ editingCompetition: undefined });
    private handleDeleteFromCompetitionEditDialog = (competition: Competition) => this.setState({ confirmingDeleteCompetition: () => this.tryDeleteEditedCompetition(competition) });
    private updateCompetitionPositions = () => updateCompetitionPos(this.state.competitions, this.props.event.id);
    private openCompetitionSettings = (editingCompetition: Competition) => this.setState({ editingCompetition, editingCompetitionIsNew: false });
    private skipDeleteCompetition = () => this.setState({ confirmingDeleteCompetition: undefined });

    private addCompetition = () => !this.props.event.course ?
        this.setState({ courseNotSelectedMessage: true }) :
        this.setState({ editingCompetition: createCompetition(this.props.event.teamSize, this.state.competitions), editingCompetitionIsNew: true })

    private addSideGame = () => !this.props.event.course ?
        this.setState({ courseNotSelectedMessage: true }) :
        this.setState({ editingCompetition: createSideGame(this.state.competitions), editingCompetitionIsNew: true })

    private deleteEditedCompetition = async (competition: Competition) => {
        await this.deleteCompetition(competition);
        await this.updateCompetitionPositions();
        if (competition.id && isMainScoring(competition.scoring)) {
            const otherCompetitions = this.state.competitions.filter(c => c.id !== competition.id);
            await updateEventAppCompetition(otherCompetitions, this.props.event);
        }
        const details = 'Format: ' + competitionSummary(competition);
        elog(this.props.event, 'Competition deleted', details, `Id: ${competition.id}`);
        this.setState({ confirmingDeleteCompetition: undefined, editingCompetition: undefined });
    }

    private tryDeleteEditedCompetition = (competition: Competition) => {
        const { event } = this.props;
        const { golfers, teams, mainCompetition } = this.state;
        const appMainCompetition = event?.appCompetition;
        if (appMainCompetition?.id === competition.id && appScoringStarted(golfers, teams, appMainCompetition, mainCompetition)) {
            showAlert('Event round has been already started for some golfers. Deletion of this competition will cause round configuration problems in Golf Pad app.' +
                '  Are you sure you want to delete this competition?',
                [
                    { title: 'Cancel' },
                    { title: 'Proceed', color: 'secondary', action: () => this.deleteEditedCompetition(competition) },
                ]);
        } else {
            this.deleteEditedCompetition(competition);
        }
    }

    private saveCompetitionsOrder = (event: Event, reorderedCompetitions: Competition[]) =>
        withProgress(updateCompetitionPos(reorderedCompetitions, event.id))
            .then(() => updateEventAppCompetition(reorderedCompetitions, event))
            .then(() => this.changeReorderingOpenStatus(false))
            .catch(() => this.changeReorderingOpenStatus(false));

    private saveNewCompetitionsOrder = (reorderedCompetitions: Competition[]) => {
        const { event } = this.props;
        const { competitions, teams, golfers, mainCompetition } = this.state;
        const currentAppMainCompetition = event.appCompetition;
        if (currentAppMainCompetition && mainCompetition && isTeamFormat(mainCompetition.scoring)) {
            const appRoundStarted = appScoringStarted(golfers, teams, currentAppMainCompetition, mainCompetition);
            const oldAppMainCompIndex = competitions.findIndex(comp => currentAppMainCompetition.id === comp.id);
            const newAppMainCompIndex = reorderedCompetitions.findIndex(comp => currentAppMainCompetition.id === comp.id);
            if (appRoundStarted && oldAppMainCompIndex !== newAppMainCompIndex) {
                showAlert('Event round has been already started for some golfers. Applying new competitions order will change the scoring available for other Golf Pad app golfers.' +
                    ' Are you sure you want to save new competitions order?',
                    [{
                        title: 'Cancel'
                    }, {
                        title: 'Proceed',
                        color: 'secondary',
                        action: async () => await this.saveCompetitionsOrder(event, reorderedCompetitions)
                    }]);
                return
            } else {
                return this.saveCompetitionsOrder(event, reorderedCompetitions);
            }
        } else {
            return this.saveCompetitionsOrder(event, reorderedCompetitions);
        }
    }

    private deleteCompetition(competition: Competition) {
        const ref = Backend.competitionsDb(this.props.event.id);
        return Backend.remove(ref, competition);
    }

    private handleClearAll = () => {
        showAlert('This will remove all scores for all players. The action can not be undone.', [
            { title: 'Proceed', action: this.doClearAll },
            { title: 'Cancel' }
        ]);
    }

    private handleBreakTies = () => this.setState({ openSelectWinnersDialog: true });
    private doClearAll = () => clearScores(this.props.event);
    private onGroups = (groups: Array<GolferGroup>) => this.setState({ groups }, this.getGolferScores);
    private onGolfers = (golfers: Map<string, Contact>) => this.setState({ golfers }, this.getGolferScores);
    private onGolferScores = (golferScores: Map<string, Score>) => this.setState({ golferScores }, this.getGolferScores);
    private onCompetitions = (competitions: Array<Competition>) => this.setState({
        competitions, competitionsLoaded: true,
        mainCompetition: getEventMainCompetition(competitions)
    }, this.getGolferScores);
    private onTeamScores = (teamScores: Map<string, Score>) => this.setState({ teamScores }, this.getGolferScores);
    private onReportedGolferScores = (reportedScores: Map<string, ReportedScore>) => this.setState({ reportedScores }, this.getGolferScores);
    private onReportedTeamScores = (reportedTeamScores: Map<string, ReportedScore>) => this.setState({ reportedTeamScores }, this.getGolferScores);
    private changeReorderingOpenStatus = (openCompetitionsReordering: boolean) => this.setState({ openCompetitionsReordering });

    private onTeams = (teams: Map<string, Team>) => {
        const { golfers } = this.state;
        setTeamHandicapIndexes(golfers, teams);
        this.setState({ teams }, this.getGolferScores);
    }

    private onDistances = (distances: Map<string, Distance>) => {
        distances.forEach(distance => distance.lengths = distance.lengths || array(MAX_HOLES, 0));
        this.setState({ distances });
    }

    private getGolferScores = () => {
        const { event } = this.props;
        const { competitions, reportedScores, reportedTeamScores, golferScores, teamScores, groups, golfers, teams, mainCompetition } = this.state;
        const calculatedScores = Scoring.getGolferScores(event, competitions, golfers, teams, groups, golferScores, teamScores, reportedScores, reportedTeamScores, mainCompetition, false);
        this.setState({ calculatedScores });
    }

    private handleSaveFromCompetitionDialog = (competition: Competition, competitionOld: Competition, resetScores: boolean, resetDistance: boolean, changes: Map<string, string>) => {
        const { event } = this.props;
        const { competitions, distances, mainCompetition } = this.state;
        const gender = genderFromEvent(event);
        if (!competition.flights) {
            competition.winners = competition.winners?.filter(w => w.flight === 0) || [];
        } else {
            range(1, competition.flights + 1).forEach(() => competition.winners = competition.winners?.filter(w => w.flight === 0) || []);
        }
        if (competition.scoring.mode === 'gross' || competition.scoring.mode === 'net') {
            competition.winners = competition.winners?.filter(w => w.mode === competition.scoring.mode) || [];
        }
        const noTeesCompetitions = competitions.filter(comp => comp.id !== competition.id && isMainScoring(comp.scoring) && !hasTees(event, comp));
        let changedCompetitions = [competition];
        const saveChanges = () => {
            if (resetDistance && mainCompetition) {
                const distance = clearDistance(event, competitionOld, distances, getTee(mainCompetition, gender, undefined));
                this.setState({ editingCompetition: undefined }, () => saveCompetitions(changedCompetitions, competitions, changes, event, resetScores, distance));
            } else {
                this.setState({ editingCompetition: undefined }, () => saveCompetitions(changedCompetitions, competitions, changes, event, resetScores));
            }
        };
        const addNoTeesAndSave = () => {
            noTeesCompetitions.forEach(comp => comp.tees = competition.tees);
            changedCompetitions = changedCompetitions.concat(noTeesCompetitions);
            saveChanges();
        };
        if (noTeesCompetitions.length > 0 && competition.tees) {
            showAlert('Use the same tee settings for other competitions where tees are not selected yet?', [
                { title: 'No, thanks', action: () => saveChanges() },
                { title: 'Yes', color: 'primary', action: () => addNoTeesAndSave() }
            ]);
        } else {
            saveChanges();
        }
    }

    private saveLiveScores = (hideLiveScores: boolean | 'OFF' | 'ON' | 'VERIFIED' | undefined) => {
        Backend.update(Backend.eventsDb, { id: this.props.event.id, hideLiveScores })
            .then(() => { this.setState({ openLiveScoresDialog: false }); this.getGolferScores(); });
    }

    private saveWinners = (selectedWinners: Map<string, Array<WinnerInfo>>) => {
        const { event } = this.props;
        const { competitions } = this.state;
        competitions.forEach(competition => competition.winners = selectedWinners.get(competition.id) || []);
        Backend.updateOrAddBatch(Backend.competitionsDb(event.id), competitions)
            .then(() => this.setState({ openSelectWinnersDialog: false }));
    }

    private handleSelectLDGolfer = (contacts: Array<Contact>) => {
        const { event } = this.props;
        const { editingLD, distances } = this.state;
        if (!editingLD) {
            return;
        }
        const selectedGolfer = contacts.length > 0 ? contacts[0] : undefined;
        updateGolferDistance(editingLD.competition, event, distances, editingLD.hole, 1, selectedGolfer)
            .then(() => this.setState({ editingLD: undefined }));
    }

    NoCompetitions = () => {
        const { classes, event } = this.props;
        const { competitionsLoaded } = this.state;
        if (competitionsLoaded && !event.course) {
            return (
                <List disablePadding>
                    <Typography noWrap align="center">{this.CourseNotSelected()}</Typography>
                </List>
            );
        } else if (competitionsLoaded) {
            return (
                <List disablePadding>
                    <Typography noWrap align="center">No competitions set up yet for this event.</Typography>
                    <Typography noWrap align="center">Click '<span className={classes.boldText}>Add Competition</span>' to get started.</Typography>
                </List>
            );
        } else {
            return (
                <List disablePadding>
                    <Typography noWrap align="center">Loading...</Typography>
                </List>
            );
        }
    }

    CourseNotSelected = () => {
        const { classes } = this.props;
        return (
            <React.Fragment>
                Course is not yet selected.
                Please select course in <span className={classes.smallIconButton}>Settings</span> before adding a competition or side game.
            </React.Fragment>
        );
    }

    Competitions = () => {
        const { competitions } = this.state;
        const competitionsMain = competitions.filter(competition => isMainScoring(competition.scoring))
            .sort((c1, c2) => c1.order - c2.order);
        const competitionsSideGames = competitions.filter(competition => !isMainScoring(competition.scoring))
            .sort((c1, c2) => c1.order - c2.order);
        return (
            <React.Fragment>
                {competitionsMain.length === 0 && this.NoCompetitions()}
                {competitionsMain.map(competition => this.Competition({ competition, key: competition.id }))}
                {competitionsSideGames.length > 0 && <ListTitle text={'Side Games'} />}
                {competitionsSideGames.map(competition => this.Competition({ competition, key: competition.id }))}
            </React.Fragment>
        );
    }

    private showPlayerScoresInfo = (e: React.SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
        const { classes } = this.props;
        showAlert(
            <React.Fragment>
                Golfers can post scores using the Golf Pad app on their phones. Scores submitted by golfers are highlighted in green.
                You can review and verify the scores by opening each golfer's or team's scorecard.
                Any scores entered by you (the organizer) will supersede golfer-submitted scores. <a href="https://support.golfpadgps.com/a/solutions/articles/6000245736" target="blank" className={classes.linkBlue}>Learn more</a>
            </React.Fragment>
        );
    }

    Competition = (params: { competition: Competition, key: string }) => {
        const { golfers, teams, reportedScores, golferScores, mainCompetition, teamScores, reportedTeamScores, groups, competitions } = this.state;
        const { competition, key } = params;
        const { classes, event } = this.props;
        let noTeesMessage = '';
        let allHandicapIndexesPresent = true;
        if (isMainScoring(competition.scoring)) {
            if (!hasTees(event, competition)) {
                noTeesMessage = 'Tee not selected';
            }
            if (competition.scoring.mode !== 'gross') {
                allHandicapIndexesPresent = Array.from(golfers.values()).every(contact => contact.handicapIndex != null);
            }
        }
        let payoutsExceedGolfers = '';
        if (isMainScoring(competition.scoring) && isPayoutsExceedGolfers(competition, golfers, teams)) {
            payoutsExceedGolfers = isTeamFormat(competition.scoring) ? 'The number of payouts exceeds the number of teams' : 'The number of payouts exceeds the number of golfers';
        }
        let isReportedScores = false;
        const teamFormatExceptBB = isTeamFormatExceptBB(competition.scoring, mainCompetition);
        if (isMainScoring(competition.scoring)) {
            if (teamFormatExceptBB) {
                reportedTeamScores.forEach(rts => {
                    isReportedScores = isReportedScores || Scoring.markAsReported(teamScores.get(rts.id), rts);
                });
            } else {
                reportedScores.forEach(rs => {
                    isReportedScores = isReportedScores || Scoring.markAsReported(golferScores.get(rs.id), rs);
                });
            }
        }
        const competitionName = Scoring.scoringName(competition, event.eventGender, competition.competitionGender, true);
        const isSkins = isSkinsScoring(competition.scoring);
        const isNet = isNetMode(competition.scoring.mode);
        const isGross = isGrossMode(competition.scoring.mode);
        const tee = getTee(mainCompetition ?? competition, genderFromEvent(event));
        let scores: ContactScoringState[] = [];
        let isFullScoresComp = false;
        let skinsMixed = false;
        if (isSkins) {
            scores = Scoring.golferHoleScores(event, competition, 0, golferScores, teamScores, reportedScores, reportedTeamScores, golfers, teams, groups, mainCompetition);
            isFullScoresComp = isFullScoresCompetition(event, competition, golferScores, teamScores, reportedScores, golfers, teams);
            skinsMixed = !!competitions.find(comp => comp.scoring.format === ScoringFormatTeams.best_ball);
        }
        //"inherit" | "disabled" | "secondary" | "primary" | "success" | "error" | "info" | "warning" | "action"
        const scoringTitle = (
            <Container>
                <InfoElement icon={<SettingsIcon color="action" />} rightIcon onCLick={() => this.openCompetitionSettings(competition)}>
                    <Typography variant="subtitle1" className={classes.listTitle + ' ' + classes.uppercaseText}>
                        {competitionName}
                    </Typography>
                </InfoElement>
                {!!noTeesMessage && <InfoElement icon={<ReportProblemIcon htmlColor={AppColors.errorColor} />} contentStyle={{ fontSize: 12, color: AppColors.errorColor }}>
                    {noTeesMessage}
                </InfoElement>}
                {!!payoutsExceedGolfers && <InfoElement icon={<ReportProblemIcon htmlColor={AppColors.errorColor} />} contentStyle={{ fontSize: 12, color: AppColors.errorColor }}>
                    {payoutsExceedGolfers}
                </InfoElement>}
                {!allHandicapIndexesPresent && <InfoElement iconColor={AppColors.webWarning} contentStyle={{ fontSize: 12, color: `#6D6D71` }}>
                    Some golfers do not have a Handicap index. 0 handicap will be used for net scoring calculation.
                </InfoElement>}
                <Typography className={classes.marginLeftAuto}>
                    {isSkins && <AppButton color='info' className={classes.marginLeftAuto} onClick={() => showAlert(
                        <div className={classes.minWidth640}>
                            {isNet && <SkinsDetailedInfo competition={Scoring.netCompetition(competition)} event={event} golfers={golfers}
                                teams={teams} skins={getSkinsScores(event, Scoring.netCompetition(competition), scores, tee)}
                                sameNameGolfersIdsSet={getSameNameGolfersIds(Array.from(golfers.values()))}
                                skinsMixed={skinsMixed} withWinnings={isFullScoresComp && isNetPayouts(competition)} />}
                            {isNet && <Spacing backgroundColor={'white'} />}
                            {isGross && <SkinsDetailedInfo competition={Scoring.grossCompetition(competition)} event={event} golfers={golfers}
                                teams={teams} skins={getSkinsScores(event, Scoring.grossCompetition(competition), scores, tee)}
                                sameNameGolfersIdsSet={getSameNameGolfersIds(Array.from(golfers.values()))}
                                skinsMixed={skinsMixed} withWinnings={isFullScoresComp && isGrossPayouts(competition)} />}
                            {isGross && <Spacing backgroundColor={'white'} />}
                        </div>,
                        [{ title: 'Close' }], undefined, undefined, false, 'Skins summary'
                    )}>Skins summary</AppButton>}
                    {isSkins && isReportedScores && <span className={classes.marginLeft10} />}
                    {isReportedScores && <Typography variant="caption" className={classes.marginLeftAuto}><span className={classes.legendIconGreen} />
                        <span style={{ display: 'inline', marginTop: '-5px', fontSize: '0.875rem' }}>
                            &nbsp;Golfer submitted scores&nbsp;
                            <IconButton size='small' onClick={this.showPlayerScoresInfo}>
                                <InfoIcon style={{ fontSize: '1em' }} />
                            </IconButton>
                        </span>
                    </Typography>}
                </Typography>
            </Container>
        );
        return (
            <React.Fragment key={key}>
                <div className={classes.listRoot2}>
                    <List disablePadding className={classes.listRoot2}>
                        {scoringTitle}
                        {isDistanceScoring(competition.scoring) ? this.DistanceCompetition({ competition }) :
                            this.ScoreCompetition({ competition })}
                    </List>
                </div>
                <Spacing backgroundColor={'white'} />
            </React.Fragment>
        );
    }

    ScoreCompetition = (params: { competition: Competition }) => {
        const { competition } = params;
        if (!competition.flights) {
            return this.ScoreCompetitionFlight(competition, 0);
        } else {
            return <React.Fragment>{range(1, competition.flights + 1).map(flight => this.ScoreCompetitionFlight(competition, flight))}</React.Fragment>;
        }
    }

    ScoreCompetitionFlight = (competition: Competition, flight: number) => {
        const { calculatedScores, golfers, mainCompetition } = this.state;
        const competitionScores = calculatedScores.get(competition.id);
        if (!competitionScores || !mainCompetition) {
            return null;
        }
        const isNet = isNetMode(competition.scoring.mode);
        const scores: ContactScoringState[] | undefined = isNet ? competitionScores.competitionScoresNet.get(flight) : competitionScores.competitionScoresGross.get(flight);
        if (!scores) {
            return null;
        }
        competition.winners = getScoresWinners(competition, calculatedScores);
        const { event } = this.props;
        const flightName = flight > 0 ? <Typography noWrap style={{ marginBottom: '0.35em', marginTop: '0.35em' }} variant="subtitle2">{getFlightName(flight, competition.flightsNaming).toUpperCase()}</Typography> : null;
        const sameNameGolfersIdsSet: Set<string> = getSameNameGolfersIds(Array.from(golfers.values()));
        const isGross = isGrossMode(competition.scoring.mode);
        let skinsGrossMap: Map<string, number>;
        let skinsNetMap: Map<string, number>;
        let skinsGross: SkinsScoringState[] = [];
        let skinsNet: SkinsScoringState[] = [];
        if (isSkinsScoring(competition.scoring)) {
            if (isGross) {
                const grossCompetition = Scoring.grossCompetition(competition);
                skinsGrossMap = new Map<string, number>();
                skinsGross = getSkinsScores(event, grossCompetition, scores, getTee(mainCompetition, genderFromEvent(event), undefined));
                processSkins(skinsGross, skinsGrossMap, grossCompetition, !isNet, scores);
            }
            if (isNet) {
                const netCompetition = Scoring.netCompetition(competition);
                skinsNetMap = new Map<string, number>();
                skinsNet = getSkinsScores(event, netCompetition, scores, getTee(mainCompetition, genderFromEvent(event), undefined));
                processSkins(skinsNet, skinsNetMap, netCompetition, true, scores, isGross ? skinsGrossMap! : undefined);
            }
        }
        const isStableford = isStablefordScoringOrMode(competition.scoring);
        if (isTeamFormat(competition.scoring)) {
            return (
                <List disablePadding key={competition.id + '-' + flight}>
                    {flightName}
                    <HeaderScore scoring={competition.scoring} isStableford={isStableford} />
                    {scores.map(score => <RowTeamScore
                        {...score}
                        mode={competition.scoring.mode}
                        key={score.contactInfo.id}
                        isStableford={isStableford}
                        onClick={() => this.setState({ openedScore: { competition, ...score, skinsGross, skinsNet } })}
                        holesType={event.holesType}
                        sameNameGolfersIdsSet={sameNameGolfersIdsSet}
                        golfers={golfers}
                        skinsGrossScore={skinsGrossMap && (skinsGrossMap.get(score.contactInfo.id) ?? 0)}
                        skinsNetScore={skinsNetMap && (skinsNetMap.get(score.contactInfo.id) ?? 0)} />)}
                </List>
            );
        } else {
            return (
                <List disablePadding key={competition.id + '-' + flight}>
                    {flightName}
                    <HeaderScore scoring={competition.scoring} isStableford={isStableford} />
                    {scores.map(score => <RowGolferScore
                        {...score}
                        mode={competition.scoring.mode}
                        key={score.contactInfo.id}
                        onClick={() => this.setState({ openedScore: { competition, ...score, skinsGross, skinsNet } })}
                        isStableford={isStableford}
                        holesType={event.holesType}
                        sameNameGolfersIdsSet={sameNameGolfersIdsSet}
                        golfers={golfers}
                        skinsGrossScore={skinsGrossMap && (skinsGrossMap.get(score.contactInfo.id) ?? 0)}
                        skinsNetScore={skinsNetMap && (skinsNetMap.get(score.contactInfo.id) ?? 0)} />)}
                </List>
            );
        }
    }

    DistanceCompetition = (params: { competition: Competition }) => {
        const { competition } = params;
        if (competition.scoring.format === ScoringFormatDistance.longest_drive) {
            return this.LDCompetition({ competition });
        } else {
            return this.KPCompetition({ competition });
        }
    }

    LDCompetition = (params: { competition: Competition }) => {
        const { event } = this.props;
        const { competition } = params;
        const { golfers, distances, mainCompetition } = this.state;
        if (!mainCompetition) {
            return null;
        }
        const gender = genderFromEvent(event);
        const distanceInfo: HolesScoringState[] = getDistanceScores(event, competition, golfers, distances, getTee(mainCompetition, gender, undefined));
        const sameNameGolfersIdsSet: Set<string> = getSameNameGolfersIds(Array.from(golfers.values()));
        return (
            <List disablePadding>
                <LDHeader />
                {distanceInfo.map(distanceProp => <LDRow key={distanceProp.hole} {...distanceProp} sameNameGolfersIdsSet={sameNameGolfersIdsSet}
                    onClick={() => this.setState({
                        editingLD: {
                            competition,
                            hole: distanceProp.hole,
                            golfer: distanceProp.contacts ? distanceProp.contacts[0] : undefined
                        }
                    })} />)}
            </List >
        );
    }

    KPCompetition = (params: { competition: Competition }) => {
        const { event } = this.props;
        const { competition } = params;
        const { golfers, distances, units, mainCompetition } = this.state;
        if (!mainCompetition) {
            return null;
        }
        const gender = genderFromEvent(event);
        const distanceInfo = getDistanceScores(event, competition, golfers, distances, getTee(mainCompetition, gender, undefined));
        const sameNameGolfersIdsSet: Set<string> = getSameNameGolfersIds(Array.from(golfers.values()));
        return (
            <List disablePadding>
                <KPHeader />
                {distanceInfo.map(distanceProp => <KPRow key={distanceProp.hole} {...distanceProp} units={units} sameNameGolfersIdsSet={sameNameGolfersIdsSet}
                    onClick={() => this.setState({
                        editingKP: {
                            competition,
                            hole: distanceProp.hole,
                            distance: distanceProp.score ? distanceProp.score : undefined,
                            golfer: distanceProp.contacts ? distanceProp.contacts[0] : undefined
                        }
                    })} />)}
            </List >
        );
    }

    render() {
        const { event, classes } = this.props;
        const { editingCompetition, editingCompetitionIsNew, openedScore, competitions, golfers, teams, golferScores, teamScores,
            confirmingDeleteCompetition, editingLD, editingKP, distances, openLiveScoresDialog, openSelectWinnersDialog,
            courseNotSelectedMessage, calculatedScores, openCompetitionsReordering, mainCompetition, groups } = this.state;
        let ties = 0;
        calculatedScores.forEach(s => s.competitionWinnersGross.forEach(w => ties += w.length > 1 ? 1 : 0));
        calculatedScores.forEach(s => s.competitionWinnersNet.forEach(w => ties += w.length > 1 ? 1 : 0));
        const readonlyScoresDialog = Boolean(openedScore?.competition.scoring.format === ScoringFormatSkins.skins_team
            && mainCompetition?.scoring.format === ScoringFormatTeams.best_ball);
        return (
            <div className={classes.root}>
                <ButtonBar margin>
                    <AppButton color="secondary" onClick={this.addCompetition}>Add Competition</AppButton>
                    <AppButton color="secondary" onClick={this.addSideGame}>Add Side Game</AppButton>
                    <AppButton color="info" onClick={this.handleExportClick}>
                        Export<DropDownArrowIcon className={classes.rightButtonIcon} />
                    </AppButton>
                    {this.ExportMenu()}
                    <AppButton color="info" onClick={this.handleBreakTies} disabled={ties === 0}>Break Ties</AppButton>
                    <AppButton color="info" onClick={this.handleClearAll}>Clear All</AppButton>
                    <AppButton color="info" style={{ marginLeft: 'auto', marginRight: 0 }} disabled={competitions.length === 0}
                        onClick={() => this.changeReorderingOpenStatus(true)}>Reorder</AppButton>
                </ButtonBar>
                <div className={classes.listRoot2}>
                    <List disablePadding className={classes.listRoot2}>
                        <LabeledField label={'Live scores'} itemClass={classes.listItem}
                            value={liveScoresName(event.hideLiveScores)}
                            edit={() => this.setState({ openLiveScoresDialog: true })} />
                    </List>
                </div>
                <Spacing backgroundColor={'white'} />
                <this.Competitions />
                {editingLD && <SelectGolfersDialog
                    competition={editingLD.competition}
                    golfers={golfers}
                    label={'Select winner'}
                    selectMode={'single-or-none'}
                    handleSelect={this.handleSelectLDGolfer}
                    handleCancel={() => this.setState({ editingLD: undefined })}
                    selectedGolferIds={editingLD.golfer ? [editingLD.golfer.id] : []} />}
                {editingKP && <AddResultDialog
                    open
                    golfers={golfers}
                    competition={editingKP.competition}
                    event={event}
                    distance={editingKP.distance}
                    distances={distances}
                    hole={editingKP.hole}
                    initialGolfer={editingKP.golfer}
                    close={() => this.setState({ editingKP: undefined })} />}
                {editingCompetition && <CompetitionSettingsDialog
                    open
                    teams={teams}
                    golfers={golfers}
                    hasScores={hasScores(event, editingCompetition, competitions, golferScores, teamScores, distances, golfers, teams)}
                    competitions={competitions}
                    competition={editingCompetition}
                    isNewCompetition={editingCompetitionIsNew}
                    mainCompetition={mainCompetition}
                    handleSave={this.handleSaveFromCompetitionDialog}
                    handleClose={this.handleCloseCompetitionEditDialog}
                    handleDelete={this.handleDeleteFromCompetitionEditDialog}
                    event={event} />}
                {confirmingDeleteCompetition && <ConfirmDialog
                    open
                    onOk={confirmingDeleteCompetition}
                    onCancel={this.skipDeleteCompetition}
                    content="Permanently delete competition?"
                    okLabel="Delete" />}
                {courseNotSelectedMessage && <MessageDialog
                    open
                    onClose={() => this.setState({ courseNotSelectedMessage: undefined })}
                    title="Course not selected"
                    content={this.CourseNotSelected()} />}
                {openedScore && <EditScoreDialog
                    open
                    readonly={readonlyScoresDialog}
                    golfers={golfers}
                    teams={teams}
                    eventData={{ competitions, golfers, teams, groups } as EventData}
                    {...openedScore}
                    mainCompetition={mainCompetition}
                    close={() => this.setState({ openedScore: undefined })} />}
                {openLiveScoresDialog && <SelectLiveScoresDialog
                    open
                    save={this.saveLiveScores}
                    close={() => this.setState({ openLiveScoresDialog: false })}
                    hideLiveScores={event.hideLiveScores} />}
                {openSelectWinnersDialog && <SelectWinnersDialog
                    open
                    event={event}
                    competitions={competitions}
                    calculatedScores={calculatedScores}
                    save={this.saveWinners}
                    close={() => this.setState({ openSelectWinnersDialog: false })} />}
                {openCompetitionsReordering && <ReorderCompetitionsDialog
                    open
                    competitions={competitions}
                    handleClose={() => this.changeReorderingOpenStatus(false)}
                    handleSave={async reorderedCompetitions => { await this.saveNewCompetitionsOrder(reorderedCompetitions); }} />}
                <FirebaseDataComponent query={Backend.query(Backend.golferDb(event.id), Backend.where('hidden', '==', false))} onMap={this.onGolfers} />
                <FirebaseDataComponent query={Backend.query(Backend.golferTeamDb(event.id), Backend.orderBy('order'))} onMap={this.onTeams} />
                <FirebaseDataComponent query={Backend.golferTeamScoresDb(event.id)} onMap={this.onTeamScores} />
                <FirebaseDataComponent query={Backend.golferScoresDb(event.id)} onMap={this.onGolferScores} />
                <FirebaseDataComponent query={Backend.reportedGolferScoresDb(event.id)} onMap={this.onReportedGolferScores} />
                <FirebaseDataComponent query={Backend.reportedTeamScoresDb(event.id)} onMap={this.onReportedTeamScores} />
                <FirebaseDataComponent query={Backend.golferDistancesDb(event.id)} onMap={this.onDistances} />
                <FirebaseDataComponent query={Backend.query(Backend.competitionsDb(event.id), Backend.orderBy('order'))} onData={this.onCompetitions} />
                <FirebaseDataComponent query={Backend.query(Backend.golferGroupDb(event.id), Backend.orderBy('order'))} onData={this.onGroups} />
                <FirebaseUserPubDataComponent uid={event.userId} onData={data => this.setState({ units: data.units })} />
            </div>
        );
    }
}

export default withStyles(styles)(ScoreList);
