import * as React from 'react';

import { Event, EVENT_GENDER_LABELS } from '../../../../types/EventTypes';
import EventGenderSettingsDialog from './EventGenderSettingsDialog';
import LabeledField from '../../../../common/form/LabeledField';
import { ListElem } from '../../../../common/Misc';

interface Props {
    event: Event;
}

interface State {
    dialogOpened?: boolean;
}

class EventGenderSettingsField extends React.Component<Props, State> {
    state: State = {};

    private handleOpen = () => this.setState({ dialogOpened: true });
    private handleClose = () => this.setState({ dialogOpened: false });

    render() {
        const { event } = this.props;
        const { dialogOpened } = this.state;
        const title = EVENT_GENDER_LABELS[event.eventGender === 'men' ? 1 : event.eventGender === 'women' ? 2 : 0];
        return (
            <React.Fragment>
                <LabeledField label="Gender" value={(<ListElem id={title} title={title} subtitle=" " />)} edit={this.handleOpen} />
                {dialogOpened && <EventGenderSettingsDialog open={true} onClose={this.handleClose} event={event} />}
            </React.Fragment>
        );
    }
}

export default EventGenderSettingsField;
