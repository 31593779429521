import * as React from 'react';
import { DialogProps } from '@mui/material/Dialog';
import { XSMobileDialog } from '../../../common/dialog/MobileDialog';
import DialogAppBar from '../../../common/dialog/DialogAppBar';
import { List, ListItem, DialogActions, DialogContent, InputLabel, Select, MenuItem, Typography } from '@mui/material';
import TypedFormRadioLabel from '../../../common/form/TypedFormRadioLabel';
import LinkIcon from '@mui/icons-material/OpenInNew';
import { Event, Contact, Team, FlightsNamingMode, isTeamFormat, getFlightTee, getFlightParticipantsCount, contactsOfFlight, teamsOfFlight, getParticipantsCount, Competition } from '../../../types/EventTypes';
import { copyCompetition, isPayoutsExceedGolfers, getPayoutsCaption } from '../../Event';
import { getFlightName } from '../../TeeTimes';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../../styles';
import { range, withS } from '../../../util/utility';
import { processEnterKey } from '../../../util/react_utils';
import FormControl from '@mui/material/FormControl';
import AppButton from '../../../common/components/AppButton';
import { Urls } from '../../../util/config';
import { Container, Item, EditIcon } from '../../../common/Misc';
import CompetitionFlightDetailsDialog from './CompetitionFlightDetailsDialog';

type Props = {
    competition: Competition;
    golfers: Map<string, Contact>;
    teams: Map<string, Team>;
    event: Event;
    handleSave: (competition: Competition) => void;
    handleDelete: () => void;
} & WithStyles<typeof styles> & DialogProps;

type State = {
    competition: Competition;
    editedFlight?: number;
};

const FlightsHeader = withStyles(styles)((props: WithStyles<typeof styles>) => {
    const { classes } = props;
    const rightIcon = <EditIcon invisible />;
    return (
        <ListItem className={classes.listItemHeader}>
            <Container wrap="nowrap">
                <Item xs={2} noWrap variant="body2">Flight</Item>
                <Item xs={3} noWrap variant="body2">Participants</Item>
                <Item xs={3} noWrap variant="body2">Handicaps</Item>
                <Item xs={3} noWrap variant="body2">Tees</Item>
                <Item xs={3} noWrap variant="body2">Payouts</Item>
                <Item xs={1} noWrap variant="body2" placeRight className={classes.colorWhite}>{rightIcon}</Item>
            </Container>
        </ListItem>
    );
});

type FlightsRowProps = {
    competition: Competition;
    golfers: Map<string, Contact>;
    teams: Map<string, Team>;
    event: Event;
    flight: number;
    clickHandler: (flight: number) => void;
} & WithStyles<typeof styles>;

const FlightsRow = withStyles(styles)((props: FlightsRowProps) => {
    const { classes, flight, competition, golfers, teams, event } = props;
    const rightIcon = <EditIcon />;
    const flightName = getFlightName(flight, competition.flightsNaming);
    const participantsCount = getFlightParticipantsCount(competition, golfers, teams, flight);
    const participantsLabel = withS(participantsCount, isTeamFormat(competition.scoring) ? 'team' : 'golfer');
    const handicapRange = isTeamFormat(competition.scoring) ? teamsOfFlight(competition, golfers, teams, flight).map((t: Contact | Team) => (t.handicapIndex || 0)) : contactsOfFlight(competition, golfers, teams, flight).map((t: Contact | Team) => (t.handicapIndex || 0));
    const teeM = getFlightTee(competition, flight, 'male');
    const teeF = getFlightTee(competition, flight, 'female');
    const mensTeeName = teeM ? teeM.name : 'Default';
    const womensTeeName = teeF ? teeF.name : 'Default';
    const teesCaption = (!event.eventGender || event.eventGender === 'both') && (!competition.competitionGender || competition.competitionGender === 'both') ? mensTeeName + '/' + womensTeeName
        : event.eventGender === 'men' || competition.competitionGender === 'men' ? mensTeeName : womensTeeName;
    const payoutsCaption = getPayoutsCaption(competition, flight);
    const isPayoutExceed = isPayoutsExceedGolfers(competition, golfers, teams, flight);
    return (
        <div>
            <ListItem button className={classes.listItem} onClick={() => props.clickHandler(flight)}>
                <Container wrap="nowrap">
                    <Item xs={2} noWrap variant="body2">{flightName}</Item>
                    <Item xs={3} noWrap variant="body2">{participantsLabel}</Item>
                    <Item xs={3} noWrap variant="body2">{handicapRange[0] + ' to ' + handicapRange[handicapRange.length - 1]}</Item>
                    <Item xs={3} noWrap variant="body2">{teesCaption}</Item>
                    <Item xs={3} noWrap variant="body2" className={isPayoutExceed ? classes.redText : ''}>{payoutsCaption}</Item>
                    <Item xs={1} noWrap variant="body2" placeRight className={classes.colorWhite}>{rightIcon}</Item>
                </Container>
            </ListItem>

        </div>
    );
});

class CompetitionFlightsSettingsDialog extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        const competition = copyCompetition(this.props.competition);
        if (!competition.flights) competition.flights = 2;
        if (!competition.flightsNaming) competition.flightsNaming = 'numerical';
        this.state = {
            competition: competition,
        };
    }

    private handleClose = () => {
        const close = this.props.onClose;
        if (close) {
            close({} as React.SyntheticEvent<any>, 'escapeKeyDown');
        }
    }

    private handleSave = () => {
        const { handleSave } = this.props;
        const { competition } = this.state;
        handleSave(competition);
    }

    private handleFlightRowClick = (flight: number) => {
        this.setState({ editedFlight: flight });
    }

    private handleFlightsChange = (uiEvent: any) => {
        const { competition } = this.state;
        competition.flights = uiEvent.target.value;
        this.setState({ competition });
    }

    private handleNamingChange = (val: string) => {
        const { competition } = this.state;
        competition.flightsNaming = val as FlightsNamingMode;
        this.setState({ competition });
    }

    render() {
        const { classes, golfers, teams, event } = this.props;
        const { competition, editedFlight } = this.state;
        const title = 'Flights settings';
        const flights = [{ count: 2, label: '2 flights' }, { count: 3, label: '3 flights' }, { count: 4, label: '4 flights' }, { count: 5, label: '5 flights' }, { count: 6, label: '6 flights' }, { count: 7, label: '7 flights' }];
        const participantsCount = getParticipantsCount(competition, golfers, teams);
        const isPayoutExceed = range(1, competition.flights || 2).reduce((acc, curr) => acc += isPayoutsExceedGolfers(competition, golfers, teams, curr) ? 1 : 0, 0) > 0;
        return (
            <React.Fragment>
                {!!editedFlight && <CompetitionFlightDetailsDialog open={true}
                    golfers={golfers}
                    teams={teams}
                    competition={competition}
                    event={event}
                    flight={editedFlight}
                    handleSave={(competition: Competition) => this.setState({ competition, editedFlight: undefined })}
                    onClose={() => this.setState({ editedFlight: undefined })} />
                }
                <XSMobileDialog open={this.props.open} onClose={this.handleClose}>
                    <DialogAppBar label={title} close={this.handleClose} />
                    <DialogContent onKeyDown={uiEvent => processEnterKey(uiEvent, this.handleSave)} >
                        <FormControl variant="standard" fullWidth style={{ flexDirection: 'row' }}>
                            <InputLabel margin="dense" htmlFor="select-flights-menu">Number of flights</InputLabel>
                            <Select
                                variant="standard"
                                id="select-flights-menu"
                                aria-describedby="select-helper-text"
                                value={competition.flights}
                                onChange={this.handleFlightsChange}>
                                {flights.slice(0, Math.min(participantsCount - 1, flights.length)).map(f => <MenuItem key={f.count} value={f.count}>{f.label}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <Typography variant="body1" style={{ marginTop: 5 }}>Individual golfers or teams will be divided equally into flights, based on handicap index. <a href={Urls.helpFlightsURL} target="help" className={classes.link}><LinkIcon className={classes.textIcon} />Learn more</a></Typography>
                        <FormControl
                            variant="standard"
                            margin="dense"
                            fullWidth
                            style={{ flexDirection: 'row' }}>
                            <InputLabel shrink>Default flight naming</InputLabel>
                            <TypedFormRadioLabel value="literal" label="A, B, C, ..." currentValue={competition.flightsNaming}
                                handleChange={this.handleNamingChange} className={classes.formSelector} />
                            <TypedFormRadioLabel value="numerical" label="1, 2, 3, ..." currentValue={competition.flightsNaming}
                                handleChange={this.handleNamingChange} className={classes.formSelector} />
                        </FormControl>
                        <FormControl
                            variant="standard"
                            margin="dense"
                            fullWidth
                            style={{ flexDirection: 'row' }}>
                            <InputLabel shrink>Flights</InputLabel>
                            {isPayoutExceed && <Typography variant="body1" color="error" style={{ margin: '0px 0px 0px 60px' }}>The number of payouts exceeds the number of golfers</Typography>}
                        </FormControl>
                        <List disablePadding style={{ marginTop: 5 }} >
                            <FlightsHeader />
                            {range(0, competition.flights || 1).map(i => <FlightsRow key={i} competition={competition} golfers={golfers} teams={teams} event={event} flight={i + 1} clickHandler={this.handleFlightRowClick} ></FlightsRow>)}
                        </List>
                    </DialogContent>
                    <DialogActions>
                        <AppButton color="info" onClick={() => this.props.handleDelete()}>Delete flights</AppButton>
                        {<span style={{ flex: '1 1 0%' }} />}
                        <AppButton color="info" onClick={this.handleClose}>Cancel</AppButton>
                        <AppButton color="secondary" onClick={this.handleSave} disabled={!competition.flights || competition.flights === 1}>Save</AppButton>
                    </DialogActions>
                </XSMobileDialog>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(CompetitionFlightsSettingsDialog);