import * as React from 'react';
import Typography from '@mui/material/Typography';
import LabeledField from './LabeledField';
import { ReactNode } from 'react';
import withForm, { ValidateProps } from '../../validation/ValidatedForm';
import withEditDialog, { CommonEditDialogProps, EditDialogProps, WithSave } from './WithEditDialog';
import AppButton from '../../common/components/AppButton';

interface Props<T> {
    toShow: (value: T) => string | ReactNode;
    fullScreen?: boolean;
    skipEnterKey?: boolean;
    contentPadding?: string;
}

interface State {
    editing: boolean;
}

const withEditableField = function <P, T>(props: Props<T>) {
    return function(Component: React.ComponentType<P & EditDialogProps<T> & CommonEditDialogProps<T>>) {
        type ResProps = P & CommonEditDialogProps<T> & ValidateProps & WithSave<T>;
        const { toShow, fullScreen, skipEnterKey, contentPadding } = props;
        const WDialog = withEditDialog(Component);
        return withForm(class extends React.Component<ResProps, State> {
            state: State = { editing: false };

            private handleOpenDialog = () => this.setState({ editing: true });
            private handleClose = () => this.setState({ editing: false });

            private handleSave = (value: T) => {
                if (this.props.savePromise) {
                    this.props.savePromise(value).then(close => this.setState({ editing: !close }));
                } else {
                    this.setState({ editing: false }, () => this.props.save(value));
                }
            }

            render() {
                const { label, hint, value, placeholder, icon, valid, save, ...others } = this.props as any;
                const { editing } = this.state;
                return (
                    <React.Fragment>
                        {(placeholder && !value) ?
                            <LabeledField label={label} hint={hint} value={<Typography style={{ color: 'gray' }}>{placeholder}&nbsp;&nbsp;&nbsp;<AppButton color="primary" onClick={this.handleOpenDialog}>Add</AppButton></Typography>} icon={icon} /> :
                            <LabeledField label={label} hint={hint} value={toShow(value)} icon={icon} edit={this.handleOpenDialog} />}
                        <WDialog
                            open={editing}
                            contentPadding={contentPadding}
                            save={this.handleSave}
                            close={this.handleClose}
                            fullScreen={fullScreen}
                            skipEnterKey={skipEnterKey}
                            label={label} value={value}
                            {...others} />
                    </React.Fragment>
                );
            }
        });
    };
};

export default withEditableField;
