import * as React from 'react';
import { SyntheticEvent } from 'react';
import { Theme } from '@mui/material/styles';
import { WithStyles, StyleRules } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { XSMobileDialog } from '../../../common/dialog/MobileDialog';
import DialogAppBar from '../../../common/dialog/DialogAppBar';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Input, DialogActions, DialogContent, InputLabel, InputAdornment, Typography, IconButton, Checkbox } from '@mui/material';
import { ChangeEvent } from 'react';
import FormControl from '@mui/material/FormControl';
import { DialogProps } from '@mui/material/Dialog';
import { styles } from '../../../styles';
import { Competition, PurseType, ScoringFormatSkins, isKPScoring, isLongestDriveScoring, isSkinsScoring, isTeamScoring, isIndividualScoring, isTeamFormat, PayoutSettings, Event, getTotalHoles } from '../../../types/EventTypes';
import { copyPayoutsSettings, calcComputedPurseAmount } from '../../Event';
import { range, withS } from '../../../util/utility';
import { processEnterKey } from '../../../util/react_utils';
import AppButton from '../../../common/components/AppButton';
import TypedFormRadioLabel from '../../../common/form/TypedFormRadioLabel';
import { Container, Item } from '../../../common/Misc';
import { InfoIcon } from '../../../common/Icons';
import { showAlert } from '../../../redux/ReduxConfig';
import CompetitionPayoutCommonSplitsDialog from './CompetitionPayoutCommonSplitsDialog';
import withForm, { ValidateProps } from '../../../validation/ValidatedForm';
import { round, formatCurrency, isCurrNumber } from '../../../util/utility';

const DEFAULT_PAYOUTS = [50, 30, 20, 0, 0, 0, 0, 0, 0, 0];

type Props = {
    open: boolean;
    event: Event;
    isTeam: boolean;
    isSkins: boolean;
    competition: Competition;
    participantCount: number;
    payoutSettings: PayoutSettings;
    handleSave: (payoutSettings: PayoutSettings) => void;
} & WithStyles<typeof styles> & DialogProps & ValidateProps;

type State = {
    payoutSettings: PayoutSettings;
    selectCommonSplit?: boolean;
    fixedPurseStr: string;
    computedPurseStr: string;
    feeAmountStr: string;
    roundingStr: string;
    payoutPerHoleStr: string;
    showMore?: boolean;
    valuePerSkinStr: string;
};

interface EditProps {
    value?: number;
    onValue: (val?: number) => void;
    readonly?: boolean;
    error?: boolean;
    disable?: boolean;
}

interface EditState {
    focused: boolean;
}

const editStyles = (theme: Theme) => {
    return {
        input: {
            width: 56,
            height: 32,
            boxSizing: 'border-box',
            padding: 0,
            border: `1px solid ${theme.palette.grey[200]}`,
            textAlign: 'center',
            outline: 'none',
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.fontSize,
            zIndex: 1,
            background: 'none'
        },
        inputError: {
            backgroundColor: '#FFE0E0'
        },
        decor: {
            border: `1px solid transparent`,
            position: 'absolute',
            top: 8,
            left: 8,
            right: 8,
            bottom: 8,
        }
    } as StyleRules;
};


export function getParticipantsName(isTeam: boolean, participantCount: number) {
    if (participantCount) {
        if (isTeam) {
            return withS(participantCount, 'team');
        } else {
            return withS(participantCount, 'golfer');
        }
    } else {
        if (isTeam) {
            return 'No teams yet';
        } else {
            return 'No golfers yet';
        }
    }
}


class EditImpl extends React.Component<EditProps & WithStyles<typeof styles>, EditState> {
    state: EditState = { focused: false };
    render() {
        const { value, onValue, classes, error, disable } = this.props;
        const { focused } = this.state;
        const strVal = (value === undefined ? '0' : value) + (focused ? '' : '%');
        function onChange(event: ChangeEvent<HTMLInputElement>) {
            const newVal = event.target.value;
            if (newVal === '') {
                onValue();
                return;
            }
            const val = parseInt(newVal, 10);
            if (val >= 0 && val <= 100) {
                onValue(val);
            } else {
                onValue();
            }
        }
        return (
            <React.Fragment>
                <input value={strVal}
                    disabled={disable}
                    onChange={onChange}
                    onFocus={_ => this.setState({ focused: true })}
                    onBlur={_ => this.setState({ focused: false })}
                    className={`${classes.input} ${error ? classes.inputError : ''}`} />
            </React.Fragment>
        );
    }
}

export const updatePayouts = (payoutSettings: PayoutSettings, participantCount: number, holesNumber: number) => {
    if (payoutSettings.purseType === 'valuePerSkin') {
        return;
    }
    const purse = (payoutSettings.purseType === 'fixed' ? payoutSettings.fixedAmount : calcComputedPurseAmount(payoutSettings, participantCount)) || 0;
    payoutSettings.payoutPerHole = round(purse / holesNumber, 2);
    if (payoutSettings.rounding && payoutSettings.roundingValue) {
        payoutSettings.payoutPerHole = round(payoutSettings.payoutPerHole / payoutSettings.roundingValue, 0) * payoutSettings.roundingValue;
    }
}

export const Edit = withStyles(editStyles)(EditImpl);

const schedule_explanation = 'Tied positions will be added together, then split between # of ties. Example: Tie for 2nd place, 30%+20%=50%/2=25% for each 2nd place finisher, remaining percentages stay the same.';

class CompetitionPayoutSettingsDialog extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        const payoutSettings = copyPayoutsSettings(this.props.payoutSettings);
        const computedPurseStr = formatCurrency(calcComputedPurseAmount(this.props.payoutSettings, this.props.participantCount));
        if (!payoutSettings!.payoutSchedule || payoutSettings!.payoutSchedule.length === 0) {
            payoutSettings!.payoutSchedule = DEFAULT_PAYOUTS;
        } else {
            const index = payoutSettings!.payoutSchedule!.findIndex(i => !i);
            if (index >= 0 && payoutSettings!.payoutSchedule!.length > Math.ceil(index / 10) * 10) {
                payoutSettings!.payoutSchedule = payoutSettings!.payoutSchedule!.slice(0, Math.ceil(index / 10) * 10);
            }
        }
        this.state = {
            payoutSettings: payoutSettings,
            showMore: false,
            computedPurseStr: computedPurseStr,
            fixedPurseStr: payoutSettings!.fixedAmount ? formatCurrency(payoutSettings!.fixedAmount) : '0',
            feeAmountStr: payoutSettings!.entryFee ? formatCurrency(payoutSettings!.entryFee) : '0',
            roundingStr: payoutSettings!.roundingValue ? formatCurrency(payoutSettings!.roundingValue) : '0',
            payoutPerHoleStr: payoutSettings!.payoutPerHole ? formatCurrency(payoutSettings!.payoutPerHole) : '0',
            valuePerSkinStr: payoutSettings.valuePerSkin ? formatCurrency(payoutSettings.valuePerSkin) : '0'
        };
    }

    private getHolesNumber = () => {
        const { competition, event } = this.props;
        const isSkins = isSkinsScoring(competition.scoring);
        return competition.scoring.holes ? competition.scoring.holes.length : isSkins ? (event.holesType ? getTotalHoles(event.holesType) : 18) : 0;
    }

    private updatePayouts = () => {
        const { participantCount } = this.props;
        const { payoutSettings } = this.state;
        const holesNumber = this.getHolesNumber();
        if (holesNumber > 0) {
            updatePayouts(payoutSettings, participantCount, holesNumber);
            this.setState({ payoutSettings, payoutPerHoleStr: formatCurrency(payoutSettings.payoutPerHole!) });
        }
    }

    private handleClose = () => {
        const close = this.props.onClose;
        if (close) {
            close({} as React.SyntheticEvent<any>, 'escapeKeyDown');
        }
    }

    private handleSave = () => {
        const { payoutSettings, payoutPerHoleStr, fixedPurseStr } = this.state;
        const { handleSave } = this.props;
        const index = payoutSettings.payoutSchedule!.findIndex(i => !i);
        if (index >= 0 && payoutSettings.payoutSchedule!.length > Math.ceil(index / 10) * 10) {
            payoutSettings.payoutSchedule = payoutSettings.payoutSchedule!.slice(0, Math.ceil(index / 10) * 10);
        }
        if (payoutSettings.payoutPerHole === undefined && payoutSettings.purseType === 'fixed' && payoutSettings.fixedAmount === undefined) {
            payoutSettings.payoutPerHole = parseFloat(payoutPerHoleStr) || 0;
            payoutSettings.fixedAmount = parseFloat(fixedPurseStr) || 0;
        }
        this.setState({ payoutSettings });
        if (payoutSettings.purseType !== 'valuePerSkin' && payoutSettings.payoutPerHole === 0) {
            showAlert('Please note that payout per hole is 0. Are you sure about saving this settings?', [
                { title: 'Cancel' },
                { title: 'Save', color: 'secondary', action: () => handleSave(payoutSettings) }
            ]);
        } else {
            handleSave(payoutSettings);
        }
    }

    private onPurseTypeChange = (purseType: PurseType) => {
        const { payoutSettings } = this.state;
        payoutSettings.purseType = purseType;
        this.setState({ payoutSettings });
        this.updatePayouts();
    };

    private handlePurseAmountChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { payoutSettings } = this.state;
        if (isCurrNumber.test(event.target.value) || event.target.value === '') {
            const val = (event.target.value.length > 1 && event.target.value[0] === '0') ? event.target.value.slice(1, event.target.value.length) : event.target.value;
            payoutSettings.fixedAmount = parseFloat(val) || 0;
            this.setState({ fixedPurseStr: val });
            this.updatePayouts();
        }
    };

    private handlePurseAmountBlur = () => {
        this.setState({ fixedPurseStr: formatCurrency(this.state.payoutSettings.fixedAmount || 0) });
    };

    private handleValuePerSkinChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { payoutSettings } = this.state;
        if (isCurrNumber.test(event.target.value) || event.target.value === '') {
            const val = (event.target.value.length > 1 && event.target.value[0] === '0') ? event.target.value.slice(1, event.target.value.length) : event.target.value;
            payoutSettings.valuePerSkin = parseFloat(val) || 0;
            this.setState({ valuePerSkinStr: val });
        }
    };

    private handleValuePerSkinBlur = () => {
        this.setState({ valuePerSkinStr: formatCurrency(this.state.payoutSettings.valuePerSkin || 0) });
    };

    private handleFeeAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { participantCount } = this.props;
        const { payoutSettings } = this.state;
        if (isCurrNumber.test(event.target.value) || event.target.value === '') {
            const val = (event.target.value.length > 1 && event.target.value[0] === '0') ? event.target.value.slice(1, event.target.value.length) : event.target.value;
            payoutSettings.entryFee = parseFloat(val) || 0;
            const computedPurseStr = formatCurrency(payoutSettings.entryFee * participantCount);
            this.setState({ feeAmountStr: val, computedPurseStr: computedPurseStr });
            this.updatePayouts();
        }
    };

    private handleFeeAmountBlur = () => {
        this.setState({ feeAmountStr: formatCurrency(this.state.payoutSettings.entryFee || 0) });
    };

    private handleRoundingChange = (checked: boolean) => {
        const { payoutSettings } = this.state;
        payoutSettings.rounding = checked;
        this.setState({ payoutSettings });
        this.updatePayouts();
    };

    private handleRoundingValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { payoutSettings } = this.state;
        if (isCurrNumber.test(event.target.value)) {
            payoutSettings.roundingValue = parseFloat(event.target.value.replace(',', '.')) || 0;
            this.setState({ payoutSettings, roundingStr: payoutSettings.roundingValue.toString() });
            this.updatePayouts();
        }
    };

    private handlePickCommonSplit = (e: SyntheticEvent<any>) => {
        e.preventDefault(); e.stopPropagation();
        this.setState({ selectCommonSplit: true });
    }

    private handleAddMore = (e: SyntheticEvent<any>) => {
        e.preventDefault(); e.stopPropagation();
        const { showMore, payoutSettings } = this.state;
        if (!showMore && payoutSettings.payoutSchedule!.findIndex(i => !i) > 0) {
            this.setState({ showMore: true });
        } else if (payoutSettings.payoutSchedule!.length <= 29) {
            if (payoutSettings.payoutSchedule!.length % 10 === 0) {
                for (let i = 0; i < 10; i++) {
                    payoutSettings.payoutSchedule!.push(0);
                }
            } else {
                for (let i = 0; i < 10 - payoutSettings.payoutSchedule!.length % 10; i++) {
                    payoutSettings.payoutSchedule!.push(0);
                }
            }
        }
        this.setState({ payoutSettings, showMore: true });
    }

    render() {
        const { competition, classes, participantCount, isTeam } = this.props;
        const { selectCommonSplit, fixedPurseStr, feeAmountStr, payoutPerHoleStr, showMore, payoutSettings, valuePerSkinStr } = this.state;
        const isMain = isTeamScoring(competition.scoring) || isIndividualScoring(competition.scoring);
        const isKP = isKPScoring(competition.scoring);
        const isLongestDrive = isLongestDriveScoring(competition.scoring);
        const isSkins = isSkinsScoring(competition.scoring);
        const title = isMain ? 'Competition payout settings' : isLongestDrive ? 'Long drive payout settings' : isKP ? 'KP payout settings' : 'Skins payout settings';
        const scheduleArray = payoutSettings.payoutSchedule!;
        const reverseScheduleArray = [...scheduleArray];
        const reverseIndex = reverseScheduleArray.reverse().findIndex(i => !!i);
        const indexOfLast = reverseScheduleArray.length - (scheduleArray.findIndex(i => !!i) > - 1 ? reverseIndex : 0);
        const countScheduleRows = indexOfLast > 0 && !showMore ? (Math.ceil(indexOfLast / 10)) : (Math.ceil(scheduleArray.length / 10));
        const totalAllocated = scheduleArray.reduce((acc, curr) => acc + curr, 0);
        const zeroFixPurseAmount = payoutSettings.purseType === 'fixed' && !payoutSettings.fixedAmount;
        const zeroComputedPurseAmount = payoutSettings.purseType === 'computed' && !payoutSettings.entryFee;
        const zeroValuePerSkin = payoutSettings.purseType === 'valuePerSkin' && !payoutSettings.valuePerSkin;
        const disableSave = zeroFixPurseAmount || zeroComputedPurseAmount || zeroValuePerSkin || (isMain && (totalAllocated !== 100 ||
            (scheduleArray.findIndex(i => !i) > -1 && indexOfLast > scheduleArray.findIndex(i => !i))));
        const participantsCountStr = getParticipantsName(isTeam || competition.scoring.format === ScoringFormatSkins.skins_team, participantCount);
        const holesNumber = this.getHolesNumber();
        const holesNumberLabel = withS(holesNumber, 'hole');
        const startAdornment = <InputAdornment position="start">$</InputAdornment>;
        return (
            <React.Fragment>
                <XSMobileDialog open={this.props.open} onClose={this.handleClose}>
                    <DialogAppBar label={title} close={this.handleClose} />
                    <DialogContent onKeyDown={uiEvent => {
                        if (!disableSave) {
                            processEnterKey(uiEvent, this.handleSave);
                        }}} >
                        <FormControl variant="standard" style={{ flexDirection: 'column' }}>
                            <InputLabel shrink>Purse</InputLabel>
                            {!isSkins && <Container className={classes.buttonBadge}>
                                <TypedFormRadioLabel key='default' currentValue={payoutSettings.purseType} value={'fixed'}
                                    handleChange={() => this.onPurseTypeChange('fixed')} label={'Use the fixed amount'} className={classes.radio} style={{ width: 180 }} />
                                <Input id="fix-purse-amount" value={fixedPurseStr} onBlur={this.handlePurseAmountBlur} onChange={this.handlePurseAmountChange}
                                       disabled={payoutSettings.purseType !== 'fixed'} startAdornment={startAdornment} error={zeroFixPurseAmount} style={{ marginTop: -10, width: 150 }} />
                                {zeroFixPurseAmount && <Typography className={classes.purseAlertBase + ' ' + classes.fixPurseAlert}>Purse amount is not valid</Typography>}
                            </Container>}
                            {isSkins && <Container className={classes.buttonBadge}>
                                <TypedFormRadioLabel key='perSkin' currentValue={payoutSettings.purseType} value={'valuePerSkin'} label={'Value per skin of'}
                                    className={classes.radio} handleChange={() => this.onPurseTypeChange('valuePerSkin')} style={{ width: 180 }} />
                                <Input id="value-per-skin" value={valuePerSkinStr} onBlur={this.handleValuePerSkinBlur} onChange={this.handleValuePerSkinChanged}
                                       disabled={payoutSettings.purseType !== 'valuePerSkin'} startAdornment={startAdornment} error={zeroValuePerSkin} style={{ marginTop: -10, width: 150 }} />
                            </Container>}
                        </FormControl>
                        <FormControl variant="standard" style={{ flexDirection: 'column' }}>
                            {!isSkins && <Container className={classes.radioTwoLines}>
                                <TypedFormRadioLabel key='default' currentValue={payoutSettings.purseType} value={'computed'}
                                    handleChange={() => this.onPurseTypeChange('computed')} label={'Compute based on number of ' + (isTeamFormat(competition.scoring) ? 'teams' : 'golfers')} className={classes.radio} /><br />
                                {payoutSettings.purseType === 'computed' && <Container className={classes.radioTwoLines}>
                                    <Typography variant="body1" style={{ marginLeft: 22, width: 165 }}>{'Per' + (isTeamFormat(competition.scoring) ? ' team' : ' golfer') + ' entry fee'}</Typography>
                                    <Input id="per-golfer-entry-fee" value={feeAmountStr} onBlur={this.handleFeeAmountBlur} onChange={this.handleFeeAmountChange} disabled={payoutSettings.purseType !== 'computed'} startAdornment={<InputAdornment position="start">$</InputAdornment>} error={zeroComputedPurseAmount} style={{ marginTop: -10, width: 150 }} />
                                    <Typography variant="body1" style={{ marginLeft: 5 }}>{`x ${participantsCountStr}`}</Typography>
                                </Container>}
                                {payoutSettings.purseType === 'computed' && <Container className={classes.radioTwoLines}>
                                    <Typography variant="body1" style={{ marginLeft: 22, marginTop: 5, width: 165 }}>{`Purse`}</Typography> <Typography color={payoutSettings.purseType !== 'computed' ? "textSecondary" : "inherit"} variant="subtitle1" style={{ fontWeight: 'bold' }}>{'$'}&nbsp;{this.state.computedPurseStr}</Typography>
                                    {zeroComputedPurseAmount && <Typography className={classes.purseAlertBase + ' ' + classes.computedPurseAlert}>Purse amount is not valid</Typography>}
                                </Container>}
                            </Container>}
                            {isSkins && <Container className={classes.radioTwoLines}>
                                <TypedFormRadioLabel key='divideOverall' currentValue={payoutSettings.purseType} value={'fixed'} values={['fixed', 'computed']}
                                    label={'Divide overall skins budget of'} className={classes.radio} handleChange={() => this.onPurseTypeChange('fixed')} />
                                <Input id="divide-overall" value={fixedPurseStr} onBlur={this.handlePurseAmountBlur} onChange={this.handlePurseAmountChange}
                                    disabled={payoutSettings.purseType !== 'fixed'} startAdornment={startAdornment} error={zeroFixPurseAmount} style={{ marginTop: -10, width: 150 }} />
                                <Typography>across skins won</Typography>
                            </Container>}
                        </FormControl>
                        {payoutSettings?.purseType !== 'valuePerSkin' && <FormControl variant="standard" style={{ flexDirection: 'column', marginTop: 25 }}>
                            <InputLabel shrink>Rounding</InputLabel>
                            <FormControlLabel className={classes.formSelector} control={<Container className={classes.radioTwoLines} style={{ marginTop: -5 }}>
                                <Item><Checkbox color="secondary" onChange={(e, v) => { e.stopPropagation(); this.handleRoundingChange(v); }} checked={payoutSettings.rounding || false} /></Item>
                                <Typography variant="body1" style={{ marginTop: 8, marginRight: 25 }}>{'Round the payout amount to the nearest'}</Typography> <Input id="rounding" startAdornment={<InputAdornment position="start">$</InputAdornment>} disabled={!payoutSettings.rounding} value={this.state.roundingStr} onChange={this.handleRoundingValueChange} style={{ marginTop: 2, width: 75 }} />
                            </Container>} label="" />
                            <Typography variant="body1" className={classes.notSelected} style={{ fontSize: '0.75rem' }}>{'Leave empty for no rounding'}</Typography>
                        </FormControl>}
                        {isMain && <FormControl variant="standard" style={{ flexDirection: 'column' }}>
                            <InputLabel shrink style={{ marginTop: 15 }}>Payout schedule<IconButton
                                className={classes.smallIconButton}
                                onClick={() => showAlert(schedule_explanation)}
                                size="large"><InfoIcon /></IconButton></InputLabel>
                            <Typography variant="body1" style={{ marginTop: 42 }}>{'Enter percentage of payout awarded to each position.'}</Typography>
                            <a href="/" onClick={this.handlePickCommonSplit} className={classes.linkBlue} style={{ fontSize: '0.85rem', marginTop: 5 }}>{'Pick a common split'}</a>
                            {range(1, 1 + countScheduleRows).map(j => <div key={j} style={{ marginTop: 10, width: 560 }}>
                                <div className={classes.tableHeader}>
                                    {range(1, showMore || indexOfLast < 0 || indexOfLast % 10 === 0 || j < countScheduleRows ? 11 : (indexOfLast % 10 + 1)).map(i => <span className={classes.headerCell} key={i}>{(10 * (j - 1)) + i + (i === 1 ? 'st' : i === 2 ? 'nd' : i === 3 ? 'rd' : 'th')}</span>)}
                                </div>
                                <div className={classes.row}>
                                    {range(0, showMore || indexOfLast < 0 || indexOfLast % 10 === 0 || j < countScheduleRows ? 10 : indexOfLast % 10).map(i => <Edit error={indexOfLast > (10 * (j - 1) + i) && payoutSettings.payoutSchedule![(10 * (j - 1)) + i] === 0} value={payoutSettings.payoutSchedule![(10 * (j - 1)) + i]} onValue={(val) => { payoutSettings.payoutSchedule![(10 * (j - 1)) + i] = val || 0; this.setState({ payoutSettings }); }} key={i} />)}
                                </div>
                            </div>)}
                            <div style={{ marginTop: 10 }}>
                                <Typography variant="body1" color={totalAllocated === 100 ? "inherit" : "error"} style={{ textAlign: 'left', display: 'inline-block' }}>{`Total allocated: ${totalAllocated}%`}</Typography>
                                {payoutSettings.payoutSchedule!.length < 30 && <a href="/" onClick={this.handleAddMore} className={classes.linkBlue} style={{ fontSize: '0.85rem', float: 'right' }}>{!showMore && indexOfLast > 0 ? 'Show more' : 'Add more'}</a>}
                            </div>
                        </FormControl>}<br />
                        {(isKP || isLongestDrive) && <FormControl variant="standard" style={{ flexDirection: 'column' }}>
                            <InputLabel shrink style={{ marginTop: 15, width: 150 }}>Payout per Hole</InputLabel>
                            <Container className={classes.radioTwoLines}>
                                <Typography variant="body1" style={{ marginTop: 30 }}>{`${holesNumberLabel}: `}</Typography>&nbsp;&nbsp;<Typography variant="subtitle1" style={{ marginTop: 25, fontWeight: 'bold' }}>{'$'}&nbsp;{payoutPerHoleStr}</Typography>&nbsp;&nbsp;<Typography variant="body1" style={{ marginTop: 30 }}>{` per hole`}</Typography>
                            </Container>
                        </FormControl>}
                    </DialogContent>
                    <DialogActions>
                        <AppButton color="info" onClick={this.handleClose}>Cancel</AppButton>
                        <AppButton color="secondary" onClick={this.handleSave} disabled={disableSave}>Save</AppButton>
                    </DialogActions>
                </XSMobileDialog>
                {selectCommonSplit && <CompetitionPayoutCommonSplitsDialog
                    open={true}
                    schedule={payoutSettings.payoutSchedule || DEFAULT_PAYOUTS}
                    handleSave={(schedule: number[]) => {
                        payoutSettings.payoutSchedule = schedule;
                        this.setState({ payoutSettings, selectCommonSplit: false, showMore: false });
                    }}
                    onClose={() => this.setState({ selectCommonSplit: false })} />}
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(withForm(CompetitionPayoutSettingsDialog));
