import * as React from 'react';
import { DialogActions, DialogContent, Typography } from '@mui/material';
import { Event, Contact, Team, EventGender, teamGender } from '../../../types/EventTypes';
import { golferTeamNameShort } from '../../../contact/Contact';
import { XSMobileDialog } from '../../../common/dialog/MobileDialog';
import DialogAppBar from '../../../common/dialog/DialogAppBar';
import AppButton from '../../../common/components/AppButton';
import GridSelector, { GridItem } from '../../../common/GridSelector';

// type EventGender = 'both' | 'men' | 'women'
function matchGenders(target: EventGender, current: EventGender) {
    return target === 'both' || target === current;
} 

class TeamGridItem implements GridItem {
    team: Team;
    selectGender?: EventGender;
    golfers: Map<string, Contact>;
    constructor(team: Team, golfers: Map<string, Contact>, selectGender?: EventGender) {
        this.team = team;
        this.golfers = golfers;
        this.selectGender = selectGender;
    }
    id = () => this.team.id;
    isDisabled = () => !matchGenders(this.selectGender ?? 'both', teamGender(this.team, this.golfers) ?? 'both');
    getLabel = () => golferTeamNameShort(this.team, this.golfers);
    getBadge = () => '';
}

interface Props {
    event: Event;
    teams: Map<string, Team>;
    golfers: Map<string, Contact>;
    selectGender?: EventGender;
    selectedTeamsIds: string[];
    handleCancel: () => void;
    handleSelectTeams: (teams: Array<Team>) => void;
}

interface State {
    selectedCount: number;
}

export class SelectTeamsDialog extends React.Component<Props, State> {
    state: State;
    private readonly gridSelector: React.RefObject<GridSelector>;

    constructor(props: Props) {
        super(props);
        this.state = {
            selectedCount: props.selectedTeamsIds?.length ?? 0
        };
        this.gridSelector = React.createRef();
    }

    private selectAll = () => this.gridSelector.current?.selectAll();
    private selectNone = () => this.gridSelector.current?.selectNone();
    private handleCancel = () => this.props.handleCancel();
    private handleSelected = (items: Array<GridItem>, checked: boolean) => this.setState({ selectedCount: this.gridSelector.current?.selectedCount() ?? 0 });

    private handleOk = () => {
        if (!this.gridSelector.current) {
            return;
        }
        const { teams, handleSelectTeams } = this.props;
        const { selected } = this.gridSelector.current.state;
        const selectedTeams = Array.from(teams.values()).filter(g => selected.has(g.id));
        handleSelectTeams(selectedTeams);
    }

    render() {
        const { golfers, teams, selectGender, selectedTeamsIds } = this.props;
        const { selectedCount } = this.state;
        const teamItems = Array.from(teams.values()).map(team => new TeamGridItem(team, golfers, selectGender));
        const emptyStatus = 'No recent golfers yet';
        return (
            <React.Fragment>
                <XSMobileDialog open={true} onClose={this.props.handleCancel} maxWidth={'lg'}>
                    <DialogAppBar label="Select teams" close={this.props.handleCancel} />
                    {teams.size > 0 && <DialogActions style={{ padding: 20, paddingBottom: 4 }}>
                        <AppButton color="secondary" onClick={this.selectAll}>Select all</AppButton>
                        <AppButton color="info" onClick={this.selectNone}>Select none</AppButton>
                        <span style={{ flex: '1 1 0%' }} />
                        <Typography variant="body1">{teams.size} total, {selectedCount} selected</Typography>
                    </DialogActions>}
                    <DialogContent>
                    {teams.size > 0 &&
                        <GridSelector ref={this.gridSelector} items={teamItems} preselected={selectedTeamsIds} handleSelected={this.handleSelected} />}
                    {teams.size === 0 &&
                        <Typography variant="body1" style={{ margin: 16 }}>{emptyStatus}</Typography>}
                    </DialogContent>
                    {teams.size > 0 && <DialogActions>
                        <AppButton onClick={this.handleCancel} color="info">Cancel</AppButton>
                        <AppButton onClick={this.handleOk} color="secondary">Save</AppButton>
                    </DialogActions>}
                    {teams.size === 0 && <Typography variant="body1" style={{ margin: 16 }}>No teams yet</Typography>}
                </XSMobileDialog>
            </React.Fragment>
        );
    }
}

export default SelectTeamsDialog;
