import * as React from 'react';
import { DialogActions, DialogContent, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Event, EventData, Contact, HolesRange, teeTimeName, getHolesRange } from '../../../types/EventTypes';
import { ContactGroup } from '../../Event';
import { getTeeTime } from '../../TeeTimes';
import { fullName, golfersOfTeam, getSameNameGolfersIds } from '../../../contact/Contact';
import * as Backend from '../../../util/firebase';
import { XSMobileDialog } from '../../../common/dialog/MobileDialog';
import DialogAppBar from '../../../common/dialog/DialogAppBar';
import TextEditDialog from '../../../common/dialog/TextEditDialog';
import AppButton from '../../../common/components/AppButton';
import GridSelector, { GridItem } from '../../../common/GridSelector';
import { styles } from '../../../styles';

export type GolferDB = 'GOLFERS_EMAILS' | 'GOLFER_GROUPS';

const compareContacts = (a: ContactGroup, b: ContactGroup) => a.name.localeCompare(b.name);

class ContactGridItem implements GridItem {
    contactGroup: ContactGroup;
    event: Event;
    disabled: boolean;
    homeCourseOrCity?: string;
    holesRange: HolesRange;
    constructor(contactGroup: ContactGroup, event: Event, disabled: boolean, homeCourseOrCity?: string) {
        this.contactGroup = contactGroup;
        this.homeCourseOrCity = homeCourseOrCity;
        this.event = event;
        this.disabled = disabled;
        this.holesRange = getHolesRange(event.holesType);
    }
    id = () => this.contactGroup.id;
    isDisabled = () => this.disabled;
    getBadge = () => this.contactGroup.group ? teeTimeName(getTeeTime(this.event.teeTime, this.holesRange, this.contactGroup.group.order)) : '';
    getLabel = () => (
        <React.Fragment>
            {this.contactGroup.name}
            <div style={{ fontSize: '0.6rem' }}>
                {this.homeCourseOrCity ? ` (${this.homeCourseOrCity})` : ''}
            </div>
        </React.Fragment>
    );
}

interface AddGolfersDialogProps {
    open: boolean;
    label: string;
    okLabel?: string;
    event: Event;
    eventData: EventData;
    selectedGolfersIds?: Array<string>;
    golferDB: GolferDB;
    selectAll?: boolean;
    withNotes?: boolean;
    handleAddGolfers: (contacts: Array<ContactGroup>, notes?: string) => void;
    handleCancel: () => void;
}

interface State {
    showNotes: boolean;
    eventNotes?: string;
    selectedGroups?: Map<string, boolean>;
}

type Props = AddGolfersDialogProps & WithStyles<typeof styles>;

class AddGolfersDialog extends React.Component<Props, State> {
    readonly state: State;

    private readonly gridSelector1: React.RefObject<GridSelector>;
    private readonly gridSelector2: React.RefObject<GridSelector>;

    constructor(props: Props) {
        super(props);
        this.state = {
            showNotes: false,
            eventNotes: this.props.event.scorecardNotes,
            selectedGroups: props.golferDB === 'GOLFER_GROUPS' ? new Map<string, boolean>() : undefined
        };
        this.gridSelector1 = React.createRef();
        this.gridSelector2 = React.createRef();
    }

    private selectAll = () => this.gridSelector1.current?.selectAll();
    private selectNone = () => this.gridSelector1.current?.selectNone();
    private handleCancel = () => this.props.handleCancel();
    private showNotes = () => this.setState({ showNotes: true });

    private handleAdd = () => {
        if (!this.gridSelector1.current) {
            return;
        }
        const { golferDB, handleAddGolfers, event, eventData } = this.props;
        const { selected } = this.gridSelector1.current.state;
        const { eventNotes } = this.state;
        const toSave = { id: event.id, exists: true, scorecardNotes: eventNotes };
        Backend.updateOrAdd(Backend.eventsDb, toSave);
        if (golferDB === 'GOLFER_GROUPS') {
            const { groups } = eventData;
            const contactItems = Array.from(groups.values())
                .filter(group => selected.has(group.id))
                .map(group => ({ id: group.id, name: '*', group: group } as ContactGroup));
            handleAddGolfers(contactItems, eventNotes);
        } else if (golferDB === 'GOLFERS_EMAILS') {
            const { roster } = eventData;
            const contactItems = Array.from(roster.values())
                .filter(g => selected.has(g.id))
                .map(g => new ContactGroup(g, true));
            handleAddGolfers(contactItems, eventNotes);
        }
    }

    private isLoaded() {
        const { golferDB, event, eventData } = this.props;
        const { loadedTeams, loadedGroups, loadedGolfers, loadedRoster } = eventData;
        return golferDB === 'GOLFER_GROUPS' && event.teamSize === 1 ? loadedGolfers > 0 && loadedGroups > 0 :
               golferDB === 'GOLFER_GROUPS' && event.teamSize > 1 ? loadedTeams > 0 && loadedGroups > 0 :
               golferDB === 'GOLFERS_EMAILS' ? loadedRoster > 0 && loadedGolfers > 0 : false;
    }

    private includeContactWithEmail = (contact: Contact) => {
        const { eventData } = this.props;
        const { roster } = eventData;
        const contactDetail = roster.get(contact.id);
        return contactDetail && contactDetail.email;
    }

    private includeContactWithoutEmail = (contact: Contact) => {
        const { eventData } = this.props;
        const { roster } = eventData;
        const contactDetail = roster.get(contact.id);
        return !(contactDetail && contactDetail.email);
    }

    private gridItem = (contactGroup: ContactGroup, sameNameGolfersIdsSet: Set<string>, disabled: boolean) => {
        const { event } = this.props;
        return new ContactGridItem(contactGroup, event, disabled,
            contactGroup.contact?.homeCourseOrCity && sameNameGolfersIdsSet.has(contactGroup.contact.id) ? contactGroup.contact.homeCourseOrCity : undefined);
    }

    private handleGroupsSelected = (items: Array<GridItem>, checked: boolean) => {
        const { selectedGroups } = this.state;
        if (selectedGroups && this.props.golferDB === "GOLFER_GROUPS" && items.length) {
            items.forEach(item => selectedGroups.set(item.id(), checked));
            this.setState({ selectedGroups });
        }
    };

    render() {
        const { event, golferDB, okLabel, selectedGolfersIds, withNotes, open, label, eventData } = this.props;
        const { golfers, teams, groups } = eventData;
        const { showNotes, selectedGroups } = this.state;
        let filteredGolfers: Array<ContactGroup> = [];
        let filteredGolfersNoEmails: Array<ContactGroup> = [];
        const loaded = this.isLoaded();
        const emptyStatus = loaded ? (golferDB === 'GOLFER_GROUPS' ?
            'No tee times are assigned yet. Edit tee times under Golfers / Schedule.' : 'No recent golfers yet') : 'Loading...';
        let unscheduledInfo = '';
        const golfersArray = Array.from(golfers.values());
        const sameNameGolfersIdsSet = getSameNameGolfersIds(golfersArray);
        let buttonDisabled = false;
        if (golferDB === 'GOLFER_GROUPS') {
            const scheduled = new Set();
            groups.forEach(group => {
                const names: Array<string> = [];
                group.contactIds.forEach(contactId => {
                    scheduled.add(contactId);
                    if (event.teamSize === 1) {
                        const contact = golfers.get(contactId);
                        if (contact) {
                            names.push(fullName(contact) + (sameNameGolfersIdsSet.has(contact.id) && contact.homeCourseOrCity ? ` (${contact.homeCourseOrCity})` : ''));
                        }
                    } else {
                        const team = teams.get(contactId);
                        if (team) {
                            golfersOfTeam(team, golfers).forEach(contact => names.push(fullName(contact) + (sameNameGolfersIdsSet.has(contact.id) && contact.homeCourseOrCity ? ` (${contact.homeCourseOrCity})` : '')));
                        }
                    }
                });
                if (names.length > 0) {
                    filteredGolfers.push({ id: group.id, name: names.join(' + '), group, selected: false });
                }
            });
            let unscheduled = 0;
            if (event.teamSize === 1) {
                golfers.forEach(golfer => unscheduled += scheduled.has(golfer.id) ? 0 : 1);
            } else {
                teams.forEach(team => unscheduled += scheduled.has(team.id) ? 0 : 1);
            }
            if (unscheduled === 1) {
                unscheduledInfo = `${unscheduled} ${event.teamSize === 1 ? 'golfer' : 'team'} is not assigned tee times yet`;
            } else if (unscheduled > 1) {
                unscheduledInfo = `${unscheduled} ${event.teamSize === 1 ? 'golfers' : 'teams'} are not assigned tee times yet`;
            }
            buttonDisabled = Array.from(selectedGroups!.values()).every(checked => !checked);
        } else if (golferDB === 'GOLFERS_EMAILS') {
            filteredGolfers = golfersArray.filter(this.includeContactWithEmail).map(c => new ContactGroup(c, false));
            filteredGolfersNoEmails = golfersArray.filter(this.includeContactWithoutEmail).map(c => new ContactGroup(c, false));
        }
        if (golferDB !== 'GOLFER_GROUPS') {
            filteredGolfers.sort(compareContacts);
            filteredGolfersNoEmails.sort(compareContacts);
        }
        const golfersCount = filteredGolfers.length + filteredGolfersNoEmails.length;
        const buttonName = okLabel ?? selectedGolfersIds ? 'Save' : 'Add';
        const contactItems = filteredGolfers.map(c => this.gridItem(c, sameNameGolfersIdsSet, false));
        const contactItemsNoEmails = filteredGolfersNoEmails.map(c => this.gridItem(c, sameNameGolfersIdsSet, true));
        return (
            <React.Fragment>
                <XSMobileDialog fullWidth={true} open={open} onClose={this.handleCancel} maxWidth={'lg'}>
                    <DialogAppBar label={label} close={this.handleCancel} />
                    <DialogActions style={{ padding: 20, paddingBottom: 4 }}>
                        <AppButton color="secondary" onClick={this.selectAll}>Select all</AppButton>
                        <AppButton color="info" onClick={this.selectNone}>Select none</AppButton>
                        {withNotes && <AppButton color="info" onClick={this.showNotes}>Notes...</AppButton>}
                        <span style={{ flex: '1 1 0%' }} />
                    </DialogActions>
                    <DialogContent>
                        {golfersCount > 0 && <div>
                            <GridSelector ref={this.gridSelector1} items={contactItems} preselected={selectedGolfersIds}
                                          handleSelected={this.handleGroupsSelected} />
                            {filteredGolfersNoEmails.length > 0 && <Typography variant="body1" style={{ margin: 2 }}>
                                Golfers with no e-mail addresses:
                            </Typography>}
                            <GridSelector ref={this.gridSelector2} items={contactItemsNoEmails} />
                        </div>}
                        {golfersCount === 0 && <Typography variant="body1" style={{ margin: 16 }}>
                            {emptyStatus}
                        </Typography>}
                        {!!unscheduledInfo && <Typography variant="body1" style={{ margin: 16 }}>
                            {unscheduledInfo}
                        </Typography>}
                    </DialogContent>
                    {golfersCount > 0 && <DialogActions>
                        <AppButton color="info" onClick={this.handleCancel}>Cancel</AppButton>
                        <AppButton color="secondary" disabled={buttonDisabled} onClick={this.handleAdd}>
                            {buttonName}
                        </AppButton>
                    </DialogActions>}
                    {showNotes && <TextEditDialog label='Notes to be included on printed scorecards'
                        handleClose={(val) => this.setState({ eventNotes: val, showNotes: false })}
                        title="Notes" content={this.state.eventNotes || ''} />}
                </XSMobileDialog>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(AddGolfersDialog);
