import * as React from 'react';
import { DialogProps } from '@mui/material/Dialog';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { FormControl, DialogActions, Radio, Typography, Grid } from '@mui/material';
import DropDownArrowIcon from '@mui/icons-material/ArrowDropDown';
import EditIcon from '@mui/icons-material/Edit';
import { WithUserId } from '../../../../../auth/Auth';
import * as Backend from '../../../../../util/firebase';
import { withProgress } from '../../../../../util/ProgressPromise';
import {
    Competition, Event, Contact, Course, Facility, SimpleCourse, isCompoundFacility, Tee, getCourseName, facilityFromCourse, HolesType,
    HOLES_18, HOLES_9, HOLES_9_9, HOLES_NAMES, HOLES_TYPES, HOLES_TYPES_COMPOUND, HOLES_TYPES_NINES
} from '../../../../../types/EventTypes';
import { toHolesType } from '../../../../../scoring/handicap';
import { loadCompetitions, loadContacts, elogInTransaction, updateGolfersPlayingHandicapInTransaction } from '../../../../Event';
import { getHolesType, getFacilityName, getCourse, loadCourseTees, handleFacilityMissingTees, iCourse, oCourse } from '../../../../Courses';
import { XSMobileDialog } from '../../../../../common/dialog/MobileDialog';
import DialogAppBar from '../../../../../common/dialog/DialogAppBar';
import MessageDialog from '../../../../../common/dialog/MessageDialog';
import AppButton from '../../../../../common/components/AppButton';
import CourseSelectionMenu from './CourseSelectionMenu';
import EditTeesListDialog from '../../../../tabs/common/EditTeesListDialog';
import { styles } from '../../../../../styles';

interface CourseViewProps {
    course: SimpleCourse;
    setAsOut: (course: SimpleCourse) => void;
    setAsIn: (course: SimpleCourse) => void;
    outNine: boolean;
    inNine: boolean;
}

export const CourseViewHeaderLong = withStyles(styles)((props: WithStyles<typeof styles>) => {
    return (
        <Grid container>
            <Grid item xs={2} className={props.classes.childrenCenteredLeft}><Typography>FRONT</Typography></Grid>
            <Grid item xs={2} className={props.classes.childrenCenteredLeft}><Typography>BACK</Typography></Grid>
            <Grid item xs={2} className={props.classes.childrenCenteredLeft}><Typography /></Grid>
        </Grid>
    );
});

export const CourseViewHeaderShort = withStyles(styles)((props: WithStyles<typeof styles>) => {
    return (
        <Grid container>
            <Grid item xs={4} className={props.classes.childrenCenteredLeft}><Typography>PLAY THIS NINE</Typography></Grid>
            <Grid item xs={2} className={props.classes.childrenCenteredLeft}><Typography /></Grid>
        </Grid>
    );
});

export const CourseViewLong = withStyles(styles)((props: CourseViewProps & WithStyles<typeof styles>) => {
    const { classes } = props;
    return (
        <Grid container>
            <Grid item xs={2} className={classes.childrenCenteredLeft + ' ' + classes.clickable}
                onClick={() => props.setAsOut(props.course)}>
                <Radio color="secondary" checked={props.outNine} />
            </Grid>
            <Grid item xs={2} className={classes.childrenCenteredLeft + ' ' + classes.clickable}
                onClick={() => props.setAsIn(props.course)}>
                <Radio color="secondary" checked={props.inNine} />
            </Grid>
            <Grid item xs={2} className={classes.childrenCenteredLeft + ' ' + classes.clickable}
                onClick={() => { props.setAsOut(props.course); props.setAsIn(props.course); }}>
                <Typography style={{ marginLeft: -30 }}>{props.course.name}</Typography>
            </Grid>
        </Grid>
    );
});

export const CourseViewShort = withStyles(styles)((props: CourseViewProps & WithStyles<typeof styles>) => {
    const { classes } = props;
    return (
        <Grid container>
            <Grid item xs={1} className={classes.childrenCenteredLeft + ' ' + classes.clickable}
                onClick={() => props.setAsIn(props.course)}>
                <Radio color="secondary" checked={props.inNine} />
            </Grid>
            <Grid item xs={2} className={classes.childrenCenteredLeft + ' ' + classes.clickable}
                onClick={() => props.setAsIn(props.course)}>
                <Typography>{props.course.name}</Typography>
            </Grid>
        </Grid>
    );
});

interface HolesViewProps {
    value: string;
    label: string;
    selected: boolean;
    select: (value: string) => void;
}

const HolesView = withStyles(styles)((props: HolesViewProps & WithStyles<typeof styles>) => {
    const { classes } = props;
    return (
        <Grid container>
            <Grid item xs={1} className={classes.childrenCenteredLeft + ' ' + classes.clickable}
                onClick={() => props.select(props.value)}>
                <Radio color="secondary" checked={props.selected} />
            </Grid>
            <Grid item xs={2} className={classes.childrenCenteredLeft + ' ' + classes.clickable}
                onClick={() => props.select(props.value)}>
                <Typography className={classes.width70}>{props.label}</Typography>
            </Grid>
        </Grid>
    );
});

interface CourseSelectionDialogProps extends DialogProps {
    event: Event;
    preselectedFacility?: Facility;
    preselectedHolesType?: HolesType;
    preselectedTees?: Array<Tee>;
}

interface State {
    selectedFacility?: Facility;
    holesType: HolesType;
    anchorSelect?: any;
    selectedIn?: SimpleCourse;
    selectedOut?: SimpleCourse;
    editingTees?: boolean;
    courseChangedWarning?: boolean;
    noTeesWarning?: boolean;
    competitions: Array<Competition>;
    contacts: Array<Contact>;
    tees: Array<Tee>;
}

type Props = CourseSelectionDialogProps & WithStyles<typeof styles> & WithUserId;

class CourseSelectionDialog extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const { event } = this.props;
        this.state = {
            holesType: this.props.preselectedHolesType ? this.props.preselectedHolesType : event.holesType ? event.holesType : HOLES_18,
            selectedIn: iCourse(event.course),
            selectedOut: oCourse(event.course),
            selectedFacility: this.props.preselectedFacility ? this.props.preselectedFacility : event.course ? facilityFromCourse(event.course) : undefined,
            competitions: [],
            contacts: [],
            tees: this.props.preselectedTees ? this.props.preselectedTees : []
        };
        withProgress(loadCompetitions(event.id)).then(this.competitionsLoaded);
        withProgress(loadContacts(event.id)).then(this.contactsLoaded);
    }

    private setAsOut = (course: SimpleCourse) => this.setState({ selectedOut: course });
    private setAsIn = (course: SimpleCourse) => this.setState({ selectedIn: course });
    private handleSelectClick = (event: any) => this.setState({ anchorSelect: event.currentTarget });
    private handleHolesChange = (val: string) => this.setState({ holesType: toHolesType(val), selectedIn: undefined, selectedOut: undefined });
    private handleEditScorecard = () => this.setState({ editingTees: true });
    private handleCloseEditScorecard = () => this.setState({ editingTees: false });
    private competitionsLoaded = (competitions: Array<Competition>) => this.setState({ competitions });
    private contactsLoaded = (contacts: Array<Contact>) => this.setState({ contacts });

    private handleSave = () => {
        const { event } = this.props;
        const { selectedFacility, selectedIn, selectedOut, holesType, tees } = this.state;
        if (selectedFacility && selectedFacility.courses) {
            const newName = getFacilityName(selectedFacility, selectedOut, selectedIn);
            const curName = getCourseName(event.course);
            const clearTee = curName !== newName;
            const course = getCourse(selectedFacility, selectedIn, selectedOut, holesType);
            if (course) {
                this.save(clearTee, course, tees);
            }
        } else {
            this.save(false);
        }
    }

    private save = (clearTee: boolean, course?: Course, tees?: Array<Tee>) => {
        const { event } = this.props;
        const { holesType, competitions, contacts, selectedFacility, selectedIn, selectedOut } = this.state;
        const eventUpdate = {
            id: event.id,
            handicapSystem: event.handicapSystem,
            exists: true,
            teamSize: event.teamSize
        } as Event;
        if (course) {
            eventUpdate.course = course;
            if (tees && tees.length > 0) {
                eventUpdate.handicapSystem = tees[0].handicapSystem;
            }
        }
        eventUpdate.holesType = holesType;
        if (eventUpdate.holesType !== event.holesType) {
            eventUpdate.teeTime = { ...event.teeTime };
            delete eventUpdate.teeTime.startingHoles;
            delete eventUpdate.teeTime.startingHolesType;
        }
        Backend.withTransaction(transaction => {
            Backend.updateInTransaction(Backend.eventsDb, eventUpdate, transaction);
            elogInTransaction(transaction, event, 'Course modified', 'Course: ' + course ? getFacilityName(selectedFacility, selectedOut, selectedIn) : 'undefined', `Id: ${eventUpdate.id}`);
            if (clearTee) {
                competitions.forEach(competition => {
                    if (competition.tees) {
                        const competitionUpdate = { id: competition.id, exists: true } as Competition;
                        competitionUpdate.tees = [];
                        Backend.updateInTransaction(Backend.competitionsDb(event.id), competitionUpdate, transaction);
                    }
                });
                contacts.forEach(contact => {
                    if (contact.tee) {
                        Backend.updateInTransaction(Backend.golferDb(event.id), { id: contact.id, tee: undefined }, transaction);
                    }
                });
            }
            return updateGolfersPlayingHandicapInTransaction(transaction, eventUpdate, contacts, competitions);
        })
            .then(this.handleClose);
    }

    private handleClose = () => {
        this.setState({ anchorSelect: null });
        const close = this.props.onClose;
        if (close) {
            close({} as React.SyntheticEvent<any>, 'escapeKeyDown');
        }
    }

    private handleFacilityClose = () => {
        this.setState({ anchorSelect: null, tees: [] });
    }

    private handleFacilitySelect = (selectedFacility: Facility) => {
        const { userId } = this.props;
        const course = getCourse(selectedFacility);
        if (course) {
            withProgress(loadCourseTees(userId, course, false, true), true).then(async tees => {
                if (tees.length === 0) {
                    await handleFacilityMissingTees(selectedFacility, course);
                    this.setState({ noTeesWarning: true });
                } else {
                    const holesType = getHolesType(selectedFacility, tees);
                    this.setState({ selectedFacility, selectedIn: undefined, selectedOut: undefined, holesType, tees }, this.showWarning);
                }
            }).catch(err => { });
        } else {
            const holesType = HOLES_18;
            this.setState({ selectedFacility, selectedIn: undefined, selectedOut: undefined, holesType, tees: [] }, this.showWarning);
        }
        this.setState({ anchorSelect: null, tees: [] });
    }

    private showWarning = async () => {
        const { event } = this.props;
        const { selectedFacility, selectedIn, selectedOut } = this.state;
        const newName = getFacilityName(selectedFacility, selectedOut, selectedIn);
        const curName = getCourseName(event.course);
        if (curName && curName !== newName) {
            const competitions = await Backend.getEntities<Competition>(Backend.competitionsDb(event.id));
            if (competitions.length > 0) {
                this.setState({ courseChangedWarning: true });
            }
        }
    }

    CourseChanged = () => {
        const { classes } = this.props;
        return (
            <React.Fragment>
                Important: please review and update competition tee settings under <span className={classes.boldText}>Scoring</span> tab.
            </React.Fragment>
        );
    }

    CourseNoTees = () => {
        return (
            <React.Fragment>
                Unable to start an event on this course. Some required information is missing.
                The support has been notified and we are working on fixing this as soon as possible.
                Sorry about the inconvenience.
            </React.Fragment>
        );
    }

    render() {
        const { classes, event } = this.props;
        const { selectedFacility, anchorSelect, holesType, courseChangedWarning, noTeesWarning } = this.state;
        const { selectedIn, selectedOut, editingTees } = this.state;
        const courseName = getFacilityName(selectedFacility, selectedOut, selectedIn);
        const isCompound = isCompoundFacility(selectedFacility);
        const compoundShort = isCompound && holesType !== HOLES_18;
        const compoundLong = isCompound && holesType === HOLES_18;
        const cannotSave = !selectedFacility ||
            (compoundShort && !selectedIn) ||
            (compoundLong && !(selectedOut && selectedIn));
        const currentCourse = getCourse(selectedFacility, selectedIn, selectedOut, holesType);
        const orderSimple = ['North', 'South', 'East'];
        const orderTemp = orderSimple.reduce((c, v, i) => Object.assign(c, { [v]: i }), {} as any);
        const isNineCourse = !isCompound && (holesType === HOLES_9 || holesType === HOLES_9_9);
        const holes = isNineCourse ? HOLES_TYPES_NINES : isCompound ? HOLES_TYPES_COMPOUND : HOLES_TYPES;
        return (
            <React.Fragment>
                <XSMobileDialog open={this.props.open} onClose={this.handleClose} maxWidth="sm" fullWidth={true}>
                    <DialogAppBar label="Course" close={this.handleClose} />
                    <div style={{ padding: '16px' }}>
                        <FormControl
                            variant="standard"
                            margin="dense"
                            fullWidth
                            style={{ flexDirection: 'column' }}>
                            <Typography>Course</Typography>
                            <Typography variant="h6" gutterBottom>{courseName}</Typography>
                            <AppButton color="secondary" style={{ width: 166 }} onClick={this.handleSelectClick}>CHANGE<DropDownArrowIcon /></AppButton>
                        </FormControl>
                        <FormControl
                            variant="standard"
                            margin="dense"
                            fullWidth
                            style={{ flexDirection: 'column' }}>&nbsp;
                            <Typography>Holes</Typography>
                            {holes.map(ht => <HolesView key={ht} value={String(ht)} label={HOLES_NAMES[ht]} selected={holesType === ht} select={this.handleHolesChange} />)}
                        </FormControl>
                        <div>&nbsp;
                            {isCompound && <div>
                                {compoundShort ? <CourseViewHeaderShort /> : <CourseViewHeaderLong />}
                                {compoundShort ?
                                    selectedFacility!.courses.sort((a, b) => orderTemp[a.name] - orderTemp[b.name]).map(course => <CourseViewShort key={course.id} course={course}
                                        outNine={(selectedOut?.id ?? '') === course.id}
                                        inNine={(selectedIn?.id ?? '') === course.id}
                                        setAsOut={this.setAsOut} setAsIn={this.setAsIn} />) :
                                    selectedFacility!.courses.sort((a, b) => orderTemp[a.name] - orderTemp[b.name]).map(course => <CourseViewLong key={course.id} course={course}
                                        outNine={(selectedOut?.id ?? '') === course.id}
                                        inNine={(selectedIn?.id ?? '') === course.id}
                                        setAsOut={this.setAsOut} setAsIn={this.setAsIn} />)}
                            </div>}
                        </div>
                        <FormControl
                            variant="standard"
                            margin="dense"
                            fullWidth
                            style={{ flexDirection: 'column' }}>&nbsp;
                            <Typography>Scorecard</Typography>
                            <AppButton color="info" style={{ width: 166 }} onClick={this.handleEditScorecard} disabled={cannotSave}><EditIcon /> VIEW / EDIT</AppButton>
                        </FormControl>
                    </div>
                    <DialogActions>
                        <AppButton color="info" className={classes.iconButton} onClick={this.handleClose}>Cancel</AppButton>
                        <AppButton color="secondary" className={classes.iconButton} onClick={this.handleSave} disabled={cannotSave}>Save</AppButton>
                    </DialogActions>
                </XSMobileDialog>
                {anchorSelect && <CourseSelectionMenu userId={event.userId} anchorSelect={anchorSelect} handleFacilitySelect={this.handleFacilitySelect} handleClose={this.handleFacilityClose} />}
                {editingTees && currentCourse && <EditTeesListDialog course={currentCourse} event={event} close={this.handleCloseEditScorecard} />}
                {courseChangedWarning && <MessageDialog
                    open={true}
                    onClose={() => this.setState({ courseChangedWarning: undefined })}
                    title="Course change"
                    content={<this.CourseChanged />} />}
                {noTeesWarning && <MessageDialog
                    open={true}
                    onClose={() => this.setState({ noTeesWarning: undefined })}
                    title="Course change"
                    content={<this.CourseNoTees />} />}
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(CourseSelectionDialog);
