import * as Parser from 'xlsx';
import { Sheet, WorkBook, Range } from 'xlsx';
import { ContactDetails } from '../../types/EventTypes';
import { parseHandicap } from '../../scoring/handicap';
import { parseGender } from '../Contact';
import { parseEmail, toSafeString, isNumeric } from '../../util/utility';

export function CSVToArray(strData: string, strDelimiter?: string): WorkBook {
    strData += '\r\n';
    if (!strDelimiter) {
        let header = strData.substring(0, strData.indexOf('\n'));
        strDelimiter = header.indexOf(';') >= 0 ? ';' : ',';
    }
    let objPattern = new RegExp((
        '(\\' + strDelimiter + '|\\r?\\n|\\r|^)' +
        '(?:"([^"]*(?:""[^"]*)*)"|' +
        '([^"\\' + strDelimiter + '\\r\\n]*))'
    ), 'gi');
    const arrData: Array<Array<string>> = [[]];
    let arrMatches = null;
    while (!!(arrMatches = objPattern.exec(strData))) {
        const strMatchedDelimiter = arrMatches[1];
        if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter) {
            arrData.push([]);
        }
        let strMatchedValue = '';
        if (arrMatches[2]) {
            strMatchedValue = arrMatches[2].replace(new RegExp('""', 'g'), '"');
        } else {
            strMatchedValue = arrMatches[3];
        }
        arrData[arrData.length - 1].push(strMatchedValue);
    }
    const A = 'A'.charCodeAt(0);
    let maxRowLen = 0;
    const sheetData = {} as any;
    for (let rowNum = 0; rowNum < arrData.length; rowNum++) {
        const row = arrData[rowNum];
        if (maxRowLen < row.length) {
            maxRowLen = row.length;
        }
        for (let colNum = 0; colNum < row.length; colNum++) {
            const cell = String.fromCharCode(A + colNum) + (rowNum + 1);
            if (isNumeric(row[colNum])) {
                sheetData[cell] = { t: 'n', w: row[colNum], v: parseFloat(row[colNum]) };
            } else {
                sheetData[cell] = { t: 's', v: row[colNum] };
            }
        }
    }
    return {
        SheetNames: ['Sheet1'],
        Sheets: {
            Sheet1: {
                '!ref': 'A1:' + String.fromCharCode(A + maxRowLen - 1) + (arrData.length - 1),
                ...sheetData
            }
        }
    };
}

interface Mapping {
    email?: number;
    lastName?: number;
    firstName?: number;
    handicap?: number;
    gender?: number;
}

export interface Result {
    added: number;
    updated: number;
    rejected: number;
    error?: string;
    contacts: Array<ContactDetails>;
    statuses: Map<number, string>;
}

type ImportCallback = (result: Result) => void;

export const importFile = (file: File, listener: ImportCallback) => {
    const reader = new FileReader();
    const isCSV = file.type === 'text/csv';
    reader.addEventListener('load', event => {
        const data = (event.target as any).result;
        try {
            const workbook = isCSV ? CSVToArray(data) :
                Parser.read(data, { type: 'binary', codepage: 65001 });
            if (isValidWorkBook(workbook)) {
                parseWorkBook(workbook, listener);
            } else {
                listener({ added: 0, updated: 0, rejected: 0, error: 'Unsupported file format', contacts: [], statuses: new Map() });
            }
        } catch (e) {
            listener({ added: 0, updated: 0, rejected: 0, error: 'Unsupported file format', contacts: [], statuses: new Map() });
        }
    });
    if (isCSV) {
        reader.readAsText(file);
    } else {
        reader.readAsBinaryString(file);
    }
};

function isValidWorkBook(wb: WorkBook) {
    wb.SheetNames.forEach(name => {
    });
    return true;
}

function parseWorkBook(wb: WorkBook, listener: ImportCallback) {
    const contacts: Array<ContactDetails> = [];
    wb.SheetNames.forEach(name => parseSheet(wb.Sheets[name], contacts));
    const result: Result = {
        added: 0,
        updated: 0,
        rejected: 0,
        contacts: [],
        statuses: new Map()
    };
    contacts.forEach(contact => result.contacts.push(contact));
    listener(result);
}

function parseSheet(sheet: Sheet, contacts: ContactDetails[]) {
    const rangeIdx = sheet['!ref'];
    if (!rangeIdx) {
        return;
    }
    const range = Parser.utils.decode_range(rangeIdx);
    let columnsMapping: Mapping | undefined;
    for (let row = range.s.r; row <= range.e.r; ++row) {
        range.s.r = row;
        columnsMapping = getColumnsMapping(getColumns(sheet, range));
        if (columnsMapping.lastName !== undefined) {
            break;
        }
    }
    if (!columnsMapping || columnsMapping.lastName === undefined) {
        return;
    }
    for (let row = range.s.r + 1; row <= range.e.r; ++row) {
        const contact = parseContact(sheet, row, columnsMapping);
        if ((contact.firstName || '') !== '' || (contact.lastName || '') !== '' || (contact.email || '') !== '') {
            contacts.push(contact);
        }
    }
}

function parseContact(sheet: Sheet, row: number, mapping: Mapping) {
    const res: ContactDetails = {
        id: '',
        email: parseEmail(getOptional(sheet, row, mapping.email, true)),
        firstName: getOptional(sheet, row, mapping.firstName),
        lastName: getOptional(sheet, row, mapping.lastName),
        handicapIndex: parseHandicap(getOptional(sheet, row, mapping.handicap)),
        gender: parseGender(getOptional(sheet, row, mapping.gender)),
        hidden: false
    };
    return res;
}

function getOptional(sheet: Sheet, row: number, col: number | undefined, lowerCase?: boolean) {
    if (col === undefined) {
        return;
    }
    const val = sheet[Parser.utils.encode_cell({ c: col, r: row })];
    let v = val == null ? null : (val.w == null ? val.v : val.w);
    if (!v) {
        return;
    }
    if (typeof v === 'string') {
        v = toSafeString(v, true);
        return lowerCase ? v!.toLowerCase() : v;
    }
    return v;
}

function getColumns(sheet: Sheet, range: Range) {
    const columnHeaders: Array<any> = [];
    for (let column = range.s.c; column <= range.e.c; ++column) {
        const val = sheet[Parser.utils.encode_cell({ c: column, r: range.s.r })];
        if (!val || val.t !== 's') {
            continue;
        }
        columnHeaders[column] = val.v;
    }
    return columnHeaders;
}

type Entry = keyof Mapping;
const vals: [Entry, string[]][] = [
    ['email', ['email', 'Email']],
    ['firstName', ['first', 'firstname', 'given', 'name', 'FirstName']],
    ['lastName', ['last', 'lastname', 'family', 'surname', 'LastName']],
    ['handicap', ['handicap', 'ids', 'index', 'idx', 'Handicap']],
    ['gender', ['gender', 'sex', 'Gender']]
];
const mappings: Map<Entry, string[]> = new Map(vals);

function getColumnsMapping(columns: string[]) {
    const res = {} as Mapping;
    return columns.reduce((prev, val, idx) => {
        const value = normalize(val);
        mappings.forEach((mapping, key) => {
            for (const v of mapping) {
                if (value === v) {
                    prev[key] = idx;
                    break;
                }
            }
        });
        return prev;
    }, res);
}

function normalize(name: string) {
    return name.replace(/[.,/#!$%^&*;:{}=\-_`~()\s]/g, '').toLocaleLowerCase().trim();
}
