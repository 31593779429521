import React from "react";

export function processEnterKey(ev: React.KeyboardEvent, func: () => void, disabled?: boolean) {
    if (ev.key === 'Enter' && !disabled) {
        ev.preventDefault();
        func();
    }
}

export function readFile(file: File) {
    return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onload = resolve;
        fr.onerror = reject;
        fr.readAsDataURL(file);
    });
}

export function indexOfFile(files: Array<File>, name: string) {
    for (let i = 0; i < files.length; i++) {
        if (files[i].name === name) {
            return i;
        }
    }
    return -1;
}

export function detectMob() {
    const ua = navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua);
}
