import React, { useRef, useState, useCallback } from 'react';
import update from 'immutability-helper'
import { Divider, Typography, IconButton, List, ListItem, Badge } from '@mui/material';
import { CSVLink } from 'react-csv';
import PeopleIcon from '@mui/icons-material/People';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PersonIcon from '@mui/icons-material/Person';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import {
    DragSource, DragSourceConnector, DragSourceMonitor, ConnectDragSource, ConnectDragPreview,
    DropTarget, DropTargetConnector, DropTargetMonitor, ConnectDropTarget
} from 'react-dnd';
import * as Utils from '../../../../util/utility';
import * as Backend from '../../../../util/firebase';
import * as BackendRedux from '../../../../util/firebase';
import { WithUserId, withUserId } from '../../../../auth/Auth';
import { withProgress } from '../../../../util/ProgressPromise';
import {
    Event, EventData, Contact, Team, GolferGroup, ContactDetails, ScoringTeamSize, HandicapMode, GenderMode,
    makeNewGroups, golfersOrTeams, golfersOrTeamsPerGroup, Score, ReportedScore, Distance
} from '../../../../types/EventTypes';
import { fullName, getSameNameGolfersIds } from '../../../../contact/Contact';
import {
    teamName, teamSizeName, addGolferToTeam, removeGolferFromTeam, setEventTeamSize, deleteGolfersFromEvent,
    updateGolfersPlayingHandicap, elog, saveContact, saveGroupsBatch
} from '../../../Event';
import EditTeamsDialog from './EditTeamsDialog';
import { FixedWidthChip } from '../../../../common/components/FixedWidthChip';
import EditContactDialog from '../../../../contact/EditContactDialog';
import AutoScheduleDialog from '../../common/AutoScheduleDialog';
import ButtonBar from '../../../../common/components/ButtonBar';
import AppButton from '../../../../common/components/AppButton';
import LabeledField from '../../../../common/form/LabeledField';
import SelectTeamSizeDialog from '../../common/SelectTeamSizeDialog';
import { Container, Item, NoWrapOverflow } from '../../../../common/Misc';
import { styles } from '../../../../styles';
import { range } from "../../../../util/utility";
import { EmailVariant, sendPaymentMessage } from "../../../../util/email_utils";

const DRAG_TYPE_TEAM = 'TEAM';

function visibleTeamContactIds(team: Team, golfers: Map<string, Contact>): Array<string> {
    return team.contactIds.filter(contactId => golfers.has(contactId) && !golfers.get(contactId)!.hidden);
}

function visibleTeamContacts(team: Team, golfers: Map<string, Contact>): Array<Contact> {
    return visibleTeamContactIds(team, golfers).map(contactId => golfers.get(contactId)!);
}

export interface TeamWithContacts {
    team: Team;
    contacts: Array<Contact>;
}

interface TeamRowItem {
    id: string;
    name: string;
    homeCourseOrCity?: string;
    isPlaceholder: boolean;
    hidden: boolean;
    version: number;
}

interface TeamItemProps {
    initialItems: Array<TeamRowItem>;
    event: Event;
    golfers: Map<string, Contact>;
    golferTeam: Map<string, Team>;
    teamsList: Array<Team>;
    team: TeamWithContacts;
    teamSize: number;
    teamFullness: number;
    addToTeam: (team: TeamWithContacts) => void;
    dropToTeam: (golferId: string) => void;
    deleteFromTeam: (team: TeamWithContacts, contactId: string) => void;
    openGolfer: (id: string) => void;
}

interface TeamContactItemProps {
    id: string;
    name: string;
    homeCourseOrCity?: string;
    isPlaceholder?: boolean;
    team: TeamWithContacts;
    teamSize: number;
    index: number;
    isTeamOver: boolean;
    teamFullness: number;
    moveChip: (chip: TeamRowItem, groupFrom: TeamWithContacts, draggedIndex: number, groupTo: TeamWithContacts, hoverIndex: number) => void;
    dropToTeam: (team: TeamWithContacts, golferId: string, index: number) => void;
    onDelete: () => void;
    onOpen: (id: string) => void;
}

interface TeamContactItemSourceProps {
    connectDragSource: ConnectDragSource;
    connectDragPreview: ConnectDragPreview;
    connectDropTarget: ConnectDropTarget;
    isDragging: boolean;
    canDrop: boolean;
    item: TeamContactItemProps;
}

interface TargetProps {
    connectDropTarget: ConnectDropTarget;
    canDrop: boolean;
    isOver: boolean;
    item: TeamContactItemProps;
}

const contactSource = {
    beginDrag(props: TeamContactItemProps) {
        return props;
    },
};

const contactTarget = {
    hover(props: TeamContactItemProps, monitor: DropTargetMonitor) {
        const item = monitor.getItem() as TeamContactItemProps;
        if (item.team.team.id === props.team.team.id) {
            const chip: TeamRowItem = { id: item.id, name: item.name, isPlaceholder: false, hidden: false, version: -1 }
            item.moveChip(chip, item.team, item.index, props.team, props.index);
        } else {
            const chip: TeamRowItem = { id: item.id, name: item.name, isPlaceholder: true, hidden: false, version: -1 }
            props.moveChip(chip, item.team, item.index, props.team, props.index);
        }
    },
    canDrop(props: TeamContactItemProps, monitor: DropTargetMonitor) {
        const item = monitor.getItem() as TeamContactItemProps;
        return props.teamFullness < 1 || (!!props.team.contacts.find(c => c.id === item.id) || !!props.team.contacts.find(t => t.id === item.id));
    },
    drop(props: TeamContactItemProps, monitor: DropTargetMonitor) {
        const item = monitor.getItem() as TeamContactItemProps;
        if (item.team.team.id === props.team.team.id) {
            item.dropToTeam(props.team, item.id, props.index);
        } else {
            props.dropToTeam(props.team, item.id, props.index);
        }
    }
};

const teamTarget = {
    canDrop(props: TeamItemProps, monitor: DropTargetMonitor) {
        const item = monitor.getItem() as TeamContactItemProps;
        return monitor.isOver() && (props.team.contacts.length < props.teamSize + 1 || !!props.team.contacts.find(c => c.id === item.id));
    },
    drop(props: TeamItemProps, monitor: DropTargetMonitor) {
        if (!monitor.didDrop()) {
            const item = monitor.getItem() as TeamContactItemProps;
            props.dropToTeam(item.id);
        }
    }
};

const collectSource = (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
});

const collectContactTarget = (connect: DropTargetConnector, monitor: DropTargetMonitor) => ({
    connectDropTarget: connect.dropTarget(),
    canDrop: monitor.canDrop(),
    isOver: monitor.isOver(),
    item: monitor.getItem() as TeamContactItemProps,
});

const collectTeamTarget = (connect: DropTargetConnector, monitor: DropTargetMonitor) => ({
    connectDropTarget: connect.dropTarget(),
    canDrop: monitor.canDrop(),
    isOver: monitor.isOver(),
    item: monitor.getItem() as TeamContactItemProps,
});

const TeamContactItemDnD = React.forwardRef<HTMLDivElement, TeamContactItemProps & TeamContactItemSourceProps & TargetProps & WithStyles<typeof styles>>(
    ({ id, name, team, classes, isPlaceholder, onDelete, onOpen, isOver, isTeamOver, item, isDragging, connectDragSource, connectDropTarget, homeCourseOrCity }, ref) => {
        const elementRef = useRef(null);
        connectDragSource(elementRef);
        connectDropTarget(elementRef);
        const label = <NoWrapOverflow><PersonIcon className={classes.textIcon} />{name}<span className={classes.homeCourseOrCity}>{homeCourseOrCity ? ` (${homeCourseOrCity})` : ''}</span></NoWrapOverflow>;
        return !isPlaceholder || (isTeamOver && item.id === id) ? (<span ref={elementRef} style={{ backgroundColor: 'rgba(0, 0, 0, 0)', height: '100%', display: 'inline-block', borderRadius: 16 }}>
            {<FixedWidthChip label={label} color={isOver && !!item && item.id === id ? "secondary" : "default"} onDelete={onDelete} onDoubleClick={e => onOpen(id)} />}
        </span>) : <span></span>;
    },
);

const TeamContactItem = withStyles(styles)(DropTarget(DRAG_TYPE_TEAM, contactTarget, collectContactTarget)(DragSource(DRAG_TYPE_TEAM, contactSource, collectSource)(TeamContactItemDnD)));

function gatherItems(event: Event, team: TeamWithContacts, golfers: Map<string, Contact>, version: number): TeamRowItem[] {
    const sameNameGolfersIdsSet: Set<string> = getSameNameGolfersIds(Array.from(golfers.values()));
    const items: TeamRowItem[] = [];
    team.contacts.map((contact, i) => items.push({
        id: contact.id,
        name: fullName(contact),
        homeCourseOrCity: sameNameGolfersIdsSet.has(contact.id) && contact.homeCourseOrCity ? contact.homeCourseOrCity : undefined,
        isPlaceholder: false,
        hidden: !golfers.has(contact.id),
        version
    }));
    return items;
}

const TeamItemDnD = React.forwardRef<HTMLDivElement, TeamItemProps & TargetProps & WithStyles<typeof styles>>(
    ({ initialItems, event, team, golfers, teamsList, classes, teamSize, canDrop, isOver, item, teamFullness, addToTeam, openGolfer, deleteFromTeam, connectDropTarget }, ref) => {
        const showIcon = teamFullness < 1 && !item;
        const [initialState, setInitialState] = useState(initialItems);
        const [items, setItems] = useState(initialState);
        if (initialState.length !== initialItems.length || (initialState.length > 0 && initialState[0].version !== initialItems[0].version)) {
            setInitialState(initialItems);
            setItems(initialItems);
        }
        const dropToTeam = (team: Team, contactId: string, index: number) =>
            addGolferToTeam(event, teamsList, golfers.get(contactId)!, team, index);
        const moveChip = useCallback(
            (chip: TeamRowItem, teamFrom: TeamWithContacts, dragIndex: number, teamTo: TeamWithContacts, hoverIndex: number) => {
                const pos = items.findIndex(i => i.id === chip.id);
                if (teamFrom.team.id === teamTo.team.id) {
                    setItems(update(items, { $splice: [[dragIndex, 1], [hoverIndex, 0, chip]] }));
                } else if (teamFullness < 1) {
                    if (items.length === 0 || pos < 0) {
                        setItems(update(items, { $splice: [[hoverIndex, 0, chip]] }));
                    } else {
                        setItems(update(items, { $splice: [[pos, 1], [hoverIndex, 0, chip]] }));
                    }
                }
            },
            [items, teamFullness]);
        const contactsList = items.filter(i => !i.hidden).map((item: TeamRowItem, i: number) => <TeamContactItem
            isPlaceholder={item.isPlaceholder} index={i} key={item.id} id={item.id} name={item.name} homeCourseOrCity={item.homeCourseOrCity ? item.homeCourseOrCity : undefined}
            team={team} teamSize={teamSize} teamFullness={teamFullness} isTeamOver={isOver} moveChip={moveChip}
            onOpen={openGolfer} onDelete={() => deleteFromTeam(team, item.id)}
            dropToTeam={(team: TeamWithContacts, contactId: string, index: number) => dropToTeam(team.team, contactId, index)} />);
        if (!!item && canDrop && isOver && item.team.team.id !== team.team.id && items.findIndex(i => i.id === item.id) < 0) {
            const label = <NoWrapOverflow><PersonIcon className={classes.textIcon} />{item.name}</NoWrapOverflow>;
            contactsList.push((<FixedWidthChip key={item.id + '_placeholder'} label={label} onDelete={() => { }} className={classes.dragActive} onDoubleClick={() => { }} />));
        }
        const decorations = classes.listItem;
        return connectDropTarget(
            <div>
                <ListItem className={decorations}>
                    <Container wrap="nowrap">
                        <Item height={38}>
                            <Typography variant="body2" noWrap style={{ width: 64 }}>{teamName(team.team)}</Typography>
                            {team.contacts.length > teamSize && <span style={{ color: 'red', fontSize: '0.65rem' }}>{Utils.withS(team.contacts.length, 'golfer')}</span>}
                        </Item>
                        <Item wrapLabel>
                            {contactsList}
                            {showIcon && <IconButton
                                className={classes.smallIconButton}
                                onClick={() => addToTeam(team)}
                                size="large"><AddCircleOutlineIcon /></IconButton>}
                        </Item>
                    </Container>
                </ListItem >
                <Divider />
            </div>
        );
    });

const TeamItem = withStyles(styles)(DropTarget(DRAG_TYPE_TEAM, teamTarget, collectTeamTarget)(TeamItemDnD));

interface State {
    editingTeam: number;
    editedContact?: ContactDetails;
    doAutoPair: boolean;
    openTeamSizeDialog?: boolean;
    handleTeamExport: boolean;
}

type Props = { event: Event; eventData: EventData; } & WithStyles<typeof styles> & WithUserId;

class TeamsList extends React.Component<Props, State> {

    state: State = {
        editingTeam: -1,
        doAutoPair: false,
        handleTeamExport: false,
    };

    componentDidMount() {
        Backend.trackEvent('view_teams');
        //this.initTeams();
    }

    private handleGolferRowClick = (contact?: ContactDetails) => contact && this.setState({ editedContact: { ...contact } });
    private handleContactDeleted = (golfers: Array<Contact>) => this.deleteGolfersFromEvent(golfers);
    private handleCloseEditDialog = () => this.setState({ editedContact: undefined });
    private handleTeamExport = () => this.setState({ handleTeamExport: true });
    private addToTeam = (team: TeamWithContacts) => this.setState({ editingTeam: team.team.order });
    private hideTeamsSizeDialog = () => this.setState({ openTeamSizeDialog: false });
    private showTeamsSizeDialog = () => this.setState({ openTeamSizeDialog: true });

    private handleReset = () => {
        const { event } = this.props;
        const { teamsList } = this.props.eventData;
        BackendRedux.removeBatch(Backend.golferTeamDb(event.id), teamsList)
            .then(() => elog(event, `Teams reset `, `No details`, ''));
    }

    private deleteGolfersFromEvent(golfersToDelete: Array<Contact>) {
        const { event } = this.props;
        deleteGolfersFromEvent(event, golfersToDelete, undefined)
            .then(() => updateGolfersPlayingHandicap(event));
    }

    private handleContactChanged = (contactDetail: Contact, notificationLess: boolean) => {
        const { event } = this.props;
        const { roster } = this.props.eventData;
        roster.set(contactDetail.id, contactDetail)
        saveContact(event, contactDetail, contactDetail.id ? 'Golfer modified' : 'Golfer added', notificationLess)
            .then(() => this.setState({ editedContact: undefined }));
    }

    private saveTeams = (teams: Array<Team>, teamsToDel: Array<Team>, genderMode: GenderMode, handicapMode: HandicapMode) =>
        BackendRedux.updateOrAddAndRemoveBatch(Backend.golferTeamDb(this.props.event.id), teams, true, teamsToDel)
            .then(() => elog(this.props.event, `Teams auto pairing `, `With handicapMode: ${handicapMode} genderMode: ${genderMode}. ${Utils.withS(teams.length, 'new team')} have been paired.`, ''))

    private pairTeams = (genderMode: GenderMode, handicapMode: HandicapMode, keepTeams: boolean) => {
        const { event, eventData } = this.props;
        const { golfers, groups, teamsList } = eventData;
        const visibleGolfers = new Map<string, Contact>();
        golfers.forEach(g => { if (!g.hidden) { visibleGolfers.set(g.id, g); } });
        const count = Math.ceil(visibleGolfers.size / event.teamSize);
        const teamsFrom = keepTeams ? teamsList : [{ id: '', contactIds: [], order: 0 }];
        const newTeams = makeNewGroups(teamsFrom, event.teamSize, count, true, golfersOrTeams(visibleGolfers, teamsFrom, true), handicapMode, genderMode);
        const getNewTeamId = (() => range(0, newTeams.length)
            .findIndex(idx => newTeams.every(g => g.id !== `${idx}`)));
        newTeams.forEach(team => team.id = !team.id ? `${getNewTeamId()}` : team.id);
        const teamsToDel = keepTeams ? [] : teamsList.filter(t1 => t1.id && !newTeams.find(t2 => t2.id === t1.id));
        const autoScheduledGroups: GolferGroup[] = makeNewGroups([], golfersOrTeamsPerGroup(event, event.teeTime.golfersPerGroup), 0, false,
            new Map<string, Team>(newTeams.map((team, ind) => ['' + ind, team])), 'random', 'random');
        const groupsToDelete = groups.filter(group => group.id && !autoScheduledGroups.find(other => other.id === group.id));
        this.saveTeams(newTeams, teamsToDel, genderMode, handicapMode)
            .then(() => saveGroupsBatch(autoScheduledGroups, genderMode, handicapMode, groupsToDelete, event));
    }

    private handlePairTeams = (genderMode: GenderMode, handicapMode: HandicapMode, keepTeams: boolean) =>
        this.setState({ doAutoPair: false }, () => this.pairTeams(genderMode, handicapMode, keepTeams));

    private teamWithContacts(team: Team): TeamWithContacts {
        const { golfers } = this.props.eventData;
        return { team: team, contacts: visibleTeamContacts(team, golfers) };
    }

    private deleteFromTeam = (teamc: TeamWithContacts, contactId: string) => {
        const { event } = this.props;
        const { golfers, teamsList } = this.props.eventData;
        removeGolferFromTeam(event, teamsList, golfers.get(contactId)!, teamc.team);
    }

    private saveTeamSize = (teamSize: ScoringTeamSize, oldTeamSize: ScoringTeamSize) => {
        const { event } = this.props;
        const { golfers, teamsList, groups, competitions } = this.props.eventData;
        withProgress(setEventTeamSize(event, teamsList, competitions, teamSize, oldTeamSize, golfers, groups))
            .then(() => this.setState({ openTeamSizeDialog: false }));
    }

    private exportTeamData() {
        const { event } = this.props;
        const { golfers, teamsList } = this.props.eventData;
        const exportData: string[][] = [];
        const exportHeader = ['Team'];
        for (let i = 1; i <= event.teamSize; i += 1) {
            exportHeader.push('Golfer ' + i);
        }
        exportData.push(exportHeader);
        teamsList.forEach((team, index) => {
            const teamContacts = visibleTeamContacts(team, golfers)
            if (teamContacts.length > 0) {
                const exportRow: string[] = [];
                exportRow.push(String(index + 1));
                teamContacts.forEach(contact => exportRow.push(fullName(contact)));
                exportData.push(exportRow);
            }
        });
        return exportData;
    }

    private Teams = () => {
        const { classes, event } = this.props;
        const { golfers, golferTeam, teamsList, loadedTeams, loadedGolfers } = this.props.eventData;
        const { handleTeamExport } = this.state;
        const moveTeam = (team: Team, contactId: string) =>
            addGolferToTeam(event, teamsList, golfers.get(contactId)!, team);
        let unpairedInfo = '';
        const fileName = event.name.replace(' ', '-') + '-' + Utils.formatDate2(event.date);
        const exportFile = `${fileName}-teams.csv`;
        const exportData = handleTeamExport ? this.exportTeamData() : '';
        if (loadedTeams > 0 && loadedGolfers > 0) {
            const paired = new Set();
            teamsList.forEach(team => {
                if (visibleTeamContactIds(team, golfers).length > 0) {
                    team.contactIds.forEach(contactId => paired.add(contactId));
                }
            });
            let unpaired = 0;
            golfers.forEach(golfer => unpaired += paired.has(golfer.id) ? 0 : 1);
            if (unpaired === 1) {
                unpairedInfo = `${unpaired} golfer not paired yet`;
            } else if (unpaired > 1) {
                unpairedInfo = `${unpaired} golfers not paired yet`;
            }
        }
        return (
            <div className={classes.listRoot2}>
                <List disablePadding className={classes.listRoot2}>
                    <LabeledField label={'Teams'} itemClass={classes.listItem} value={teamSizeName(event.teamSize)} edit={this.showTeamsSizeDialog} />
                </List>
                {event.teamSize > 1 && <List disablePadding className={classes.listRoot2}>
                    <ListItem className={classes.listItem}>
                        <ButtonBar margin>
                            <AppButton color="secondary" onClick={() => this.setState({ doAutoPair: true })}>
                                <PeopleIcon className={classes.leftButtonIcon} />Auto pair...
                            </AppButton>
                            {<CSVLink data={exportData} filename={exportFile} style={{ textDecoration: 'none' }}>
                                <AppButton color="info" onClick={this.handleTeamExport}>Export</AppButton>
                            </CSVLink>}
                            <AppButton color="info" onClick={this.handleReset}>Reset all</AppButton>
                            {!!unpairedInfo && <Typography variant="body1" style={{ marginLeft: 16 }}>
                                {unpairedInfo}
                                <Badge sx={{ marginBottom: 2, marginLeft: 1 }} color="error" variant="dot" overlap="rectangular" />
                            </Typography>}
                        </ButtonBar>
                    </ListItem>
                    {teamsList.map((team, idx) => {
                        const teamWithContacts = this.teamWithContacts(team);
                        return <TeamItem
                            initialItems={gatherItems(event, teamWithContacts, golfers, loadedTeams * 10000 + loadedGolfers)}
                            key={idx}
                            event={event}
                            golfers={golfers}
                            teamsList={teamsList}
                            golferTeam={golferTeam}
                            team={teamWithContacts}
                            teamSize={event.teamSize}
                            teamFullness={visibleTeamContactIds(team, golfers).length - event.teamSize}
                            addToTeam={this.addToTeam}
                            dropToTeam={(contactId: string) => moveTeam(team, contactId)}
                            openGolfer={id => this.handleGolferRowClick(golfers.get(id))}
                            deleteFromTeam={this.deleteFromTeam} />
                    })}
                </List>}
            </div>
        );
    };

    private handleSendMessage = async (contactsToSend: Contact[], variant: EmailVariant) => {
        const { event, eventData } = this.props;
        await sendPaymentMessage(contactsToSend, event, eventData, new Map<string, Score>(), new Map<string, Score>(),
            new Map<string, ReportedScore>(), new Map<string, ReportedScore>(), new Map<string, Distance>(), variant);
    };

    render() {
        const { event, eventData } = this.props;
        const { golfers, teamsList, competitions } = eventData;
        const { editedContact, doAutoPair, openTeamSizeDialog } = this.state;
        return (
            <React.Fragment>
                {!!editedContact && <EditContactDialog
                    open
                    event={event}
                    actionMode={editedContact.id ? 'edit' : 'add'}
                    initialContact={editedContact}
                    saveToEvent={this.handleContactChanged}
                    handleClose={this.handleCloseEditDialog}
                    sendEmail={this.handleSendMessage}
                    deleteFromEvent={this.handleContactDeleted} />}
                {this.state.editingTeam >= 0 && <EditTeamsDialog
                    event={event}
                    eventData={eventData}
                    editingTeam={this.state.editingTeam}
                    handleClose={() => this.setState({ editingTeam: -1 })} />}
                {doAutoPair && <AutoScheduleDialog
                    open
                    event={event}
                    pairing={true}
                    close={() => this.setState({ doAutoPair: false })}
                    save={this.handlePairTeams}
                    golfersOrTeams={golfersOrTeams(golfers, teamsList, true)}
                    groups={teamsList}
                    count={Math.ceil(golfers.size / event.teamSize)}
                    groupSize={event.teamSize} />}
                {openTeamSizeDialog && <SelectTeamSizeDialog saveTeamSize={this.saveTeamSize} close={this.hideTeamsSizeDialog} teamSize={event.teamSize} competitions={competitions} />}
                <this.Teams />
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(withUserId(TeamsList));
