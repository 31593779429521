import * as React from 'react';
import RichTextView from '../common/form/richtext/RichTextView';
import {
    ACTION_REGISTRATION, AddGolfersRequest, AddGolfersResult, Competition, Contact, ContactDetails, Event,
    getCourseName, getTee, HOLES_NAMES, PaymentStatus, Portal
} from '../types/EventTypes';
import * as Utils from '../util/utility';
import { formatDate3, formatDayOfWeek, roundDate, upperFirstLetter, findGetParameter } from '../util/utility';
import { scoringName } from '../scoring/scoring';
import { formatTeeTime } from '../event/TeeTimes';
import { Box, DialogActions, DialogContent, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { AppColors } from "../main/Theme";
import ListGridItem, { OneToEleven } from "../common/components/ListGridItem";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { bottomTextStyle, getName, topTextStyle } from "../event/list/EventCreatedDialog";
import {
    BlueDollarIcon, CheckMarkIcon, DoneIconPrimary, GolferAddIcon, InfoIcon, LinkIcon, LocationIcon, ScoringIcon
} from "../common/Icons";
import { formatDateUniversal, getRegistrationDate, hasMen, hasWomen, onContactTheOrganizerClick } from "../event/Event";
import AppButton from "../common/components/AppButton";
import { showAlert, showProgress } from "../redux/ReduxConfig";
import { FirebaseDataComponent, FirebaseDocComponent } from "../common/WithData";
import * as Backend from "../util/firebase";
import { RegisterContactDialog } from "../contact/EditContactDialog";
import axios from "axios";
import { Urls } from "../util/config";
import withStyles from "@mui/styles/withStyles";
import { styles } from "../styles";
import { WithStyles } from "@mui/styles";
import {fullName, newContact, shortSurname} from "../contact/Contact";
import CopyTextField from "../common/components/CopyTextField";
import { XSMobileDialog } from "../common/dialog/MobileDialog";
import DialogAppBar from "../common/dialog/DialogAppBar";
import CurrencyList from 'currency-list';
import { PayPalPaymentDialog } from "../payments/paypal/PayPalPaymentDialog";

type EventInfo = {
    courseName: string,
    eventDate: string,
    startTime: string,
    teeTimeMode: string,
    competitionName: string
    teesName: string;
};

export function getEventInfo(event: Event, mainCompetition?: Competition): EventInfo {
    const courseName = event.course ? getCourseName(event.course) : 'Course not selected';
    const eventDate = formatDate3(event.date);
    const startTime = formatTeeTime(event.teeTime);
    const mode = event.teeTime?.mode;
    const teeTimeMode = mode !== 'regular' ? upperFirstLetter(mode || '') : '';
    const competitionName = mainCompetition ? scoringName(mainCompetition, event.eventGender, mainCompetition.competitionGender) : '';
    let teesName = HOLES_NAMES[event.holesType || 0];
    if (hasMen(event, mainCompetition)) {
        const teeMen = getTee(mainCompetition, 'male');
        if (teeMen) {
            teesName += ', ' + getName(teeMen) + ' for men';
        }
    }
    if (hasWomen(event, mainCompetition)) {
        const teeWoman = getTee(mainCompetition, 'female');
        if (teeWoman) {
            teesName += ', ' + getName(teeWoman) + ' for women';
        }
    }
    return { courseName, eventDate, startTime, teeTimeMode, competitionName, teesName };
}

const textStylesCommon: React.CSSProperties = {
    fontSize: 16,
    fontFamily: '"poppins", sans-serif',
    lineHeight: '145%'
};

type RegistrationAvailableProps = Omit<EventInfoContentProps, 'mainCompetition' | 'adminAddress' | 'classes'> &
    { iconXs: 1 | 2 | 3, maxContentSize: OneToEleven };

const RegistrationAvailableContent = (props : RegistrationAvailableProps) => {
    const { event, addedContact, adminEmail, iconXs, maxContentSize, payingContactInfo, onRegister } = props;
    const registrationDate = getRegistrationDate(event);
    const title = `Registration deadline — through ${formatDateUniversal(registrationDate, 'MMMM D, YYYY')}`;
    const txtGolferRegistered = addedContact ? `${fullName(addedContact)} registered successfully.` : undefined;
    const registrationLink = Urls.makeAboutLink(event.publicId);
    const paymentSettings = event.paymentSettings;
    const currencySymbol = React.useMemo(
        () => paymentSettings ? CurrencyList.get(paymentSettings.currencyCode)?.symbol ?? '' : '',
        [event.paymentSettings?.currencyCode]
    );
    const entryFeeTitle = paymentSettings?.enabled ? `${currencySymbol}${currencySymbol.length > 1 ? ' ' : ''}${paymentSettings.feeCost} entry fee` : '';
    const entryFeeSubTitle = paymentSettings?.feeDescription ?? 'Entry fee';
    return (<Grid item margin="dense">
        <ListGridItem icon={<GolferAddIcon color="primary" />} iconXs={iconXs}
            sx1={topTextStyle} sx2={bottomTextStyle} maxContentSize={maxContentSize}
            text1={title} text2={Utils.formatDayOfWeek(registrationDate)} />
        {paymentSettings?.enabled && <ListGridItem icon={<BlueDollarIcon />} iconXs={iconXs}
                      sx1={topTextStyle} sx2={bottomTextStyle} maxContentSize={maxContentSize}
                      text1={entryFeeTitle} text2={entryFeeSubTitle} />}
        <Box>
            {addedContact && <Box>
                <Box height={8} />
                <Typography sx={{ ...textStylesCommon, color: AppColors.webBlack }}>
                    <DoneIconPrimary style={{ verticalAlign: 'middle', marginRight: '6px', marginBottom: 3 }} />
                    {txtGolferRegistered}
                </Typography>
                <Typography sx={{ ...textStylesCommon, color: AppColors.webGray }}>
                    For changes and cancellations please contact event administrator
                    {adminEmail && <> at <span style={{ ...textStylesCommon, color: AppColors.blue500 }}>
                        {adminEmail}
                    </span></>}.
                </Typography>
            </Box>}
            <Box height={4} />
            {payingContactInfo && <Box display="flex" flexDirection="column" alignItems="center">
                    <ListGridItem icon={<Box padding="2px" display="block"><CheckMarkIcon color={AppColors.bluish} /></Box>}
                                  text1={`Entry fee for ${shortSurname(payingContactInfo.contact)} has been successfully paid`}
                                  iconXs={iconXs} maxContentSize={maxContentSize} sx1={topTextStyle} text2={''} />
                <Box height={4} />
            </Box>}
            <Box display="flex" flexDirection="row" alignItems="center">
                <CopyTextField
                    readOnly btnOnly
                    color="secondary"
                    fieldValue={registrationLink}
                    btnLabel={<React.Fragment>INVITE FRIENDS<LinkIcon /></React.Fragment>} />
                <Box width={16} />
                <AppButton color="secondary"
                           disabled={payingContactInfo?.payButtonEnabled === false}
                           onClick={payingContactInfo?.handlePayClick ?? onRegister}>
                    {payingContactInfo?.payButtonEnabled !== undefined ? 'Pay' :
                        addedContact ? 'Register another golfer' : 'Register'}
                </AppButton>
            </Box>
        </Box>
    </Grid>);
};

type EventInfoContentProps = {
    event: Event;
    addedContact?: Contact;
    adminEmail?: string;
    mainCompetition?: Competition;
    adminAddress?: string;
    payingContactInfo?: PayingContactInfo;
    onRegister: () => void;
} & WithStyles<typeof styles>;

const EventInfoComponent = withStyles(styles)((props: EventInfoContentProps) => {
    const { event, mainCompetition, adminEmail, addedContact, payingContactInfo, onRegister } = props;
    const { startTime, courseName, competitionName, teesName } = getEventInfo(event, mainCompetition);
    const startDateTime = `Start date — ${formatDateUniversal(event.date, 'D MMMM YYYY')}, ${startTime}`;
    const registrationDate = getRegistrationDate(event);
    const deadline = roundDate(registrationDate);
    const now = roundDate(new Date().getTime());
    const registrationClosed = event.registrationByAdmin || now > deadline;
    const textRegistrationClosed = event.registrationByAdmin ? 'Only event administrator can add golfers' :
        'Registration closed';
    const eventName = event.name ?? '';
    const theme = useTheme();
    const lessThanXl = useMediaQuery(theme.breakpoints.down('xl'));
    const lessThanMd = useMediaQuery(theme.breakpoints.down('md'));
    const lessThanSm = useMediaQuery(theme.breakpoints.down('sm'));
    const maxContentSize = lessThanSm ? 9 : lessThanMd ? 10 : 11;
    const iconXs = lessThanSm ? 3 : lessThanMd ? 2 : 1;
    return (
        <Box sx={{ marginLeft: lessThanXl ? '6px' : 0 }}>
            <Typography sx={{
                fontWeight: 500, fontSize: 20, lineHeight: '30px', color: AppColors.webBlack,
                fontFamily: '"poppins", sans-serif'
            }}>{eventName}</Typography>
            <Grid container direction="row" columnSpacing={3}>
                <Grid item sx={{ width: '45%' }} margin="dense">
                    <ListGridItem icon={<DateRangeIcon color="primary" />} iconXs={iconXs}
                        sx1={topTextStyle} sx2={bottomTextStyle} maxContentSize={maxContentSize}
                        text1={startDateTime} text2={formatDayOfWeek(event.date)} />
                    <ListGridItem icon={<LocationIcon color="primary" />} iconXs={iconXs}
                        sx1={topTextStyle} sx2={bottomTextStyle} maxContentSize={maxContentSize}
                        text1={courseName} text2={teesName} />
                    <ListGridItem icon={<ScoringIcon color="primary" />} iconXs={iconXs}
                        sx1={topTextStyle} sx2={bottomTextStyle} maxContentSize={maxContentSize}
                        text1={competitionName} text2={event.teamSize > 1 ? 'Team' : 'Individual'} />
                </Grid>
                <Grid item sx={{ width: '45%' }} margin="dense">
                    {registrationClosed ?
                        <ListGridItem icon={<GolferAddIcon color="primary" />} iconXs={iconXs}
                            sx1={topTextStyle} sx2={bottomTextStyle} maxContentSize={maxContentSize}
                            text1={textRegistrationClosed} text2={''}
                            button={adminEmail ?
                                <AppButton color="secondary" sx={{
                                    fontSize: 14, lineHeight: '21px', marginTop: 1, marginLeft: '36px',
                                    fontFamily: '"poppins", sans-serif'
                                }} onClick={() => onContactTheOrganizerClick(eventName, adminEmail)}
                                >Contact the organizer</AppButton> : undefined} />
                        : <RegistrationAvailableContent event={event}
                                                        adminEmail={adminEmail}
                                                        addedContact={addedContact}
                                                        iconXs={iconXs}
                                                        maxContentSize={maxContentSize}
                                                        onRegister={onRegister}
                                                        payingContactInfo={payingContactInfo} />}
                </Grid>
            </Grid>
        </Box>
    );
});

type RegistrationCompletedComponent = {
    handleClose: () => void;
    paymentStatus: PaymentStatus;
} & WithStyles<typeof styles>;

const RegistrationCompletedComponent = withStyles(styles)((props: RegistrationCompletedComponent) => {
    const { classes, paymentStatus, handleClose } = props;
    const successfulPayment = 'You have successfully registered, Event organizer will be notified of your payment.';
    const successfullyRegistered = 'You have successfully registered.';
    const successFulPaymentStatus = paymentStatus === PaymentStatus.SUCCESSFUL_PAYMENT ||
        paymentStatus === PaymentStatus.SUCCESSFUL_PAYMENT_WITH_EXCEPTION;
    const paymentFailure = paymentStatus === PaymentStatus.PAYMENT_FAILURE;
    const payLater = paymentStatus === PaymentStatus.PAY_LATER;
    const registerResultText = successFulPaymentStatus ? successfulPayment : payLater ? successfullyRegistered :
        paymentFailure ? successfullyRegistered : '';
    const commonStyle: React.CSSProperties = { fontSize: 16, lineHeight: '24px', fontFamily: '"poppins", sans-serif' };
    return (
        <XSMobileDialog open={true} maxWidth="xs">
            <DialogAppBar label={<Box display="flex" flexDirection="row">
                <img style={{ width: 24, height: 24, marginRight: 12 }} src={Urls.checkMarkBlueIcon} alt="" />
                <Typography className={classes.bigHeaderFontStyle}>REGISTRATION COMPLETED</Typography>
            </Box>} close={handleClose}>
            </DialogAppBar>
            <DialogContent style={{ ...commonStyle }}>
                {registerResultText && <Box>
                    <Typography style={{ fontWeight: 600, ...commonStyle }}>{registerResultText}</Typography>
                    <Box height={16} />
                </Box>}
                {successFulPaymentStatus &&
                    <Box style={{ backgroundColor: AppColors.webSuccess, color: AppColors.white, ...commonStyle }}
                         padding={1} borderRadius="4px" display="flex" flexDirection="row" alignItems="center">
                        <Box width={20} height={20}><CheckMarkIcon /></Box>
                        <Box width={8}/>
                        <Typography style={{ fontSize: 16 }}>
                            The payment was successful. Thank you for using PayPal!
                        </Typography>
                    </Box>}
                {(paymentFailure || payLater) &&
                    <Box style={{ backgroundColor: AppColors.webGrayLight1 }} padding={1} borderRadius="4px"
                         display="flex" flexDirection="row" alignItems="center">
                        <InfoIcon sx={{ color: payLater ? AppColors.webWarning : AppColors.webError }}/>
                        <Box width={8}/>
                        <Box display="flex" flexDirection="column" style={{ ...commonStyle }}>
                            {paymentFailure && <Typography style={{ color: AppColors.webError, fontWeight: 600 }}>
                                The payment was unsuccessful.
                            </Typography>}
                            <Typography style={{ color: AppColors.webBlack }}>
                                Please coordinate payment with the tournament organizer directly.
                            </Typography>
                        </Box>
                    </Box>}
                <Box height={16} />
                <Typography style={{ ...commonStyle }}>
                    Please add <span style={{ cursor: 'pointer', color: AppColors.blue500 }}>updates@golfpad.events</span>&nbsp;to your email contacts to ensure receipt of future event correspondence.
                </Typography>
            </DialogContent>
            <DialogActions>
                <AppButton color="secondary" onClick={handleClose}>Ok</AppButton>
            </DialogActions>
        </XSMobileDialog>
    );
});

type PaymentCompletedDialogProps = {
    paymentStatus: PaymentStatus;
    handleClose: () => void;
} & WithStyles<typeof styles>;

const PaymentCompletedDialog = withStyles(styles)((props: PaymentCompletedDialogProps) => {
    const { paymentStatus, classes, handleClose } = props;
    const success = paymentStatus === PaymentStatus.SUCCESSFUL_PAYMENT ||
        paymentStatus === PaymentStatus.SUCCESSFUL_PAYMENT_WITH_EXCEPTION;
    const commonStyle: React.CSSProperties = { fontFamily: '"poppins", sans-serif' };
    return (
        <XSMobileDialog open={true} maxWidth="xs">
            <DialogAppBar label={<Box display="flex" flexDirection="row">
                {success && <img style={{ width: 24, height: 24, marginRight: 12 }} src={Urls.checkMarkBlueIcon} alt="" />}
                <Typography className={classes.bigHeaderFontStyle}>PAYMENT OF ENTRY FEE</Typography>
            </Box>} close={handleClose}>
            </DialogAppBar>
            <DialogContent style={commonStyle}>
                {success ? <Box>
                        <Box>
                            <Typography style={{ fontWeight: 600, ...commonStyle }}>
                                Event organizer will be notified of your payment.
                            </Typography>
                            <Box height={16} />
                        </Box>
                        <Box style={{ backgroundColor: AppColors.webSuccess, color: AppColors.white, ...commonStyle }}
                                    padding={1} borderRadius="4px" display="flex" flexDirection="row" alignItems="center">
                            <Box width={20} height={20}><CheckMarkIcon /></Box>
                            <Box width={8} />
                            <Typography style={{ fontSize: 16 }}>
                                The payment was successful. Thank you for using PayPal!
                            </Typography>
                        </Box>
                    </Box>
                    :
                    <Box style={{ backgroundColor: AppColors.webGrayLight1 }} padding={1} borderRadius="4px"
                         display="flex" flexDirection="row" alignItems="center">
                        <InfoIcon sx={{ color: AppColors.webError }}/>
                        <Box width={8} />
                        <Box display="flex" flexDirection="column" style={{ ...commonStyle }}>
                            <Typography style={{ color: AppColors.webError, fontWeight: 600 }}>
                                The payment was unsuccessful.
                            </Typography>
                            <Typography style={{ color: AppColors.webBlack }}>
                                Please try again later.
                            </Typography>
                        </Box>
                    </Box>}
            </DialogContent>
            <DialogActions>
                <AppButton color="secondary" onClick={handleClose}>Ok</AppButton>
            </DialogActions>
        </XSMobileDialog>
    );
});

type PayingContactInfo = {
    payButtonEnabled: boolean;
    contact: Contact;
    handlePayClick?: () => void;
};

type AboutProperties = {
    event: Event;
    portal: Portal;
    mainCompetition?: Competition;
} & WithStyles<typeof styles>;

type AboutState = {
    editedContact?: Contact,
    addedContact?: Contact,
    adminEmail?: string,
    golfers: Map<string, Contact>;
    maxEventContacts: number;
    showRegistrationSuccess: boolean;
    paymentStatus: PaymentStatus;
    showPaymentDialog: boolean;
    payingUserId?: string;
    showPaymentCompletedDialog: boolean;
};

class About extends React.Component<AboutProperties, AboutState> {
    state: AboutState;
    constructor(props: AboutProperties) {
        super(props);
        const payingUserId = findGetParameter('payingUserId');
        this.state = {
            golfers: new Map<string, Contact>(),
            maxEventContacts: 100,
            showRegistrationSuccess: false,
            paymentStatus: PaymentStatus.NONE,
            showPaymentDialog: Boolean(payingUserId),
            payingUserId: payingUserId,
            showPaymentCompletedDialog: false
        };
    }

    private onGolfers = (golfers: Map<string, Contact>) => this.setState({ golfers });

    private onUserData = (adminEmail: string) => this.setState({ adminEmail });

    private handleRegister = (contactDetails: ContactDetails, paymentStatus: PaymentStatus = PaymentStatus.NONE) => {
        this.setState({ editedContact: undefined }, () => {
            const { event } = this.props;
            const currentUser = Backend.firebaseAuth.currentUser;
            const source = currentUser ? (currentUser.email ? currentUser.email : currentUser.uid) : '';
            const nowDate = new Date();
            const req: AddGolfersRequest = {
                eventId: event.id,
                contact: contactDetails,
                action: ACTION_REGISTRATION,
                source,
                token: '',
                userTime: nowDate.getTime(),
                userTimezoneOffset: nowDate.getTimezoneOffset()
            };
            const hideProgress = showProgress();
            axios.post(Urls.regGolfer, req)
                .then(res => {
                    hideProgress();
                    let { adminEmail, maxEventContacts } = this.state;
                    const result = res.data as AddGolfersResult;
                    if (result.adminEmail) {
                        adminEmail = result.adminEmail;
                    }
                    if (result.maxEventContacts) {
                        maxEventContacts = result.maxEventContacts;
                    }
                    if (result.registeredContactId) {
                        contactDetails.id = result.registeredContactId;
                    }
                    if (result.limitExceeded) {
                        this.setState({ adminEmail, maxEventContacts }, () => showAlert(this.fullMsg()));
                    } else {
                        this.setState({ addedContact: contactDetails, adminEmail, maxEventContacts,
                            showRegistrationSuccess: true, paymentStatus });
                    }
                })
                .catch(err => hideProgress(`Failed to register golfer: ${err.message}`));
        });
    };

    private fullMsg = () => {
        const { event } = this.props;
        const { adminEmail } = this.state;
        const contactRef = "mailto:" + (adminEmail || event.userEmail);
        return (
            <React.Fragment>
                This event is currently full. Please contact the event organizer <a href={contactRef} target="blank">({contactRef})</a> about waitlist options.
            </React.Fragment>
        );
    };

    private onRegister = () => {
        const { golfers, maxEventContacts } = this.state;
        if (golfers.size < maxEventContacts) {
            this.setState({ editedContact: newContact(this.props.event) })
        } else {
            showAlert(this.fullMsg());
        }
    };

    private showPaymentDialog = () => this.setState({ showPaymentDialog: true });
    private closePaymentDialog = () => this.setState({ showPaymentDialog: false });

    private closePaymentCompletedDialog = () => this.setState({ showPaymentCompletedDialog: false });

    private handlePaymentStatusChanged = (paymentStatus: PaymentStatus) => {
        this.setState({ paymentStatus, showPaymentCompletedDialog: true, showPaymentDialog: false });
    };

    render() {
        const { event, mainCompetition, portal } = this.props;
        let {
            editedContact, addedContact, adminEmail, showRegistrationSuccess, paymentStatus, showPaymentDialog,
            payingUserId, golfers, showPaymentCompletedDialog
        } = this.state;
        const adminAddress = adminEmail || event.userEmail;
        const contactRef = "mailto:" + adminAddress;
        const payingContact = payingUserId ? golfers.get(payingUserId) : undefined;
        let payingContactInfo: PayingContactInfo | undefined = undefined;
        if (payingContact) {
            payingContactInfo = {
                contact: payingContact,
                payButtonEnabled: paymentStatus !== PaymentStatus.PAY_LATER && paymentStatus !== PaymentStatus.NONE,
                handlePayClick: paymentStatus === PaymentStatus.PAYMENT_FAILURE ? () => this.showPaymentDialog()
                    : undefined
            };
        }
        return (
            <Box>
                {showRegistrationSuccess && !payingContact && <RegistrationCompletedComponent
                    paymentStatus={paymentStatus}
                    handleClose={() => this.setState({ showRegistrationSuccess: false })} />}
                <EventInfoComponent
                    event={event}
                    mainCompetition={mainCompetition}
                    onRegister={this.onRegister}
                    payingContactInfo={payingContactInfo}
                    addedContact={addedContact}
                    adminEmail={adminEmail}
                    adminAddress={adminAddress} />
                {portal.about && <RichTextView value={portal.about} />}
                {!!editedContact && <RegisterContactDialog
                    open
                    event={event}
                    initialContact={editedContact}
                    actionMode="register"
                    contactRef={contactRef}
                    saveToEvent={(contact, _, __, paymentStatus) => this.handleRegister(contact, paymentStatus)}
                    handleClose={() => this.setState({ editedContact: undefined })} />}
                {showPaymentDialog && payingContact && !payingContact.paymentId && <PayPalPaymentDialog
                    event={event}
                    contact={payingContact}
                    handlePaymentStatusChanged={this.handlePaymentStatusChanged}
                    handleClose={this.closePaymentDialog} />}
                {payingContact && showPaymentCompletedDialog && <PaymentCompletedDialog
                    paymentStatus={paymentStatus}
                    handleClose={this.closePaymentCompletedDialog} />}
                <FirebaseDataComponent query={Backend.query(Backend.golferDb(event.id), Backend.where('hidden', '==', false))} onMap={this.onGolfers} />
                <FirebaseDocComponent docReference={Backend.userFields(event.userId)} onData={data => this.onUserData(data.email)} />
            </Box>
        );
    }
}

export default withStyles(styles)(About);
