import * as React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';

export const AppButton = (props: ButtonProps) => {
    const { children, ...other } = props;
    return (
        <Button variant="contained" {...other}>
            {children}
        </Button>
    );
};

export default AppButton;
