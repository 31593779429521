import * as React from 'react';
import {
    Box, Checkbox, CircularProgress, DialogActions, DialogContent, FormControl, FormControlLabel, FormHelperText,
    Grid, IconButton, InputAdornment, InputLabel, Typography, useMediaQuery, useTheme
} from '@mui/material';
import { createTheme, StyledEngineProvider, Theme, ThemeProvider } from '@mui/material/styles';
import SelectTeesComponent from '../event/tabs/common/SelectTeesComponent';
import { Container, Item, Spacing } from '../common/Misc';
import { InputLabelProps } from '@mui/material/InputLabel';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import axios from 'axios';
import {
    Event, Tee, Contact, ContactDetails, Gender, MENS_TEE, WOMENS_TEE, USGAGolferInfo, PaymentStatus,
    PaymentInfo
} from '../types/EventTypes';
import { formatHandicap, parseGender, toContact } from './Contact';
import TextField, {
    email, homeCourseOrCity, maxLength, required, ValidatedTextFieldProps, ValidationRule
} from '../validation/ValidatedTextField';
import withForm, { ValidateProps } from '../validation/ValidatedForm';
import EditableAvatar from './avatar/EditableContactAvatar';
import { WithUserId, withUserId } from '../auth/Auth';
import { emailFormat, makeParameterizedGetRequestUrl, roundToNextDate, toSafeString } from '../util/utility';
import { processEnterKey } from '../util/react_utils';
import { XSMobileDialog } from '../common/dialog/MobileDialog';
import AppButton from '../common/components/AppButton';
import DialogAppBar from '../common/dialog/DialogAppBar';
import TypedFormRadioLabel from '../common/form/TypedFormRadioLabel';
import { styles } from '../styles';
import { Urls } from '../util/config';
import { isValidHCPIndex, parseHandicap } from '../scoring/handicap';
import { showAlert, showProgress } from '../redux/ReduxConfig';
import * as Backend from '../util/firebase';
import { DocumentData, errMsg } from '../util/firebase';
import { showReportedGolfersDeletionAlert } from "../util/common_alerts";
import { defaultAxiosRequestConfig } from "../util/axios_utils";
import { Buffer } from 'buffer';
import CloseIcon from "@mui/icons-material/Close";
import { AppColors } from "../main/Theme";
import { InfoIconTooltip } from "../common/components/InfoToolTipProps";
import SearchIcon from "@mui/icons-material/Search";
import { RegistrationPayPalPaymentDialog } from "../payments/paypal/RegistrationPayPalPaymentDialog";
import { ChangeEvent, FocusEvent } from "react";
import { PaidStatusSelectionComponent } from "../payments/PaidStatusSelectionComponent";
import { formatDateUniversal, isUSGolfCourse } from "../event/Event";
import { FirebaseDocComponent } from "../common/WithData";
import { EmailVariant } from "../util/email_utils";

declare module '@mui/styles' {
    interface DefaultTheme extends Theme { }
}

const enableUSGAUI = true;

const usgaHandicapDataLabelVertical = '/img/usgaHandicapDataAffiliateLabelVer.svg';
const usgaHandicapDataLabelHorizontal = '/img/usgaHandicapDataAffiliateLabelHor.svg';

interface GenderResponse {
    name: string;
    gender: string;
    probability: string;
    count: string;
}

interface DataContactBase {
    id?: string;
    firstName?: string;
    lastName: string;
    avatar?: string;
    email?: string;
    handicapIndex?: string;
    phone?: string;
    notes?: string;
    reportedBy?: string;
    homeCourseOrCity?: string;
    handicapId?: string;
}

interface DataContact extends DataContactBase {
    gender: Gender;
    tee?: Tee;
    feePaid?: boolean;
    feePaidDate?: number;
}

function isValidOrEmptyEmail(email?: string) {
    return !email || emailFormat.test(email);
}

function toContactDetails(data: DataContact, paymentDocId?: string): ContactDetails {
    const contact = { ...data } as ContactDetails;
    if (contact.firstName) {
        contact.firstName = contact.firstName.trim();
    }
    if (contact.homeCourseOrCity) {
        contact.homeCourseOrCity = contact.homeCourseOrCity.trim();
    }
    if (paymentDocId) {
        contact.paymentId = paymentDocId;
    }
    contact.lastName = contact.lastName.trim();
    contact.handicapIndex = parseHandicap(data.handicapIndex);
    return contact;
}

function toData(contact?: Contact): DataContact {
    if (!contact) {
        return {} as DataContact;
    }
    const { handicapIndex, ...others } = contact;
    const res = { ...others } as DataContact;
    res.handicapIndex = formatHandicap(handicapIndex);
    return res as DataContact;
}

const headerTheme = createTheme({
    typography: {
        fontFamily: '"poppins", sans-serif',
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500
    },
    palette: {
        mode: 'light',
        secondary: {
            main: 'rgba(88, 147, 199, .2)',
        }
    }
});

type HandicapOption = 'Enter Manually' | 'USGA';

interface EditContactDialogProps {
    open: boolean;
    event: Event;
    initialContact?: Contact;
    confirmRemove?: boolean;
    contactRef?: string;
    handleClose: () => void;
    saveToEvent: (contactDetails: ContactDetails, notificationLess: boolean, initialContact?: Contact,
                  paymentStatus?: PaymentStatus) => void;
    deleteFromEvent?: (contacts: Array<Contact>) => void;
    linkedFromApp?: boolean;
    onRevokeSuccess?: () => void;
    actionMode: 'register' | 'add' | 'edit';
    tees?: Tee[];
    sendEmail?: (contactsToSend: Contact[], variant: EmailVariant) => Promise<void>;
}

type CommonProps = EditContactDialogProps & WithStyles<typeof styles> & ValidateProps;
type Props = EditContactDialogProps & WithStyles<typeof styles> & ValidateProps & { userId?: string };

interface State {
    data: DataContact;
    showMore: boolean;
    genderBlurred: boolean;
    initialContact?: Contact;
    handicapOption: HandicapOption;
    handicapIdStatus: 'unset' | 'fromMemory' | 'failed' | 'success';
    linkedFromApp: boolean;
    showProgressBar?: boolean;
    lastSearchedHandicapId?: string;
    lastFoundHandicapIndexUSGA?: string;
    notifyAfterAdding: boolean;
    showUSGAUI: boolean;
    registrationPaymentDialogOpened: boolean;
    paymentInfo?: PaymentInfo;
}

const toHandicapIndexStr = (handicapIndex: number) => {
    return handicapIndex < 0 ? `+${Math.abs(handicapIndex)}` : handicapIndex.toString();
};

class ContactDialog extends React.Component<Props, State> {

    private mounted: boolean = false;
    private controller = new AbortController();
    private genderSpecified: boolean;

    constructor(props: Props) {
        super(props);
        const { initialContact, linkedFromApp, event } = props;
        const handicapModeUSGA = initialContact?.handicapId && initialContact.handicapIndex != null;
        const showUSGAUI = enableUSGAUI;
        this.state = {
            data: toData(initialContact),
            showMore: false,
            genderBlurred: false,
            handicapOption: handicapModeUSGA && showUSGAUI ? 'USGA' : 'Enter Manually',
            linkedFromApp: linkedFromApp ?? false,
            notifyAfterAdding: true,
            lastFoundHandicapIndexUSGA: handicapModeUSGA ? toHandicapIndexStr(initialContact!.handicapIndex!) : undefined,
            handicapIdStatus: handicapModeUSGA ? 'fromMemory' : 'unset',
            lastSearchedHandicapId: initialContact?.handicapId,
            showUSGAUI,
            registrationPaymentDialogOpened: false
        };
        this.genderSpecified = false;
        if (initialContact?.id && linkedFromApp === undefined) {
            Backend.getDocument(Backend.acceptedInvitesCollection(event.userId), initialContact.id)
                .then(acceptedInviteSnapshot => this.setState({ linkedFromApp: acceptedInviteSnapshot.exists() }));
        }
    }

    componentWillUnmount() {
        this.mounted = false;
        this.controller.abort();
    }

    private handleShowMore = () => this.setState({ showMore: !this.state.showMore });

    static getDerivedStateFromProps(props: Props, state: State) {
        if (props.initialContact !== state.initialContact) {
            return {
                initialContact: props.initialContact,
                data: toData(props.initialContact),
                openMore: false
            };
        }
        return null;
    };

    private handleFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
        const data = { ...this.state.data };
        const val = toSafeString(e.target.value);
        if (this.props.validateField) {
            this.props.validateField(e.target.name, val)
        }
        data[e.target.name as keyof DataContactBase] = val;
        this.setState({ data });
    };

    private handleSave = (paymentStatus: PaymentStatus = PaymentStatus.NONE, paymentDocId?: string) => {
        this.setState({ genderBlurred: true });
        const { initialContact, saveToEvent, validate, valid, actionMode } = this.props;
        const {
            data, handicapIdStatus, lastSearchedHandicapId, lastFoundHandicapIndexUSGA, handicapOption,
            notifyAfterAdding
        } = this.state;
        if (validate) {
            validate();
        }
        if ((valid && !valid()) || !data.gender) {
            return;
        }
        if ((handicapIdStatus === 'success' || handicapIdStatus === 'fromMemory') && handicapOption === 'USGA') {
            data.handicapId = lastSearchedHandicapId;
            data.handicapIndex = lastFoundHandicapIndexUSGA;
        } else {
            delete data.handicapId;
        }
        const notificationLess = actionMode === 'add' && !notifyAfterAdding;
        saveToEvent(toContactDetails(data, paymentDocId), notificationLess, initialContact, paymentStatus);
    };

    private openPaymentDialog = () => this.setState({ registrationPaymentDialogOpened: true });
    private closePaymentDialog = () => this.setState({ registrationPaymentDialogOpened: false });

    private handleRemove = () => {
        const { confirmRemove } = this.props;
        const data = this.state.data;
        if (data.reportedBy) {
            showReportedGolfersDeletionAlert([toContactDetails(data)], this.handleConfirmedRemove);
        } else {
            if (confirmRemove) {
                showAlert(`Delete user from event?`, [
                    { title: 'Cancel' },
                    { title: 'Delete', color: 'secondary', action: this.handleConfirmedRemove }
                ]);
            } else {
                this.handleConfirmedRemove();
            }
        }
    };

    private handleAvatarChange = (url?: string) => {
        let data = { ...this.state.data };
        data.avatar = url;
        this.setState({ data });
    };

    private handleConfirmedRemove = () => {
        const { initialContact, deleteFromEvent } = this.props;
        if (initialContact && initialContact.id && deleteFromEvent) {
            const contactDetails = toContactDetails(this.state.data);
            deleteFromEvent([toContact(contactDetails)]);
            this.props.handleClose();
        }
    };

    private setGender = (val: Gender) => {
        let data = { ...this.state.data };
        data.gender = val;
        this.setState({ data });
    };

    private handleGenderChange = (val: Gender) => {
        this.setGender(val);
        this.genderSpecified = true;
    };

    private handleNameBlur = (_e: FocusEvent<HTMLInputElement>) => {
        const contactDetails = toContactDetails(this.state.data);
        if ((!contactDetails.id)
            && contactDetails.firstName && contactDetails.firstName !== '' && (!contactDetails.gender || !this.genderSpecified)) {
            const encodedFirstName = new Buffer(this.state.data.firstName || '').toString('utf8');
            axios.get(Urls.genderizeAPI, { signal: this.controller.signal, params: { name: encodedFirstName } })
                .then(({ data }: { data: GenderResponse }) => {
                    if (this.mounted && !contactDetails.gender || !this.genderSpecified) {
                        if (+data.probability > .7) {
                            this.setGender(parseGender(data.gender));
                        } else {
                            this.setGender(parseGender(''));
                        }
                    }
                }).catch(_err => {
                    if (!this.controller.signal.aborted) {
                        Backend.trackEvent('gender_determination_failed');
                    }
                });
        }
    };

    private handleRevokeAction = () => {
        const { initialContact, event, onRevokeSuccess } = this.props;
        const hideProgress = showProgress();
        Backend.deleteDocument(Backend.acceptedInvitesCollection(event.userId), initialContact!.id).then(() => {
            hideProgress('Connection revoked successfully');
            if (onRevokeSuccess) {
                onRevokeSuccess();
            }
            this.setState({ linkedFromApp: false });
        }).catch((err: any) => hideProgress(errMsg(err)));
    };

    private handleRevoke = () => {
        const { initialContact, event, classes } = this.props;
        if (initialContact?.id && event.userId) {
            const revocationRestricted = !!initialContact.reportedBy && Date.now() < roundToNextDate(event.dateUTC);
            showAlert(revocationRestricted ?
                'Golfers connection cannot be revoked during an active Event round.'
                : <React.Fragment>
                    Revoking this connection will remove your events from the golfers Golf Pad app.<br />
                    This connection can be reestablished with an Events invitation.<br />
                    <a href={Urls.emailInvitationsSupportLink} className={classes.linkBluePointer}>Learn more</a> about Events invites.
                </React.Fragment>, revocationRestricted ? [{ title: 'Ok' },] : [{ title: 'Cancel' }, {
                    title: 'Revoke connection', action: this.handleRevokeAction, color: 'secondary'
                }]);
        }
    };

    renderGender = () => {
        const { data, genderBlurred } = this.state;
        const { classes } = this.props;
        const gender = data.gender || '';
        return (
            <React.Fragment>
                <FormControl
                    variant="standard"
                    margin="dense"
                    fullWidth
                    style={{ flexDirection: 'row' }}>
                    <InputLabel shrink={true}>Gender</InputLabel>
                    <TypedFormRadioLabel
                        currentValue={gender}
                        value="male" label="Male"
                        handleChange={this.handleGenderChange}
                        className={classes.formSelector} />
                    <TypedFormRadioLabel
                        currentValue={gender}
                        value="female" label="Female"
                        handleChange={this.handleGenderChange}
                        onBlur={_e => this.setState({ genderBlurred: true })}
                        className={classes.formSelector} />
                </FormControl>
                {(!this.state.data.gender && genderBlurred) && <div><FormHelperText error margin="dense">Required field</FormHelperText></div>}
            </React.Fragment>
        );
    };

    private onSelectTees = (tees: Array<Tee | null>) => {
        const { data } = this.state;
        data.tee = data.gender === 'male' ? (tees[MENS_TEE] ?? undefined) : (tees[WOMENS_TEE] ?? undefined);
        this.setState({ data });
    };

    private onHandicapOptionChanged = (handicapOption: HandicapOption) => this.setState({ handicapOption });

    private setHandicapIndexById = async (handicapId: string): Promise<void> => {
        const url = makeParameterizedGetRequestUrl(Urls.handicapGHINRequestUrl, 'handicapId',
            [handicapId]);
        const lastSearchedHandicapId = handicapId;
        try {
            const response = await axios.get(url, defaultAxiosRequestConfig);
            if (response.status === 200 && response.data?.golfersInfo) {
                const golfersInfo = response.data.golfersInfo;
                const golferInfo = golfersInfo[handicapId] as USGAGolferInfo;
                if (golferInfo != null && isValidHCPIndex(golferInfo.handicapIndex)) {
                    const { data } = this.state;
                    data.handicapIndex = toHandicapIndexStr(golferInfo.handicapIndex);
                    if (golferInfo.homeCity && !data.homeCourseOrCity) {
                        data.homeCourseOrCity = golferInfo.homeCity;
                    }
                    this.setState({
                        handicapIdStatus: 'success', data, showProgressBar: false,
                        lastSearchedHandicapId, lastFoundHandicapIndexUSGA: data.handicapIndex
                    });
                    return;
                }
            }
            this.setState({ handicapIdStatus: 'failed', showProgressBar: false, lastSearchedHandicapId });
        } catch (err) {
            console.log(errMsg((err)));
            this.setState({ handicapIdStatus: 'failed', showProgressBar: false, lastSearchedHandicapId });
        }
    };

    private onHandicapIdLookup = async () => {
        const { handicapId } = this.state.data;
        if (handicapId && handicapId.length > 0) {
            this.setState({ showProgressBar: true });
            await this.setHandicapIndexById(handicapId);
        }
    };

    private handleClose = (_uiEvent: string, reason: string) => {
        if ("backdropClick" === reason) {
            return;
        }
        this.props.handleClose();
    };

    private setNotifyAfterAdding = (event: React.ChangeEvent<HTMLInputElement>) =>
        this.setState({ notifyAfterAdding: event.target.checked });

    private setPaymentDoc = (doc: Backend.DocumentSnapshot<DocumentData, DocumentData>) => {
        if (doc.exists()) {
            const paymentInfo = Backend.fromEntity<PaymentInfo>(doc);
            this.setState({ paymentInfo });
        }
    };

    render() {
        const { open, event, classes, actionMode, initialContact } = this.props;
        const headerName = actionMode === 'register' ? 'REGISTRATION' : actionMode === 'add' ? 'ADD GOLFER' : 'EDIT GOLFER INFO';
        return (
            <React.Fragment>
                <XSMobileDialog open={open} onClose={this.handleClose} onKeyDown={e => processEnterKey(e, this.handleSave)}>
                    <DialogAppBar appBarStyle={{ padding: 16, backgroundColor: AppColors.bluish }}>
                        <Typography className={classes.bigHeaderFontStyle}>
                            {headerName}
                            <IconButton sx={{ float: 'right' }} onClick={this.props.handleClose} color="inherit" size="large">
                                <CloseIcon />
                            </IconButton>
                        </Typography>
                        <Typography className={`${classes.contactDialogEventName} ${classes.smallTextLabel}`}>{event.name}</Typography>
                    </DialogAppBar>
                    <DialogContent sx={{ paddingTop: 0 }}>
                        <div className={classes.body}>
                            <this.MainInfoComponent />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <this.ActionsComponent />
                    </DialogActions>
                </XSMobileDialog>
                {initialContact?.paymentId && <FirebaseDocComponent
                    docReference={Backend.eventPaymentDoc(event.id, initialContact.paymentId)}
                    onDoc={this.setPaymentDoc} />}
            </React.Fragment>
        );
    }

    private handlePaymentStatusChanged = (paymentStatus: PaymentStatus, paymentDocId?: string) => {
        this.handleSave(paymentStatus, paymentDocId);
        this.closePaymentDialog();
        this.props.handleClose();
    };

    public ActionsComponent = () => {
        const { event, initialContact, actionMode, deleteFromEvent } = this.props;
        const { data, notifyAfterAdding, registrationPaymentDialogOpened } = this.state;
        const canDelete = Boolean(initialContact && initialContact.id && deleteFromEvent);
        const isXs = useMediaQuery(useTheme().breakpoints.down('sm'));
        const showPayingDialog = actionMode === 'register' && event.paymentSettings?.enabled;
        return <Box display="flex" width={isXs ? "100%" : "unset"} justifyContent={canDelete ? 'space-between' : 'end'}>
            {canDelete && <AppButton style={{ width: isXs ? 'calc(48%)' : 'initial' }} onClick={this.handleRemove} color="info">Delete</AppButton>}
            <AppButton style={{ width: isXs ? (actionMode === 'edit' ? 'calc(48%)' : '100%') : 'initial', marginLeft: isXs ? 0 : '10px' }}
                onClick={showPayingDialog ? this.openPaymentDialog : () => this.handleSave()} color="secondary"
                disabled={!data.lastName || !data.gender || (actionMode == 'add' && notifyAfterAdding && !isValidOrEmptyEmail(data.email))}>
                {actionMode === 'register' ? event.paymentSettings?.enabled ? 'Pay and register' : 'Register' :
                    actionMode === 'add' ? (notifyAfterAdding && data.email) ? 'Add and notify via email' : 'Add golfer'
                        : 'Save'}
            </AppButton>
            {registrationPaymentDialogOpened && <RegistrationPayPalPaymentDialog
                handleClose={this.closePaymentDialog}
                contact={toContactDetails(data)}
                event={event}
                handlePaymentStatusChanged={this.handlePaymentStatusChanged} />}
        </Box>;
    };

    private setFeePaidStatus = (feePaid: boolean) => {
        const { data } = this.state;
        data.feePaid = feePaid;
        data.feePaidDate = new Date().getTime();
        this.setState({ data });
        return Promise.resolve();
    };

    public MainInfoComponent = () => {
        const { event, classes, initialContact, actionMode, tees, sendEmail } = this.props;
        const {
            data, handicapOption, handicapIdStatus, linkedFromApp, showProgressBar, lastFoundHandicapIndexUSGA,
            showUSGAUI, showMore, genderBlurred, paymentInfo
        } = this.state;
        const gender = data.gender || '';
        const isXs = useMediaQuery(useTheme().breakpoints.down('sm'));
        const fieldsDescription = this.getFieldsDescription(isXs);
        return (<Grid container>
            {!isXs && <Grid item xs={2}>
                <EditableAvatar url={data.avatar} isXs={isXs} handleAvatarChange={this.handleAvatarChange} />
            </Grid>}
            <Grid item xs={isXs ? 9 : 10}>
                <Grid container justifyContent="space-around" wrap="nowrap" direction={isXs ? 'column' : 'row'}>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={headerTheme}>
                            <Grid item xs={isXs ? 12 : 6}><TextField {...fieldsDescription.firstName} /></Grid>
                            <Grid item xs={isXs ? 12 : 6}><TextField {...fieldsDescription.lastName} /></Grid>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </Grid>
                <Grid container justifyContent="space-around" wrap="nowrap" style={{ marginTop: 4 }} direction={isXs ? 'column' : 'row'}>
                    <Grid item xs={isXs ? 12 : 6}><TextField {...fieldsDescription.email} /></Grid>
                    {isXs && <this.NotifyGolferComponent isXs={isXs} />}
                    <Grid item xs={isXs ? 12 : 6}>
                        <React.Fragment>
                            <FormControl
                                variant="standard"
                                margin="dense"
                                fullWidth
                                style={{ flexDirection: 'row', marginBottom: 0, maxWidth: 215, float: isXs ? 'left' : 'unset' }}>
                                <InputLabel className={classes.boldLabel} shrink={true}>
                                    Gender (for tee selection)
                                </InputLabel>
                                <TypedFormRadioLabel
                                    currentValue={gender}
                                    value="male" label="Male"
                                    disabled={event.eventGender === 'women'}
                                    handleChange={this.handleGenderChange}
                                    className={classes.formSelector} />
                                <TypedFormRadioLabel
                                    currentValue={gender}
                                    value="female" label="Female"
                                    disabled={event.eventGender === 'men'}
                                    handleChange={this.handleGenderChange}
                                    onBlur={_e => this.setState({ genderBlurred: true })}
                                    className={classes.formSelector} />
                            </FormControl>
                            {genderBlurred && gender === '' && <div style={{ float: 'left', marginLeft: 10 }}>
                                <FormHelperText error margin="dense">Required field</FormHelperText>
                            </div>}
                        </React.Fragment>
                    </Grid>
                </Grid>
            </Grid>
            {isXs && <Grid item xs={3}>
                <EditableAvatar url={data.avatar} isXs={isXs} handleAvatarChange={this.handleAvatarChange} />
            </Grid>}
            {!isXs && <Grid item xs={2} />}
            <Grid item xs={isXs ? 12 : 10}>
                {!isXs && <this.NotifyGolferComponent isXs={isXs} />}
                <Spacing />
                {showUSGAUI && <Grid container style={{ marginLeft: isXs ? 0 : 12 }}>
                    <Typography className={classes.boldLabel} style={{ marginTop: 8, float: 'left' }}>Handicap</Typography>
                    <FormControl
                        variant="standard"
                        margin="dense"
                        fullWidth
                        style={{ float: 'left', flexDirection: isXs ? 'column' : 'row', position: 'relative' }}>
                        <TypedFormRadioLabel
                            currentValue={handicapOption}
                            value="Enter Manually"
                            label={<Typography style={{ color: AppColors.webBlack }} className={classes.basicLabel}>Enter manually</Typography>}
                            handleChange={this.onHandicapOptionChanged}
                            style={{ marginRight: 20, marginLeft: 0 }} />
                        <TypedFormRadioLabel
                            currentValue={handicapOption}
                            value="USGA"
                            label={<Typography style={{ color: AppColors.webBlack }} className={classes.basicLabel}>Look up by USGA® Handicap ID</Typography>}
                            handleChange={this.onHandicapOptionChanged}
                            style={{ marginLeft: 0 }} />
                    </FormControl>
                </Grid>}
                {showUSGAUI && <Spacing />}
                {<Grid container style={{ marginLeft: isXs ? 0 : 12 }}>
                    {handicapOption === 'USGA' && showUSGAUI ? <>
                        <Grid item width="100%" flexDirection="row">
                            <div className={classes.handicapId}>
                                <TextField {...fieldsDescription.handicapId} className={`${classes.handicapTextField} ${classes.handicapIdField}`} />
                                {handicapIdStatus === 'failed' && <Typography className={classes.incorrectHandicapId}>Incorrect handicap ID</Typography>}
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'row' }}>
                                {showProgressBar && <div style={{ width: 104, marginLeft: 18, marginTop: 'auto', marginBottom: 'auto' }}>
                                    <CircularProgress color="secondary" size="1.2em" />
                                </div>}
                                {!showProgressBar && <div style={{ width: 104, marginLeft: 18 }} className={`${classes.flexColumnContainer}`}>
                                    <Typography style={{ color: handicapIdStatus === 'success' ? AppColors.webGray : '#949494' }} className={classes.handicapIndexById}>
                                        Handicap Index®
                                    </Typography>
                                    <Typography style={{ color: handicapIdStatus === 'success' ? '#2C2C2C' : '#949494' }} className={classes.handicapIndexSuccess}>
                                        {handicapIdStatus === 'success' || handicapIdStatus === 'fromMemory' ? lastFoundHandicapIndexUSGA : 0}
                                    </Typography>
                                </div>}
                                {!isXs && <img style={{ marginRight: 39 }} src={usgaHandicapDataLabelVertical} alt="" />}
                            </div>
                        </Grid>
                        <Grid display="flex" flexDirection="column" height="46px">
                            {isXs && <img style={{ marginTop: 'auto' }} src={usgaHandicapDataLabelHorizontal} alt="" />}
                        </Grid>
                    </>
                        : <TextField {...fieldsDescription.handicapIndex} className={`${classes.handicapTextField} ${classes.handicapField}`} />}
                </Grid>}
                <Spacing />
                {actionMode != 'register' &&
                    <Grid container wrap="nowrap" direction={isXs ? 'column' : 'row'}>
                        <Grid item xs={isXs ? 12 : 6} maxWidth={isXs ? 240 : 215}>
                            <SelectTeesComponent open={true} variant="dropDownList"
                                                 dropDownListStyle={{ marginLeft: isXs ? 0 : 12, maxWidth: isXs ? 240 : 215 }}
                                                 onSelectTees={this.onSelectTees}
                                                 selectedGolfers={[toContactDetails(data)]}
                                                 event={event} tees={tees} />
                        </Grid>
                        {event.paymentSettings?.enabled && initialContact && <Grid item xs={isXs ? 12 : 6}>
                            <Box>
                                {initialContact.paymentId ? <TextField {...fieldsDescription.feePaid} /> :
                                    <FormControl variant="standard" margin="dense" fullWidth
                                                 style={{ maxWidth: isXs ? 240 : 215 }}>
                                        <InputLabel id="select-entry-fee-status" shrink>Entry fee</InputLabel>
                                        <PaidStatusSelectionComponent
                                            feePaid={data.feePaid}
                                            contactEmail={data.email && emailFormat.test(data.email) ? data.email : undefined}
                                            styles={{ textAlign: 'left', padding: '4px 0 5px' }}
                                            disableUnderline={false}
                                            horizontalPopupPlacement="center"
                                            handleSendMessage={actionMode === 'add' ? undefined : async () =>
                                                await sendEmail?.([initialContact], EmailVariant.eventUpcomingPayment)}
                                            handleFeePaidStatusUpdate={this.setFeePaidStatus} />
                                    </FormControl>}
                                {(data.feePaidDate || paymentInfo?.timeStampUTC) && <Typography style={{
                                    lineHeight: '15.95px', paddingLeft: 10, textAlign: 'left', fontSize: 11,
                                    color: AppColors.webGray
                                }}>Updated {formatDateUniversal((data.feePaidDate ?? paymentInfo?.timeStampUTC)!,
                                    isUSGolfCourse(event.course) ? 'MMMM-DD-YYYY' : 'DD-MMMM-YYYY')}
                                </Typography>}
                            </Box>
                        </Grid>}
                    </Grid>}
                {linkedFromApp && <div className={classes.connectedWithApp}>
                    <img src={Urls.connectedPhoneIcon} alt={''} />
                    Connected with the golfer's Golf Pad app.
                    <a href={Urls.revokeConnectionSupportLink} className={classes.linkBluePointer}>Learn more</a>
                    <AppButton color="info" disabled={Boolean(!initialContact?.id)} style={{ marginLeft: 'auto', marginRight: 0 }} onClick={this.handleRevoke}>Revoke</AppButton>
                </div>}
                <Spacing />
                <Box sx={{ backgroundColor: '#F5F5F5' }} padding={showMore ? '15px' : '0 0 0 15px'}
                    display="flex" justifyContent="center" flexDirection="column" minHeight={53} borderRadius="10px">
                    <Grid container spacing={1}>
                        <Grid item xs={10} className={classes.boldLabel} style={{ textAlign: 'left' }}>
                            Optional (home course, phone, notes for organizer)
                        </Grid>
                        <Grid item xs={2} display="flex" alignItems="center" justifyContent="end">
                            <img onClick={this.handleShowMore} src={Urls.iconSwitch} alt=""
                                style={{
                                    cursor: 'pointer', marginRight: showMore ? 0 : 15,
                                    transform: showMore ? 'rotate(180deg)' : undefined
                                }} />
                        </Grid>
                        {showMore && <this.ShowMoreComponentInner isXs={isXs} fieldsDescription={fieldsDescription} />}
                    </Grid>
                </Box>
            </Grid>
        </Grid>);
    };

    public NotifyGolferComponent = ({ isXs }: { isXs: boolean }) => {
        const { actionMode, classes } = this.props;
        const { data, notifyAfterAdding } = this.state;
        const toolTipText = <span>An email will be sent with a link to the Portal page and an app invitation. <br />
            The golfer can add the event to the Golf Pad app, to view the schedule & standings and post live scores.
        </span>;
        return (
            <Grid container>
                {actionMode === 'add' && <Grid item xs={12}>
                    <FormControlLabel style={{ marginLeft: isXs ? 0 : 12, marginTop: 8, float: 'left', marginRight: 0 }} control={<Container>
                        <Item><Checkbox disabled={!data.email} color="secondary" onChange={this.setNotifyAfterAdding} checked={notifyAfterAdding} /></Item>
                        <Item><Typography style={{ color: data.email ? AppColors.webBlack : '#949494', marginLeft: isXs ? 4 : 0 }} className={classes.basicLabel}>Notify the golfer via email</Typography></Item>
                        <Item><InfoIconTooltip offset1={-7} style={{ marginLeft: 6 }} title={toolTipText} placement={'bottom-start'} leaveTouchDelay={4000} /></Item>
                    </Container>} label="" />
                </Grid>}
            </Grid>
        );
    };

    public ShowMoreComponentInner = ({ fieldsDescription, isXs }: { isXs: boolean, fieldsDescription: { [_: string]: ValidatedTextFieldProps } }) => {
        return <>
            <Grid item xs={isXs ? 12 : 6}>
                <TextField {...fieldsDescription.homeCourseOrCity} />
            </Grid>
            <Grid item xs={isXs ? 12 : 6}>
                <TextField keepError {...fieldsDescription.phone} />
            </Grid>
            <Grid item xs={12}>
                <TextField {...fieldsDescription.notes} />
            </Grid>
        </>
    };

    private getFieldsDescription(isXs: boolean): { [key: string]: ValidatedTextFieldProps } {
        const { data, handicapIdStatus, lastSearchedHandicapId } = this.state;
        const { classes, register } = this.props;
        const tfCommon = {
            onChange: this.handleFieldChange,
            InputLabelProps: { shrink: true } as InputLabelProps
        };
        const common = {
            register
        };
        function quote(s?: string | number) {
            return s == null ? '' : s;
        }
        const xsTextFieldMaxWidth = 240;
        return {
            lastName: {
                textFieldProps: {
                    id: 'lastName',
                    label: 'Last name',
                    value: quote(data.lastName),
                    style: {
                        float: isXs ? 'left' : 'unset',
                        maxWidth: isXs ? xsTextFieldMaxWidth : 215
                    },
                    ...tfCommon
                },
                rules: [required, maxLength(50)],
                ...common
            },
            firstName: {
                textFieldProps: {
                    id: 'firstName',
                    label: 'First name',
                    onBlur: this.handleNameBlur,
                    value: quote(data.firstName),
                    style: {
                        float: isXs ? 'left' : 'unset',
                        maxWidth: isXs ? xsTextFieldMaxWidth : 215
                    },
                    autoFocus: true,
                    ...tfCommon
                },
                rules: [maxLength(50)],
                ...common
            },
            email: {
                textFieldProps: {
                    id: 'email',
                    label: 'Email',
                    value: quote(data.email),
                    style: {
                        float: isXs ? 'left' : 'unset',
                        maxWidth: isXs ? xsTextFieldMaxWidth : 215
                    },
                    ...tfCommon
                },
                rules: [email, maxLength(50)],
                ...common
            },
            handicapId: enableUSGAUI ? {
                textFieldProps: {
                    id: 'handicapId',
                    label: <Typography style={{ minWidth: 90 }}>Handicap ID</Typography>,
                    value: quote(data.handicapId),
                    placeholder: '123456',
                    InputProps: enableUSGAUI ? {
                        endAdornment: <InputAdornment position="end">
                            <IconButton disabled={!data.handicapId || handicapIdStatus === 'failed' || handicapIdStatus === 'fromMemory'}
                                tabIndex={-1} size="large" onClick={this.onHandicapIdLookup}>
                                {handicapIdStatus === 'success' ? <img src={Urls.checkMarkBlueIcon} alt="" /> : <SearchIcon />}
                            </IconButton>
                        </InputAdornment>,
                        className: handicapIdStatus === 'failed' ? classes.handicapByIdError : undefined
                    } : {},
                    onChange: enableUSGAUI ? (event: ChangeEvent<HTMLInputElement>) => {
                        tfCommon.onChange(event);
                        if (lastSearchedHandicapId !== event.target.value) {
                            data.handicapId = undefined;
                            this.setState({ handicapIdStatus: 'unset' });
                        }
                    } : tfCommon.onChange,
                    InputLabelProps: tfCommon.InputLabelProps
                },
                ...common
            } : {
                textFieldProps: {
                    id: 'handicapIndex',
                    label: 'Handicap index',
                    value: quote(data.handicapIndex),
                    InputProps: { inputProps: { step: '0.1' } },
                    ...tfCommon
                },
                rules: [handicap],
                ...common
            },
            handicapIndex: {
                textFieldProps: {
                    id: 'handicapIndex',
                    label: <Typography style={{ minWidth: 80 }}>Handicap</Typography>,
                    placeholder: '0',
                    value: quote(data.handicapIndex),
                    InputProps: isXs ? {
                        style: {
                            maxWidth: 45
                        }
                    } : {},
                    ...tfCommon
                },
                rules: [handicap],
                ...common
            },
            homeCourseOrCity: {
                textFieldProps: {
                    id: 'homeCourseOrCity',
                    InputProps: {
                        endAdornment: <InfoIconTooltip
                            placement="bottom-start"
                            title={"If there are two golfers with the same first and last name in the event, the system will rely on this field to tell them apart."}
                            style={{ marginBottom: 4 }} offset1={-13} />
                    },
                    label: 'Home course or city',
                    style: {
                        maxWidth: 200,
                        float: 'left'
                    },
                    value: quote(data.homeCourseOrCity),
                    ...tfCommon
                },
                rules: [homeCourseOrCity, maxLength(20)],
                ...common
            },
            phone: {
                textFieldProps: {
                    id: 'phone',
                    label: 'Phone number',
                    style: {
                        maxWidth: isXs ? 190 : 200,
                        float: isXs ? 'left' : 'right'
                    },
                    value: quote(data.phone), ...tfCommon
                },
                rules: [maxLength(20)],
                ...common
            },
            notes: {
                textFieldProps: {
                    id: 'notes',
                    label: 'Notes',
                    placeholder: 'Pairing or team requests, etc.',
                    value: quote(data.notes), ...tfCommon
                },
                rules: [maxLength(200)],
                ...common
            },
            feePaid: {
                textFieldProps: {
                    id: 'feePaid',
                    label: 'Entry fee',
                    style: {
                        maxWidth: isXs ? 240 : 215
                    },
                    value: 'Paid',
                    inputProps: {
                        readOnly: true
                    },
                    InputProps: {
                        endAdornment: <img className={classes.connectedPhoneIcon} src={Urls.iconPayPalLogo} alt="" />
                    }
                }
            }
        };
    }
}

export const handicap: ValidationRule<string> = {
    valid: function(value?: string) {
        const v = parseHandicap(value);
        return isValidHCPIndex(v);
    },
    text: 'Valid golfer index is +99 to 99.'
};

class EditContactDialog extends React.Component<CommonProps & WithUserId> {
    render() {
        const { userId, sup, ...props } = this.props;
        return (<ContactDialog userId={userId} {...props} />);
    }
}

export const RegisterContactDialog = withStyles(styles)(withForm(ContactDialog));
export default withStyles(styles)(withUserId(withForm(EditContactDialog)));
