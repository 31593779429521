import * as React from 'react';
import { Badge } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Event, EventData } from '../../../types/EventTypes';
import { everybodyIsScheduled, everybodyIsPaired } from '../../Event';
import LinkTabs, { Tab } from '../common/LinkTabs';
import GolfersList from './list/GolfersList';
import TeamsList from './list/TeamsList';
import ScheduleList from './pairings/ScheduleList';
import { styles } from '../../../styles';

interface Properties {
    event: Event;
    eventData: EventData;
    initial?: string;
    onSelectedTab: (subtab: string) => void;
}

function GolfersTab(props: Properties & WithStyles<typeof styles>) {
    const { event, eventData } = props;
    const { golfers, teams, groups } = eventData;
    const tabs: Array<Tab<string>> = [{
        id: 'list',
        label: <Badge color="error" variant="dot" overlap="rectangular" invisible={golfers.size > 0}>LIST &nbsp;</Badge>,
        node: <GolfersList event={event} eventData={eventData} />
    }];
    tabs.push({
        id: 'teams',
        label: <Badge color="error" variant="dot" overlap="rectangular" invisible={event.teamSize === 1 || everybodyIsPaired(golfers, teams)}>TEAMS &nbsp;</Badge>,
        node: <TeamsList event={event} eventData={eventData} />
    });
    tabs.push({
        id: 'schedule',
        label: <Badge color="error" variant="dot" overlap="rectangular" invisible={everybodyIsScheduled(event, groups, teams, golfers)}>SCHEDULE &nbsp;</Badge>,
        node: <ScheduleList event={event} eventData={eventData} />
    });
    return <LinkTabs initial={props.initial || 'list'} tabs={tabs} onSelectedTab={props.onSelectedTab} classes={props.classes} />;
}

export default withStyles(styles)(GolfersTab);
