import { Contact } from "../types/EventTypes";
import { fullName } from "../contact/Contact";
import { showAlert } from "../redux/ReduxConfig";

export function showReportedGolfersDeletionAlert(reportedContacts: Array<Contact>, onRemove: () => void) {
    const reportedNames = reportedContacts.map(golfer => fullName(golfer)).join(", ");
    const golferLabel = reportedContacts.length === 1 ? "this golfer" : "these golfers";
    const alertText = `Scoring has already started for ${reportedContacts.length > 1 ? `the following golfers: ${reportedNames}` :
        reportedNames}. Are you sure about removing ${golferLabel} from event?`;
    showAlert(alertText, [
        { title: 'Cancel' },
        { title: 'Proceed', color: 'secondary', action: () => { if (!!onRemove) onRemove(); } }
    ]);
}