import * as React from 'react';
import { SyntheticEvent } from 'react';
import { DialogProps } from '@mui/material/Dialog';
import FormControl from '@mui/material/FormControl';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { DialogActions, DialogContent, Divider, InputLabel, MenuItem, Select, Typography, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Event, Contact, Tee, Gender, Competition, ResultStatus, getCourseName, MENS_TEE, WOMENS_TEE } from '../../../types/EventTypes';
import { hasMen, hasWomen } from '../../Event';
import TypedFormRadioLabel from '../../../common/form/TypedFormRadioLabel';
import { XSMobileDialog } from '../../../common/dialog/MobileDialog';
import EmailDialog from '../common/EmailDialog';
import DialogAppBar from '../../../common/dialog/DialogAppBar';
import AppButton from '../../../common/components/AppButton';
import { Spacing } from '../../../common/Misc';
import { processEnterKey } from '../../../util/react_utils';
import { getTeeName, compareTee, getGender } from '../../../scoring/handicap';
import EditTeesListDialog from '../../tabs/common/EditTeesListDialog';
import TeesProvider from './TeesProvider';
import { styles } from '../../../styles';
import { Emails } from "../../../util/config";
import { InfoElement } from '../../../redux/ReduxConfig';
import { AppColors } from "../../../main/Theme";

const useDefault = 'Use competition\'s default tees';
const competitionsDefault = 'Competition\'s default';

type Props = DialogProps & WithStyles<typeof styles> & {
    event: Event;
    competition?: Competition;
    selectedGolfers?: Array<Contact>;
    variant: 'dialog' | 'dropDownList';
    dropDownListStyle?: React.CSSProperties;
    onSelectTees: (tees: Array<Tee | null>) => void;
    tees?: Tee[];
};

type State = {
    tees: Array<Tee>;
    selectedMTee: Tee | null;
    selectedFTee: Tee | null;
    editingTees?: boolean;
    actionSendRequestOpen?: boolean;
    teesLoadStatus: ResultStatus;
};

function getCommonTee(tees: Array<Tee>, golfers: Array<Contact>): Tee | null {
    if (golfers.length === 0) {
        return null;
    }
    const firstGolfer = golfers[0];
    if (!firstGolfer) {
        return null;
    }
    if (!firstGolfer.tee) {
        return null;
    }
    const teeId = firstGolfer.tee.id;
    for (let i = 1; i < golfers.length; i++) {
        if (teeId !== golfers[i].tee?.id) {
            return null;
        }
    }
    return tees.find(t => t.id === teeId) || null;
}

class SelectTeesComponent extends React.Component<Props, State> {

    private readonly teesLoader: React.RefObject<TeesProvider>;

    constructor(props: Props) {
        super(props);
        this.teesLoader = React.createRef();
        this.state = {
            teesLoadStatus: 'in_progress',
            selectedMTee: null,
            selectedFTee: null,
            tees: []
        };
        if (this.props.tees?.length) {
            this.teesLoaded("ok", this.props.tees);
        }
    }

    private teesLoaded = (teesLoadStatus: ResultStatus, tees?: Array<Tee>) => {
        const { competition, selectedGolfers } = this.props;
        if (tees) {
            let selectedMTee: Tee | null = null;
            let selectedFTee: Tee | null = null;
            const maleTees = tees.filter(tee => getGender(tee) === 'male');
            const femaleTees = tees.filter(tee => getGender(tee) === 'female');
            if (competition) {
                const competitionTees = competition.tees;
                if (competitionTees) {
                    selectedMTee = maleTees.find(t => t.id === competitionTees[MENS_TEE]?.id) ?? null;
                    selectedFTee = femaleTees.find(t => t.id === competitionTees[WOMENS_TEE]?.id) ?? null;
                }
            } else if (selectedGolfers) {
                selectedMTee = getCommonTee(maleTees, selectedGolfers.filter(g => g.gender === 'male'));
                selectedFTee = getCommonTee(femaleTees, selectedGolfers.filter(g => g.gender === 'female'));
            }
            this.setState({ selectedMTee, selectedFTee });
        }
        this.setState({ tees: tees || [], teesLoadStatus });
    }

    private loadTees = () => this.teesLoader.current?.loadTees();

    private handleEditScorecard = () => this.setState({ editingTees: true });

    private handleClose = () => {
        const close = this.props.onClose;
        if (close) {
            close({} as SyntheticEvent<any>, 'escapeKeyDown');
        }
    }

    private handleCloseEditScorecard = (changed: boolean) => {
        this.setState({ editingTees: false });
        if (changed) {
            this.loadTees();
        }
    }

    private handleSave = () => {
        const { selectedFTee, selectedMTee } = this.state;
        const { onSelectTees } = this.props;
        onSelectTees([selectedMTee, selectedFTee]);
    }

    private onDropDownListValueSelection = () => {
        if (this.props.variant === 'dropDownList') {
            this.handleSave();
        }
    };

    private onSelect = (tee: Tee | null, gender: Gender) => {
        if (gender === 'female') {
            this.setState({ selectedFTee: tee }, this.onDropDownListValueSelection);
        } else {
            this.setState({ selectedMTee: tee }, this.onDropDownListValueSelection);
        }
    };

    render() {
        const { classes, open, event, competition, selectedGolfers, variant, dropDownListStyle } = this.props;
        const { tees, selectedFTee, selectedMTee, actionSendRequestOpen, teesLoadStatus, editingTees } = this.state;
        const maleTees = tees.filter(tee => getGender(tee) === 'male').sort(compareTee);
        const femaleTees = tees.filter(tee => getGender(tee) === 'female').sort(compareTee);
        const selectedMTeeId = selectedMTee?.id ?? '';
        const selectedFTeeId = selectedFTee?.id ?? '';
        const loading = teesLoadStatus === 'in_progress';
        const maleTeesInfo = loading ? 'Loading tees...' :
            !event.course ? 'Please select course in event settings' :
                maleTees.length === 0 ? 'Click view/edit to add the tee setting. If no men are in the competition, please change gender selection in settings.' : '';
        const femaleTeesInfo = loading ? 'Loading tees...' :
            !event.course ? 'Please select course in event settings' :
                femaleTees.length === 0 ? 'Click view/edit to add the tee setting. If no women are in the competition, please change gender selection in settings.' : '';
        const competitionMode = Boolean(competition);
        const contactsMode = selectedGolfers && selectedGolfers.length > 0;
        const men = competitionMode ? hasMen(event, competition) : (selectedGolfers ? selectedGolfers.find(contact => contact.gender === 'male') : false);
        const women = competitionMode ? hasWomen(event, competition) : (selectedGolfers ? selectedGolfers.find(contact => contact.gender === 'female') : false);
        const notSelected = competitionMode && ((men && !selectedMTeeId) || (women && !selectedFTeeId));
        const title = men && women ? 'Default tees' : 'Select tee';
        return <>{variant === 'dialog' ? <XSMobileDialog open={open} onClose={this.handleClose}>
            <DialogAppBar label={title} close={this.handleClose} />
            <DialogContent onKeyDown={uiEvent => processEnterKey(uiEvent, () => this.handleSave())}>
                {men && <FormControl
                    variant="standard"
                    margin="dense"
                    fullWidth
                    style={{ flexDirection: 'column' }}>
                    <Typography>{men && women ? 'Men\'s tees:' : 'Tees:'}</Typography>
                    {contactsMode && !loading && <TypedFormRadioLabel key='mensDefault'
                        currentValue={!selectedMTeeId ? useDefault : selectedMTeeId}
                        value={useDefault}
                        handleChange={() => this.onSelect(null, 'male')}
                        label={useDefault} className={classes.radio} />}
                    {maleTees.map(tee => <TypedFormRadioLabel key={tee.id} currentValue={selectedMTeeId} value={tee.id}
                        handleChange={() => this.onSelect(tee, 'male')}
                        label={getTeeName(tee, event.holesType)}
                        className={classes.radio} />)}
                    <Typography color={!loading && event.course && maleTees.length === 0 ? 'error' : 'inherit'}>{maleTeesInfo}</Typography>
                    <Spacing />
                </FormControl>}
                {women && <FormControl
                    variant="standard"
                    margin="dense"
                    fullWidth
                    style={{ flexDirection: 'column' }}>
                    <Typography>{men && women ? 'Women\'s tees:' : 'Tees:'}</Typography>
                    {contactsMode && !loading && <TypedFormRadioLabel key='womensDefault'
                        currentValue={!selectedFTeeId ? useDefault : selectedMTeeId}
                        value={useDefault}
                        handleChange={() => this.onSelect(null, 'female')}
                        label={useDefault} className={classes.radio} />}
                    {femaleTees.map(tee => <TypedFormRadioLabel key={tee.id} currentValue={selectedFTeeId}
                        value={tee.id}
                        handleChange={() => this.onSelect(tee, 'female')}
                        label={getTeeName(tee, event.holesType)}
                        className={classes.radio} />)}
                    <Typography
                        color={!loading && event.course && femaleTees.length === 0 ? 'error' : 'inherit'}>{femaleTeesInfo}</Typography>
                    <Spacing />
                </FormControl>}
                <AppButton color="info" style={{ width: 166 }} onClick={this.handleEditScorecard}
                    disabled={!event.course}><EditIcon /> VIEW / EDIT</AppButton>
                {competitionMode && <FormControl
                    variant="standard"
                    margin="dense"
                    fullWidth
                    style={{ flexDirection: 'column' }}>
                    <InfoElement contentStyle={{ fontSize: '.8em', color: 'inherit' }}
                        iconStyle={{ transform: 'scale(0.9)' }}>
                        This will set the competition's default tees. You can always override tees for individual
                        golfers from the Golfers / List
                    </InfoElement>
                </FormControl>}
            </DialogContent>
            <DialogActions>
                <AppButton color="info" onClick={this.handleClose}>Cancel</AppButton>
                <AppButton color="secondary" onClick={this.handleSave}
                    disabled={loading || notSelected}>{competitionMode ? 'Select' : 'Save'}</AppButton>
            </DialogActions>
        </XSMobileDialog>
            :
            <Box style={dropDownListStyle}>
                <FormControl variant="standard" margin="dense" fullWidth>
                    <InputLabel id="select-tees-label" shrink>Tee</InputLabel>
                    <Select
                        variant="standard"
                        labelId="select-tees-label"
                        id="select-tees"
                        label="women"
                        style={{ textAlign: 'left' }}
                        value={(men ? selectedMTeeId : women ? selectedFTeeId : '') || competitionsDefault}
                        inputProps={{
                            classes: {
                                icon: classes.blueArrowDown
                            }
                        }}
                        onChange={e => {
                            if (e.target.value) {
                                const teeId = e.target.value as string;
                                const tee = (men ? maleTees : women ? femaleTees : []).find(tee => tee.id === teeId);
                                this.onSelect(tee ?? null, tee?.gender ?? men ? 'male' : women ? 'female' : '');
                            }
                        }}>
                        <MenuItem key={'default'} value={competitionsDefault}>{competitionsDefault}</MenuItem>
                        {(men ? maleTees : women ? femaleTees : [])
                            .map(tee => <MenuItem key={tee.id} value={tee.id}>{getTeeName(tee, event.holesType)}</MenuItem>)}
                        <Divider variant="middle" />
                        <MenuItem sx={{ color: AppColors.blue500, fontSize: '14px', lineHeight: '21px' }}
                            value="" onClick={this.handleEditScorecard}>VIEW / EDIT</MenuItem>
                    </Select>
                </FormControl>
            </Box>}
            {actionSendRequestOpen && <EmailDialog
                to={Emails.supportEmail}
                subject={'[EVENTS] Missing tees for ' + getCourseName(event.course)}
                text="The following tees are missing: "
                open={true}
                handleCancel={() => this.setState({ actionSendRequestOpen: false })}
                handleSent={() => this.setState({ actionSendRequestOpen: false })} />}
            {editingTees && event.course && <EditTeesListDialog course={event.course} event={event} close={this.handleCloseEditScorecard} />}
            {!this.props.tees?.length && <TeesProvider event={event} course={event.course} onResult={this.teesLoaded} ref={this.teesLoader} />}
        </>;
    }
}

export default withStyles(styles)(SelectTeesComponent);
