import * as React from 'react';
import { DialogProps } from '@mui/material/Dialog';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { MenuItem, FormControl, DialogActions, DialogContent, InputLabel, Select } from '@mui/material';
import { Event, StartingHolesType, TeeTimeSettings, getStartingHoles, getStartingHolesType, getHolesRange } from '../../../../types/EventTypes';
import { getStartingHolesStr } from '../../../Event';
import TypedFormRadioLabel from '../../../../common/form/TypedFormRadioLabel';
import { XSMobileDialog } from '../../../../common/dialog/MobileDialog';
import DialogAppBar from '../../../../common/dialog/DialogAppBar';
import AppButton from '../../../../common/components/AppButton';
import SelectHolesDialog from '../../../tabs/common/SelectHolesDialog';
import { range, visible } from '../../../../util/utility';
import { processEnterKey } from '../../../../util/react_utils';
import { styles } from '../../../../styles';

type Props = DialogProps & WithStyles<typeof styles> & {
    event: Event;
    teeTimeSettings?: TeeTimeSettings;
    onSelectedStartingHoles: (startingHolesType: StartingHolesType, startingHoles: Readonly<Array<number>>) => void;
};

type State = {
    startingHolesType: StartingHolesType;
    startingHoles: Readonly<Array<number>>;
    selectingHoles?: boolean;
};

class StartingHoleDialog extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        const { event, teeTimeSettings } = props;
        const holesRange = getHolesRange(event.holesType);
        this.state = {
            startingHolesType: getStartingHolesType(teeTimeSettings?.startingHolesType, holesRange, teeTimeSettings?.mode),
            startingHoles: getStartingHoles(teeTimeSettings?.startingHolesType, teeTimeSettings?.startingHoles, holesRange, teeTimeSettings?.mode)
        };
    }

    private handleSave = () => {
        const { onSelectedStartingHoles } = this.props;
        const { startingHolesType, startingHoles } = this.state;
        onSelectedStartingHoles(startingHolesType, startingHoles);
    }

    private handleClose = () => {
        const close = this.props.onClose;
        if (close) {
            close({} as React.SyntheticEvent<any>, 'escapeKeyDown');
        }
    }

    private setStartingHoleType = (startingHolesType: StartingHolesType) => this.setState({ startingHolesType });
    private setStartingHoles = (startingHoles: Array<number>) => this.setState({ startingHoles });

    renderRegular = () => {
        const { classes, event } = this.props;
        const { startingHolesType, startingHoles } = this.state;
        const holesRange = getHolesRange(event.holesType);
        const hasFront = holesRange.first === 0;
        const hasBack = holesRange.last === 18;
        return (
            <React.Fragment>
                <FormControl variant="standard" fullWidth>
                    <InputLabel shrink style={{ left: 6 }}>All groups will start from the hole selected.</InputLabel>
                </FormControl>
                <FormControl variant="standard">
                    {hasFront && <TypedFormRadioLabel value="hole1" label="Hole #1 (regular start)" currentValue={startingHolesType}
                        handleChange={this.setStartingHoleType} className={classes.formSelector} />}
                    {hasBack && <TypedFormRadioLabel value="hole10" label="Hole #10  (back nine start)" currentValue={startingHolesType}
                        handleChange={this.setStartingHoleType} className={classes.formSelector} />}
                    <TypedFormRadioLabel value="other" label="Other" currentValue={startingHolesType}
                        handleChange={this.setStartingHoleType} className={classes.formSelector} />
                </FormControl>
                <FormControl
                    variant="standard"
                    style={{ marginLeft: 30, width: '95%', ...visible(startingHolesType === 'other') }}>
                    <InputLabel id="select-custom-hole">
                        Choose the starting hole from the drop down below
                    </InputLabel>
                    <Select
                        variant="standard"
                        style={{ width: 60 }}
                        labelId="select-custom-hole"
                        id="select-custom-hole-menu"
                        value={startingHoles[0]}
                        onChange={uiEvent => this.setStartingHoles([parseInt(uiEvent.target.value as string)])}>
                        {range(holesRange.first + 1, holesRange.last + 1).map(hole => <MenuItem key={hole} value={hole - 1}>{hole}</MenuItem>)}
                    </Select>
                </FormControl>
            </React.Fragment>
        );
    }

    renderShotgun = () => {
        const { classes, event, teeTimeSettings } = this.props;
        const { startingHolesType, startingHoles, selectingHoles } = this.state;
        const holesRange = getHolesRange(event.holesType);
        const hasFront = holesRange.first === 0;
        const hasBack = holesRange.last === 18;
        return (
            <React.Fragment>
                <FormControl variant="standard" fullWidth>
                    <InputLabel shrink style={{ left: 6 }}>Groups will be assigned to these holes in sequence. Any overflow groups be assigned as B groups, starting with par 5s.</InputLabel>
                </FormControl>
                <FormControl variant="standard">
                    {(hasFront && hasBack) && <TypedFormRadioLabel value="holes1_18" label="Holes 1-18" currentValue={startingHolesType}
                        handleChange={this.setStartingHoleType} className={classes.formSelector} />}
                    {hasFront && <TypedFormRadioLabel value="holes1_9" label="Holes 1-9  (front nine)" currentValue={startingHolesType}
                        handleChange={this.setStartingHoleType} className={classes.formSelector} />}
                    {hasBack && <TypedFormRadioLabel value="holes10_18" label="Holes 10-18  (back nine)" currentValue={startingHolesType}
                        handleChange={this.setStartingHoleType} className={classes.formSelector} />}
                    <TypedFormRadioLabel value="other" label="Custom" currentValue={startingHolesType}
                        handleChange={this.setStartingHoleType} className={classes.formSelector} />
                </FormControl>
                <FormControl
                    variant="standard"
                    style={{ marginLeft: 30, width: '95%', ...visible(startingHolesType === 'other') }}>
                    <InputLabel shrink />
                    <InputLabel shrink style={{ display: 'unset' }}>{getStartingHolesStr(startingHolesType, startingHoles, holesRange, teeTimeSettings?.mode)}&nbsp;&nbsp;&nbsp;
                        <AppButton color="info" onClick={() => this.setState({ selectingHoles: true })}>Select</AppButton>
                    </InputLabel>
                </FormControl>
                {selectingHoles && <SelectHolesDialog
                    selectedHoles={startingHoles.slice()}
                    event={event}
                    handleOk={selectedHoles => this.setState({ selectingHoles: false, startingHoles: selectedHoles })}
                    handleCancel={() => this.setState({ selectingHoles: false })} />}
            </React.Fragment>
        );
    }

    render() {
        const { open, event, teeTimeSettings } = this.props;
        const { startingHolesType, startingHoles } = this.state;
        const holesRange = getHolesRange(event.holesType);
        const canSave = getStartingHoles(startingHolesType, startingHoles, holesRange, teeTimeSettings?.mode).length > 0;
        return (
            <XSMobileDialog open={open} onClose={this.handleClose}>
                <DialogAppBar label={teeTimeSettings?.mode === 'shotgun' ? 'Starting holes' : 'Starting hole'} close={this.handleClose} />
                <DialogContent onKeyDown={uiEvent => processEnterKey(uiEvent, this.handleSave)} style={{ overflowY: 'unset' }}>
                    {teeTimeSettings?.mode === 'shotgun' ? <this.renderShotgun /> : <this.renderRegular />}
                </DialogContent>
                <DialogActions>
                    <AppButton color="info" onClick={this.handleClose}>Cancel</AppButton>
                    <AppButton color="secondary" onClick={this.handleSave} disabled={!canSave}>Save</AppButton>
                </DialogActions>
            </XSMobileDialog>
        );
    }
}

export default withStyles(styles)(StartingHoleDialog);
