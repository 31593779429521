import * as React from 'react';
import { DialogActions, DialogContent, Typography, FormControl, Grid, ListItem, SxProps, useMediaQuery, useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import DateRangeIcon from '@mui/icons-material/DateRange';
import * as AppTheme from '../../main/Theme';
import * as Utils from '../../util/utility';
import * as Backend from '../../util/firebase';
import * as Scoring from '../../scoring/scoring';
import { WithUserId, withUserId } from '../../auth/Auth';
import { EVENT_DATETIME_FORMAT, Urls } from '../../util/config';
import { Event, Competition, Tee, getCourseName, getEventMainCompetition, HOLES_NAMES, MENS_TEE, WOMENS_TEE, MAX_GOLFERS_PRO, MAX_GOLFERS } from '../../types/EventTypes';
import { LinkIcon, DoneIcon, LocationIcon, ScoringIcon, GolferAddIcon } from '../../common/Icons';
import { SMMobileDialog } from '../../common/dialog/MobileDialog';
import AppButton from '../../common/components/AppButton';
import ListGridItem from '../../common/components/ListGridItem';
import DialogAppBar from '../../common/dialog/DialogAppBar';
import CopyTextField from '../../common/components/CopyTextField';
import { showProgress } from '../../redux/ReduxConfig';
import { getRegistrationDate, formatDateUniversal } from "../Event";
import CurrencyList from 'currency-list';

export function getName(tee?: Tee | null) {
    if (!tee) {
        return '';
    }
    return tee.name;
}

const dlgStyles = (_: Theme) => {
    return {
        ball: {
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            height: 20,
            width: 20,
            minHeight: 20,
            minWidth: 20,
            marginRight: 10,
            backgroundColor: AppTheme.AppColors.blue300
        },
        title: {
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        titleSmall: {
            paddingLeft: 16,
            paddingRight: 16,
            paddingBottom: 20
        }
    }
};

export const topTextStyle: SxProps = {
    fontSize: 16,
    lineHeight: '24px',
    color: AppTheme.AppColors.webBlack
};

export const bottomTextStyle: SxProps = {
    fontSize: 14,
    lineHeight: '21px',
    color: '#757575'
};

type NextStepsGridProps = {
    handleClose: (variant: 'settings/portal' | 'golfers' | '') => void;
};

const NextStepsGrid = ({ handleClose }: NextStepsGridProps) => {
    const buttonHolder = <>
        <AppButton onClick={() => handleClose('settings/portal')} color="info" style={{ minWidth: 100 }}>Edit
            portal</AppButton>
        <AppButton onClick={() => handleClose('golfers')} color="secondary"
            style={{ marginLeft: 7, minWidth: 100 }}>Add golfers</AppButton>
    </>;
    const text = <Typography sx={bottomTextStyle} variant="caption">
        Customize your tournament portal images and content, or add golfers to the event.
    </Typography>;
    const isXs = useMediaQuery(useTheme().breakpoints.down('sm'));
    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography sx={{ fontSize: 14, lineHeight: '21px', color: AppTheme.AppColors.webBlack, fontWeight: 500 }}>
                    Next steps
                </Typography>
            </Grid>
            <Grid item>
                {isXs ? <Grid container direction="column" rowSpacing={1}>
                    <Grid item xs={12}>
                        {text}
                    </Grid>
                    <Grid item xs={12}>
                        {buttonHolder}
                    </Grid>
                </Grid> : <Grid container direction="row">
                    <Grid item xs={7}>
                        {text}
                    </Grid>
                    <Grid item xs={5} sx={{ display: 'flex', alignItems: 'center' }}>
                        {buttonHolder}
                    </Grid>
                </Grid>}
            </Grid>
        </Grid>);
};

type Props = {
    event: Event;
    handleClose: (variant: 'settings/portal' | 'golfers' | '') => void;
} & WithStyles<typeof dlgStyles> & WithUserId;

interface State {
    competitions: Array<Competition>;
}

class EventCreatedDialog extends React.Component<Props, State> {
    state: State = {
        competitions: []
    };

    componentDidMount() {
        this.loadInfo();
    }

    private loadInfo = async () => {
        const { event } = this.props;
        const hideProgress = showProgress();
        const competitions = await Backend.getEntities<Competition>(Backend.competitionsDb(event.id), Backend.orderBy('order'));
        this.setState({ competitions });
        hideProgress();
    };

    render() {
        const { classes, event, pro, handleClose } = this.props;
        const { competitions } = this.state;
        const courseName = getCourseName(event.course) || 'Course not selected';
        const mainCompetition = getEventMainCompetition(competitions);
        const tees = mainCompetition?.tees;
        const menTee = tees && tees[MENS_TEE] ? ', ' + getName(tees[MENS_TEE]) + ' for men' : '';
        const womenTee = tees && tees[WOMENS_TEE] ? ', ' + getName(tees[WOMENS_TEE]) + ' for women' : '';
        const competitionName = mainCompetition ? Scoring.scoringName(mainCompetition, event.eventGender, mainCompetition.competitionGender) : '';
        const absMaxGolfers = pro ? MAX_GOLFERS_PRO : MAX_GOLFERS;
        const registrationLink = Urls.makeAboutLink(event.publicId);
        const textRegistrationClosed = 'Only event administrator can add golfers';
        const textRegistrationOpen = `Registration open to anyone with a link${event.paymentSettings?.feeCost ?
            ` (${CurrencyList.get(event.paymentSettings.currencyCode).symbol}${event.paymentSettings.feeCost} fee)` : ''}, ${event.maxGolfers || absMaxGolfers} spots`;
        const textRegistrationAdmin = 'Only you as an admin can add golfers';
        const textDeadlineThrough = `Deadline through ${formatDateUniversal(getRegistrationDate(event),
            'D MMMM YYYY, dddd')}`;
        const label = (
            <div className={classes.title}>
                <span className={classes.ball}><DoneIcon color={AppTheme.AppColors.primaryTextColor} /></span>
                Event created
            </div>);
        return (
            <SMMobileDialog open={true} onClose={() => handleClose('')} maxWidth={'md'} fullWidth>
                <DialogAppBar bar close={() => handleClose('')} label={label} toolbarBarStyle={{ padding: 0, marginLeft: 16, marginRight: 16 }} />
                <DialogAppBar>
                    <span className={classes.titleSmall}>You can modify event details in Settings</span>
                </DialogAppBar>
                <DialogContent>
                    <ListItem sx={{ paddingLeft: 0 }}>
                        <Typography variant="h6">{event.name}</Typography>
                    </ListItem>
                    <FormControl variant="standard" margin="dense" fullWidth>
                        <ListGridItem icon={<DateRangeIcon color="primary" />}
                            sx1={topTextStyle} sx2={bottomTextStyle}
                            text1={formatDateUniversal(event.date, EVENT_DATETIME_FORMAT)}
                            text2={Utils.formatDayOfWeek(event.date)} />
                        <ListGridItem icon={<LocationIcon color="primary" />}
                            sx1={topTextStyle} sx2={bottomTextStyle}
                            text1={courseName}
                            text2={HOLES_NAMES[event.holesType || 0] + menTee + womenTee} />
                        <ListGridItem icon={<ScoringIcon color="primary" />}
                            sx1={topTextStyle} sx2={bottomTextStyle}
                            text1={competitionName}
                            text2={event.teamSize > 1 ? 'Team' : 'Individual'} />
                        <ListGridItem icon={<GolferAddIcon color="primary" />}
                            sx1={topTextStyle} sx2={bottomTextStyle} maxContentSize={10}
                            text1={event.registrationByAdmin ? textRegistrationClosed : textRegistrationOpen}
                            text2={event.registrationByAdmin ? textRegistrationAdmin : textDeadlineThrough} />
                        {!event.registrationByAdmin && <ListItem>
                            <CopyTextField style={{ paddingLeft: 32 }} readOnly btnOnly
                                color="secondary"
                                fieldValue={registrationLink}
                                btnLabel={<React.Fragment>Copy link<LinkIcon /></React.Fragment>}
                            />
                        </ListItem>}
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <ListItem style={{ width: '100%', paddingLeft: 30 }}>
                        <NextStepsGrid handleClose={handleClose} />
                    </ListItem>
                </DialogActions>
            </SMMobileDialog>
        );
    }
}

export default withStyles(dlgStyles)(withUserId(EventCreatedDialog));
