import * as React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import makeStyles from '@mui/styles/makeStyles';
import { TextField, Button, ButtonOwnProps } from '@mui/material';
import { showError } from '../../redux/ReduxConfig';

const useStyles = makeStyles({
    copyField: {
        fontSize: 14,
        color: 'rgb(0, 0, 200)',
    },
    copyBtn: {
        height: 38,
        marginLeft: -1,
    }
});

interface CopyTextFieldProps {
    fieldValue: string;
    btnLabel: React.ReactNode;
    btnOnly?: boolean;
    readOnly?: boolean;
    disabled?: boolean;
    style?: React.CSSProperties;
    color?: ButtonOwnProps['color'];
    btnStyle?: React.CSSProperties;
}

function CopyTextField(props: CopyTextFieldProps) {
    const classes = useStyles();
    const { readOnly, fieldValue, btnLabel, btnOnly, disabled, style, color, btnStyle } = props;
    return (
        <span style={style}>
            {!btnOnly && <TextField id="copyTextField"
                size="small"
                variant="outlined"
                value={fieldValue}
                InputProps={{ readOnly, disabled, className: classes.copyField }}
                helperText="Participants can self-register online and will appear in golfers list" />}
            <CopyToClipboard text={fieldValue} onCopy={() => showError('Link copied to the clipboard')}>
                <Button style={btnStyle} variant="outlined" color={color} className={classes.copyBtn}
                        disabled={disabled}>{btnLabel}</Button>
            </CopyToClipboard>
        </span>
    );
}

export default CopyTextField;
