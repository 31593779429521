import { DialogContent, DialogTitle, Typography } from "@mui/material";
import { XSMobileDialog } from "./dialog/MobileDialog";
import MaterialTime from "./MaterialTime";
import * as React from "react";

type StartTimeDialogProps = {
    eventDate: number,
    onClose: () => void,
    setTime: (date: Date) => void
};

export const TimeDialog = ({ eventDate, setTime, onClose }: StartTimeDialogProps) => {
    const initialHeight = 256;
    const expandedHeight = 490;
    const initialTitle = 'ENTER TIME';
    const expandedTitle = 'SELECT TIME';
    const [maxHeight, setMaxHeight] = React.useState(initialHeight);
    const [title, setTitle] = React.useState(initialTitle);
    const onHeightChange = (expanded: boolean) => {
        setMaxHeight(expanded ? expandedHeight : initialHeight);
        setTitle(expanded ? expandedTitle : initialTitle);
    };
    return <XSMobileDialog PaperProps={{ style: { maxWidth: 360, maxHeight: maxHeight, marginTop: 24 }}} open={true}
                           maxWidth={'xs'} fullWidth={true} onClose={onClose}>
        <DialogTitle style={{ backgroundColor: 'rgba(88, 147, 199)', height: 32, padding: '12px 0px 4px 24px' }}>
            <Typography style={{ color: 'white', fontFamily: 'poppins, sans-serif', fontSize: 20,
                lineHeight: '24px', fontWeight: 500 }}>
                {title}
            </Typography>
        </DialogTitle>
        <DialogContent>
            <MaterialTime value={eventDate} onChange={setTime} onClose={onClose}
                          onHeightChange={onHeightChange} />
        </DialogContent>
    </XSMobileDialog>;
};