import * as React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { Tabs, Tab, Typography, Badge, Hidden } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import DateRangeIcon from '@mui/icons-material/DateRange';
import GolfersIcon from '@mui/icons-material/PeopleAlt';
import { LocationIcon } from '../../common/Icons';
import {
    Event, EventData, Portal, Gender, Contact, ContactDetails, Team, GolferGroup, Competition, AcceptedInvite, TeeTime,
    getCourseName, generateGroups, getEventMainCompetition, getHolesRange
} from '../../types/EventTypes';
import { generateTeeTimes } from '../TeeTimes';
import { everybodyIsPaired, hasEmptyTees, eventBadgeUrl, everybodyIsScheduled, formatDateUniversal } from '../Event';
import SettingsTab from '../tabs/settings/SettingsTab';
import { DEBUG, EVENT_DATETIME_FORMAT } from '../../util/config';
import * as Backend from '../../util/firebase';
import * as Utils from '../../util/utility';
import * as Scoring from '../../scoring/handicap';
import { pushUrl } from '../../redux/ReduxConfig';
import GolfersTab from '../tabs/golfers/GolfersTab';
import ScoreList from '../tabs/scores/ScoreList';
import ToolsTab from '../tabs/tools/ToolsTab';
import { UserAware, userProviderContextTypes } from '../../auth/Auth';
import { WithDataItem, withDataItemFromUrl, FirebaseDocComponent, FirebaseDataComponent } from '../../common/WithData';
import { ErrorPage } from '../../main/ErrorBoundary';
import EventCreatedDialog from './EventCreatedDialog';
import { ArcStepper } from './ArcStepper';
import { styles } from '../../styles';
import AppButton from 'src/common/components/AppButton';
import CompleteEventSetupDialog from './CompleteEventSetupDialog';
import { Flex, IconLinkElement } from 'src/common/Misc';

type SelectedTab = 'settings' | 'golfers' | 'scores' | 'tools';

function fixTeamHandicap(team: Team, golfers: Map<string, Contact>) {
    team.handicapIndex = Utils.evalAvg(team.contactIds.map(cId => !!golfers.get(cId) ? (golfers.get(cId)!.handicapIndex || 0) : 0));
    let evalGender: Gender | undefined;
    for (let i = 0; i < team.contactIds.length; ++i) {
        let golfer = golfers.get(team.contactIds[i]);
        if (golfer && !golfer.hidden) {
            if (!evalGender) {
                evalGender = golfer.gender;
            } else if (evalGender !== golfer.gender) {
                evalGender = undefined;
                break;
            }
        }
    }
    team.gender = evalGender;
}

const EventShortInfo = withStyles(styles)((props: { event: Event, eventData: EventData } & WithStyles<typeof styles>) => {
    const { event, eventData, classes } = props;
    const { portal, golfers, teams, groups, loadedGolfers, loadedTeams, loadedGroups } = eventData;
    const [completeSetup, setCompleteSetup] = React.useState(0);
    const clickComplete = () => setCompleteSetup(1);
    const clickCustomize = () => setCompleteSetup(2);
    const clickStandings = (e: React.MouseEvent) => pushUrl(`/event/${event.publicId}/standings`);
    const eventSetupClosed = (target?: string) => {
        setCompleteSetup(0);
        if (target) {
            pushUrl(target);
        }
    };
    const linkPortal = <IconLinkElement href={`/event/${event.publicId}`} target="portal">Event site</IconLinkElement>;
    const linkTV = <IconLinkElement href={`/tv/${event.publicId}`} target="tv">TV</IconLinkElement>;
    let name = event.name;
    if (DEBUG && event.legitDate) {
        name += ' (legit)';
    }
    if (DEBUG && event.legitAppDate) {
        name += ' (legit-app)';
    }
    if (event.deleted) {
        name += ' (deleted)';
    }
    const stepsTotal = 3;
    let participantsOk = false;
    let scheduleOk = false;
    let teesOk = false;
    let stepsCompleted = 0;
    const dataLoaded = loadedGolfers > 0 && (event.teamSize === 1 || loadedTeams > 0) && loadedGroups > 0;
    if (dataLoaded && golfers.size > 0 && (event.teamSize === 1 || everybodyIsPaired(golfers, teams))) {
        stepsCompleted++;
        participantsOk = true;
    }
    if (dataLoaded && everybodyIsScheduled(event, groups, teams, golfers)) {
        stepsCompleted++;
        scheduleOk = true;
    }
    if (dataLoaded && !hasEmptyTees(event)) {
        stepsCompleted++;
        teesOk = true;
    }
    const today = Utils.getUserToday();
    const eventDate = event.date;
    if (today <= eventDate && eventDate < today + Utils.DAY_MILLIS && stepsCompleted === stepsTotal) {
        stepsCompleted++;
    }
    return (
        <div className={classes.eventHeader}>
            <Grid container spacing={2} alignItems="center">
                <Hidden mdDown>
                    <Grid>
                        <ArcStepper badgeUrl={dataLoaded ? eventBadgeUrl(event, portal) : ''} stepsTotal={stepsTotal} stepsCompleted={stepsCompleted} size={80} />
                    </Grid>
                </Hidden>
                <Grid flex={1}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid><Typography variant="h5" >{name}</Typography></Grid>
                        <Grid><Typography className={classes.link} noWrap>{linkPortal}</Typography></Grid>
                        <Grid><Typography className={classes.link} noWrap>{linkTV}</Typography></Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                        <Grid>
                            <Typography noWrap>
                                <Flex>
                                    <DateRangeIcon className={classes.textIcon + ' ' + classes.secondaryText} />
                                    {formatDateUniversal(event.date, EVENT_DATETIME_FORMAT)}
                                </Flex>
                            </Typography>
                        </Grid>
                        <Grid>
                            <Typography noWrap>
                                <Flex>
                                    <LocationIcon className={classes.textIcon + ' ' + classes.secondaryText} />
                                    {getCourseName(event.course)}
                                </Flex>
                            </Typography>
                        </Grid>
                        <Grid>
                            <Typography noWrap>
                                <Flex>
                                    <GolfersIcon className={classes.textIcon + ' ' + classes.secondaryText} />
                                    {Utils.withS(golfers.size, 'golfer') + ' registered'}
                                </Flex>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Hidden mdUp>
                    <Grid>
                        <ArcStepper badgeUrl={dataLoaded ? eventBadgeUrl(event, portal) : ''} stepsTotal={stepsTotal} stepsCompleted={stepsCompleted} size={80} />
                    </Grid>
                    <Grid xs={12} />
                </Hidden>
                <Grid>
                    {dataLoaded && stepsCompleted < stepsTotal &&
                        <AppButton color="secondary" onClick={clickComplete}>Complete event setup</AppButton>}
                    {dataLoaded && stepsCompleted > stepsTotal &&
                        <AppButton color="secondary" onClick={clickStandings}>Standings</AppButton>}
                    {dataLoaded && stepsCompleted === stepsTotal &&
                        <AppButton color="info" onClick={clickCustomize}>Customize event</AppButton>}
                </Grid>
            </Grid>
            {completeSetup > 0 && <CompleteEventSetupDialog event={event} participantsOk={participantsOk} scheduleOk={scheduleOk} teesOk={teesOk} handleClose={eventSetupClosed} customize={completeSetup === 2} />}
        </div >
    );
});

interface EventHeaderProperties {
    event: Event;
    eventData: EventData;
    value: SelectedTab;
    onTabChange: (event: React.SyntheticEvent, value: SelectedTab) => void;
}

const EventHeader = withStyles(styles)((props: EventHeaderProperties & WithStyles<typeof styles>) => {
    const { classes, onTabChange, value, event, eventData } = props;
    const { golfers, teams, groups } = eventData;
    const golfersOk = golfers.size > 0 && (event.teamSize === 1 || everybodyIsPaired(golfers, teams))
        && everybodyIsScheduled(event, groups, teams, golfers);
    const scoringOk = !hasEmptyTees(event);
    return (
        <React.Fragment>
            <div>
                <EventShortInfo event={event} eventData={eventData} />
            </div>
            <div className={classes.stickyTop} style={{ background: 'white' }}>
                <Tabs className={classes.eventTabs} value={value} onChange={onTabChange} indicatorColor="primary" textColor="primary">
                    <Tab label="Settings" value="settings" />
                    <Tab label={<Badge color="error" variant="dot" overlap="rectangular" invisible={golfersOk}>Golfers &nbsp;</Badge>} value="golfers" />
                    <Tab label={<Badge color="error" variant="dot" overlap="rectangular" invisible={scoringOk}>Scoring &nbsp;</Badge>} value="scores" />
                    <Tab label="Tools" value="tools" />
                </Tabs>
            </div>
        </React.Fragment>
    );
});

type RenderInfoProps = { event: Event, tab: SelectedTab, url: string };
type RenderInfoState = EventData & { recent: Map<string, string> };

class RenderInfo extends React.Component<RenderInfoProps, RenderInfoState> {
    state: RenderInfoState = {
        recent: new Map<string, string>(),
        portal: {} as Portal,
        portalLoaded: false,
        teams: new Map<string, Team>(),
        teamsList: new Array<Team>(),
        golferTeam: new Map<string, Team>(),
        groups: new Array<GolferGroup>(),
        golfers: new Map<string, Contact>(),
        roster: new Map<string, ContactDetails>(),
        competitions: new Array<Competition>(),
        acceptedInvites: new Map<string, AcceptedInvite>(),
        loadedTeams: 0,
        loadedGroups: 0,
        loadedGolfers: 0,
        loadedRoster: 0,
        teeTimes: new Array<TeeTime>(),
        genTeeTimes: () => Promise.resolve(),
    };

    componentDidMount() {
        this.setState({ genTeeTimes: this.genTeeTimes });
    }

    genTeeTimes = async () => {
        const { event } = this.props;
        const { golfers, teams, groups, loadedGolfers, loadedTeams } = this.state;
        if (event.teamSize === 1 && loadedGolfers > 0 || event.teamSize > 1 && loadedTeams > 0) {
            const fixedGroups = generateGroups(event, golfers, teams, groups);
            const holesRange = getHolesRange(event.holesType);
            const teeTimes = generateTeeTimes(event.teeTime, holesRange, fixedGroups.length);
            this.setState({ teeTimes, groups: fixedGroups }, () => Promise.resolve());
        }
    }

    render() {
        const { event, tab, url } = this.props;
        const { recent, ...eventData } = this.state;
        const changeTab = (_: React.ChangeEvent<{}>, value: SelectedTab) => pushUrl(`${url}/${value}`);
        const onSelectedTab = (t: string, sub: string) => recent.set(t, sub);
        return (
            <React.Fragment>
                <EventHeader event={event} eventData={eventData} value={tab as SelectedTab} onTabChange={changeTab} />
                {tab === 'settings' && <SettingsTab event={event} onSelectedTab={subtab => onSelectedTab(tab, subtab)} initial={recent.get(tab)} />}
                {tab === 'golfers' && <GolfersTab event={event} eventData={eventData} onSelectedTab={subtab => onSelectedTab(tab, subtab)} initial={recent.get(tab)} />}
                {tab === 'scores' && <ScoreList event={event} />}
                {tab === 'tools' && <ToolsTab event={event} eventData={eventData} onSelectedTab={subtab => onSelectedTab(tab, subtab)} initial={recent.get(tab)} />}
                <FirebaseDocComponent onDoc={doc => {
                    this.setState({ portal: Backend.fromEntity<Portal>(doc), portalLoaded: true });
                }} docReference={Backend.portalFields(event.id)} />
                <FirebaseDataComponent query={Backend.query(Backend.golferTeamDb(event.id), Backend.orderBy('order'))} onDataMap={(teamsList: Array<Team>, teams: Map<string, Team>) => {
                    const { loadedTeams, golfers } = this.state;
                    teams.forEach(team => fixTeamHandicap(team, golfers));
                    ///TODO: fix usage , duplicated code in fixTeamHandicap and Scoring.setTeamHandicapIndexes
                    teamsList.push({ id: '', contactIds: [], order: teamsList.length });
                    const golferTeam = new Map<string, Team>();
                    teamsList.forEach(team => team.contactIds.forEach(golferId => golferTeam.set(golferId, team)));
                    Scoring.setTeamHandicapIndexes(golfers, teams);
                    this.setState({ teams, teamsList, golferTeam, loadedTeams: loadedTeams + 1 }, this.genTeeTimes);
                }} />
                <FirebaseDataComponent query={Backend.query(Backend.golferDb(event.id), Backend.where('hidden', '==', false))} onMap={(golfers: Map<string, Contact>) => {
                    const { loadedGolfers } = this.state;
                    this.setState({ golfers, loadedGolfers: loadedGolfers + 1 }, this.genTeeTimes);
                }} />
                <FirebaseDataComponent query={Backend.query(Backend.competitionsDb(event.id), Backend.orderBy('order'))} onData={(competitions: Array<Competition>) =>
                    this.setState({ competitions, mainCompetition: getEventMainCompetition(competitions) })} />
                <FirebaseDataComponent query={Backend.acceptedInvitesCollection(event.userId)}
                    onMap={(acceptedInvites: Map<string, AcceptedInvite>) => this.setState({ acceptedInvites })} />
                <FirebaseDataComponent query={Backend.rosterDb(event.userId)} onMap={(roster: Map<string, ContactDetails>) => {
                    const { loadedRoster } = this.state;
                    this.setState({ roster, loadedRoster: loadedRoster + 1 });
                }} />
                <FirebaseDataComponent query={Backend.golferGroupDb(event.id)} onData={(groups: Array<GolferGroup>) => {
                    const { loadedGroups } = this.state;
                    this.setState({ groups, loadedGroups: loadedGroups + 1 }, this.genTeeTimes);
                }} name="groups" />
            </React.Fragment>
        );
    }
}

type EventInfoProps = WithDataItem<Event> & WithDataItem<Portal> & RouteComponentProps<{ tab: SelectedTab }>;

class EventInfo extends React.Component<EventInfoProps> {
    static contextTypes = userProviderContextTypes;
    context!: UserAware;
    state: { isNewEvent?: boolean } = {};
    componentDidMount() {
        const event = this.props.item;
        console.log(`createId ${this.context.eventId}`)
        if (this.context.eventId === event?.id) {
            this.context.setEventId();
            setTimeout(() => this.setState({ isNewEvent: true }), 100);
        } else {
            /// setTimeout(() => this.setState({ isNewEvent: true }), 1000); // DEBUG REMOVE!!!
        }
    }
    private handleCloseNewEventDlg = (page: string) => {
        const parentUrl = this.props.match.url;
        this.setState({ isNewEvent: false }, () => pushUrl(`${parentUrl}/${page}`))
    }
    render() {
        const event = this.props.item;
        const { isNewEvent } = this.state;
        if (!event?.exists) {
            const err = 'Event not found: ' + event?.id;
            return <ErrorPage error={err} errorInfo={""} noheader />;
        }
        const { user, effectiveUserId } = this.context;
        if (!user) {
            const err = `Incorrect user context!`;
            return <ErrorPage error={err} errorInfo={""} noheader />;
        }
        const uid = effectiveUserId ? effectiveUserId : user.uid;
        if (event.userId !== uid || (event.deleted && !effectiveUserId)) {
            return null;
        }
        if (event.handicapSystem === 'CONGU') {
            event.handicapSystem = 'WHS_UK';
        } else if (event.handicapSystem === 'GA') {
            event.handicapSystem = 'WHS_AU';
        } else if (event.handicapSystem !== 'WHS' && event.handicapSystem !== 'WHS_UK' && event.handicapSystem !== 'WHS_AU' && event.handicapSystem !== 'SMPLFD') {
            event.handicapSystem = 'WHS';
        }
        const parentUrl = this.props.match.url;
        return (
            <React.Fragment>
                <Switch>
                    <Route path={`${parentUrl}/:tab`} render={p => <RenderInfo event={event} tab={p.match.params.tab as SelectedTab} url={parentUrl} />} />
                    <Redirect to={`${parentUrl}/settings`} />
                </Switch>
                {isNewEvent && <EventCreatedDialog
                    event={event}
                    handleClose={this.handleCloseNewEventDlg} />}
            </React.Fragment>
        );
    }
}

export default withDataItemFromUrl<Event, {}>(Backend.eventsDb)(EventInfo);
