import * as React from 'react';
import { Action, isOfType, withKey } from './ReduxCommon';
import { AnyAction, Reducer } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface RouterState {
    token: number;
    path: string;
    replace: boolean;
}

const DEFAULT_STATE: RouterState = {
    token: 1,
    path: '',
    replace: false
};

const ROUTER_TYPE = 'router_url';

interface RouterAction extends Action {
    path: string;
    replace: boolean;
}

export function routeToAction(path: string, replace: boolean): RouterAction {
    return { type: ROUTER_TYPE, path: path, replace: replace };
}

export const routerReducer: Reducer<RouterState> = (state: RouterState | undefined, action: AnyAction) => {
    if (state && isOfType<RouterAction>(action, ROUTER_TYPE)) {
        const copy = { ...state };
        copy.path = action.path;
        copy.replace = action.replace;
        copy.token = Math.random();
        return copy;
    }
    return state || DEFAULT_STATE;
};

class WithRouter extends React.Component<RouterState & RouteComponentProps<any>> {

    componentWillReceiveProps(newProps: RouterState & RouteComponentProps<any>) {
        const { path, history } = newProps;
        if (this.props.token !== newProps.token) {
            newProps.replace ? history.replace(path) : history.push(path);
        }
    }

/*
    shouldComponentUpdate(newProps: RouterState & RouteComponentProps<any>) {
        let { path, history } = newProps;
        if (this.props.token !== newProps.token) {
            newProps.replace ? history.replace(path) : history.push(path);
            return true;
        } else {
            return false;
        }
    }
*/

    render() {
        return null;
    }
}

export const RouteHandler = withRouter(connect(withKey<RouterState>('routerReducer'))(WithRouter));
