import * as React from 'react';
import { DialogProps } from '@mui/material/Dialog';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { FormControl, DialogActions, Typography, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import * as Backend from '../../../../util/firebase';
import { Event, Contact, EventGender, Competition, EVENT_GENDER_LABELS } from '../../../../types/EventTypes';
import { updateGolfersPlayingHandicapInTransaction, deleteGolfersFromEvent, elog } from '../../../Event';
import { XSMobileDialog } from '../../../../common/dialog/MobileDialog';
import DialogAppBar from '../../../../common/dialog/DialogAppBar';
import AppButton from '../../../../common/components/AppButton';
import { showAlert } from '../../../../redux/ReduxConfig';
import { Spacing } from '../../../../common/Misc';
import { styles } from '../../../../styles';
import * as Utils from '../../../../util/utility';
import { errMsg } from "../../../../util/firebase";

type Props = { event: Event; } & WithStyles<typeof styles> & DialogProps;
type State = { eventGender: EventGender; };

class EventGenderSettingsDialog extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            eventGender: props.event.eventGender || 'both',
        };
    }

    private handleSave = async () => {
        const { event } = this.props;
        const { eventGender } = this.state;
        const [golfers, competitions] = await Promise.all([
            Backend.getEntities<Contact>(Backend.golferDb(event.id)),
            Backend.getEntities<Competition>(Backend.competitionsDb(event.id))
        ]);
        if (eventGender === 'men' || eventGender === 'women') {
            const excludeGolfers = (eventGender === 'men' ? golfers.filter(g => g.gender === 'female') : golfers.filter(g => g.gender === 'male'));
            if (excludeGolfers.length > 0) {
                const countStr = eventGender === 'men' ?
                    Utils.withIs(excludeGolfers.length, 'women') :
                    Utils.withIs(excludeGolfers.length, 'men');
                const theyStr = excludeGolfers.length === 1 ?
                    (eventGender === 'men' ? 'she' : `he`) :
                    ('they');
                showAlert(`There ${countStr} already added in the event. If you proceed with this change, ${theyStr} will be deleted. Please confirm the change.`, [
                    { title: 'Cancel' },
                    { title: 'Delete golfers', color: 'secondary', action: () => this.finishSave(golfers, competitions, excludeGolfers) }
                ]);
            } else {
                this.finishSave(golfers, competitions);
            }
        } else {
            this.finishSave(golfers, competitions);
        }
    }

    private finishSave = (golfers: Array<Contact>, competitions: Array<Competition>, golfersToDelete?: Array<Contact>) => {
        const { event } = this.props;
        const { eventGender } = this.state;
        const eventUpdate = {
            id: event.id,
            exists: true,
            eventGender
        } as Event;
        deleteGolfersFromEvent(event, golfersToDelete ?? [], eventGender, (transaction) => this.finishDeleteGolfersFromEvent(transaction, eventUpdate, golfers, competitions))
            .then(() => elog(event, `Gender settings modified`, eventGender === 'both' ? 'men and women' : `${eventGender}. ${golfersToDelete ?? 'No'} golfers deleted.`, `Id: ${event.id}`))
            .then(this.handleClose);
    }

    private finishDeleteGolfersFromEvent = (transaction: Backend.Transaction, event: Event, golfers: Array<Contact>, competitions: Array<Competition>) => {
        Backend.updateInTransaction(Backend.eventsDb, event, transaction);
        updateGolfersPlayingHandicapInTransaction(transaction, event, golfers, competitions)
            .catch(err => console.log(errMsg(err)));
    }

    private handleClose = () => {
        const close = this.props.onClose;
        if (close) {
            close({} as React.SyntheticEvent<any>, 'escapeKeyDown');
        }
    }

    render() {
        const { classes, open } = this.props;
        const { eventGender } = this.state;
        return (
            <React.Fragment>
                <XSMobileDialog open={open} onClose={this.handleClose} maxWidth="sm" fullWidth={true}>
                    <DialogAppBar label="Gender" close={this.handleClose} />
                    <div style={{ padding: '16px' }}>
                        <FormControl
                            variant="standard"
                            margin="dense"
                            fullWidth
                            style={{ flexDirection: 'column' }}>
                            <Typography gutterBottom>Golfers in this event are:</Typography>
                            <RadioGroup value={eventGender}
                                onChange={e => this.setState({ eventGender: e.target.value as EventGender })}>
                                <FormControlLabel
                                    value={'both'}
                                    control={<Radio color="primary" />}
                                    label={EVENT_GENDER_LABELS[0]} />
                                <FormControlLabel
                                    value={'men'}
                                    control={<Radio color="primary" />}
                                    label={EVENT_GENDER_LABELS[1]} />
                                <FormControlLabel
                                    value={'women'}
                                    control={<Radio color="primary" />}
                                    label={EVENT_GENDER_LABELS[2]} />
                            </RadioGroup>
                            <Spacing />
                        </FormControl>
                    </div>
                    <DialogActions>
                        <AppButton color="info" onClick={this.handleClose}>Cancel</AppButton>
                        <AppButton color="secondary" className={classes.iconButton} onClick={this.handleSave}>Save</AppButton>
                    </DialogActions>
                </XSMobileDialog>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(EventGenderSettingsDialog);
