import * as React from 'react';
import Badge from '@mui/material/Badge';
import Paper from '@mui/material/Paper';
import { ButtonBase } from '@mui/material';
import { DialogActions } from '@mui/material';
import { DialogContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import ArrowOutlineIcon from '@mui/icons-material/ArrowDropDownCircle';
import {
    Event, EventData, Contact, Team, ContactDetails, EventLog, MAX_TEAM_SIZE
} from '../../../../types/EventTypes';
import { addGolferToTeam, removeGolferFromTeam, updateTeams, logEvent, saveContact } from '../../../Event';
import { fullName, newContact, getSameNameGolfersIds, compareByNamesOrCity } from '../../../../contact/Contact';
import { XSMobileDialog } from '../../../../common/dialog/MobileDialog';
import { ItemS, FlexGrid, NoWrap, Red } from '../../../../common/Misc';
import * as Backend from '../../../../util/firebase';
import * as Utils from '../../../../util/utility';
import { withProgress } from '../../../../util/ProgressPromise';
import DialogAppBar from '../../../../common/dialog/DialogAppBar';
import AppButton from '../../../../common/components/AppButton';
import ConfirmDialog from '../../../../common/dialog/ConfirmDialog';
import EditContactDialog from '../../../../contact/EditContactDialog';
import { styles } from '../../../../styles';

function nextCurTeam(curTeam: number, teamSize: number, teams: Array<Team>) {
    while (curTeam < teams.length) {
        const openings = teamSize - teams[curTeam].contactIds.length;
        if (openings > 0) {
            return curTeam;
        }
        curTeam++;
    }
    if (curTeam > teams.length - 1) {
        curTeam = teams.length - 1;
    }
    return curTeam;
}

interface GolferItemProps {
    golfer: Contact;
    team?: Team;
    curTeam: number;
    select: (golfer: Contact) => void;
    courseOrCity?: string;
}

export class GolferItem extends React.Component<GolferItemProps & WithStyles<typeof styles>> {
    GolferBadge = () => {
        const { classes, golfer, select, courseOrCity } = this.props;
        return (
            <ButtonBase className={classes.buttonBadge} onClick={() => select(golfer)}>
                <Paper className={classes.teamPaper}>
                    <Typography className={classes.labelOverflow}>
                        {fullName(golfer)}
                        {courseOrCity && <span className={classes.homeCourseOrCity}>{` (${courseOrCity})`}</span>}
                    </Typography>
                </Paper>
            </ButtonBase >
        );
    }
    render() {
        const { classes, team, curTeam } = this.props;
        const badge = team ? (team.order + 1) : '';
        const badgeStyle = team && curTeam === team.order ? classes.teamBadge : classes.teamBadge + ' ' + classes.backgroundGray;
        return (
            <ItemS>
                {team ?
                    <Badge classes={{ badge: badgeStyle }} color="primary" badgeContent={badge}>
                        <this.GolferBadge />
                    </Badge> :
                    <this.GolferBadge />}
            </ItemS>
        );
    }
}

interface EditTeamsDialogProps {
    event: Event;
    eventData: EventData;
    editingTeam: number;
    handleClose: () => void;
    syncUpdate?: boolean;
}

interface State {
    curTeam: number;
    editedTeams: Array<Team>;
    golferTeam: Map<string, Team>;
    editedContact?: ContactDetails;
    changes: number;
    changesLog: Array<EventLog>;
    confirmingCancel?: () => void;
}

type Props = EditTeamsDialogProps & WithStyles<typeof styles>;

export class EditTeamsDialog extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            curTeam: props.editingTeam,
            editedTeams: [],
            golferTeam: new Map<string, Team>(),
            changes: 0,
            changesLog: []
        };
    }

    componentDidMount() {
        this.initTeams();
    }

    initTeams() {
        const { eventData } = this.props;
        const { teamsList } = eventData;
        const editedTeams = [...teamsList];
        const golferTeam = new Map<string, Team>(eventData.golferTeam);
        if (editedTeams.length === 0 || editedTeams[editedTeams.length - 1].contactIds.length > 0) {
            editedTeams.push({ id: '', contactIds: [], order: editedTeams.length });
        }
        this.setState({ editedTeams, golferTeam });
    }

    private addGolfer = (golfer: Contact) => {
        const { event, syncUpdate } = this.props;
        const { editedTeams, golferTeam, changesLog } = this.state;
        let { curTeam, changes } = this.state;
        if (curTeam >= editedTeams.length) {
            return;
        }
        const team = editedTeams[curTeam];
        if (team.contactIds.indexOf(golfer.id) < 0 && team.contactIds.length < MAX_TEAM_SIZE) {
            if (syncUpdate) {
                addGolferToTeam(event, editedTeams, golfer, team)
                    .then(this.incCurTeamIfFull);
            } else {
                const fromTeam = golferTeam.get(golfer.id);
                if (fromTeam && fromTeam.contactIds.indexOf(golfer.id) >= 0) {
                    fromTeam.contactIds.splice(fromTeam.contactIds.indexOf(golfer.id), 1);
                }
                team.contactIds.push(golfer.id);
                golferTeam.set(golfer.id, team);
                const { nonemptyTeams } = updateTeams(editedTeams);
                if (nonemptyTeams.length === 0 || nonemptyTeams[nonemptyTeams.length - 1].contactIds.length > 0) {
                    nonemptyTeams.push({ id: '', contactIds: [], order: nonemptyTeams.length });
                }
                curTeam = nextCurTeam(curTeam, event.teamSize, nonemptyTeams);
                changes++;
                changesLog.push(logEvent(event.publicId, 'Golfer moved to team', `${golfer.firstName} ${golfer.lastName} to Team ${team.order + 1}`, `Id: ${golfer.id}`));
                this.setState({ editedTeams: nonemptyTeams, golferTeam, changes, curTeam });
            }
        } else if (team.contactIds.indexOf(golfer.id) >= 0) {
            if (syncUpdate) {
                removeGolferFromTeam(event, editedTeams, golfer, team)
                    .then(this.incCurTeamIfFull);
            } else {
                team.contactIds.splice(team.contactIds.indexOf(golfer.id), 1);
                golferTeam.delete(golfer.id);
                const { nonemptyTeams } = updateTeams(editedTeams);
                if (nonemptyTeams.length === 0 || nonemptyTeams[nonemptyTeams.length - 1].contactIds.length > 0) {
                    nonemptyTeams.push({ id: '', contactIds: [], order: nonemptyTeams.length });
                }
                changes++;
                changesLog.push(logEvent(event.publicId, 'Golfer removed from team', `${golfer.firstName} ${golfer.lastName} from Team ${team.order + 1}`, `Id: ${golfer.id}`));
                this.setState({ editedTeams: nonemptyTeams, golferTeam, changes });
            }
        }
    }

    private incCurTeamIfFull = () => this.setState({ curTeam: nextCurTeam(this.state.curTeam, this.props.event.teamSize, this.state.editedTeams) });

    private incCurTeam = () => {
        const { curTeam, editedTeams } = this.state;
        if (curTeam < editedTeams.length - 1) {
            this.setState({ curTeam: curTeam + 1 });
        }
    }

    private decCurTeam = () => {
        const { curTeam } = this.state;
        if (curTeam > 0) {
            this.setState({ curTeam: curTeam - 1 });
        }
    }

    private handleAddClick = () => this.setState({ editedContact: newContact(this.props.event) });

    private handleContactChanged = (contactDetails: ContactDetails) => {
        const { event } = this.props;
        withProgress(saveContact(event, contactDetails, 'Golfer modified'))
            .then(() => this.setState({ editedContact: undefined }));
    }

    private handleClose = () => {
        const { changes } = this.state;
        if (changes === 0) {
            this.props.handleClose();
        } else {
            this.setState({ confirmingCancel: () => this.handleSave() });
        }
    }

    private handleCloseX = (uiEvent: string, reason: string) => {
        if ("backdropClick" === reason) { 
            return;
        }
        this.handleClose();
    }

    private handleSave = () => {
        const { event, eventData } = this.props;
        const { teamsList } = eventData;
        const { changesLog, editedTeams } = this.state;
        withProgress(Backend.removeAddBatchPromise(Backend.golferTeamDb(event.id), teamsList, editedTeams.filter(team => team.contactIds.length > 0))
            .then(() => Backend.updateOrAddBatch(Backend.eventLogsDb(event.id), changesLog))
            .then(this.props.handleClose));
    };

    render() {
        const { event, eventData, classes, editingTeam, syncUpdate } = this.props;
        const { golfers, loadedTeams, loadedGolfers } = eventData;
        const { curTeam, editedTeams, golferTeam, editedContact, changes } = this.state;
        const openings = curTeam < editedTeams.length ? event.teamSize - editedTeams[curTeam].contactIds.length : 0;
        const openingsText = openings < 0 ? <Red>You are exceeding team size setting</Red> : '(' + Utils.withS(openings, 'opening') + ')';
        const curTeamName = curTeam < editedTeams.length ? 'Team ' + (editedTeams[curTeam].order + 1) : '';
        const curTeamOrder = curTeam < editedTeams.length ? editedTeams[curTeam].order : -1;
        const open = editingTeam >= 0;
        const emptyStatus = loadedGolfers > 0 && loadedTeams > 0 ? 'No golfers yet' : 'Loading...';
        const golfersList = Array.from(golfers.values()).sort((c1, c2) => compareByNamesOrCity(c1, c2));
        const sameNameIds = getSameNameGolfersIds(golfersList);
        return (
            <React.Fragment>
                <XSMobileDialog open={open} onClose={this.handleCloseX} maxWidth="lg">
                    <DialogAppBar label="Select golfers" close={this.handleClose} />
                    <DialogContent>
                        {golfers.size > 0 &&
                            <div className={classes.noselect}>
                                <span className={classes.flex}><NoWrap>Assign team:&nbsp;&nbsp;&nbsp;</NoWrap>
                                    <IconButton
                                        className={classes.colorSecondary}
                                        disabled={curTeam === 0}
                                        onClick={this.decCurTeam}
                                        size="large">
                                        <ArrowOutlineIcon className={classes.rotate90} /></IconButton>
                                    <NoWrap className={classes.colorSecondary + ' ' + classes.thickText}>&nbsp;&nbsp;&nbsp;{curTeamName}&nbsp;&nbsp;&nbsp;</NoWrap>
                                    <IconButton
                                        className={classes.colorSecondary}
                                        disabled={curTeam === editedTeams.length - 1}
                                        onClick={this.incCurTeam}
                                        size="large">
                                        <ArrowOutlineIcon className={classes.rotate270} /></IconButton>
                                    <NoWrap>&nbsp;&nbsp;&nbsp;{openingsText}</NoWrap>
                                </span>
                                <FlexGrid spacing={2}>
                                    {golfersList.map(golfer => <GolferItem key={golfer.id} golfer={golfer} team={golferTeam.get(golfer.id)}
                                        courseOrCity={sameNameIds.has(golfer.id) ? golfer.homeCourseOrCity : undefined}
                                        select={this.addGolfer} curTeam={curTeamOrder} classes={classes} />)}
                                </FlexGrid>
                            </div>}
                        {golfers.size === 0 && <Typography variant="body1" style={{ margin: 16 }}>{emptyStatus}</Typography>}
                    </DialogContent>
                    <DialogActions>
                        <AppButton color="info" onClick={this.handleAddClick}>{'Add new'}</AppButton>
                        {syncUpdate && <AppButton color="secondary" onClick={this.handleClose}>{'Done'}</AppButton>}
                        {!syncUpdate && <AppButton color="info" onClick={this.handleClose}>{'Cancel'}</AppButton>}
                        {!syncUpdate && <AppButton color="secondary" onClick={this.handleSave} disabled={changes === 0}>{'Save'}</AppButton>}
                    </DialogActions>
                </XSMobileDialog>
                {!!editedContact && <EditContactDialog
                    open
                    event={event}
                    actionMode="add"
                    initialContact={editedContact}
                    saveToEvent={this.handleContactChanged}
                    handleClose={() => this.setState({ editedContact: undefined })} />}
                {!!this.state.confirmingCancel && <ConfirmDialog
                    open
                    disableEscapeKeyDown
                    disableBackdropClick
                    onOk={this.state.confirmingCancel}
                    onCancel={this.props.handleClose}
                    content="Save teams updates?"
                    cancelLabel="Discard"
                    okLabel="Save" />}
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(EditTeamsDialog);
