import * as React from 'react';
import { Switch, Route, RouteComponentProps, withRouter } from 'react-router-dom';
import { Typography } from '@mui/material';
import { FirebaseDocComponent, FirebaseDataComponent, withDataItemFromUrl } from '../common/WithData';
import { showAlert, showProgress } from '../redux/ReduxConfig';
import * as Backend from '../util/firebase';
import { Competition, Event, EventMapping, Portal, getEventMainCompetition } from '../types/EventTypes';
import { eventBannerUrl, eventBadgeUrl } from '../event/Event';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import LinkTabs, {Tab} from '../event/tabs/common/LinkTabs';
import About from './About';
import Schedule from './Schedule';
import Standings from './Standings';
import { Container, Item } from '../common/Misc';
import { ErrorPage } from '../main/ErrorBoundary';
import { styles } from '../styles';
import { Urls, dbgLog } from "../util/config";
import { detectServiceWorkerUpdate } from 'src/main/SWRegistration';
import { AboutTabIcon, ScheduleTabIcon, StandingsTabIcon } from "../common/Icons";

interface PublicProperties {
    event: Event;
    portal: Portal;
    withTabs?: string[];
}

const tabs: Array<Tab<string>> = [{
    id: 'about',
    label: 'ABOUT',
    getIcon: (active: boolean) => <AboutTabIcon active={active} />
}, {
    id: 'schedule',
    label: 'SCHEDULE',
    getIcon: (active: boolean) => <ScheduleTabIcon active={active} />
}, {
    id: 'standings',
    label: 'STANDINGS',
    getIcon: (active: boolean) => <StandingsTabIcon active={active} />
}];

export const Header = withStyles(styles)((props: PublicProperties & WithStyles<typeof styles>) => {
    const { event, portal, classes, withTabs } = props;
    return (
        <React.Fragment>
            <div className={classes.publicBannerContainer}>
                <img className={classes.publicBanner} src={eventBannerUrl(event, portal)} alt="" />
                <img className={classes.publicBadge} src={eventBadgeUrl(event, portal)} alt="" />
                <Typography className={classes.publicName}>{event.name}</Typography>
            </div>
            <LinkTabs initial={event.leaderboard ? 'standings' : 'about'}
                tabs={tabs.filter(tab => withTabs?.includes(tab.id))}
                pub={true} sticky onSelectedTab={_ => { }} classes={classes}
            />
        </React.Fragment>
    );
});

export const NoEvent = withStyles(styles)((props: WithStyles<typeof styles> & { text?: string }) => {
    const { classes, text } = props;
    return (
        <div className={classes.centerOuter}>
            <div className={classes.centerInner}>
                <Container spacing={10} style={{ width: 600 }}>
                    <Item xs={12} placeCenter>
                        <img src={Urls.golfImage} style={{ height: 170 }} alt="" />
                    </Item>
                    <Item xs={12} placeCenter>{text || `Event not found`}</Item>
                    <Item xs={12} placeCenter><a href="/">Events Home</a></Item>
                </Container>
            </div>
        </div>
    );
});


type State = {
    eventId?: string;
    event?: Event,
    portal?: Portal
    competitions: Array<Competition>;
    updateServiceWorker?: () => void;
    eventNotificationShown: boolean;
};

type Props = WithStyles<typeof styles> & RouteComponentProps<any>;

const Public = withRouter(class extends React.Component<Props, State> {
    state: State = {
        competitions: [],
        eventNotificationShown: false
    };
    private readonly fromInvite = window.location.pathname.endsWith('/standings/invite');
    constructor(props: Props) {
        super(props);
        detectServiceWorkerUpdate(() => this.setState({
            updateServiceWorker: () => {
                dbgLog('Pub reload...');
                window.location.reload();
            }
        }));
    }
    async componentDidMount() {
        const { match } = this.props;
        const hideProgress = showProgress();
        const eventMappingId = await Backend.getEntity<EventMapping>(Backend.eventMappingDb, match.params.id);
        this.setEventId(eventMappingId);
        hideProgress();
    }
    private setEventId = (em?: EventMapping) => this.setState({ eventId: em?.eventId || '' });
    private setEvent = (event: Event) => this.setState({ event });
    private setPortal = (portal: Portal) => this.setState({ portal });
    private onCompetitions = (competitions: Array<Competition>) => this.setState({ competitions });
    private renderEvent = ({ event, portal }: { event: Event, portal: Portal }) => {
        const { classes, match } = this.props;
        const { competitions } = this.state;
        const parentUrl = match.url;
        match.params.id = 0;
        const mainCompetition = getEventMainCompetition(competitions);
        return (
            <div className={classes.pubRoot}>
                <Header event={event} portal={portal} withTabs={
                    event.leaderboard ? ['standings',] : ['about', 'schedule', 'standings',]
                } />
                <Switch>
                    {!event.leaderboard &&
                        <Route path={`${parentUrl}/about`} render={() => <About event={event} portal={portal} mainCompetition={mainCompetition} />} />
                    }
                    {!event.leaderboard &&
                        <Route path={`${parentUrl}/schedule`} render={() => <Schedule event={event} />} />
                    }
                    <Route path={`${parentUrl}/standings`} render={() => <Standings event={event} />} />
                </Switch>
            </div>
        );
    }

    private readonly landingPageLink = <a className={this.props.classes.linkBlue} href={Urls.landingPageLink}
        rel='noopener noreferrer'>Learn more</a>;

    private readonly eventsInvitationInfoElement = <a className={this.props.classes.linkBlue}
        href={Urls.eventInvitationInfoLink} rel='noopener noreferrer'>Learn more</a>;

    private readonly alertFragment =
        <React.Fragment>
            <span className={this.props.classes.spanStyle}>Trying to join an event? Please open the link on your phone. </span>
            <br />
            <span className={this.props.classes.spanStyle}>{this.eventsInvitationInfoElement}&nbsp;about Events invites in Golf Pad.</span>
        </React.Fragment>;

    render() {
        if (!!this.state.updateServiceWorker) {
            this.state.updateServiceWorker();
        }
        const { eventId } = this.state;
        if (eventId === undefined) {
            return <NoEvent text="Loading" />;
        }
        if (eventId === '') {
            const { match } = this.props;
            const err = `Event with id "${match.params.id}" not found!`;
            return <ErrorPage error={err} errorInfo={""} />;
        }
        const { event, portal, eventNotificationShown } = this.state;
        if (event != null) {
            if (event.handicapSystem === 'CONGU') {
                event.handicapSystem = 'WHS_UK';
            } else if (event.handicapSystem === 'GA') {
                event.handicapSystem = 'WHS_AU';
            } else if (event.handicapSystem !== 'WHS' && event.handicapSystem !== 'WHS_UK' && event.handicapSystem !== 'WHS_AU' && event.handicapSystem !== 'SMPLFD') {
                event.handicapSystem = 'WHS';
            }
        }
        const { classes } = this.props;
        let content;
        if (event && portal && event.exists) {
            content = !event.deleted ? <this.renderEvent event={event} portal={portal} /> :
                <div className={classes.midScreenRoot}>
                    <h1>The event you are trying to access has been deleted.</h1>
                    <br />
                    <p>Click here to {this.landingPageLink} about Golf Pad Events.</p>
                </div>;
        } else {
            content = <NoEvent text="Loading" />;
        }
        if (!event?.leaderboard && !eventNotificationShown && this.fromInvite) {
            showAlert(this.alertFragment, [{ title: 'CLOSE', color: 'primary', action: () => this.setState({ eventNotificationShown: true }) },]);
        }
        return (
            <React.Fragment>
                {content}
                <FirebaseDocComponent onDoc={doc => this.setEvent(Backend.fromEntity<Event>(doc))} docReference={Backend.eventFields(eventId)} />
                <FirebaseDocComponent onDoc={doc => this.setPortal(Backend.fromEntity<Portal>(doc))} docReference={Backend.portalFields(eventId)} />
                <FirebaseDataComponent query={Backend.query(Backend.competitionsDb(eventId), Backend.orderBy('order'))} onData={this.onCompetitions} />
            </React.Fragment>
        );
    }
});

export default withDataItemFromUrl(Backend.eventMappingDb)(withStyles(styles)(Public));
