import * as React from 'react';
import {
    Typography, DialogActions, DialogContent, InputLabel, IconButton, Checkbox, Select, MenuItem,
    FormControlLabel, FormHelperText, FormControl, MenuProps, useMediaQuery, useTheme
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import {
    Competition, Event, Contact, ScoringMode, ScoringFormat, Tee, Team, GolferGroup, ScoringFormatIndividual, ScoringFormatTeams, ScoringFormatDistance, ScoringFormatSkins, PayoutSettings,
    isMainScoring, isDistanceScoring, isSkinsScoring, isTeamScoring, isIndividualScoring, isTeamFormat, isNetMode, isGrossMode, isCompatibleScores, getTee, getParticipantsCount, getPayoutSettings,
    MENS_TEE, WOMENS_TEE, getEventMainCompetition, isCompatibleCompetition, PayoutMethod, isStablefordScoring, ScoringType, isTeamFormatExceptBB, appScoringStarted, ScoringTeamSize
} from '../../../types/EventTypes';
import { getHolesName, hasTees, hasMen, hasWomen, initHandicaps, copyCompetition, genderFromEvent, isDuplicatedCompetition } from '../../Event';
import { showAlert, replaceUrl, NoticeElement } from '../../../redux/ReduxConfig';
import { XSMobileDialog } from '../../../common/dialog/MobileDialog';
import { SettingsIcon, InfoIcon } from '../../../common/Icons';
import DialogAppBar from '../../../common/dialog/DialogAppBar';
import AppButton from '../../../common/components/AppButton';
import TypedFormRadioLabel from '../../../common/form/TypedFormRadioLabel';
import SelectTeesComponent from '../common/SelectTeesComponent';
import SelectHolesDialog from '../../tabs/common/SelectHolesDialog';
import { equalArrays, makeFriendlyString, withS } from '../../../util/utility';
import { getTeeName } from '../../../scoring/handicap';
import { ListElem, Container, Item } from '../../../common/Misc';
import { FirebaseDataComponent } from '../../../common/WithData';
import { styles } from '../../../styles';
import { getGolferInfo } from '../../EventFormat';
import * as Backend from '../../../util/firebase';
import * as Scoring from '../../../scoring/scoring';
import ParticipantsDialog from './ParticipantsDialog';
import StablefordPointSettingsDialog from './StablefordPointSettingsDialog';
import CompetitionPayoutSettingsDialog, { getParticipantsName, updatePayouts } from './CompetitionPayoutSettingsDialog';
import CompetitionFlightsSettingsDialog from './CompetitionFlightsSettingsDialog';
import CompetitionNameDialog from './CompetitionNameDialog';

export const defaultTeeExplanation = 'Select the default tees here. You can always override tees for individual golfers from the Golfers / List.';
const flightExplanation = 'Flights are a series of smaller competitions within a golf tournament. A flight is a grouping of golfers who are competing ' +
    'against one another, instead of the entire field. Each flight is typically made up of golfers of similar scoring ability.';
const carryoversUncheckedExplanation = 'Unchecked without carryovers means any tied holes are not awarded. Winners are holes won by one golfer, if payouts are used the skins purse will be divided amongst the number of winners. ';
const carryoversCheckedExplanation = 'Checked carryovers means any tied holes will be carried over, and accumulate to the next hole won. If payouts are used the skins purse will be divided amongst the number of holes played, with each hole having the same value.';
const listItemSymbolComponent = <span style={{ fontSize: "1.1rem" }}>•</span>;
const skinsPayoutExplanation: React.ReactNode = <span>{listItemSymbolComponent}&nbsp;{carryoversUncheckedExplanation}<br />{listItemSymbolComponent}&nbsp;{carryoversCheckedExplanation}</span>;

const PERCENTS = function() {
    const percents: Array<number> = [];
    for (let i = 0; i <= 100; i += 5) {
        percents.push(i);
        if (i === 30) {
            percents.push(33);
        }
        if (i === 90) {
            percents.push(93);
        }
    }
    return percents;
}();

const menuProps: Partial<MenuProps> = {
    style: {
        marginTop: 10
    },
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "left"
    },
    PaperProps: {
        style: {
            height: "185px",
            overflowY: "scroll"
        }
    }
};

export function HandicapPercentSelector(props: { value: number, index: number, handlePercentsChange: (num: number, val: number) => void, individual?: boolean, teamSize?: ScoringTeamSize }) {
    const { value, index, handlePercentsChange, individual, teamSize } = props;
    const ABC = ['A', 'B', 'C', 'D'];
    const labelId = 'select-hcp-percent-' + index;
    const label = individual ? (teamSize ? 'Handicap Allowance' : 'Use') : (ABC[index]) + ' player';
    const isXs = useMediaQuery(useTheme().breakpoints.down('sm'));
    const minWidth = teamSize ? (teamSize > 1 ? 73 : isXs ? 150 : 200) : 80;
    const margin = teamSize ? `8px ${index === teamSize - 1 ? 0 : 10}px 8px 0` : 8;
    return (
        <FormControl variant="standard" style={{ minWidth, margin }}>
            <InputLabel shrink id={labelId}>
                {label}
            </InputLabel>
            <Select
                variant="standard"
                MenuProps={menuProps}
                labelId={labelId}
                id={labelId + 'menu'}
                value={value}
                onChange={uiEvent => handlePercentsChange(index, parseInt(uiEvent.target.value as string, 10))}>
                {PERCENTS.map(p => <MenuItem key={p} value={p}>{p + '%'}</MenuItem>)}
            </Select>
            {individual && <FormHelperText>of course handicap</FormHelperText>}
        </FormControl>
    );
}

type Props = {
    open: boolean;
    hasScores: boolean;
    isNewCompetition?: boolean;
    event: Event;
    competition: Competition;
    competitions: Array<Competition>;
    golfers: Map<string, Contact>;
    teams: Map<string, Team>;
    handleSave: (competition: Competition, competitionOld: Competition, resetScores: boolean, resetDistance: boolean, changes: Map<string, string>) => void;
    handleClose: () => void;
    handleDelete: (competition: Competition) => void;
    mainCompetition?: Competition;
} & WithStyles<typeof styles>;

type State = {
    competition: Competition;
    selectingParticipants?: boolean;
    editPayout?: PayoutSettings;
    editPayoutMode?: string;
    editFlights?: boolean;
    selectingHoles?: boolean;
    selectingTees?: boolean;
    handicapsChanged: boolean;
    golferTeeMess: boolean;
    showPointSettings?: boolean;
    selectingName?: boolean;
    teeMessMessage: string;
    teams: Map<string, Team>;
    groups: Array<GolferGroup>;
    changes: Map<string, string>;
    golfers: Map<string, Contact>;
};

class CompetitionSettingsDialog extends React.Component<Props, State> {
    state: State = {
        competition: copyCompetition(this.props.competition),
        handicapsChanged: !this.props.isNewCompetition,
        golferTeeMess: false,
        teeMessMessage: '',
        groups: [],
        teams: new Map<string, Team>(),
        changes: new Map<string, string>(),
        golfers: this.props.golfers
    };

    componentDidMount() {
        this.checkTeeMess();
    }

    private onTeams = (teams: Map<string, Team>) => this.setState({ teams }, this.checkTeeMess);
    private onGroups = (groups: Array<GolferGroup>) => this.setState({ groups }, this.checkTeeMess);
    private onGolfers = (golfers: Map<string, Contact>) => this.setState({ golfers }, this.checkTeeMess);
    private handleSelectHoles = () => this.setState({ selectingHoles: true });
    private handleHolesCancel = () => this.setState({ selectingHoles: false });

    private checkTeeMess = () => {
        const { event, competitions } = this.props;
        const { competition, teams, groups, golfers } = this.state;
        const golferInfo = getGolferInfo(event, competitions, golfers, teams, groups, competition);
        let teeMess = golferInfo.isGolferTeeMees();
        let teeMessMessage = golferInfo.teeConflictMessage();
        if (!teeMess && competition.everyone && !!competition.tees) {
            const skinsMixed = !!competitions.find(comp => comp.scoring.format === 'best_ball');
            const menTee = competition.tees[MENS_TEE];
            const womenTee = competition.tees[WOMENS_TEE];
            if (menTee && !!golferInfo.allMenTee && golferInfo.allMenTee.key!.id !== menTee.id) {
                teeMess = true;
                teeMessMessage = golferInfo.teeWarningMessage(Scoring.scoringName(golferInfo.allMenTee.value!, event.eventGender, competition.competitionGender, skinsMixed));
            } else if (womenTee && !!golferInfo.allWomenTee && golferInfo.allWomenTee.key!.id !== womenTee.id) {
                teeMess = true;
                teeMessMessage = golferInfo.teeWarningMessage(Scoring.scoringName(golferInfo.allWomenTee.value!, event.eventGender, competition.competitionGender, skinsMixed));
            }
        }
        this.setState({ golferTeeMess: teeMess, teeMessMessage: teeMessMessage });
    }

    private handleNetPayoutChange = (checked: boolean, setUndefined: boolean) => {
        const { competition, changes } = this.state;
        if (checked) {
            if (!competition.payoutsNet[0]) {
                competition.payoutsNet[0] = {
                    enabled: true,
                    purseType: isSkinsScoring(competition.scoring) ? 'valuePerSkin' : 'fixed',
                    payoutMethod: 'divided'
                } as PayoutSettings;
            } else {
                competition.payoutsNet[0].enabled = true;
            }
        } else {
            if (competition.payoutsNet[0]) {
                competition.payoutsNet[0].enabled = false;
            }
        }
        changes.set('payouts net', checked ? 'enabled' : 'disabled');
        this.setState({ competition, editPayoutMode: setUndefined ? undefined : 'net' });
    };

    private handleGrossPayoutChange = (checked: boolean, setUndefined: boolean) => {
        const { competition, changes } = this.state;
        if (checked) {
            if (!competition.payoutsGross[0]) {
                competition.payoutsGross[0] = {
                    enabled: true,
                    purseType: isSkinsScoring(competition.scoring) ? 'valuePerSkin' : 'fixed',
                    payoutMethod: 'divided'
                } as PayoutSettings;
            } else {
                competition.payoutsGross[0].enabled = true;
            }
        } else {
            if (competition.payoutsGross[0]) {
                competition.payoutsGross[0].enabled = false;
            }
        }
        changes.set('payouts gross', checked ? 'enabled' : 'disabled');
        this.setState({ competition, editPayoutMode: setUndefined ? undefined : 'gross' });
    };

    private handleFlightsChange = (checked: boolean) => {
        const { competition } = this.state;
        if (!checked) {
            competition.flights = undefined;
        }
        this.setState({ competition, editFlights: checked });
    }

    private handleDeleteFlights = () => {
        const { competition } = this.state;
        competition.flights = undefined;
        this.setState({ competition, editFlights: false });
    }

    private handleHandicapChange = (val: string) => {
        const { event } = this.props;
        const { competition, handicapsChanged, changes } = this.state;
        competition.scoring.mode = val as ScoringMode;
        changes.set('mode', val);
        initHandicaps(competition, isIndividualScoring(competition.scoring) ? 1 : event.teamSize, handicapsChanged);
        this.setState({ competition });
    }

    private handleHolesOk = (selectedHoles: Array<number>) => {
        const { competition, changes } = this.state;
        const { golfers, teams } = this.props;
        if (competition.scoring.holes !== selectedHoles && (isDistanceScoring(competition.scoring) || isSkinsScoring(competition.scoring)) && (competition.payoutsGross[0] || competition.payoutsNet[0])) {
            if (competition.payoutsGross[0]) {
                updatePayouts(competition.payoutsGross[0], getParticipantsCount(competition, golfers, teams), selectedHoles.length);
            }
            if (competition.payoutsNet[0]) {
                updatePayouts(competition.payoutsNet[0], getParticipantsCount(competition, golfers, teams), selectedHoles.length);
            }
            changes.set('holes', 'changed');
        }
        competition.scoring.holes = selectedHoles;
        this.setState({ competition, selectingHoles: false, changes });
    }

    private handleFormatChange = (uiEvent: any) => {
        if (!uiEvent.target.value) {
            return;
        }
        const { event } = this.props;
        const { competition, handicapsChanged, changes } = this.state;
        competition.scoring.format = uiEvent.target.value as ScoringFormat;
        delete competition.scoring.holes;
        changes.set('format', uiEvent.target.value);
        initHandicaps(competition, isIndividualScoring(competition.scoring) ? 1 : event.teamSize, handicapsChanged);
        this.setState({ competition });
    }

    private handleHandicapPercentsChange = (num: number, val: number) => {
        const { event } = this.props;
        const { competition, handicapsChanged, changes } = this.state;
        if (competition.scoring.handicaps![num] !== val) {
            changes.set('handicapPercents' + num, String(val));
            initHandicaps(competition, isIndividualScoring(competition.scoring) ? 1 : event.teamSize, handicapsChanged);
            competition.scoring.handicaps![num] = val;
            this.setState({ competition, handicapsChanged: true });
        }
    }

    private handleScoringModeChanged = (val: ScoringType) => {
        const { competition } = this.state;
        if (val === ScoringFormatIndividual.strokeplay && competition.scoring.stablefordMode) {
            competition.scoring.stablefordMode = false;
            this.setState({ competition });
        } else if (val === ScoringFormatIndividual.stableford && !competition.scoring.stablefordMode) {
            competition.scoring.stablefordMode = true;
            this.setState({ competition });
        }
    }

    private setCompetitionName = (name: string) => {
        const { competition } = this.state;
        competition.name = name;
        this.setState({ selectingName: false, competition });
    }

    private handleClose = () => {
        this.props.handleClose();
    }

    private handleSave = () => {
        const { competition: competitionOld, hasScores, isNewCompetition, competitions, handleSave, mainCompetition, event } = this.props;
        const { competition, changes, teams, golfers } = this.state;
        const isDistance = isDistanceScoring(competitionOld.scoring);
        if (competitionOld.id && hasScores && !isCompatibleScores(competitionOld.scoring, competition.scoring, mainCompetition)) {
            showAlert('Some scores were entered using the existing format and can\'t be used for the new format. This will reset the existing scores.', [
                { title: 'Proceed', action: () => handleSave(competition, competitionOld, !isDistance, isDistance, changes) },
                { title: 'Cancel' }
            ]);
        } else if (isNewCompetition && isDuplicatedCompetition(competition, competitions)) {
            showAlert('It appears this is a duplicate competition. Do you want to add a new competition, with the same settings?', [
                { title: 'Cancel' },
                {
                    title: 'Proceed',
                    color: 'secondary',
                    action: () => handleSave(competition, competitionOld, false, false, changes)
                }
            ]);
        } else if (appScoringStarted(golfers, teams, event.appCompetition, mainCompetition) && (
            (competitionOld.scoring.format === ScoringFormatTeams.best_ball && isTeamFormatExceptBB(competition.scoring, mainCompetition)) ||
            (competition.scoring.format === ScoringFormatTeams.best_ball && isTeamFormatExceptBB(competitionOld.scoring, mainCompetition)))) {
            showAlert('Event round has been already started for some golfers. Changing competition format will effect the scores posting ability from the Golf Pad app round.' +
                ' Are you sure you want to save new competition format?',
                [
                    { title: 'Cancel' },
                    { title: 'Proceed', color: 'secondary', action: () => handleSave(competition, competitionOld, false, false, changes) },
                ]);
        } else {
            handleSave(competition, competitionOld, false, false, changes);
        }
    }

    private TeesSettings = () => {
        const { classes, event } = this.props;
        const { competition, selectingTees } = this.state;
        const { tees } = competition;
        const men = hasMen(event, competition);
        const women = hasWomen(event, competition);
        return (
            <React.Fragment>
                <FormControl
                    variant="standard"
                    margin="dense"
                    fullWidth
                    style={{ flexDirection: 'row' }}>
                    <InputLabel margin="dense" style={{ top: '-10px' }} shrink>Default tees {NoticeElement(defaultTeeExplanation)}</InputLabel>
                    <FormControlLabel className={classes.formSelector} control={<Container>
                        {men && <Item margin="dense" paddingRight="10px" ><ListElem title={'Men'} className={!(tees && tees[MENS_TEE]) ? classes.redText : ''} subtitle={tees && tees[MENS_TEE] ? getTeeName(tees[MENS_TEE], event.holesType) : 'Not selected'} /></Item>}
                        {women && <Item margin="dense" paddingRight="10px" ><ListElem title={'Women'} className={!(tees && tees[WOMENS_TEE]) ? classes.redText : ''} subtitle={tees && tees[WOMENS_TEE] ? getTeeName(tees[WOMENS_TEE], event.holesType) : 'Not selected'} /></Item>}
                        <Item><AppButton color="info" onClick={() => this.setState({ selectingTees: true })}>Select</AppButton></Item>
                    </Container>} label="" />
                </FormControl>
                {selectingTees && <SelectTeesComponent variant="dialog" open={true}
                    onClose={() => this.setState({ selectingTees: false })}
                    onSelectTees={this.onSelectTees}
                    competition={competition}
                    event={event} />}
            </React.Fragment>
        );
    }

    private onSelectTees = (tees: Array<Tee | null>) => {
        const { competition, changes } = this.state;
        const menTee = tees[MENS_TEE];
        const womenTee = tees[WOMENS_TEE];
        competition.tees = [...tees];
        changes.set('tees', (menTee ? 'Men: ' + menTee.name : '') + (womenTee ? ' Women: ' + womenTee.name : ''));
        this.setState({ competition, selectingTees: false }, this.checkTeeMess);
    }

    private onParticipantsChanged = (competition: Competition) => {
        const { changes } = this.state;
        changes.set('participants', (competition.everyone ? 'All ' : competition.contactIds ? competition.contactIds.length : 0) + (competition.competitionGender && competition.everyone && competition.competitionGender !== 'both' ? competition.competitionGender : ''));
        this.setState({ competition, selectingParticipants: false }, this.checkTeeMess)
    }

    private onFlightsChanged = (competition: Competition) => {
        const { changes } = this.state;
        changes.set('flights', String(competition.flights));
        this.setState({ competition, editFlights: false }, this.checkTeeMess)
    }

    private onPayoutsChanged = (payoutSettings: PayoutSettings) => {
        const { competition, editPayoutMode, changes } = this.state;
        if (editPayoutMode === undefined) {
            competition.payoutsNet[0] = payoutSettings;
            competition.payoutsGross[0] = payoutSettings;
        } else if (editPayoutMode === 'gross') {
            competition.payoutsGross[0] = payoutSettings;
        } else if (editPayoutMode === 'net') {
            competition.payoutsNet[0] = payoutSettings;
        }
        changes.set('payouts', 'changed');
        this.setState({ competition, editPayout: undefined, changes: changes });
    }

    private onPayoutMethodChange = (allPayoutSettings: PayoutSettings[], payoutMethod: PayoutMethod) => {
        const { competition } = this.state;
        for (const payoutSettings of allPayoutSettings) {
            payoutSettings.payoutMethod = payoutMethod;
        }
        this.setState({ competition });
    }

    private setNewCompetitionPayouts = (competition: Competition, net: boolean, isSkins: boolean) => {
        if (net) {
            competition.payoutsNet[0] = { enabled: false, purseType: isSkins ? 'valuePerSkin' : 'fixed', payoutMethod: 'divided' } as PayoutSettings;
            return competition.payoutsNet[0];
        } else {
            competition.payoutsGross[0] = { enabled: false, purseType: isSkins ? 'valuePerSkin' : 'fixed', payoutMethod: 'divided' } as PayoutSettings;
            return competition.payoutsGross[0];
        }
    };

    private getActualPayoutSettings = (competition: Competition) => {
        const isNet = isNetMode(competition.scoring.mode);
        const isGross = isGrossMode(competition.scoring.mode);
        const isSkins = isSkinsScoring(competition.scoring);
        if (isNet && isGross) {
            return [
                competition.payoutsNet[0] ?? this.setNewCompetitionPayouts(competition, true, isSkins),
                competition.payoutsGross[0] ?? this.setNewCompetitionPayouts(competition, false, isSkins)
            ];
        } else {
            return [(isNet) ? competition.payoutsNet[0] ?? this.setNewCompetitionPayouts(competition, true, isSkins)
                : (isGross) ? competition.payoutsGross[0] ?? this.setNewCompetitionPayouts(competition, false, isSkins)
                    : { enabled: false, purseType: isSkins ? 'valuePerSkin' : 'fixed', payoutMethod: 'divided' } as PayoutSettings];
        }
    };

    private savePoints = (stablefordPoints: Array<number>) => {
        const { competition } = this.state;
        competition.scoring.mstablefordPoints = stablefordPoints;
        if (competition.scoring.format === ScoringFormatIndividual.stableford && stablefordPoints?.length &&
            !equalArrays(stablefordPoints, Scoring.defaultStablefordPoints)) {
            competition.scoring.format = ScoringFormatIndividual.modified_stableford;
        }
        this.setState({ showPointSettings: false, competition });
    };

    private SkinsAwarded = () => {
        const { classes } = this.props;
        const { competition } = this.state;
        const actualPayoutSettings: PayoutSettings[] = this.getActualPayoutSettings(competition);
        if (actualPayoutSettings.length < 1) {
            return null;
        }
        return (
            <div>
                <FormControl variant="standard">
                    <InputLabel shrink style={{ marginTop: 5 }}>Skins awarded {NoticeElement(skinsPayoutExplanation)}</InputLabel>
                    <FormControlLabel className={classes.formSelector} control={<Container style={{ marginTop: 16 }}>
                        <Item><Checkbox color="secondary" className={classes.marginBottom3} onChange={(e, checked) => {
                            e.stopPropagation();
                            this.onPayoutMethodChange(actualPayoutSettings, checked ? 'carryovers' : 'divided');
                        }} checked={actualPayoutSettings[0].payoutMethod === 'carryovers'} /></Item>
                        <Item><ListElem title={'Carryover tied holes'} subtitle="" /></Item>
                    </Container>} label="" />
                </FormControl>
            </div>
        );
    };

    private Holes = () => {
        const { event, mainCompetition } = this.props;
        const { competition } = this.state;
        const gender = genderFromEvent(event);
        if (!mainCompetition) {
            return null;
        }
        return (
            <React.Fragment>
                <FormControl
                    variant="standard"
                    margin="dense"
                    fullWidth
                    style={{ flexDirection: 'row' }}>
                    <InputLabel shrink>Holes</InputLabel>
                </FormControl>
                <InputLabel shrink style={{ display: 'unset' }}>{getHolesName(event, competition, getTee(mainCompetition, gender))}&nbsp;&nbsp;&nbsp;
                    <AppButton color="info" onClick={this.handleSelectHoles}>Select</AppButton>
                </InputLabel>
            </React.Fragment>
        );
    }

    private Participants = () => {
        const { classes, event, golfers, teams } = this.props;
        const { competition, selectingParticipants, editFlights } = this.state;
        const participantsCount = getParticipantsCount(competition, golfers, teams);
        const golfersLabel = getParticipantsName(isTeamFormat(competition.scoring), participantsCount);
        const participants =
            !competition.everyone ? golfersLabel :
                competition.competitionGender === 'men' ? `All men (${golfersLabel})` :
                    competition.competitionGender === 'women' ? `All women (${golfersLabel})` :
                        `Everyone (${golfersLabel})`;
        const flights = withS(competition.flights || 1, 'flight');
        const isMain = isTeamScoring(competition.scoring) || isIndividualScoring(competition.scoring);
        const allHandicapIndexesPresent = competition.scoring.mode === 'gross' ? true : Array.from(golfers.values()).every(contact => contact.handicapIndex != null);
        return (
            <React.Fragment>
                <FormControl
                    variant="standard"
                    margin="dense"
                    fullWidth
                    style={{ flexDirection: 'row' }}>
                    <InputLabel shrink>Participants</InputLabel>
                    <FormControlLabel className={classes.formSelector} control={<Container>
                        <Item><ListElem title={participants} subtitle="" /></Item>
                        <Item><AppButton style={{ marginLeft: '1em' }} color="info" onClick={() => this.setState({ selectingParticipants: true })}>Change</AppButton></Item>
                    </Container>} label="" />
                </FormControl>
                {!allHandicapIndexesPresent && <FormControl variant="standard" margin="dense">
                    <FormControlLabel className={classes.missingHandicapsContainer} control={<Container>
                        <Item><Typography className={classes.missingHandicapsBase + ' ' + classes.missingHandicapsAlert}>Some golfers are missing handicap for this net competition </Typography></Item>
                        <Item><Typography className={`${classes.linkBluePointer} ${classes.missingHandicapsBase} ${classes.missingHandicapsEdit}`} onClick={() => replaceUrl(`/events/${event.id}/golfers/list`)}>Edit</Typography></Item>
                    </Container>} label="" />
                </FormControl>}
                {isMain && <FormControl
                    variant="standard"
                    margin="dense"
                    fullWidth
                    style={{ marginTop: -15, flexDirection: 'row' }}>
                    <FormControlLabel className={classes.formSelector} control={<Container>
                        <Item><Checkbox color="secondary" className={classes.marginBottom3} onChange={(e, v) => { e.stopPropagation(); this.handleFlightsChange(v); }} checked={!!competition.flights || false} /></Item>
                        <Item><ListElem title='Split into flights by handicap index' subtitle="" /></Item>
                        <Item marginLeft={4}>{NoticeElement(flightExplanation)}</Item>
                    </Container>} label="" />
                </FormControl>}
                {isMain && !!competition.flights && <FormControl
                    variant="standard"
                    margin="dense"
                    fullWidth
                    style={{ marginTop: -15, marginBottom: 10, flexDirection: 'row' }}>
                    <FormControlLabel className={classes.formSelector} control={<Container>
                        <Item><ListElem title={flights} subtitle="" /></Item>
                        <Item><AppButton style={{ marginLeft: '1em' }} color="info" onClick={() => this.setState({ editFlights: true })}>Edit</AppButton></Item>
                    </Container>} label="" />
                </FormControl>}
                {editFlights && <CompetitionFlightsSettingsDialog
                    open={true}
                    competition={competition}
                    golfers={golfers}
                    teams={teams}
                    event={event}
                    handleDelete={this.handleDeleteFlights}
                    handleSave={this.onFlightsChanged}
                    onClose={() => this.setState({ editFlights: false })} />}
                {selectingParticipants && <ParticipantsDialog
                    open={true}
                    event={event}
                    competition={competition}
                    teams={teams}
                    golfers={golfers}
                    handleSave={this.onParticipantsChanged}
                    onClose={() => this.setState({ selectingParticipants: false })} />}
            </React.Fragment>
        );
    }

    private PayoutSettings = () => {
        const { classes, golfers, teams, event } = this.props;
        const { editPayout, competition } = this.state;
        const isNet = isNetMode(competition.scoring.mode);
        const isGross = isGrossMode(competition.scoring.mode);
        const { payoutGrossSettings, payoutNetSettings, payoutSettings } = getPayoutSettings(competition);
        return (
            <React.Fragment>
                <FormControl
                    variant="standard"
                    margin="dense"
                    fullWidth
                    style={{ flexDirection: 'row' }}>
                    <InputLabel shrink>Payouts</InputLabel>
                    {(!isNet || !isGross) && <FormControlLabel className={classes.formSelector} control={<Container>
                        <Item><Checkbox color="secondary" className={classes.marginBottom3} onChange={(e, v) => { e.stopPropagation(); isGross ? this.handleGrossPayoutChange(v, true) : this.handleNetPayoutChange(v, true); }} checked={!!payoutSettings && payoutSettings.enabled} /></Item>
                        <Item><ListElem title={isNet && isGross ? 'Net payouts' : 'Calculate payouts'} subtitle="" /></Item>
                        <Item><AppButton style={{ marginLeft: '1em' }} disabled={!payoutSettings || !payoutSettings.enabled} color="info" onClick={() => this.setState({ editPayout: payoutSettings, editPayoutMode: undefined })}>Edit</AppButton></Item>
                    </Container>} label="" />}
                    {isNet && isGross && <FormControlLabel className={classes.formSelector} control={<Container>
                        <Item><Checkbox color="secondary" className={classes.marginBottom3} onChange={(e, v) => { e.stopPropagation(); this.handleGrossPayoutChange(v, false); }} checked={!!payoutGrossSettings && payoutGrossSettings.enabled} /></Item>
                        <Item><ListElem title={isNet && isGross ? 'Gross payouts' : 'Calculate payouts'} subtitle="" /></Item>
                        <Item><AppButton style={{ marginLeft: '1em' }} disabled={!payoutGrossSettings || !payoutGrossSettings.enabled} color="info" onClick={() => this.setState({ editPayout: payoutGrossSettings, editPayoutMode: 'gross' })}>Edit</AppButton></Item>
                    </Container>} label="" />}
                    {isNet && isGross && <FormControlLabel className={classes.formSelector} control={<Container>
                        <Item><Checkbox color="secondary" className={classes.marginBottom3} onChange={(e, v) => { e.stopPropagation(); this.handleNetPayoutChange(v, false); }} checked={!!payoutNetSettings && payoutNetSettings.enabled} /></Item>
                        <Item><ListElem title={isNet && isGross ? 'Net payouts' : 'Calculate payouts'} subtitle="" /></Item>
                        <Item><AppButton style={{ marginLeft: '1em' }} disabled={!payoutNetSettings || !payoutNetSettings.enabled} color="info" onClick={() => this.setState({ editPayout: payoutNetSettings, editPayoutMode: 'net' })}>Edit</AppButton></Item>
                    </Container>} label="" />}
                </FormControl>
                {(editPayout) && <CompetitionPayoutSettingsDialog
                    event={event}
                    open={true}
                    participantCount={getParticipantsCount(competition, golfers, teams)}
                    isTeam={isTeamScoring(competition.scoring)}
                    isSkins={isSkinsScoring(competition.scoring)}
                    payoutSettings={editPayout!}
                    competition={competition}
                    handleSave={this.onPayoutsChanged}
                    onClose={() => this.setState({ editPayout: undefined })} />}
            </React.Fragment>
        );
    };

    render() {
        const { classes, competitions, event, isNewCompetition } = this.props;
        const { competition, selectingHoles, golferTeeMess, teeMessMessage, showPointSettings, selectingName } = this.state;
        const isMain = isMainScoring(competition.scoring);
        const isSkins = isSkinsScoring(competition.scoring);
        const isDistance = isDistanceScoring(competition.scoring);
        const isTeam = isTeamScoring(competition.scoring);
        const competitionsMain = competitions.filter(comp => isMainScoring(comp.scoring));
        let mainCompetition = getEventMainCompetition(competitionsMain);
        const scoringMode = competition.scoring.stablefordMode ? 'stableford' : 'strokeplay';
        const title = isMain ? 'Competition settings' : 'Side game settings';
        let individualFormats: Array<{ format: string, label: string }>;
        let teamFormats: Array<{ format: string, label: string }> = [];
        if (isMain) {
            individualFormats = Object.keys(ScoringFormatIndividual).filter(format => format !== ScoringFormatIndividual.modified_stableford)
                .map(f => { return { format: f, label: makeFriendlyString(f, true) }; });
            individualFormats.push({ format: ScoringFormatSkins.skins_individual, label: makeFriendlyString(ScoringFormatSkins.skins_individual, true) });
            teamFormats = Object.keys(ScoringFormatTeams).map(f => { return { format: f, label: makeFriendlyString(f, true) }; });
            teamFormats.push({ format: ScoringFormatSkins.skins_team, label: makeFriendlyString(ScoringFormatSkins.skins_team, true) });
        } else {
            individualFormats = Object.keys(ScoringFormatDistance).map(f => { return { format: f, label: makeFriendlyString(f, true) }; });
        }
        const handicapUserNum = isDistance || !isNetMode(competition.scoring.mode) ? 0 : isIndividualScoring(competition.scoring) ? 1 : event.teamSize;
        const incompatible = !isCompatibleCompetition(competition, competitions, event.teamSize);
        const doesntFit = incompatible ||
            (isMain && !hasTees(event, competition)) ||
            (isDistance && (!competition.scoring.holes || competition.scoring.holes.length === 0)) ||
            (isSkins && (competition.scoring.holes && competition.scoring.holes.length === 0));
        const gender = genderFromEvent(event);
        const skinsMixed = !!competitions.find(comp => comp.scoring.format === ScoringFormatTeams.best_ball);
        const competitionName = Scoring.scoringName(competition, event.eventGender, competition.competitionGender, skinsMixed);
        const competitionsWithoutCurrent = competitions.filter(c => c.id !== competition.id);
        const individualsNotAvailable = isMain && !isCompatibleCompetition({ scoring: { format: ScoringFormatIndividual.strokeplay } } as Competition, competitionsWithoutCurrent, event.teamSize);
        if (mainCompetition?.id === competition?.id) {
            mainCompetition = getEventMainCompetition(competitionsMain.filter(comp => comp.id !== competition.id));
        }
        const format = competition.scoring.format === ScoringFormatIndividual.modified_stableford ?
            ScoringFormatIndividual.stableford : competition.scoring.format;
        return (
            <React.Fragment>
                <XSMobileDialog open={this.props.open} onClose={this.handleClose}>
                    <DialogAppBar label={title} close={this.handleClose} />
                    <DialogContent>
                        <FormControl
                            variant="standard"
                            style={{ paddingTop: 8 }}
                            onClick={() => this.setState({ selectingName: true })}>
                            <InputLabel shrink htmlFor="competition-name">Competition name</InputLabel>
                            <Typography style={{ minWidth: 160, height: 20, marginTop: 12 }}>{competitionName}</Typography>
                        </FormControl>
                        <AppButton color="secondary" style={{ marginLeft: 8, marginTop: 12 }} onClick={() => this.setState({ selectingName: true })}>Change</AppButton>
                        <br />
                        <FormControl variant="standard">
                            <InputLabel shrink htmlFor="select-format-menu">Format</InputLabel>
                            <Select
                                variant="standard"
                                id="select-format-menu"
                                aria-describedby="select-helper-text"
                                value={format}
                                onChange={this.handleFormatChange}>
                                {isMain ? event.teamSize === 1 ? <span className={classes.individualOrTeam}>Individual</span>
                                    : <span className={classes.individualOrTeam}>Team{mainCompetition && <IconButton
                                    onClick={() =>
                                            showAlert(`${makeFriendlyString(Object.keys(ScoringFormatTeams).filter(f => f !== competition.scoring.format).concat(ScoringFormatSkins.skins_team).reduce((prev, cur) => `${prev}, ${cur}`), true)} 
                                            are not compatible with the existing ${makeFriendlyString(competition.scoring.format, true)} competition.`)}
                                    size="large"><InfoIcon style={{ transform: 'scale(0.65)' }} /></IconButton>}</span> : ''}
                                {event.teamSize > 1 ? teamFormats.map(f => <MenuItem key={f.format} value={f.format} disabled={!isCompatibleCompetition({ scoring: { format: f.format } } as Competition,
                                        isNewCompetition ? competitions : competitionsWithoutCurrent, event.teamSize)}>{f.label}</MenuItem>)
                                    : individualFormats.map(f => <MenuItem key={f.format} value={f.format} disabled={individualsNotAvailable}>{f.label}</MenuItem>)}
                                {isMain ? event.teamSize === 1 ? <div className={classes.individualOrTeam}>Team</div>
                                    : <span className={classes.individualOrTeam}>Individual{incompatible && <IconButton
                                    onClick={() => showAlert('Individual formats are compatible with Best Ball only.')}
                                    size="large"><InfoIcon style={{ transform: 'scale(0.65)' }} />
                                    </IconButton>}</span> : ''}
                                {isMain && event.teamSize === 1 && <span className={`${classes.paddingLeftRight16} ${classes.setUpTeams}`}><span className={`${classes.linkBluePointer}`} onClick={() => replaceUrl(`golfers/teams`)}>
                                    Set up teams</span> to enable team scoring</span>}
                                {event.teamSize > 1 ? individualFormats.map(f => <MenuItem key={f.format} value={f.format} disabled={individualsNotAvailable}>
                                    {f.label}</MenuItem>) : teamFormats.map(f => <MenuItem key={f.format} value={f.format} disabled={true}>{f.label}</MenuItem>)}
                            </Select>
                        </FormControl>
                        {isTeam &&
                            <FormControl
                                variant="standard"
                                margin="dense"
                                fullWidth
                                style={{ flexDirection: 'row', marginTop: 0 }}>
                                <TypedFormRadioLabel value="strokeplay" label="Strokeplay" currentValue={scoringMode}
                                                     handleChange={this.handleScoringModeChanged} className={classes.formSelector} />
                                <TypedFormRadioLabel value="stableford" label="Stableford" currentValue={scoringMode}
                                                     handleChange={this.handleScoringModeChanged} className={classes.formSelector} />
                                <AppButton color="info" onClick={() => this.setState({ showPointSettings: true })}
                                           disabled={scoringMode !== 'stableford'} style={{ marginTop: 16 }}><SettingsIcon /> Point Settings</AppButton>
                            </FormControl>}
                        {isStablefordScoring(competition.scoring) && <AppButton color="info" onClick={() => this.setState({ showPointSettings: true })}
                                       style={{ marginLeft: 16, marginTop: 16 }}><SettingsIcon /> Point Settings</AppButton>}
                        {!isDistance &&
                            <FormControl
                                variant="standard"
                                margin="dense"
                                fullWidth
                                style={{ flexDirection: 'row' }}>
                                <InputLabel shrink>Handicaps</InputLabel>
                                <TypedFormRadioLabel value="gross" label="Gross" currentValue={competition.scoring.mode}
                                    handleChange={this.handleHandicapChange} className={classes.formSelector} />
                                <TypedFormRadioLabel value="net" label="Net" currentValue={competition.scoring.mode}
                                    handleChange={this.handleHandicapChange} className={classes.formSelector} />
                                <TypedFormRadioLabel value="gross+net" label="Gross+Net" currentValue={competition.scoring.mode}
                                    handleChange={this.handleHandicapChange} className={classes.formSelector} />
                            </FormControl>}
                        {handicapUserNum > 0 && <HandicapPercentSelector value={competition.scoring.handicaps![0]} index={0} handlePercentsChange={this.handleHandicapPercentsChange} individual={handicapUserNum === 1} />}
                        {handicapUserNum > 1 && <HandicapPercentSelector value={competition.scoring.handicaps![1]} index={1} handlePercentsChange={this.handleHandicapPercentsChange} />}
                        {handicapUserNum > 2 && <HandicapPercentSelector value={competition.scoring.handicaps![2]} index={2} handlePercentsChange={this.handleHandicapPercentsChange} />}
                        {handicapUserNum > 3 && <HandicapPercentSelector value={competition.scoring.handicaps![3]} index={3} handlePercentsChange={this.handleHandicapPercentsChange} />}
                        {handicapUserNum > 0 &&
                            <FormControl variant="standard" style={{ minWidth: 20, margin: 8 }}>
                                <InputLabel margin="dense" shrink>{NoticeElement(<span>Golf Pad Events will default to the most commonly used Handicap Allowance percentages for team competitions.&nbsp;
                                    <a href={'https://support.golfpadgps.com/a/solutions/articles/6000266415'} target="_blank" rel="noopener noreferrer" className={classes.linkBluePointer}>Learn more</a></span>)}
                                </InputLabel>
                            </FormControl>}
                        {(isSkins) && <this.SkinsAwarded />}
                        {(isDistance || isSkins) && <this.Holes />}
                        <this.Participants />
                        {isMain && <this.TeesSettings />}
                        {golferTeeMess && isMain && <Typography color="error" variant="body1" style={{ margin: 16 }}>{teeMessMessage}</Typography>}
                        <this.PayoutSettings />
                    </DialogContent>
                    <DialogActions>
                        {competition.id && <AppButton color="info" onClick={() => this.props.handleDelete(competition)}>Delete</AppButton>}
                        {competition.id && <span style={{ flex: '1 1 0%' }} />}
                        <AppButton color="info" onClick={this.handleClose}>Cancel</AppButton>
                        <AppButton color="secondary" onClick={this.handleSave} disabled={doesntFit || golferTeeMess}>Save</AppButton>
                    </DialogActions>
                    {selectingHoles && <SelectHolesDialog
                        event={event}
                        tee={getTee(mainCompetition, gender)}
                        competition={competition}
                        handleOk={this.handleHolesOk}
                        handleCancel={this.handleHolesCancel} />}
                    {showPointSettings && <StablefordPointSettingsDialog
                        open={true}
                        event={event}
                        points={Scoring.getStablefordPoints(competition.scoring).slice()}
                        savePoints={this.savePoints}
                        onClose={() => this.setState({ showPointSettings: false })} />}
                    {selectingName && <CompetitionNameDialog
                        open
                        competition={competition}
                        competitions={competitions}
                        handleSave={this.setCompetitionName}
                        handleClose={() => this.setState({ selectingName: false })} />}
                </XSMobileDialog>
                <FirebaseDataComponent query={Backend.query(Backend.golferDb(event.id), Backend.where('hidden', '==', false))} onMap={this.onGolfers} />
                <FirebaseDataComponent query={Backend.golferTeamDb(event.id)} onMap={this.onTeams} />
                <FirebaseDataComponent query={Backend.query(Backend.golferGroupDb(event.id), Backend.orderBy('order'))} onData={this.onGroups} />
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(CompetitionSettingsDialog);
