import * as React from 'react';
import { List } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { firebaseAuth } from '../util/firebase';
import * as Backend from '../util/firebase';
import { Urls } from '../util/config';
import { withProgress } from '../util/ProgressPromise';
import { pushUrl } from '../redux/ReduxConfig';
import { emailFormat } from '../util/utility';
import LabeledField from '../common/form/LabeledField';
import EditTextField from '../common/form/EditTextField';
import { FirebaseUserDataComponent } from '../common/WithData';
import { email, required } from '../validation/ValidatedTextField';
import AppButton from '../common/components/AppButton';
import ButtonBar from '../common/components/ButtonBar';
import { UserAware, userProviderContextTypes } from './Auth';
import CryptoJS from 'crypto-js';
import { styles } from '../styles';
import { ListElem } from "../common/Misc";
import { PayPalSettingsDialog } from "../payments/paypal/PayPalSettingsDialog";
import { UserInfo } from "../types/EventTypes";

const UTF8 = CryptoJS.enc.Utf8;
const HEX = CryptoJS.enc.Hex;

type State = {
    account: string;
    email: string;
    oldEmail: string;
    name: string;
    oldName: string;
    hasError?: boolean;
    payPalSettingsOpened: boolean;
    payPalEmailAddress: string;
    payPalCurrencyCode: string;
};

export const remoteLogout = () => window.location.assign(Urls.logOut + '/' + UTF8.parse(window.location.origin + '/login').toString(HEX));

export const remoteLogin = () => {
    Backend.trackEvent('user_sign_in_start');
    window.location.assign(Urls.logIn + '/' + UTF8.parse(window.location.origin + '/sso').toString(HEX));
}

export const remoteSignup = () => {
    Backend.trackEvent('user_sign_up_start');
    window.location.assign(Urls.signUp + '/' + UTF8.parse(window.location.origin + '/sso').toString(HEX));
}

export function evalAccount(data: Backend.DocumentData) {
    if (!data || !data.loginId) {
        return 'Account is missing';
    }
    const loginType = data.loginId[0];
    switch (loginType) {
        case 'e': {
            return data.loginId.slice(2);
        }
        case 'a': {
            return 'Sign in with AppleID';
        }
        case 'g': {
            return 'Sign in with Google';
        }
        case 'f': {
            return 'Sign in with Facebook';
        }
        default: {
            return 'Sign in with Facebook';
        }
    }
}

async function updateAccountAndEvents(data: any) : Promise<void> {
    const userId = firebaseAuth.currentUser!.uid;
    const eventSnaps = await Backend.getDocs(Backend.query(Backend.eventsDb, Backend.where('userId', '==', userId)));
    return Backend.withTransaction(async transaction => {
        transaction.update(Backend.userFields(userId), data);
        const eventData = {} as any;
        if (data.email) {
            eventData.userEmail = data.email;
        }
        if (data.name) {
            eventData.userName = data.name;
        }
        eventSnaps.forEach(eventRef => transaction.update(eventRef.ref, eventData));
    });
}

type UpdateData = {
    newName?: string;
    newEmail?: string;
    newPayPalEmailAddress?: string;
    newPayPalCurrencyCode?: String;
};

class Account extends React.Component<WithStyles<typeof styles>, State> {

    static contextTypes = userProviderContextTypes;
    context!: UserAware;

    constructor(props: WithStyles<typeof styles>) {
        super(props);
        this.state = {
            email: '',
            oldEmail: '',
            name: '',
            oldName: '',
            account: '',
            payPalEmailAddress: '',
            payPalCurrencyCode: '',
            payPalSettingsOpened: false
        };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any) {
        console.log(`Account.componentDidCatch error: ${error}, errorInfo: ${errorInfo}`);
    }

    private handleManageEvents = () => pushUrl('/events');
    private isValidNewEmail = (newEmail: string) => firebaseAuth.currentUser && newEmail && emailFormat.test(newEmail.trim()) && newEmail.trim() !== this.state.oldEmail;

    private handleLogout = () => {
        this.context.setSigningOut(true);
        withProgress(firebaseAuth.signOut(), 'Logout failed ')
            .then(remoteLogout)
            .catch(() => this.context.setSigningOut(false));
    };

    private userRemoteChanged = (userInfo: UserInfo) => {
        const account = evalAccount(userInfo);
        const email = userInfo.email || firebaseAuth.currentUser?.email || '';
        let name = userInfo.name || firebaseAuth.currentUser?.displayName || '';
        if (name === '') {
            name = localStorage.getItem('displayName') ?? '';
        } else {
            localStorage.removeItem('displayName');
        }
        this.setState({ email, oldEmail: email, payPalEmailAddress: userInfo.payPalEmailAddress ?? '',
            payPalCurrencyCode: userInfo.payPalCurrencyCode ?? '', name, oldName: name, account });
    };

    private update = (updateData: UpdateData) => {
        if (!firebaseAuth.currentUser) {
            return;
        }
        const { newName, newEmail, newPayPalEmailAddress, newPayPalCurrencyCode } = updateData;
        const userData = {} as any;
        if (newName) {
            userData.name = newName.trim();
        }
        if (newEmail && this.isValidNewEmail(newEmail)) {
            userData.email = newEmail.trim();
        }
        if (newPayPalEmailAddress && this.isValidNewEmail(newPayPalEmailAddress)) {
            userData.payPalEmailAddress = newPayPalEmailAddress.trim();
        }
        if (newPayPalCurrencyCode) {
            userData.payPalCurrencyCode = newPayPalCurrencyCode.trim();
        }
        withProgress(updateAccountAndEvents(userData) , "Failed to update account settings!").then(() => {
            if (newName) {
                this.setState({ oldName: this.state.name });
            }
            if (newEmail && this.isValidNewEmail(newEmail)) {
                this.setState({ oldEmail: this.state.email });
            }
        });
    };

    private closePayPalSettings = () => this.setState({ payPalSettingsOpened: false });
    private openPayPalSettings = () => this.setState({ payPalSettingsOpened: true });

    private onPayPalDialogSave = (newPayPalEmailAddress: string, newPayPalCurrencyCode: string) => {
        this.update({ newPayPalCurrencyCode, newPayPalEmailAddress });
        this.closePayPalSettings();
    };

    render() {
        const { classes } = this.props;
        const { payPalSettingsOpened, payPalEmailAddress, payPalCurrencyCode } = this.state;
        const logOut = <AppButton color="info" onClick={this.handleLogout}>Log out</AppButton>;
        const payPalTitle = payPalEmailAddress || "No email";
        const payPalSubtitle = "A verified PayPal email address to accept golfer payments.";
        return (
            <div className={classes.root}>
                <List className={classes.listRoot1}>
                    <LabeledField label="Golf Pad account" value={this.state.account} icon={logOut} wideIcon />
                    <EditTextField label="Communication e-mail" hint="Use this e-mail to communicate with tournament participants"
                        textFieldProps={{}}
                        value={this.state.email} rules={[email]} save={newEmail => this.update({ newEmail })} />
                    <EditTextField label="Name"
                        textFieldProps={{}}
                        value={this.state.name} rules={[required]} save={newName => this.update({ newName })} />
                    <LabeledField label="PayPal settings"
                                  value={(<ListElem id="paypal_settings"
                                                    title={payPalTitle}
                                                    subtitle={payPalSubtitle}
                                                    titleColor={payPalEmailAddress ? undefined : '#949494'} />)}
                                  edit={this.openPayPalSettings} />
                    {payPalSettingsOpened && <PayPalSettingsDialog
                        emailAddress={payPalEmailAddress}
                        currencyCode={payPalCurrencyCode}
                        onClose={this.closePayPalSettings}
                        onSave={this.onPayPalDialogSave} />}
                </List>
                <ButtonBar>
                    <AppButton color="secondary" className={classes.button} onClick={this.handleManageEvents}>
                        Manage events
                    </AppButton>
                </ButtonBar>
                <FirebaseUserDataComponent onData={this.userRemoteChanged} />
            </div>
        );
    }
}

export default withStyles(styles)(Account);
