import * as React from 'react';
import { DialogContent, DialogActions } from '@mui/material';
import { ReactNode } from 'react';
import withForm, { ValidateProps } from '../../validation/ValidatedForm';
import { XSMobileDialog } from '../dialog/MobileDialog';
import DialogAppBar from '../dialog/DialogAppBar';
import { processEnterKey } from '../../util/react_utils';
import AppButton from '../../common/components/AppButton';

export interface WithSave<T> {
    save: (val: T) => void;
    savePromise?: (val: T) => Promise<boolean>;
}

export interface WithEditDialogProps {
    open: boolean;
    close: () => void;
    fullScreen?: boolean;
    skipEnterKey?: boolean;
}

export interface CommonEditDialogProps<T> {
    value?: T;
    maxValue?: T;
    minValue?: T;
    label: string;
    hint?: string;
    icon?: ReactNode;
    contentPadding?: string;
}

export interface EditDialogProps<T> {
    setValue: (val?: T) => void;
}

interface State<T> {
    value?: T;
}

const withEditDialog = function <P, T>(Component: React.ComponentType<P & EditDialogProps<T> & CommonEditDialogProps<T>>) {
    type ResProps = P & WithSave<T> & WithEditDialogProps & CommonEditDialogProps<T> & ValidateProps;

    return withForm(class extends React.Component<ResProps, State<T>> {
        state: State<T>;

        constructor(props: ResProps) {
            super(props);
            this.state = { value: props.value };
        }

        private handleSetValue = (val: T) => {
            this.setState({ value: val });
        }

        private handleSave = () => {
            if (this.props.valid && !this.props.valid()) {
                return;
            }
            this.props.save(this.state.value!);
        }

        /*shouldComponentUpdate(newProps: Readonly<ResProps>) {
            return newProps.value !== this.state.value;
        }*/

        componentWillReceiveProps(newProps: Readonly<ResProps>) {
            if (newProps.value !== this.state.value) {
                this.setState({ value: newProps.value });
            }
        }

        render() {
            const { contentPadding, label, hint, save, open, fullScreen, skipEnterKey, value, icon, valid, close: closeDialog, ...others } = this.props as any;
            const val = this.state.value;
            const padding = contentPadding ? { padding: contentPadding } : {};
            return (
                <XSMobileDialog open={open} maxWidth={'xs'} fullWidth={true} fullScreen={fullScreen} onClose={closeDialog}>
                    <DialogAppBar label={label} close={closeDialog} />
                    <DialogContent style={{ ...padding }} onKeyDown={event => processEnterKey(event, this.handleSave, skipEnterKey)}>
                        <Component label={label} hint={hint} icon={icon} {...others} value={val} setValue={this.handleSetValue} />
                    </DialogContent>
                    <DialogActions>
                        <AppButton onClick={closeDialog} color="info">Cancel</AppButton>
                        <AppButton onClick={this.handleSave} color="secondary">Save</AppButton>
                    </DialogActions>
                </XSMobileDialog>
            );
        }
    });
};

export default withEditDialog;
