import * as React from 'react';
import { SyntheticEvent } from 'react';
import { DialogProps } from '@mui/material/Dialog';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { FormControl, DialogActions, Typography, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import * as Backend from '../../../../../util/firebase';
import { updateGolfersPlayingHandicapInTransaction, elogInTransaction } from '../../../../Event';
import { Event, Contact, Competition, HandicapSystem, HANDICAP_SYSTEM_NAMES, HANDICAP_SYSTEM_IDS } from '../../../../../types/EventTypes';
import { XSMobileDialog } from '../../../../../common/dialog/MobileDialog';
import DialogAppBar from '../../../../../common/dialog/DialogAppBar';
import AppButton from '../../../../../common/components/AppButton';
import { styles } from '../../../../../styles';
import { errMsg } from "../../../../../util/firebase";

interface HandicapSystemDialogProps extends DialogProps {
    event: Event;
}

type Props = HandicapSystemDialogProps & WithStyles<typeof styles>;

type State = {
    handicapSystem?: HandicapSystem;
};

class HandicapSystemSelectionDialog extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            handicapSystem: props.event.handicapSystem
        };
    }

    private handleSave = async () => {
        const { event } = this.props;
        const { handicapSystem } = this.state;
        const eventUpdate = {
            id: event.id,
            exists: true,
            holesType: event.holesType,
            handicapSystem,
        } as Event;
        const [golfers, competitions] = await Promise.all([
            Backend.getEntities<Contact>(Backend.golferDb(event.id)),
            Backend.getEntities<Competition>(Backend.competitionsDb(event.id))
        ]);
        Backend.withTransaction(async transaction => {
            Backend.updateInTransaction(Backend.eventsDb, eventUpdate, transaction);
            updateGolfersPlayingHandicapInTransaction(transaction, eventUpdate, golfers, competitions)
                .catch(err => console.log(errMsg(err)));
            elogInTransaction(transaction, event, `Handicap system modified`, handicapSystem || 'handicapSystem n/a', `Id: ${event.id}`);
            return Promise.resolve();
        }).then(this.handleClose);
    }

    private handleClose = () => {
        const close = this.props.onClose;
        if (close) {
            close({} as SyntheticEvent<any>, 'escapeKeyDown');
        }
    }

    render() {
        const { classes } = this.props;
        const { handicapSystem } = this.state;
        return (
            <React.Fragment>
                <XSMobileDialog open={this.props.open} onClose={this.handleClose} maxWidth="sm" fullWidth={true}>
                    <DialogAppBar label="Handicap system" close={this.handleClose} />
                    <div style={{ padding: '16px' }}>
                        <FormControl
                            variant="standard"
                            margin="dense"
                            fullWidth
                            style={{ flexDirection: 'column' }}>
                            <Typography>Select the handicap system to compute playing handicaps:</Typography>
                            <RadioGroup value={handicapSystem} onChange={e => this.setState({ handicapSystem: e.target.value as HandicapSystem })}>
                                {HANDICAP_SYSTEM_NAMES.map((hcpType, idx) =>
                                    <FormControlLabel
                                        key={hcpType}
                                        value={HANDICAP_SYSTEM_IDS[idx]}
                                        control={<Radio color="primary" />}
                                        label={hcpType}
                                    />)}
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <DialogActions>
                        <AppButton color="info" onClick={this.handleClose}>Cancel</AppButton>
                        <AppButton color="secondary" className={classes.iconButton} onClick={this.handleSave}>Save</AppButton>
                    </DialogActions>
                </XSMobileDialog>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(HandicapSystemSelectionDialog);
