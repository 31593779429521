import * as React from 'react';
import { Typography, List } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import * as Backend from '../../../../util/firebase';
import { Event, EventMapping } from '../../../../types/EventTypes';
import { deleteEvent, undeleteEvent, elog, elogInTransaction, formatDateUniversal } from '../../../Event';
import { showError, NoticeElement } from '../../../../redux/ReduxConfig';
import EditTextField from '../../../../common/form/EditTextField';
import { EditDateTimeField } from '../../../../common/form/EditDateTimeField';
import { required, maxLength } from '../../../../validation/ValidatedTextField';
import ButtonBar from '../../../../common/components/ButtonBar';
import AppButton from '../../../../common/components/AppButton';
import ConfirmDialog from '../../../../common/dialog/ConfirmDialog';
import { getEditIdField } from './EditIdField';
import {makeFriendlyString, getUTCMidnight} from '../../../../util/utility';
import { pushUrl } from '../../../../redux/ReduxConfig';
import CourseSelectionField from './course/CourseSelectionField';
import HandicapSystemSelectionField from './course/HandicapSystemSelectionField';
import RegistrationSettingsField from './RegistrationSettingsField';
import EventGenderSettingsField from './EventGenderSettingsField';
import { styles } from '../../../../styles';
import { EVENT_DATETIME_FORMAT_LOG, dbgLog } from 'src/util/config';

const eventIdWithExplanation = (val: string) => {
    const msg = "A default id is assigned to each event, and this can be changed at any time. Golfers use this id to find your event on golfpad.events. The default id will work in addition to any customized event id."
    return (
        <React.Fragment>
            <Typography variant="body1" style={{ wordWrap: "break-word" }}>{val}</Typography>
            <Typography variant="caption">Golfers and spectators can find the event using this id {NoticeElement(msg)}</Typography>
        </React.Fragment>
    );
};

interface Props {
    event: Event;
}

interface State {
    confirmingDelete?: () => void;
}

class GeneralEventSettings extends React.Component<Props & WithStyles<typeof styles>, State> {
    state: State = {};

    componentDidMount() {
        Backend.trackEvent('view_general_settings');
    }

    private handlePublicId = (value: string) => {
        const { event } = this.props;
        value = value.trim().toUpperCase();
        if (value === event.publicId) {
            return Promise.resolve(true);
        }
        const toSave = { id: event.id, exists: true, publicId: value };
        return Backend.withTransaction(async transaction => {
            const mappingsDoc = await transaction.get(Backend.doc(Backend.eventMappingDb, value));
            if (mappingsDoc.exists()) {
                const mapping = mappingsDoc.data() as EventMapping;
                if (mapping.eventId === event.id) {
                    Backend.updateOrAddInTransaction(Backend.eventsDb, toSave, false, transaction);
                    elogInTransaction(transaction, event, 'Public ID modified', `${value}`, `Id: ${event.id}`);
                    return Promise.resolve(true);
                } else {
                    showError(`${value} is already taken. Please pick a different id`);
                    return Promise.resolve(false);
                }
            } else {
                Backend.updateOrAddInTransaction(Backend.eventMappingDb, { id: value, eventId: event.id }, true, transaction);
                Backend.updateOrAddInTransaction(Backend.eventsDb, toSave, false, transaction);
                elogInTransaction(transaction, event, 'Public ID modified', `${value}`, `Id: ${event.id}`);
                return Promise.resolve(true);
            }
        });
    }

    private handleSaveEventDate = (date: number) => {
        const { event } = this.props;
        const toSave = { id: event.id, exists: true } as Event;
        toSave.date = date;
        toSave.dateUTC = getUTCMidnight(date);
        dbgLog(`handleSaveEventDate ${toSave.date} - ${toSave.dateUTC}`)
        Backend.updateOrAdd(Backend.eventsDb, toSave).then(() => elog(event, `Date modified`, formatDateUniversal(date, EVENT_DATETIME_FORMAT_LOG), `Id: ${event.id}`));
    }

    private handleSave = (key: keyof Event) => (value: any) => {
        const { event } = this.props;
        const toSave = { id: event.id, exists: true } as any;
        toSave[key] = value;
        Backend.updateOrAdd(Backend.eventsDb, toSave).then(() => elog(event, `${makeFriendlyString(key, true)} modified`,
            `${makeFriendlyString(key, true)}: ${value}`, `Id: ${event.id}`));
    }

    private handleDeleteEvent = () => this.setState({ confirmingDelete: this.confirmedDeleteEvent });

    private confirmedDeleteEvent = () => {
        const { event } = this.props;
        this.setState({ confirmingDelete: undefined }, () => deleteEvent(event)
            .catch(err => showError('Error during event delete: ' + err))
            .then(() => { showError('Event has been deleted'); pushUrl('/events'); }));
    }

    private handleUndeleteEvent = () => {
        const { event } = this.props;
        undeleteEvent(event)
            .catch(err => showError('Error during event undelete: ' + err))
            .then(() => showError('Event has been undeleted'));
    }

    render() {
        const { classes, event } = this.props;
        const { confirmingDelete } = this.state;
        const EditIdField = getEditIdField(eventIdWithExplanation);
        return (
            <React.Fragment>
                <List className={classes.listRoot1}>
                    <EditTextField
                        textFieldProps={{}}
                        label="Event name"
                        value={event.name}
                        rules={[required, maxLength(100)]}
                        save={this.handleSave('name')} />
                    <EditIdField
                        textFieldProps={{}}
                        label="Event id"
                        value={event.publicId}
                        save={() => { }}
                        savePromise={this.handlePublicId}
                        rules={[{
                            valid: (value?: string) => !!value && (event.leaderboard ? true : !value.trim().startsWith('LL-')),
                            text: 'Regular event\'s public id can not start with LL- prefix'
                        },]} />
                    <EditDateTimeField
                        label="Date"
                        value={event.date}
                        teeStartTime={event.teeTime.startTime}
                        save={this.handleSaveEventDate} />
                    <CourseSelectionField event={event} />
                    {!!event.course && <HandicapSystemSelectionField event={event} />}
                    <EventGenderSettingsField event={event} />
                    <RegistrationSettingsField event={event} />
                </List>
                <ButtonBar>{event.deleted
                    ? <AppButton color="secondary" onClick={this.handleUndeleteEvent}><DeleteIcon className={classes.leftButtonIcon} />Undelete event</AppButton>
                    : <AppButton color="secondary" onClick={this.handleDeleteEvent}><DeleteIcon className={classes.leftButtonIcon} />Delete event</AppButton>}
                </ButtonBar>
                {!!confirmingDelete && <ConfirmDialog
                    open={true}
                    disableEscapeKeyDown
                    disableBackdropClick
                    onCancel={() => this.setState({ confirmingDelete: undefined })}
                    onOk={confirmingDelete}
                    title="Confirm permanent delete"
                    content="This event will be permanently deleted, including all settings, schedule, scoring, history, public user Event site, leaderboards and all other information. This operation can not be un-done. Click CONFIRM to proceed with deletion or CANCEL to keep this event."
                    okLabel="Confirm and delete" />}
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(GeneralEventSettings);
