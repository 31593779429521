import * as React from 'react';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import {
    InputLabel, FormControl, FormControlLabel, Typography, IconButton, Box, useMediaQuery, useTheme, DialogContent,
    DialogActions
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { InfoIcon } from '../../../../common/Icons';
import TeeTimeInterval from './TeeTimeInterval';
import { Event, TeeTimeMode, TeeTimeSettings, getHolesRange } from '../../../../types/EventTypes';
import { getStartingHolesStr } from '../../../Event';
import TypedFormRadioLabel, { RadioLabel } from '../../../../common/form/TypedFormRadioLabel';
import { getTimeZoneOffsetMs, getDayTime } from '../../../../util/utility';
import GroupSize from './GroupSize';
import FormContent from '../../../../common/form/FormContent';
import { ListElem, Container, Item, Spacing } from '../../../../common/Misc';
import AppButton from '../../../../common/components/AppButton';
import StartingHoleDialog from './StartingHoleDialog';
import { BoxedInfoElement, showAlert } from '../../../../redux/ReduxConfig';
import { styles } from '../../../../styles';
import { TimeTextField } from "../../../../common/TimeTextField";
import { AppColors } from "../../../../main/Theme";
import { XSMobileDialog } from "../../../../common/dialog/MobileDialog";
import DialogAppBar from "../../../../common/dialog/DialogAppBar";

type TeeTimeComponentProps = {
    event: Event,
    teeStartTime: number,
    handleTimeChange: (val: Date) => void
};

const TeeTimeComponent = ({ event, teeStartTime, handleTimeChange }: TeeTimeComponentProps) => {
    const isXs = useMediaQuery(useTheme().breakpoints.down('sm'));
    return (
        <Box display="flex" flexDirection={isXs ? 'column' : 'row'}>
            <TimeTextField date={teeStartTime} onTimeSelected={handleTimeChange} label="Tee time" />
            <Box width="16px" height="16px" />
            {teeStartTime && (getDayTime(event.date) > getDayTime(teeStartTime)) &&
                <BoxedInfoElement sx={{ maxWidth: isXs ? 'none' : 200 }} iconColor={AppColors.webWarning}>
                    Tee time is set earlier than the start of the event.</BoxedInfoElement>}
        </Box>
    );
};

type UpdateStartTimeDialogProps = {
    event: Event,
    settings: TeeTimeSettings,
    saveSettings: (settings: TeeTimeSettings, eventTime?: number) => void,
    onClose: () => void
};

const UpdateStartTimeDialog = ({ event, settings, saveSettings, onClose }: UpdateStartTimeDialogProps) => {
    const [eventDate, setEventDate] = React.useState(event.date);
    const save = () => saveSettings(settings, eventDate);
    const isXs = useMediaQuery(useTheme().breakpoints.down('sm'));
    return (
        <XSMobileDialog open={true} maxWidth="md" PaperProps={{
            style: { maxWidth: 520, maxHeight: 268, margin: isXs ? '0px 20px' : 'none' }
        }}>
            <DialogAppBar label="Update event start time" close={onClose} />
            <DialogContent>
                <Typography style={{ fontSize: 14, lineHeight: '21px', color: AppColors.webBlack }}>
                    Tee times are set earlier than the start of the event. Update the start time of the event too?
                </Typography>
                <Box height={8} />
                <TimeTextField date={eventDate} onTimeSelected={date => setEventDate(date.getTime())}
                    label={"Event start time"} />
            </DialogContent>
            <DialogActions>
                <Box display="flex" flexDirection={isXs ? 'column' : 'row'} width={isXs ? '100%' : 'none'}>
                    <AppButton onClick={onClose} color="info">
                        Continue without changes
                    </AppButton>
                    <Box width="8px" height="8px" />
                    <AppButton onClick={save} color="secondary">
                        Update event start time
                    </AppButton>
                </Box>
            </DialogActions>
        </XSMobileDialog>
    );
};

type PropsData = {
    settings: TeeTimeSettings,
    event: Event,
    saveSettings: (settings: TeeTimeSettings, eventTime?: number) => void,
    onClose: () => void
};

type State = {
    settings: TeeTimeSettings,
    updateStartTimeDialogOpened: boolean,
    selectingHoles?: boolean
};

class TeeTimeDialog extends React.Component<PropsData & WithStyles<typeof styles>, State> {
    state: State = {
        settings: { ...this.props.settings },
        updateStartTimeDialogOpened: false
    };

    private handleModeChange = (val: TeeTimeMode) => {
        const { settings } = this.state;
        settings.mode = val;
        this.setState({ settings });
    };

    private handleIntervalChange = (val: number) => {
        const { settings } = this.state;
        settings.interval = val;
        this.setState({ settings });
    };

    private handleGroupSizeChange = (val: number) => {
        const { settings } = this.state;
        settings.golfersPerGroup = val * this.props.event.teamSize;
        this.setState({ settings });
    };

    private handleTimeChange = (val: Date) => {
        const { settings } = this.state;
        settings.startTimeUTC = val.valueOf() + getTimeZoneOffsetMs(val.valueOf());
        settings.startTime = val.valueOf();
        this.setState({ settings });
    };

    private handleSave = () => {
        const { event, saveSettings } = this.props;
        const { settings } = this.state;
        if (getDayTime(event.date) > getDayTime(settings.startTime)) {
            this.setState({ updateStartTimeDialogOpened: true })
        } else {
            saveSettings(settings);
        }
    };

    private closeUpdateTimeDialogOpened = () => this.setState({ updateStartTimeDialogOpened: false });

    render() {
        const { classes, event, onClose, saveSettings } = this.props;
        const { settings, selectingHoles, updateStartTimeDialogOpened } = this.state;
        const mode = settings?.mode || 'regular';
        const teeStartTime = settings?.startTimeUTC !== undefined ?
            settings.startTimeUTC - getTimeZoneOffsetMs(settings.startTimeUTC) : settings?.startTime ?? 0;
        const interval = settings?.interval || 0;
        const holesRange = getHolesRange(event.holesType);
        const startingHolesLabel = getStartingHolesStr(settings?.startingHolesType,
            settings?.startingHoles, holesRange, settings?.mode);
        const groupSize = Math.max(Math.floor((settings?.golfersPerGroup || 4) / event.teamSize), 1);
        const regularLabel = <RadioLabel title="Regular" text="Golfers start from hole 1 according to their tee time" />;
        const shotgunLabel = <RadioLabel title="Shotgun" text="Golfers start at the same time from different holes" />;
        const items = event.teamSize > 1 ? 'teams' : 'golfers';
        return (
            <XSMobileDialog open={true} maxWidth={'xs'} PaperProps={{ style: { minHeight: 540 } }} fullWidth={true}>
                <DialogAppBar label="Tee times settings" close={onClose} />
                <DialogContent>
                    <div style={{ height: 360 }}>
                        <TeeTimeComponent teeStartTime={teeStartTime} event={event} handleTimeChange={this.handleTimeChange} />
                        <FormControl variant="standard" margin="dense" fullWidth>
                            <TypedFormRadioLabel currentValue={mode} value="regular" handleChange={this.handleModeChange}
                                label={regularLabel} className={classes.radioTwoLines} />
                            <TypedFormRadioLabel currentValue={mode} value="shotgun" handleChange={this.handleModeChange}
                                label={shotgunLabel} className={classes.radioTwoLines} />
                        </FormControl>
                        {mode === 'regular' &&
                            <FormControl variant="standard" margin="dense" fullWidth>
                                <InputLabel shrink>Tee times interval</InputLabel>
                                <FormContent>
                                    <TeeTimeInterval value={interval} onChanged={this.handleIntervalChange} />
                                </FormContent>
                            </FormControl>}
                        <FormControl variant="standard" margin="dense" fullWidth>
                            <InputLabel shrink>Group size</InputLabel>
                            <FormContent>
                                <Typography variant="caption">Maximum number of {items} per group (tee time)</Typography>
                                <GroupSize value={groupSize} teamSize={event.teamSize} onChanged={this.handleGroupSizeChange} />
                            </FormContent>
                        </FormControl>
                        <FormControl variant="standard" margin="dense" fullWidth>
                            <InputLabel shrink>
                                {mode === 'regular'
                                    ? <span>Starting hole <IconButton
                                        onClick={() => showAlert('All tee times will start from this hole. Use the select button to start groups from a different hole.')}
                                        size="large"><InfoIcon /></IconButton></span>
                                    : <span>Starting holes </span>}
                            </InputLabel>
                            <Spacing height={12} />
                            <FormControlLabel className={classes.formSelector} control={<Container>
                                <Item><ListElem title={startingHolesLabel} subtitle="" /></Item>
                                <Item><AppButton style={{ marginLeft: '1em' }} color="info" onClick={() => this.setState({ selectingHoles: true })}><EditIcon /> Select</AppButton></Item>
                            </Container>} label="" />
                        </FormControl>
                        {selectingHoles && <StartingHoleDialog
                            open
                            event={event}
                            teeTimeSettings={settings}
                            onSelectedStartingHoles={(startingHolesType, startingHoles) => {
                                const { settings } = this.state;
                                settings.startingHolesType = startingHolesType;
                                settings.startingHoles = startingHoles;
                                this.setState({ selectingHoles: false, settings });
                            }}
                            onClose={() => this.setState({ selectingHoles: false })} />}
                        {updateStartTimeDialogOpened && <UpdateStartTimeDialog
                            event={event} settings={settings}
                            saveSettings={saveSettings}
                            onClose={this.closeUpdateTimeDialogOpened} />}
                    </div>
                </DialogContent>
                <DialogActions>
                    <AppButton onClick={onClose} color="info">Cancel</AppButton>
                    <AppButton onClick={this.handleSave} color="secondary">Save</AppButton>
                </DialogActions>
            </XSMobileDialog>
        );
    }
}

export const EditTeeTimeDialog = withStyles(styles)(TeeTimeDialog);
