import * as React from 'react';
import { DialogContent, DialogActions, Checkbox, FormControlLabel, FormControl } from '@mui/material';
import { XSMobileDialog } from '../../../common/dialog/MobileDialog';
import DialogAppBar from '../../../common/dialog/DialogAppBar';
import Typography from '@mui/material/Typography';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import TypedFormRadioLabel, { RadioLabel } from '../../../common/form/TypedFormRadioLabel';
import { Event, GolferGroup, HandicapMode, GenderMode, GolferOrTeam, groupsPerTime, teamsCount } from '../../../types/EventTypes';
import AppButton from '../../../common/components/AppButton';
import { styles } from '../../../styles';
import { withS } from '../../../util/utility';

interface AutoScheduleDialogProps {
    event: Event;
    open: boolean;
    pairing: boolean;
    count: number;
    groupSize: number;
    groups: Array<GolferGroup>;
    golfersOrTeams: Map<string, GolferOrTeam>;
    close: () => void;
    save: (genderMode: GenderMode, handicapMode: HandicapMode, keepGroups: boolean) => void;
}

interface State {
    handicapMode: HandicapMode;
    genderMode: GenderMode;
    keepExisting: boolean;
}

class AutoScheduleDialog extends React.Component<AutoScheduleDialogProps & WithStyles<typeof styles>> {
    state: State = {
        handicapMode: 'random',
        genderMode: 'random',
        keepExisting: true
    };

    private handleGenderModeChange = (genderMode: GenderMode) => this.setState({ genderMode });
    private handleHandicapModeChange = (handicapMode: HandicapMode) => this.setState({ handicapMode });
    private setKeepGroups = (e: React.ChangeEvent, keepExisting: boolean) => this.setState({ keepExisting });

    render() {
        const { open, close, save, groupSize, pairing, golfersOrTeams, event, groups } = this.props;
        const { handicapMode, genderMode, keepExisting } = this.state;
        const handicapLabel = (<RadioLabel title="By handicap" text={'Golfers of similar ability will be ' + (pairing ? 'paired' : 'groupped') + ' together'} />);
        const mixedLabel = (<RadioLabel title="Mixed ability" text={'Each ' + (pairing ? 'team' : 'group') + ' will include golfers of varying ability (ABCD)'} />);
        const genderLabel = (<RadioLabel title="Separate" text={'Each ' + (pairing ? 'team' : 'group') + ' will consist only of men or only of women'} />);
        const mixLabel = (<RadioLabel title="Mix" text={'Each ' + (pairing ? 'team' : 'group') + ' will have at least one man and at least one woman'} />);
        const weakGender = Array.from(golfersOrTeams.values()).findIndex(gt => !gt.scheduled && !gt.gender) >= 0;
        const isGenderMode = !event.eventGender || event.eventGender === 'both';
        const isTeams = !pairing && event.teamSize > 1;
        let golfersOrTeamsCnt = -1;
        let groupCnt = -1;
        let withExisting = true;
        if (keepExisting) {
            golfersOrTeamsCnt = Array.from(golfersOrTeams.values()).filter(gt => !gt.scheduled).length;
            if (!pairing) {
                withExisting = golfersOrTeamsCnt < golfersOrTeams.size;
            }
        } else {
            golfersOrTeamsCnt = golfersOrTeams.size;
        }
        groupCnt = pairing ? teamsCount(event, golfersOrTeams.size) : groupsPerTime(event, golfersOrTeams.size);
        const from = isTeams ? 'teams' : 'golfers';
        const from1 = isTeams ? 'team' : 'golfer';
        const target = pairing ? 'teams' : 'groups';
        const target1 = pairing ? 'team' : 'group';
        return (
            <XSMobileDialog open={open} maxWidth={'xs'} fullWidth={true} onClose={close}>
                <DialogAppBar label={pairing ? 'Automatic pairings' : 'Schedule'} close={close} />
                {groupSize > 1 && <DialogContent>
                    <Typography>{pairing ? 'Pair' : 'Group'} all unassigned {from} automatically, using the following grouping strategy:</Typography>
                    {isGenderMode && <FormControl variant="standard" margin="dense" fullWidth={true}>
                        <Typography>By gender</Typography>
                        <TypedFormRadioLabel currentValue={genderMode} value="random" handleChange={this.handleGenderModeChange} label="Random" />
                        <TypedFormRadioLabel currentValue={genderMode} value="gender" handleChange={this.handleGenderModeChange} label={genderLabel} disabled={weakGender || groupSize === 1} />
                        <TypedFormRadioLabel currentValue={genderMode} value="mix" handleChange={this.handleGenderModeChange} label={mixLabel} disabled={weakGender || groupSize === 1} />
                    </FormControl>}
                    <FormControl variant="standard" margin="dense" fullWidth={true}>
                        <Typography>By handicap</Typography>
                        <TypedFormRadioLabel currentValue={handicapMode} value="random" handleChange={this.handleHandicapModeChange} label="Random" />
                        <TypedFormRadioLabel currentValue={handicapMode} value="handicap" handleChange={this.handleHandicapModeChange} label={handicapLabel} />
                        <TypedFormRadioLabel currentValue={handicapMode} value="mixed" handleChange={this.handleHandicapModeChange} label={mixedLabel} />
                    </FormControl>
                    {withExisting && <FormControl variant="standard" margin="dense" fullWidth={true}>
                        <Typography>Existing {target}</Typography>
                        <FormControlLabel
                            style={{ marginLeft: 0 }}
                            control={<Checkbox  color="secondary" checked={keepExisting} onChange={this.setKeepGroups} />}
                            label={<RadioLabel title={'Keep existing ' + target} text={'Only schedule ' + from + ' who are not yet grouped'} />} />
                    </FormControl>}
                    <FormControl variant="standard" margin="dense" fullWidth={true}>
                        <Typography>Based on your selections above:</Typography>
                        {golfersOrTeamsCnt > 0 && <Typography color="secondary" variant="subtitle2">{withS(golfersOrTeamsCnt, from1)} will be {pairing ? 'paired' : 'scheduled'}, for a total of {withS(groupCnt, target1)}</Typography>}
                        {!golfersOrTeamsCnt && <Typography color="secondary" variant="subtitle2">No {from} will be {pairing ? 'paired' : 'scheduled'}</Typography>}
                    </FormControl>
                </DialogContent>}
                {groupSize === 1 && <DialogContent>
                    <Typography>{pairing ? 'Pair' : 'Group'} all unassigned {from} automatically. {groups.length > 0 ? (' Existing ' + target + ' won\'t be affected.') : ''}</Typography>
                </DialogContent>}
                <DialogActions>
                    <AppButton onClick={close} color="info">Cancel</AppButton>
                    <AppButton onClick={() => save(genderMode, handicapMode, keepExisting)} color="secondary" disabled={golfersOrTeamsCnt === 0}>OK</AppButton>
                </DialogActions>
            </XSMobileDialog>
        );
    }
}

export default withStyles(styles)(AutoScheduleDialog);
