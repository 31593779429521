import * as React from 'react';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { List, ListItem } from '@mui/material';
import { detectMob } from '../../../util/react_utils';
import * as Backend from '../../../util/firebase';
import {
    Event, EventData, Competition, Units, Distance, ReportedScore, Score, isMainScoring, isSkinsScoring,
    isNetMode, isGrossMode, isGrossPayouts, isNetPayouts
} from '../../../types/EventTypes';
import { FirebaseDataComponent, FirebaseUserPubDataComponent } from '../../../common/WithData';
import { ContactGroup, prepareInviteCodes } from '../../Event';
import { netCompetition, grossCompetition } from '../../../scoring/scoring'
import { createScheduleDoc, createScoringDoc, createScorecardsDoc, createGolfersDoc, createCartSignsDoc, createMoneyListDoc, getGolferInfo } from '../../EventFormat';
import { getSchedule, getCartSigns } from '../../TeeTimes';
import ButtonBar from '../../../common/components/ButtonBar';
import AppButton from '../../../common/components/AppButton';
import AddGolfersDialog from '../../tabs/common/AddGolfersDialog'; 
import { withProgress } from '../../../util/ProgressPromise';
import { showAlert } from '../../../redux/ReduxConfig';
import { styles } from '../../../styles';
import { errMsg } from "../../../util/firebase";
import { getSameNameGolfersIds } from "../../../contact/Contact";

function printDoc(doc: any, fileName: string) {
    if (doc) {
        const nav = (window.navigator as any);
        // work around according known Edge 10+ bug https://github.com/bpampuch/pdfmake/issues/693#issuecomment-551041722
        if (nav?.msSaveOrOpenBlob) {
            doc.getBlob((blob: Blob) => nav?.msSaveOrOpenBlob(blob, fileName));
        } else if (detectMob()) {
            doc.download(fileName);
        } else {
            doc.print();
        }
    }
}

export function getImageData(image: HTMLImageElement) {
    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;
    const context = canvas.getContext('2d')!;
    context.save();
    context.fillStyle = '#bbbbbb';
    context.beginPath();
    context.arc(canvas.width * 3 / 4, canvas.height / 2, 10, 0, Math.PI * 2, true);
    // context.arc(canvas.width / 2, canvas.height, 30, 0, Math.PI * 2, true);
    context.closePath();
    context.fill();
    // context.drawImage(image, 0, 0);
    context.fillStyle = '#eeeeee';
    context.globalCompositeOperation = 'destination-over';
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.restore();
    return canvas.toDataURL('data:image/png');
}

export function getImage(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => {
            resolve(getImageData(image));
        };
        image.width = 80;
        image.height = 80;
        // image.src = url;
        resolve(getImageData(image));
    });
}

interface State {
    golferScores: Map<string, Score>;
    teamScores: Map<string, Score>;
    reportedScores: Map<string, ReportedScore>;
    reportedTeamScores: Map<string, ReportedScore>;
    distances: Map<string, Distance>;
    printingScorecards?: boolean;
    scorecardDontShowTeeWarning: boolean;
    units?: Units;
    notes?: string;
}

type Props = { event: Event; eventData: EventData; } & WithStyles<typeof styles>;

const useBadge = false;

class PrintTab extends React.Component<Props, State> {
    state: State = {
        golferScores: new Map<string, Score>(),
        teamScores: new Map<string, Score>(),
        reportedScores: new Map<string, ReportedScore>(),
        reportedTeamScores: new Map<string, ReportedScore>(),
        distances: new Map<string, Distance>(),
        notes: '',
        scorecardDontShowTeeWarning: false
    };

    private onDistances = (distances: Map<string, Distance>) => this.setState({ distances });
    private onTeamScores = (scores: Map<string, Score>) => this.setState({ teamScores: scores });
    private onGolferScores = (scores: Map<string, Score>) => this.setState({ golferScores: scores });
    private onReportedGolferScores = (reportedScores: Map<string, ReportedScore>) => this.setState({ reportedScores });
    private onReportedTeamScores = (reportedTeamScores: Map<string, ReportedScore>) => this.setState({ reportedTeamScores });
    private handleGolfersCancel = () => this.setState({ printingScorecards: false });

    private startPrintScorecards = () => {
        const { event } = this.props;
        const { golfers, teams, groups, competitions } = this.props.eventData;
        const eventInfo = getGolferInfo(event, competitions, golfers, teams, groups);
        const noLength = eventInfo.getTees().findIndex(t => !t.len || t.len.findIndex(l => l > 0) < 0) >= 0;
        if (noLength && !event.scorecardDontShowTeeWarning) {
            showAlert(`One or more of the tees is missing hole lengths, these tees will not be shown on the printed scorecards. Add lengths under settings, course, view/edit scorecard, select tee.`, [
                { title: 'Cancel' },
                {
                    title: 'OK', color: 'secondary', action: () => {
                        if (this.state.scorecardDontShowTeeWarning) {
                            const toSave = { id: event.id, exists: true, scorecardDontShowTeeWarning: true };
                            Backend.updateOrAdd(Backend.eventsDb, toSave).catch(err => console.log(errMsg(err)));
                        }
                        this.setState({ printingScorecards: true });
                    }
                }
            ], true, () => {
                const scorecardDontShowTeeWarning = !this.state.scorecardDontShowTeeWarning;
                this.setState({ scorecardDontShowTeeWarning });
                return scorecardDontShowTeeWarning;
            });
        } else {
            this.setState({ printingScorecards: true });
        }
    }

    private handlePrintScorecards = async (contacts: Array<ContactGroup>, notes?: string) => {
        if (contacts.length === 0) {
            return;
        }
        const { event } = this.props;
        const { groups } = this.props.eventData;
        const groupCodes = await prepareInviteCodes(groups, event.id);
        this.setState({ printingScorecards: false });
        if (useBadge) {
            withProgress(Backend.getDocument(Backend.portalInfoDb, event.id))
                .then(doc => {
                    if (doc.exists()) {
                        const data = doc.data();
                        if (data) {
                            withProgress(getImage(data.badgeUrl)).then(dataUrl => this.printScorecards(contacts, groupCodes, dataUrl, notes));
                        }
                    }
                });
        } else {
            this.printScorecards(contacts, groupCodes, '', notes);
        }
    }

    private printScorecards = (contacts: Array<ContactGroup>, groupCodes: Map<string, string>, badgeUrl?: string, notes?: string) => {
        const { event } = this.props;
        const { groups, golfers, teams, competitions } = this.props.eventData;
        const { golferScores, teamScores, distances } = this.state;
        const doc = createScorecardsDoc(event, badgeUrl, contacts, golferScores, teamScores, golfers, teams, groups, distances, competitions, groupCodes, false, notes);
        printDoc(doc, event.publicId + '-scorecards.pdf');
    }

    private printSchedule = () => {
        const { event } = this.props;
        const { groups, golfers, teams } = this.props.eventData;
        const schedule = getSchedule(event, groups, golfers, teams);
        if (schedule) {
            const doc = createScheduleDoc(event, schedule, golfers);
            printDoc(doc, event.publicId + '-schedule.pdf');
        }
    }

    private printResults = () => {
        const { event } = this.props;
        const { groups, golfers, teams, competitions } = this.props.eventData;
        const { golferScores, teamScores, distances, units, reportedScores, reportedTeamScores } = this.state;
        const competitionsExt: Array<Competition> = [];
        competitions.forEach(competition => {
            if (isSkinsScoring(competition.scoring)) {
                if (isNetMode(competition.scoring.mode)) {
                    competitionsExt.push(netCompetition(competition));
                }
                if (isGrossMode(competition.scoring.mode)) {
                    competitionsExt.push(grossCompetition(competition));
                }
            } else {
                competitionsExt.push(competition);
            }
        });
        const doc = createScoringDoc(event, competitionsExt, golferScores, teamScores, reportedScores, reportedTeamScores, golfers, teams, groups, distances, units);
        printDoc(doc, event.publicId + '-results.pdf');
    }

    private printGolfers = () => {
        const { event } = this.props;
        const { competitions, golfers, teams, groups } = this.props.eventData;
        const doc = createGolfersDoc(event, competitions, golfers, teams, groups);
        printDoc(doc, event.publicId + '-golfers.pdf');
    }

    private printCartSigns = async () => {
        const { event } = this.props;
        const { groups, golfers, teams } = this.props.eventData;
        const groupCodes = await prepareInviteCodes(groups, event.id);
        const cartSigns = getCartSigns(event, groups, golfers, teams);
        const doc = createCartSignsDoc(event, cartSigns, getSameNameGolfersIds(Array.from(golfers.values())), groupCodes);
        printDoc(doc, event.publicId + '-cartsigns.pdf');
    }

    private printMoney = () => {
        const { event } = this.props;
        const { competitions, golfers, teams, groups } = this.props.eventData;
        const { golferScores, teamScores, reportedScores, reportedTeamScores, distances } = this.state;
        const doc = createMoneyListDoc(event, competitions, golferScores, teamScores, reportedScores, reportedTeamScores, golfers, teams, groups, distances);
        printDoc(doc, event.publicId + '-money.pdf');
    }

    render() {
        const { event, classes, eventData } = this.props;
        const { competitions, golfers, groups } = eventData;
        const { printingScorecards } = this.state;
        const noSchedule = groups.filter(g => g.contactIds.length > 0).length === 0;
        const payoutsCompetitions = competitions.filter(competition => isMainScoring(competition.scoring) && (isGrossPayouts(competition) || isNetPayouts(competition)));
        const noScorings = competitions.filter(comp => isMainScoring(comp.scoring)).length === 0;
        const noGolfers = golfers.size === 0;
        const noMoney = golfers.size === 0 || payoutsCompetitions.length === 0;
        return (
            <List disablePadding className={classes.listRoot1}>
                <ListItem className={classes.listItem}>
                    <ButtonBar margin>
                        <AppButton color="secondary" onClick={this.startPrintScorecards} disabled={noSchedule || noScorings}>Print Scorecards</AppButton>
                        <AppButton color="secondary" onClick={this.printSchedule} disabled={noSchedule}>Print Schedule</AppButton>
                        <AppButton color="secondary" onClick={this.printResults} disabled={noScorings}>Print Results</AppButton>
                        <AppButton color="secondary" onClick={this.printGolfers} disabled={noGolfers}>Print Golfers List</AppButton>
                        <AppButton color="secondary" onClick={this.printCartSigns} disabled={noSchedule}>Print Cart Signs</AppButton>
                        <AppButton color="secondary" onClick={this.printMoney} disabled={noMoney}>Print Money List</AppButton>
                    </ButtonBar >
                    {printingScorecards && <AddGolfersDialog
                        open={true}
                        label={'Print Scorecards'}
                        okLabel={'Print'}
                        event={event}
                        eventData={eventData}
                        golferDB={'GOLFER_GROUPS'}
                        handleAddGolfers={this.handlePrintScorecards}
                        handleCancel={this.handleGolfersCancel}
                        selectAll={true}
                        withNotes={true}
                    />}
                </ListItem>
                <FirebaseDataComponent query={Backend.golferTeamScoresDb(event.id)} onMap={this.onTeamScores} />
                <FirebaseDataComponent query={Backend.golferScoresDb(event.id)} onMap={this.onGolferScores} />
                <FirebaseDataComponent query={Backend.golferDistancesDb(event.id)} onMap={this.onDistances} />
                <FirebaseDataComponent query={Backend.reportedGolferScoresDb(event.id)} onMap={this.onReportedGolferScores} />
                <FirebaseDataComponent query={Backend.reportedTeamScoresDb(event.id)} onMap={this.onReportedTeamScores} />
                <FirebaseUserPubDataComponent uid={event.userId} onData={data => this.setState({ units: data.units })} />
            </List>
        );
    }
}

export default withStyles(styles)(PrintTab);
