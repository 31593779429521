import * as React from 'react';

import { Event, Facility, Tee, getCourseName, HOLES_18, HOLES_9, HolesType } from '../../../../../types/EventTypes';
import { getCourse, loadCourseTees } from '../../../../Courses';
import CourseSelectionDialog from './CourseSelectionDialog';
import CourseSelectionMenu from './CourseSelectionMenu';
import LabeledField from '../../../../../common/form/LabeledField';
import { getHolesRangeName } from '../../../../../scoring/handicap';
import { ListElem } from '../../../../../common/Misc';
import { withUserId, WithUserId } from '../../../../../auth/Auth';
import { withProgress } from '../../../../../util/ProgressPromise';

type CourseSelectionProps = {
    event: Event;
} & WithUserId;

interface CourseSelectionState {
    courseDialogOpened?: boolean;
    anchorSelect?: any;
    selectedFacility?: Facility;
    holesType?: HolesType;
    tees: Array<Tee>;
}

const COURSE_ELEM = 'course_elem';

class CourseSelectionField extends React.Component<CourseSelectionProps, CourseSelectionState> {
    state: CourseSelectionState = {
        tees: []
    };
    private handleClose = () => this.setState({ courseDialogOpened: false, selectedFacility: undefined });

    private handleOpen = (e: React.MouseEvent) => {
        const { event } = this.props;
        if (event.course) {
            this.setState({ courseDialogOpened: true, selectedFacility: undefined });
        } else {
            const elem = document.getElementById(COURSE_ELEM);
            this.setState({ anchorSelect: elem ? elem : e.currentTarget });
        }
    }
    
    private handleFacilityClose = () => {
        this.setState({ anchorSelect: null, tees: [] });
    }

    private handleFacilitySelect = async (selectedFacility: Facility) => {
        const { userId } = this.props;
        const course = getCourse(selectedFacility);
        if (course) {
            withProgress(loadCourseTees(userId, course, false, true), true).then(tees => {
                const holesType = (selectedFacility.courses.length === 1 && tees.length > 0 && tees[0].par.length === 9 ? HOLES_9 : HOLES_18);
                this.setState({ courseDialogOpened: true, selectedFacility, holesType, tees });
            }).catch(err => {});
        } else {
            const holesType = HOLES_18;
            this.setState({ courseDialogOpened: true, selectedFacility, holesType, tees: [] });
        }
        this.setState({ anchorSelect: null, tees: [] });
    }

    render() {
        const { event, userId } = this.props;
        const { anchorSelect, courseDialogOpened, selectedFacility, holesType, tees } = this.state;
        const courseName = getCourseName(event.course);
        const title = courseName ? courseName : 'Not selected';
        const subtitle = courseName ? getHolesRangeName(event.holesType) : ' ';
        return (
            <React.Fragment>
                <LabeledField label="Course" value={(<ListElem id={COURSE_ELEM} title={title} subtitle={subtitle} color={!!courseName ? 'inherit' : 'error'} />)} edit={this.handleOpen} />
                {courseDialogOpened && <CourseSelectionDialog userId={userId} open={true} onClose={this.handleClose} event={event}
                    preselectedFacility={selectedFacility}
                    preselectedHolesType={holesType}
                    preselectedTees={tees} />}
                {anchorSelect && <CourseSelectionMenu userId={userId} anchorSelect={anchorSelect} handleFacilitySelect={this.handleFacilitySelect} handleClose={this.handleFacilityClose} />}
            </React.Fragment>
        );
    }
}

export default withUserId(CourseSelectionField);
