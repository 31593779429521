import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { List, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import LinkIcon from '@mui/icons-material/OpenInNew';
import * as Scoring from '../scoring/scoring';
import { shortName, fullName, getSameNameGolfersIds } from '../contact/Contact';
import { FirebaseDocComponent, FirebaseDataComponent, FirebaseUserPubDataComponent, withDataItemFromUrl } from '../common/WithData';
import * as Backend from '../util/firebase';
import { showProgress } from '../redux/ReduxConfig';
import {
    Competition, Event, Contact, GolferGroup, Score, ReportedScore, EventMapping, Portal, Team, ScoringFormatDistance, Units, Distance, ScoringData, PayoutSettings,
    isDistanceScoring, isSkinsScoring, isTeamScoring, isNetMode, isGrossMode, isMainScoring, isTeamFormat, getTee, getCompetitionFlightScoresCount, teamsOf, golfersOfCompetition,
    isStablefordScoringOrMode, ContactScoringState, CalculatedScores, isNetPayouts, isGrossPayouts, isCompatibleCompetitionSkins, getEventMainCompetition, isTeamScoringExceptBB,
    ScoringFormatTeams, getHolesRange
} from '../types/EventTypes';
import {
    isFullScoresCompetition, getDistanceScores, getHolesCount, SkinsScoringState, getSkinsScores, HolesScoringState, skinCompetitionAward, formatDistance, hasTees,
    genderFromEvent, golferMainCompetitionAward, getPayouts
} from '../event/Event';
import Logo from '../common/Logo';
import { Flex, Container, Item, ItemS, Spacing } from '../common/Misc';
import { NoEvent } from './Public';
import { styles } from '../styles';
import { evalAvg, round, array } from '../util/utility';
import { ContainerOdd as ContainerStyled } from "./Standings";
import useDidUpdateEffect from "../hooks/useDidUpdateEffect";
import { Urls } from "../util/config";

const TEST_MODE = false;
const defaultBanner = '/img/banner.jpg';
const defaultPerson = '/img/baseline-person.svg';
const NAX_GROUP = 10;
const NEXT_DELAY = TEST_MODE ? 3000 : 15000;

const Title = withStyles(styles)((props: WithStyles<typeof styles> & { event: Event, portal: Portal, competitionName: string }) => {
    const { classes, portal, event, competitionName } = props;
    return (
        <Flex placeMiddle className={classes.tvHeader}>
            <img className={classes.tvBadge} src={portal.badgeUrl || defaultBanner} alt="" />
            <div>
                <span className={classes.uppercaseText}>{event.name}</span>
                <br />
                <span className={classes.tvHeaderSecondary}>{competitionName}</span>
            </div>
        </Flex>
    );
});

const Icon = withStyles(styles)((props: WithStyles<typeof styles> & { contact: Contact }) => {
    const { contact, classes } = props;
    return <img src={contact.avatar || defaultPerson} className={classes.tvAvatar} alt="" />;
});

const NoIcon = withStyles(styles)((props: WithStyles<typeof styles>) => {
    const { classes } = props;
    return <span className={classes.width0 + ' ' + classes.tvAvatar} />;
});

const NamesIcons = withStyles(styles)((props: WithStyles<typeof styles> & { contacts: Contact[], maxContacts?: number, sameNameGolfersIdsSet: Set<string> }) => {
    const { maxContacts, classes, sameNameGolfersIdsSet } = props;
    let { contacts } = props;
    const empties = contacts.filter(c => !c.id).length;
    let more = '';
    if (contacts.length > 0) {
        const teamFormat = contacts.length > 1;
        if (maxContacts && contacts.length > maxContacts + empties) {
            more = `(+${contacts.length - maxContacts - empties} more)`;
            contacts = contacts.slice(0, maxContacts + empties);
        }
        return (
            <Flex placeCenter className={classes.tvItem}>
                {contacts.map((contact, idx) => !contact.id ?
                    (<React.Fragment key={idx}>
                        <span className={classes.tvItemElem}>&nbsp;&nbsp;&nbsp;</span>
                    </React.Fragment>)
                    :
                    (<React.Fragment key={idx}>
                        <Icon contact={contact} />
                        <span className={classes.tvItemElem}>{teamFormat ? shortName(contact, false, 20) : fullName(contact, 30, 30)}&nbsp;</span>
                        <span className={classes.homeCourseOrCityTV}>{contact?.homeCourseOrCity && sameNameGolfersIdsSet.has(contact.id) ? ` (${contact.homeCourseOrCity})` : ''}</span>
                    </React.Fragment>)
                )}
                {!!more &&
                    <React.Fragment>
                        <NoIcon />
                        <span className={classes.tvItemElem} style={{ flexShrink: 0 }}>{more}</span>
                    </React.Fragment>}
            </Flex>
        );
    } else {
        return (
            <Container spacing={0}>
                {''}
            </Container>
        );
    }
});

const Footer = withStyles(styles)((props: WithStyles<typeof styles> & { event: Event }) => {
    const { classes, event } = props;
    return (
        <Container className={classes.tvFooter}>
            <ItemS xs={12} sm={4} color={'white'}><Logo /></ItemS>
            <ItemS xs={12} sm={8} placeRight baseline>
                <a href={`${Urls.baseUrl}/event/${event.publicId}`} className={classes.tvLink} target="_blank" rel="noopener noreferrer">
                    <LinkIcon className={classes.tvTextIcon} />{Urls.baseUrl.split('//')[1]}/event/{event.publicId}
                </a>
            </ItemS>
        </Container>
    );
});

const HeaderScore = withStyles(styles)((props: { scoring: ScoringData } & WithStyles<typeof styles> & { withWinnings: boolean }) => {
    const { classes, scoring, withWinnings } = props;
    const isNet = isNetMode(scoring.mode);
    const isGross = isGrossMode(scoring.mode);
    const isTeam = isTeamScoring(scoring);
    const isStableford = isStablefordScoringOrMode(scoring);
    return (
        <Container spacing={0} wrap="nowrap" className={classes.tvListItemHeader}>
            <Item className={classes.tvItem} placeCenter xs={1}>Pos</Item>
            <Item className={classes.tvItem} xs={isNet && withWinnings ? 6 : isNet || withWinnings ? 7 : 8}>{isTeam ? 'Team' : 'Golfer'}</Item>
            <Item className={classes.tvItem + ' ' + classes.tvListItemScore0} placeCenter xs={1}>{isNet ? 'Net' : 'Score'}</Item>
            <Item className={classes.tvItem} placeCenter xs={1}>Thru</Item>
            <Item className={classes.tvItem} placeCenter xs={1}>Total<br />{isNet ? ' Gross' : ''}</Item>
            {!isStableford && isNet && <Item className={classes.tvItem} placeCenter xs={1}>Total<br /> Net</Item>}
            {isStableford && isGross && <Item className={classes.tvItem} placeCenter xs={1}>Pts</Item>}
            {isStableford && isNet && <Item className={classes.tvItem} placeCenter xs={1}>Pts Net</Item>}
            {withWinnings && <Item className={classes.tvItem} placeCenter xs={1}>Purse</Item>}
        </Container>
    );
});

interface RowProps {
    scoring: ScoringData;
    holeCount: number;
    last: boolean;
    index: number;
    winning?: number;
    sameNameGolfersIdsSet: Set<string>;
    reportedScores: Map<string, ReportedScore>;
}

const RowScore = withStyles(styles)((props: ContactScoringState & WithStyles<typeof styles> & RowProps) => {
    const { last, index, pos, contactInfo, total, net, relativeTotal, relativeNet, holes, holeCount, classes, scoring,
        stableford, stablefordNet, winning, winnerIn, sameNameGolfersIdsSet, reportedScores } = props;
    const disOrWit = contactInfo.withdrawn ? 'WD' : contactInfo.disqualified ? 'DQ' : undefined;
    const isNet = isNetMode(scoring.mode);
    const isGross = isGrossMode(scoring.mode);
    const isStableford = isStablefordScoringOrMode(scoring);
    const relScore = Scoring.formatRelativeScore(isNet ? relativeNet : relativeTotal);
    const thru = holes === holeCount ? 'F' : holes;
    const posWinner = pos + (winnerIn && winnerIn.find(w => w === scoring.mode) ? '*' : '');
    const className = classes.tvListItem + (last ? ' ' + classes.tvListItemLast : '');
    const bestBall = scoring.format === ScoringFormatTeams.best_ball;
    const getComponent = (original: boolean = true) => (
        <Container spacing={0} key={contactInfo.id + original} wrap="nowrap" className={original ? className : ''}>
            <Item className={classes.tvItem} placeCenter xs={1}>{posWinner}</Item>
            <Item className={classes.tvItem} xs={isNet && (!!winning || winning === 0) ? 6 : isNet || (!!winning || winning === 0) ? 7 : 8}>
                <NamesIcons contacts={contactInfo.contacts} sameNameGolfersIdsSet={sameNameGolfersIdsSet} />
            </Item>
            <Item className={classes.tvItem + ' ' + classes['tvListItemScore' + (index < 8 ? index + 1 : 8)]} placeCenter xs={1}>{!disOrWit ? relScore : disOrWit}</Item>
            <Item className={classes.tvItem} placeCenter xs={1}>{thru}</Item>
            <Item className={classes.tvItem} placeCenter placeMiddle xs={1}>{!disOrWit ? total : disOrWit}</Item>
            {!isStableford && isNet && <Item className={classes.tvItem} placeCenter xs={1}>{!disOrWit ? net : disOrWit}</Item>}
            {isStableford && isGross && <Item className={classes.tvItem} placeCenter xs={1}>{!disOrWit ? stableford : disOrWit}</Item>}
            {isStableford && isNet && <Item className={classes.tvItem} placeCenter xs={1}>{!disOrWit ? stablefordNet : disOrWit}</Item>}
            {(!!winning || winning === 0) && <Item className={classes.tvItem} placeCenter xs={1}>{!disOrWit ? '$' + winning : disOrWit}</Item>}
        </Container>
    );
    const [scoresUpdated, setScoresUpdated] = React.useState<boolean>(false);
    const styledComponent = <ContainerStyled className={className} onAnimationEnd={() => setScoresUpdated(false)}>
        {getComponent(false)}
    </ContainerStyled>;
    const scoresToCheck = Scoring.getMonitoringReportedScoresArray(reportedScores, holeCount, bestBall, contactInfo);
    useDidUpdateEffect(() => setScoresUpdated(true), scoresToCheck);
    return scoresUpdated ? styledComponent : getComponent();
});

const LDHeader = withStyles(styles)((props: WithStyles<typeof styles> & { withWinnings: boolean }) => {
    const { classes, withWinnings } = props;
    return (
        <Container spacing={0} wrap="nowrap" className={classes.tvListItemHeader}>
            <Item className={classes.tvItem} placeCenter xs={1}>Hole</Item>
            <Item className={classes.tvItem} xs={withWinnings ? 10 : 11}>Winner</Item>
            {withWinnings && <Item xs={1}>Purse</Item>}
        </Container>
    );
});

const LDRow = withStyles(styles)((props: HolesScoringState & WithStyles<typeof styles> & { last: boolean, sameNameGolfersIdsSet: Set<string> } & { winning?: number }) => {
    const { last, hole, contacts, classes, winning, sameNameGolfersIdsSet } = props;
    return (
        <Container spacing={0} wrap="nowrap" className={classes.tvListItem + (last ? ' ' + classes.tvListItemLast : '')}>
            <Item className={classes.tvItem} placeCenter xs={1}>{hole + 1}</Item>
            <Item className={classes.tvItem} xs={(!!winning || winning === 0) ? 10 : 11}><NamesIcons contacts={contacts} sameNameGolfersIdsSet={sameNameGolfersIdsSet} /></Item>
            {(!!winning || winning === 0) && <Item className={classes.tvItem} xs={1}>{'$' + winning}</Item>}
        </Container>
    );
});

const KPHeader = withStyles(styles)((props: WithStyles<typeof styles> & { withWinnings: boolean }) => {
    const { classes, withWinnings } = props;
    return (
        <Container spacing={0} wrap="nowrap" className={classes.tvListItemHeader}>
            <Item className={classes.tvItem} placeCenter xs={1}>Hole</Item>
            <Item className={classes.tvItem} xs={withWinnings ? 8 : 9}>Winner</Item>
            <Item className={classes.tvItem} placeCenter xs={2}>Distance</Item>
            {withWinnings && <Item className={classes.tvItem} placeCenter xs={1}>Purse</Item>}
        </Container>
    );
});

const KPRow = withStyles(styles)((props: { units?: Units } & HolesScoringState & WithStyles<typeof styles> & { last: boolean, sameNameGolfersIdsSet: Set<string> } & { winning?: number }) => {
    const { units, last, hole, score, contacts, classes, winning, sameNameGolfersIdsSet } = props;
    return (
        <Container spacing={0} wrap="nowrap" className={classes.tvListItem + (last ? ' ' + classes.tvListItemLast : '')}>
            <Item className={classes.tvItem} placeCenter xs={1}>{hole + 1}</Item>
            <Item className={classes.tvItem} xs={(!!winning || winning === 0) ? 8 : 9}><NamesIcons contacts={contacts} sameNameGolfersIdsSet={sameNameGolfersIdsSet} /></Item>
            <Item className={classes.tvItem} placeCenter xs={2}>{formatDistance(units, score)}</Item>
            {(!!winning || winning === 0) && <Item className={classes.tvItem} placeCenter xs={1}>{'$' + winning}</Item>}
        </Container>
    );
});

const SkinsHeader = withStyles(styles)((props: { scoring: ScoringData } & WithStyles<typeof styles> & { withWinnings: boolean }) => {
    const { classes, scoring, withWinnings } = props;
    const isNet = isNetMode(scoring.mode);
    return (
        <Container spacing={0} wrap="nowrap" className={classes.tvListItemHeader}>
            <Item className={classes.tvItem} xs={1} placeCenter>Hole</Item>
            <Item className={classes.tvItem} xs={1} placeCenter>Par</Item>
            <Item className={classes.tvItem} xs={1} placeCenter>Lowest<br />{isNet ? 'net' : 'gross'}</Item>
            <Item className={classes.tvItem} xs={withWinnings ? 8 : 9}>Golfer(s)</Item>
            {withWinnings && <Item className={classes.tvItem} xs={1}>Purse</Item>}
        </Container>
    );
});

const SkinsRow = withStyles(styles)((props: SkinsScoringState & WithStyles<typeof styles> & { last: boolean, teamSize: number, sameNameGolfersIdsSet: Set<string> } & { winning?: number }) => {
    const { classes, hole, par, score, contacts, last, teamSize, winning, sameNameGolfersIdsSet } = props;
    const oneWinner = contacts.length === 1;
    let allContacts: Contact[] = [];
    if (teamSize > 1) {
        contacts.forEach(contact => allContacts = allContacts.concat(contact.contacts).concat({ id: '', lastName: '', gender: '', hidden: false }));
    } else {
        contacts.forEach(contact => allContacts = allContacts.concat(contact.contacts));
    }
    return (
        <Container spacing={0} wrap="nowrap" className={classes.tvListItem + (last ? ' ' + classes.tvListItemLast : '')}>
            <Item className={classes.tvItem} xs={1} placeCenter>{hole + 1}</Item>
            <Item className={classes.tvItem} xs={1} placeCenter>{par}</Item>
            <Item className={classes.tvItem + (oneWinner ? ' ' + classes.winner : '')} xs={1} placeCenter>{score === null ? '' : score}</Item>
            <Item className={classes.tvItem + (oneWinner ? ' ' + classes.winner : '')} xs={(!!winning || winning === 0) ? 8 : 9}><NamesIcons contacts={allContacts} maxContacts={3} sameNameGolfersIdsSet={sameNameGolfersIdsSet} /></Item>
            {(!!winning || winning === 0) && <Item className={classes.tvItem + (oneWinner ? ' ' + classes.winner : '')} xs={1} placeCenter>{'$' + winning}</Item>}
        </Container>
    );
});

const TransparentRow = withStyles(styles)((props: WithStyles<typeof styles>) => {
    const { classes } = props;
    return (
        <Container spacing={0} wrap="nowrap" className={classes.transparent}>
            <Item className={classes.tvItem} placeCenter xs={12}>{' '}</Item>
        </Container>
    );
});

const Transparent = withStyles(styles)((props: { size: number }) => {
    const { size } = props;
    if (size <= 0) {
        return null;
    }
    return (
        <React.Fragment>
            {array(size, 0).map((n, i) => <TransparentRow key={i} />)}
        </React.Fragment>
    );
});

const HeaderNoScore = withStyles(styles)((props: WithStyles<typeof styles>) => {
    const { classes } = props;
    return (
        <Container className={classes.tvListItemHeader}>
            <ItemS>
                <NoIcon />
                <Flex>Live scoring is currently disabled by event administrator</Flex>
            </ItemS>
        </Container>
    );
});


const HeaderNoTees = withStyles(styles)((props: WithStyles<typeof styles>) => {
    const { classes } = props;
    return (
        <Container className={classes.tvListItemHeader}>
            <ItemS>
                <NoIcon />
                <Flex>This competition has not been set up by event administrator yet</Flex>
            </ItemS>
        </Container>
    );
});

interface State {
    competitions: Competition[];
    golferScores: Map<string, Score>;
    teamScores: Map<string, Score>;
    reportedScores?: Map<string, ReportedScore>;
    reportedTeamScores?: Map<string, ReportedScore>;
    calculatedScores: Map<string, CalculatedScores>;
    golfers: Map<string, Contact>;
    teams: Map<string, Team>;
    groups: GolferGroup[];
    distances: Map<string, Distance>;
    curCompetition: number;
    curFlight: number;
    groupOffset: number;
    units?: Units;
    eventId?: string;
    event?: Event;
    portal?: Portal;
    mainCompetition?: Competition;
    loads: boolean[];
}

type Props = WithStyles<typeof styles> & RouteComponentProps<any>;

class TVLeaderboard extends React.Component<Props, State> {
    state: State = {
        golferScores: new Map<string, Score>(),
        teamScores: new Map<string, Score>(),
        calculatedScores: new Map<string, CalculatedScores>(),
        golfers: new Map<string, Contact>(),
        groups: [],
        teams: new Map<string, Team>(),
        competitions: [],
        distances: new Map<string, Distance>(),
        curCompetition: 0,
        curFlight: 1,
        groupOffset: 0,
        loads: [false, false, false, false, false, false]
    };

    private interval?: NodeJS.Timeout;

    async componentDidMount() {
        const { match } = this.props;
        const hideProgress = showProgress();
        const eventMappingId = await Backend.getEntity<EventMapping>(Backend.eventMappingDb, match.params.id);
        this.setEventId(eventMappingId);
        hideProgress();
        this.interval = setInterval(() => this.update(), NEXT_DELAY);
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    private setPropertyLoaded = (ind: 0 | 1 | 2 | 3 | 4 | 5) => {
        const { loads } = this.state;
        loads[ind] = true;
        return loads;
    }

    private setEventId = (em?: EventMapping) => this.setState({ eventId: em?.eventId || '' });
    private setEvent = (event: Event) => this.setState({ event });
    private setPortal = (portal: Portal) => this.setState({ portal });
    private onGroups = (groups: GolferGroup[]) => this.setState({ groups, loads: this.setPropertyLoaded(0) }, this.getGolferScores);
    private onGolfers = (golfers: Map<string, Contact>) => this.setState({ golfers, loads: this.setPropertyLoaded(1) }, this.getGolferScores);
    private onDistances = (distances: Map<string, Distance>) => this.setState({ distances });
    private onTeamScores = (scores: Map<string, Score>) => this.setState({ teamScores: scores, loads: this.setPropertyLoaded(2) }, this.getGolferScores);
    private onGolferScores = (scores: Map<string, Score>) => this.setState({ golferScores: scores, loads: this.setPropertyLoaded(3) }, this.getGolferScores);

    private onTeams = (teams: Map<string, Team>) => {
        const { golfers } = this.state;
        teams.forEach(team => team.handicapIndex = round(evalAvg(team.contactIds.map(cId => !!golfers.get(cId) ? (golfers.get(cId)!.handicapIndex || 0) : 0)), 1));
        this.setState({ teams }, this.getGolferScores);
    }

    private getGolferScores = () => {
        const { event, loads } = this.state;
        if (event && loads.every(val => val)) {
            const { competitions, reportedScores, reportedTeamScores, golferScores, teamScores, groups, golfers, teams, mainCompetition } = this.state;
            const calculatedScores = Scoring.getGolferScores(event, competitions, golfers, teams, groups, golferScores, teamScores, reportedScores || new Map<string, ReportedScore>(), reportedTeamScores || new Map<string, ReportedScore>(), mainCompetition);
            this.setState({ calculatedScores });
        }
    }
        
    private onReportedGolferScores = (reportedScores: Map<string, ReportedScore>) => {
        const { event } = this.state;
        if (!!event && (!event.hideLiveScores || event.hideLiveScores === 'ON' || !this.state.reportedScores)) {
            this.setState({ reportedScores, loads: this.setPropertyLoaded(4) }, this.getGolferScores);
        }
    }
    
    private onReportedTeamScores = (reportedTeamScores: Map<string, ReportedScore>) => {
        const { event } = this.state;
        if (!!event && (!event.hideLiveScores || event.hideLiveScores === 'ON' || !this.state.reportedTeamScores)) { 
            this.setState({ reportedTeamScores, loads: this.setPropertyLoaded(5) }, this.getGolferScores);
        } 
    }

    private onCompetitions = (competitions: Competition[]) => {
        const competitionsMain = competitions.filter(competition => isMainScoring(competition.scoring));
        const competitionsDistanceGames = competitions.filter(competition => !isMainScoring(competition.scoring) && isDistanceScoring(competition.scoring));
        const comps: Competition[] = [];
        competitionsMain.forEach(competition => {
            if (isNetMode(competition.scoring.mode)) {
                comps.push(Scoring.netCompetition(competition));
            }
            if (isGrossMode(competition.scoring.mode)) {
                comps.push(Scoring.grossCompetition(competition));
            }
        });
        competitionsDistanceGames.forEach(competition => comps.push(competition));
        const mainCompetition = getEventMainCompetition(comps);
        this.setState({ competitions: comps, mainCompetition }, this.getGolferScores);
    }

    private update = () => {
        const { event, golfers, teams, competitions } = this.state;
        let { curCompetition, curFlight, groupOffset } = this.state;
        if (event && curCompetition < competitions.length) {
            const competition = competitions[curCompetition];
            const compLength = isSkinsScoring(competition.scoring) || isDistanceScoring(competition.scoring) ?
                getHolesCount(competition, event.holesType) :
                getCompetitionFlightScoresCount(competition, golfers, teams, curFlight);
            if (groupOffset + NAX_GROUP < compLength) {
                groupOffset += NAX_GROUP;
            } else if (curFlight < (competition.flights || 0)) {
                curFlight++;
                groupOffset = 0;
            } else {
                curCompetition++;
                if (curCompetition === competitions.length) {
                    curCompetition = 0;
                }
                groupOffset = 0;
                curFlight = 1;
            }
        } else {
            curFlight = 1;
            curCompetition = 0;
            groupOffset = 0;
        }
        this.setState({ curCompetition, groupOffset, curFlight });
    }

    Competition = (params: { event: Event, portal: Portal, competition: Competition, flight: number }) => {
        const { event, portal, competition, flight } = params;
        const { mode } = competition.scoring;
        const Skins = () => (
            <React.Fragment>
                {isNetMode(mode) && this.SkinsCompetition({ event, portal, competition: Scoring.netCompetition(competition) })}
                {isGrossMode(mode) && this.SkinsCompetition({ event, portal, competition: Scoring.grossCompetition(competition) })}
            </React.Fragment>
        );
        const Scores = () => (
            <React.Fragment>
                {isNetMode(mode) && this.ScoreCompetition({ event, portal, competition: Scoring.netCompetition(competition), flight })}
                {isGrossMode(mode) && this.ScoreCompetition({ event, portal, competition: Scoring.grossCompetition(competition), flight })}
            </React.Fragment>
        );
        return (
            <React.Fragment>
                {isDistanceScoring(competition.scoring) ?
                    this.DistanceCompetition({ event, portal, competition }) :
                    isSkinsScoring(competition.scoring) ?
                        <React.Fragment>{Skins()}</React.Fragment> :
                        <React.Fragment>{Scores()}</React.Fragment>
                }
            </React.Fragment>
        );
    }

    SkinsCompetition = (params: { event: Event, portal: Portal, competition: Competition }) => {
        const { event, portal, competition } = params;
        const { competitions, golferScores, teamScores, reportedScores, reportedTeamScores, groups, golfers, teams, groupOffset, mainCompetition } = this.state;
        if (!mainCompetition || !isCompatibleCompetitionSkins(competition, mainCompetition)) {
            return null;
        }
        const skinsMixed = !!competitions.find(comp => comp.scoring.format === ScoringFormatTeams.best_ball);
        const scores: ContactScoringState[] = Scoring.golferHoleScores(event, competition, 0, golferScores, teamScores, reportedScores || new Map<string, ReportedScore>(), reportedTeamScores || new Map<string, ReportedScore>(), golfers, teams, groups, mainCompetition);
        const gender = genderFromEvent(event);
        const tee = getTee(mainCompetition, gender);
        let skins: SkinsScoringState[] = getSkinsScores(event, competition, scores, tee);
        const allWinners: number = skins.filter(s => s.contacts.length === 1).length;
        if (!TEST_MODE) {
            skins = skins.slice(groupOffset, groupOffset + NAX_GROUP);
        }
        const noTees = !hasTees(event, mainCompetition);
        const withWinnings = (isNetPayouts(competition) || isGrossPayouts(competition)) && isFullScoresCompetition(event, competition, golferScores, teamScores || new Map<string, ReportedScore>(), reportedScores || new Map<string, ReportedScore>(), golfers, teams);
        const sameNameGolfersIdsSet: Set<string> = getSameNameGolfersIds(Array.from(golfers.values()));
        return (
            <React.Fragment>
                <Title portal={portal} event={event} competitionName={Scoring.scoringName(competition, event.eventGender, competition.competitionGender, skinsMixed)} />
                {noTees ?
                    <HeaderNoTees /> :
                    <List disablePadding>
                        <SkinsHeader scoring={competition.scoring} withWinnings={withWinnings} />
                        {skins.map((skin, i) => <SkinsRow teamSize={event.teamSize} key={skin.hole} last={i === skins.length - 1} {...skin} winning={withWinnings ? skinCompetitionAward(skin, competition, skins, golfers, teams, allWinners) : undefined} sameNameGolfersIdsSet={sameNameGolfersIdsSet} />)}
                        <Transparent size={NAX_GROUP - skins.length} />
                    </List>}
                <Spacing />
            </React.Fragment>
        );
    }

    DistanceCompetition = (params: { event: Event, portal: Portal, competition: Competition }) => {
        const { event, portal, competition } = params;
        const { golfers, distances, groupOffset, units, mainCompetition } = this.state;
        if (!mainCompetition) {
            return null;
        }
        const gender = genderFromEvent(event);
        let distanceInfo = getDistanceScores(event, competition, golfers, distances, getTee(mainCompetition, gender));
        if (!TEST_MODE) {
            distanceInfo = distanceInfo.slice(groupOffset, groupOffset + NAX_GROUP);
        }
        const isNet = isNetMode(competition.scoring.mode);
        const payoutSettings: PayoutSettings | undefined | null = isNet && competition.payoutsNet ? competition.payoutsNet[0] : competition.payoutsGross ? competition.payoutsGross[0] : undefined;
        const sameNameGolfersIdsSet: Set<string> = getSameNameGolfersIds(Array.from(golfers.values()));
        const payoutPerHole = payoutSettings?.payoutPerHole;
        const payoutsEligible = Boolean(payoutSettings?.enabled) && payoutPerHole != null && payoutPerHole >= 0;
        return (
            <React.Fragment>
                <Title portal={portal} event={event} competitionName={Scoring.scoringName(competition)} />
                {competition.scoring.format === ScoringFormatDistance.longest_drive ?
                    <List disablePadding>
                        <LDHeader withWinnings={payoutsEligible && distanceInfo.findIndex(info => info.contacts && info.contacts.length > 0) > -1} />
                        {distanceInfo.map((info, index) => <LDRow key={info.hole} last={index === distanceInfo.length - 1} {...info} winning={(payoutsEligible && info.contacts && info.contacts.length > 0) ? payoutPerHole : undefined} sameNameGolfersIdsSet={sameNameGolfersIdsSet} />)}
                        <Transparent size={NAX_GROUP - distanceInfo.length} />
                    </List > : <List disablePadding>
                        <KPHeader withWinnings={payoutsEligible && distanceInfo.findIndex(info => info.contacts && info.contacts.length > 0) > -1} />
                        {distanceInfo.map((info, index) => <KPRow key={info.hole} last={index === distanceInfo.length - 1} {...info} units={units} winning={(payoutsEligible && info.contacts && info.contacts.length > 0) ? payoutPerHole : undefined} sameNameGolfersIdsSet={sameNameGolfersIdsSet} />)}
                        <Transparent size={NAX_GROUP - distanceInfo.length} />
                    </List >}
                <Spacing />
            </React.Fragment>
        );
    }

    ScoreCompetition = (params: { event: Event, portal: Portal, competition: Competition, flight: number }) => {
        const { calculatedScores, mainCompetition } = this.state;
        const { classes } = this.props;
        const { event, portal, competition, flight } = params;
        const competitionScores = calculatedScores.get(competition.id);
        if (!competitionScores) {
            return null;
        }
        const { golferScores, teamScores, reportedScores, reportedTeamScores, groups, golfers, teams, groupOffset, curFlight } = this.state;
        const isNet = isNetMode(competition.scoring.mode);
        let scoresSlice = isNet ? competitionScores.competitionScoresNet.get(flight) : competitionScores.competitionScoresGross.get(flight);
        if (!TEST_MODE) {
            scoresSlice = scoresSlice?.slice(groupOffset, groupOffset + NAX_GROUP);
        }
        const scores = scoresSlice;
        if (!scores) {
            return null;
        }
        const noTees = !hasTees(event, competition) && !event.leaderboard;
        const holesRange = getHolesRange(event.holesType);
        const holeCount = holesRange.last - holesRange.first;
        const payouts = getPayouts(competition, competition.scoring.mode, flight);
        const winnings = new Map<string, number>();
        if (isTeamFormat(competition.scoring)) {
            if (competition.scoring.format !== ScoringFormatTeams.best_ball) {
                teamsOf(competition, golfers, teams).forEach(team => winnings.set(team.id, teamScores.has(team.id) ? golferMainCompetitionAward(team.id, event, competition, golferScores, teamScores, reportedScores || new Map<string, ReportedScore>(), reportedTeamScores || new Map<string, ReportedScore>(), golfers, teams, groups, mainCompetition) : 0));
            } else {
                teamsOf(competition, golfers, teams).forEach(team => winnings.set(team.id, team.contactIds.reduce((arr, curr) => arr.length > 0 || !golferScores.has(curr) ? curr : '', '').length === 0 ? golferMainCompetitionAward(team.id, event, competition, golferScores, teamScores, reportedScores || new Map<string, ReportedScore>(), reportedTeamScores || new Map<string, ReportedScore>(), golfers, teams, groups, mainCompetition) : 0));
            }
        } else {
            golfersOfCompetition(competition, golfers, teams).forEach(contact => winnings.set(contact.id, golferScores.has(contact.id) ? golferMainCompetitionAward(contact.id, event, competition, golferScores, teamScores, reportedScores || new Map<string, ReportedScore>(), reportedTeamScores || new Map<string, ReportedScore>(), golfers, teams, groups, mainCompetition) : 0));
        }
        const withWinnings = !!payouts && payouts.enabled && isFullScoresCompetition(event, competition, golferScores, teamScores || new Map<string, ReportedScore>(), reportedScores || new Map<string, ReportedScore>(), golfers, teams);
        const withTieBreaker = () => scores.find(s => !!s.winnerIn) ? <Typography className={`${classes.tvListItem} ${classes.tvTieBreaker}`}>*Winner by tie-breaker<br /><br /></Typography> : undefined;
        const sameNameGolfersIdsSet: Set<string> = getSameNameGolfersIds(Array.from(golfers.values()));
        const isTeamFormatExceptBB = isTeamScoringExceptBB(competition.scoring);
        return (
            <React.Fragment>
                <Title portal={portal} event={event}
                    competitionName={Scoring.scoringName(competition, event.eventGender, competition.competitionGender, undefined, competition.flights ? curFlight : undefined, competition.flightsNaming)} />
                {noTees ?
                    <HeaderNoTees /> :
                    <React.Fragment>
                        <List disablePadding>
                            <HeaderScore scoring={competition.scoring} withWinnings={withWinnings} />
                            {scores.map((score, index) => <RowScore key={score.contactInfo.id} {...score} index={index}
                                last={index === scores.length - 1} holeCount={holeCount} scoring={competition.scoring}
                                winning={withWinnings ? winnings.get(score.contactInfo.id) : undefined}
                                reportedScores={isTeamFormatExceptBB ? (reportedTeamScores || new Map<string, ReportedScore>()) : (reportedScores || new Map<string, ReportedScore>())}
                                sameNameGolfersIdsSet={sameNameGolfersIdsSet} />)}
                            {withTieBreaker()}
                            <Transparent size={NAX_GROUP - scores.length} />
                        </List>
                    </React.Fragment>}
                <Spacing />
            </React.Fragment>
        );
    }

    HideLiveScores = () => {
        const { event, portal } = this.state;
        return (
            <React.Fragment>
                <Title portal={portal!} event={event!} competitionName={''} />
                <List disablePadding><HeaderNoScore /></List>
            </React.Fragment>
        );
    }

    render() {
        const { eventId, curFlight } = this.state;
        if (eventId === undefined) {
            return <NoEvent text="Loading" />;
        }
        if (eventId === '') {
            return <NoEvent />;
        }
        const { classes } = this.props;
        const { event, portal, competitions, curCompetition } = this.state;
        const hideLiveScores = !event || event.hideLiveScores === true || event.hideLiveScores === 'OFF';
        let comps = [];
        if (competitions[curCompetition]) {
            comps.push(competitions[curCompetition]);
        }
        const content = (event && portal && event.exists && !event.deleted) ?
            (
                <div className={classes.tvRoot} onClick={() => { this.update() }} onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => { if (e.key === 'ArrowRight' || e.key === 'Enter' || e.key === ' ') this.update() }} tabIndex={0} >
                    <div className={classes.tvContent}>
                        {hideLiveScores && <this.HideLiveScores />}
                        {!hideLiveScores && comps.map((competition, idx) => <this.Competition key={idx} event={event} portal={portal} competition={competition} flight={competition.flights ? curFlight : 0} />)}
                        {!hideLiveScores && competitions.length === 0 && <HeaderNoTees />}
                        <Footer event={event} />
                    </div>
                    <FirebaseDataComponent query={Backend.query(Backend.golferDb(event.id), Backend.where('hidden', '==', false))} onMap={this.onGolfers} />
                    <FirebaseDataComponent query={Backend.golferTeamDb(event.id)} onMap={this.onTeams} />
                    <FirebaseDataComponent query={Backend.golferTeamScoresDb(event.id)} onMap={this.onTeamScores} />
                    <FirebaseDataComponent query={Backend.golferScoresDb(event.id)} onMap={this.onGolferScores} />
                    <FirebaseDataComponent query={Backend.reportedGolferScoresDb(event.id)} onMap={this.onReportedGolferScores} />
                    <FirebaseDataComponent query={Backend.reportedTeamScoresDb(event.id)} onMap={this.onReportedTeamScores} />
                    <FirebaseDataComponent query={Backend.golferDistancesDb(event.id)} onMap={this.onDistances} />
                    <FirebaseDataComponent query={Backend.query(Backend.competitionsDb(event.id), Backend.orderBy('order'))} onData={this.onCompetitions} />
                    <FirebaseDataComponent query={Backend.query(Backend.golferGroupDb(event.id), Backend.orderBy('order'))} onData={this.onGroups} />
                    <FirebaseUserPubDataComponent uid={event.userId} onData={data => this.setState({ units: data.units })} />
                </div>
            ) : (!event || !portal) ?
                (<NoEvent text="Loading" />) :
                (<NoEvent />);
        return (
            <React.Fragment>
                {content}
                <FirebaseDocComponent onDoc={doc => this.setEvent(Backend.fromEntity<Event>(doc))} docReference={Backend.eventFields(eventId)} />
                <FirebaseDocComponent onDoc={doc => this.setPortal(Backend.fromEntity<Portal>(doc))} docReference={Backend.portalFields(eventId)} />
            </React.Fragment>
        );
    }
}

export default withDataItemFromUrl(Backend.eventMappingDb)(withStyles(styles)(TVLeaderboard));
