import * as React from 'react';
import {
    DialogActions, DialogContent, FormControl, FormControlLabel, FormHelperText, Typography, Radio, RadioGroup,
    Paper, Grid, StyledEngineProvider, Divider, InputAdornment, IconButton, TextField, InputLabel, MenuItem, Select,
    Checkbox, ButtonGroup, Stepper, Step, StepLabel, StepIconProps, Popper, ClickAwayListener, SxProps, useMediaQuery,
    useTheme
} from '@mui/material';
import { createTheme, Theme, ThemeProvider } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from '@mui/icons-material/Search';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import {
    isCompoundFacility, ScoringMode, ScoringModes, ScoringFormatIndividual, ScoringFormat, ScoringFormatTeams,
    ScoringTeamSize, isCompoundCourse, Event, Competition, EventGender, ResultStatus, CourseResponse, Facility,
    FacilitiesGroup, SimpleCourse, Tee, Gender, EVENT_GENDER_LABELS, HOLES_18, HOLES_9, HOLES_9_9, HOLES_TYPES,
    HOLES_TYPES_COMPOUND, HOLES_NAMES, HOLES_TYPES_NINES, TEAM_SCORINGS_AND_SKINS, INDIVIDUAL_SCORINGS,
    INDIVIDUAL_SCORINGS_AND_SKINS, SCORING_TEAM_SIZES, MENS_TEE, WOMENS_TEE, DEFAULT_REGISTRATION_EMAIL_NOTIFY,
    ScoringFormatSkins, isNetMode, ScoringType, EventPaymentSettings, DEFAULT_EVENT_PAYMENT_SETTINGS_ENABLED, UserInfo,
    Pro
} from '../../types/EventTypes';
import {
    createEvent, createNewEvent, getPortalInfo, getUserInfo, createCompetition, fillProportionalHandicaps,
    fillEventHandicaps, getRegistrationDate, tryUpdateUserPayPalInfo
} from '../Event';
import * as AppTheme from '../../main/Theme';
import { WithUserId, withUserId } from '../../auth/Auth';
import { EVENT_DATETIME_FORMAT_DLG, Urls } from '../../util/config';
import * as Backend from '../../util/firebase';
import * as Scoring from '../../scoring/scoring';
import { processEnterKey } from '../../util/react_utils';
import * as Utils from '../../util/utility';
import {DAY_MS, Func, roundDate, getUTCMidnight} from '../../util/utility';
import MaterialDate from '../../common/MaterialDate';
import { SMMobileDialog } from '../../common/dialog/MobileDialog';
import DialogAppBar from '../../common/dialog/DialogAppBar';
import AppButton from '../../common/components/AppButton';
import CopyTextField from '../../common/components/CopyTextField';
import { Spacing } from '../../common/Misc';
import { LinkIcon, SettingsIcon, BkCloseButton } from '../../common/Icons';
import { showProgress } from '../../redux/ReduxConfig';
import { toHolesType, getTeeName, getGender, compareTee } from '../../scoring/handicap';
import { defaultTeeExplanation, HandicapPercentSelector } from '../tabs/scores/CompetitionSettingsDialog';
import StablefordPointSettingsDialog from '../tabs/scores/StablefordPointSettingsDialog';
import { FacilityList } from '../tabs/settings/general/course/FacilityView';
import { CourseViewHeaderShort, CourseViewHeaderLong, CourseViewShort, CourseViewLong } from '../tabs/settings/general/course/CourseSelectionDialog';
import { getHolesType, getCourse, getFacilityName, handleFacilityMissingTees, iCourse, oCourse } from '../Courses';
import RecentProvider, { saveToRecent } from '../tabs/settings/general/course/providers/RecentProvider';
import LocationFacilitiesProvider from '../tabs/settings/general/course/providers/LocationFacilitiesProvider';
import AddressFacilitiesProvider from '../tabs/settings/general/course/providers/AddressFacilitiesProvider';
import TeesProvider from '../tabs/common/TeesProvider';
import EditTeeDialog from '../tabs/common/EditTeeDialog';
import { AppColors } from "../../main/Theme";
import moment from "moment/moment";
import { InfoIconTooltip } from "../../common/components/InfoToolTipProps";
import { TimeTextField } from "../../common/TimeTextField";
import { PayPalPaymentSettings } from "../../payments/paypal/PayPalPaymentSettings";
import { FirebaseDocComponent, FirebaseUserDataComponent } from "../../common/WithData";
import { firebaseAuth } from "../../util/firebase";
import { ParticipantsNumberField } from "../../common/components/ParticipantsNumberField";

declare module '@mui/styles/defaultTheme' {
    interface DefaultTheme extends Theme { }
}

const NEW_EVENT_ELEM_WIDTH = 400;
const NEW_EVENT_STEPS = ['Event details', 'Course setup', 'Registration', 'Scoring'];

const scoringFormatDescriptions = new Map<ScoringFormat, Array<string>>(
    [
        [
            ScoringFormatIndividual.strokeplay, [
                'This is the most common tournament format. Leaderboard is ranked by each golfer’s gross or net score.']
        ],
        [
            ScoringFormatIndividual.stableford, [
                'This format rewards aggressive play style with extra incentives to go for birdies and eagles.',
                'Leaderboard is ranked by Stableford points derived from each golfer’s gross or net score.']
        ],
        [
            ScoringFormatSkins.skins_individual, [
                'In this format each hole is worth one skin. The golfer with the lowest score on a hole wins the skin, no skin is awarded in case of a tie.',
                'The player with the highest number of skins at the end of the competition wins.']
        ],
        [
            ScoringFormatTeams.best_ball, [
                'This team format offers the great balance of a competitive and low pressure tournament experience.',
                'Leaderboard is ranked by team score on each hole, which is the best individual score of the team from each hole.']
        ],
        [
            ScoringFormatTeams.alternate_shot, [
                'The competition usually consists of 2-person teams.',
                'The format suggests teammates to alternate shot on each hole, throughout the competition. Regardless of which golfer finishes the previous hole, teammates must alternate tee shots throughout the round.']
        ],
        [
            ScoringFormatTeams.scramble, [
                'The format suggests relaxed rules and lack of pressure to achieve individual scores.',
                'After team members tee off, the team selects the best result and everyone plays their next shot from this spot. This process is continued throughout the rest of the shots (including putting), until the ball is holed.']
        ],
        [
            ScoringFormatTeams.chapman, [
                'The competition usually consists of 2-person teams.',
                'Teammates both tee off and then switch balls and play the other persons tee shot. Team then selects the best second shot result and alternate shots in from there, until the ball is holed.']
        ],
        [
            ScoringFormatSkins.skins_team, [
                'In this format each hole is worth one skin. The team with the lowest score on a hole wins the skin, no skin is awarded in case of a tie.',
                'The team with the highest number of skins at the end of the competition wins.']
        ],
    ]
);

const scoringModeDescriptions = new Map<ScoringMode, Array<string>>([
    [
        'gross',
        ['Gross scoring is a total of all strokes plus penalty strokes. It\'s a good, simple scoring method for golfers just starting out or those playing by themselves.']
    ],
    [
        'net',
        ['Net format scores incorporate a player\'s course handicap into this process. The net score is determined by subtracting the player\'s handicap from the gross score (number of strokes actually taken).']
    ],
    [
        'gross+net',
        ['Both gross and net scores are being calculated separately. This option provides more information for players to see.']
    ]
]);

const scoringTypeDescriptions = new Map<ScoringType, Array<string>>([
    [
        'strokeplay',
        ['This scoring type represents a common way of points calculation. The golfer with the best overall score at the end of the round, or number of rounds is deemed the winner.']
    ],
    [
        'stableford',
        ['The goal of this scoring type is to amass more points than your opponents throughout the round or competition. This option also allows points customization based on your preferences.']
    ]
]);

const ProgressRadio = ({ value, label, width }: { value: string, label: React.ReactNode, disabled?: boolean, width?: number }) => {
    return (
        <span style={{ width }}>
            <FormControlLabel value={value} style={{ marginLeft: 0 }} control={<Radio color="secondary" />} label={label} />
        </span>
    );
}

const ScoringFormatRadio = ({ teamMode, scoringFormat, selectedScoringFormat, width, onClick }: { teamMode: boolean, scoringFormat: ScoringFormat, selectedScoringFormat: ScoringFormat, width?: number, onClick?: Func<void> }) => {
    return (
        <span key={scoringFormat} style={{ width }}>
            <FormControlLabel key={scoringFormat}
                value={scoringFormat}
                style={{ marginLeft: 0 }}
                control={<Radio color="secondary" />}
                label={Utils.makeFriendlyString(scoringFormat, true)} />
            {Boolean(teamMode && scoringFormat === ScoringFormatIndividual.stableford && onClick) &&
                <IconButton
                    color="default"
                    disabled={selectedScoringFormat !== ScoringFormatIndividual.stableford}
                    onClick={onClick}
                    size="large"><SettingsIcon /></IconButton>}
            {Boolean(!teamMode && scoringFormat === ScoringFormatIndividual.stableford && onClick) &&
                <AppButton color="info" sx={{ '&.Mui-disabled': { backgroundColor: 'inherit', borderColor: '#949494' } }}
                    disabled={selectedScoringFormat !== ScoringFormatIndividual.stableford} onClick={onClick}>
                    <SettingsIcon /> Point settings
                </AppButton>}
        </span>
    );
}

const stepperTheme = createTheme({
    components: {
        MuiStepper: {
            styleOverrides: {
                root: {
                    backgroundColor: AppColors.primary,
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingTop: 0,
                    paddingBottom: 20,
                    '@media (max-width: 400px)': {
                        paddingLeft: 8,
                        paddingRight: 8,
                    },
                    '@media (max-width: 360px)': {
                        paddingLeft: 4,
                        paddingRight: 4,
                    },
                }
            }
        },
        MuiStep: {
            styleOverrides: {
                root: {
                    '&.MuiStep-horizontal': {
                        padding: 0,
                    },
                }
            }
        },
        MuiStepLabel: {
            styleOverrides: {
                label: {
                    '@media (max-width: 400px)': {
                        fontSize: '0.7rem',
                    },
                    '@media (max-width: 360px)': {
                        fontSize: '0.6rem',
                    },
                    '@media (max-width: 300px)': {
                        fontSize: '0.5rem',
                    },
                    color: AppColors.blue100,
                    '&.Mui-completed': {
                        color: AppColors.blue100
                    },
                    '&.Mui-active': {
                        color: AppColors.white
                    },
                }
            }
        },
        MuiStepConnector: {
            styleOverrides: {
                line: {
                    borderColor: AppColors.blue100,
                    marginLeft: 8,
                    marginRight: 8,
                    '@media (max-width: 360px)': {
                        marginLeft: 2,
                        marginRight: 2,
                    },
                }
            }
        }
    }
});

const progressStyles = makeStyles({
    icon: {
        width: 22,
        height: 22,
        fontSize: '0.75rem',
        borderRadius: '50%',
        border: '1px solid',
        color: AppColors.blue100,
        backgroundColor: AppColors.primary,
    }
});

const StepIcon = (props: StepIconProps) => {
    const { icon, active, completed } = props;
    const color = active || completed ? AppColors.primary : AppColors.blue100;
    const classes = progressStyles();
    return (
        <div className={classes.icon}>
            <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" style={{ pointerEvents: 'none' }}>
                {completed && <path d="M12 0a12 12 0 1 0 0 24 12 12 0 0 0 0-24zm-2 17l-5-5 1.4-1.4 3.6 3.6 7.6-7.6L19 8l-9 9z" fill={AppColors.blue100} stroke={AppTheme.AppColors.blue100} />}
                {active && <circle cx="12" cy="12" r="12" fill={AppColors.white} stroke={AppColors.white} />}
                {!completed && <text x="12" y="16" textAnchor="middle" fill={color}>{icon}</text>}
            </svg>
        </div>
    );
}

const ProgressBar = ({ selectedStep }: { selectedStep: number }) => {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={stepperTheme}>
                <Stepper activeStep={selectedStep}>
                    {NEW_EVENT_STEPS.map((step, idx) => {
                        const theme = useTheme();
                        const isXs = useMediaQuery(theme.breakpoints.down('sm'));
                        return (<Step key={step}>
                            <StepLabel StepIconComponent={StepIcon}>{isXs ? (idx <= selectedStep ? step : "") : step}</StepLabel>
                        </Step>);
                    })}
                </Stepper>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

type FacilityListWrappedProps = {
    onSelect: (facility: Facility) => void,
    facilitiesGroups: FacilitiesGroup[],
    userId: string,
    onClickAway: () => void
};

const FacilityListWrapped = ({ onSelect, facilitiesGroups, userId, onClickAway }: FacilityListWrappedProps) => {
    const moreThanLg = useMediaQuery(useTheme().breakpoints.up('lg'));
    return (<Paper style={{ width: NEW_EVENT_ELEM_WIDTH, maxHeight: 500, overflow: 'auto', marginTop: 10 }}>
        <ClickAwayListener onClickAway={() => { if (moreThanLg) onClickAway() }}>
            <FacilityList userId={userId} facilitiesGroups={facilitiesGroups} onSelect={onSelect} />
        </ClickAwayListener>
    </Paper>);
};

type RegistrationRadioGroupProps = {
    typo1: React.ReactNode;
    typo2: React.ReactNode;
    onOptionChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
    registeringByAdmin: boolean;
    disabled: boolean;
    registrationLink: string;
};

const RegistrationRadioGroup = ({ typo1, typo2, onOptionChanged, registeringByAdmin, disabled, registrationLink }: RegistrationRadioGroupProps) => {
    const lessThanMd = useMediaQuery(useTheme().breakpoints.down('md'));
    return (<RadioGroup value={String(registeringByAdmin)} sx={{ flexDirection: 'row' }} onChange={onOptionChanged}>
        {lessThanMd ?
            (<Grid container>
                <Grid item xs={12}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <ProgressRadio value="false" label={typo1} />
                        <CopyTextField
                            readOnly btnOnly
                            color="secondary"
                            style={{ marginLeft: 36 }}
                            fieldValue={registrationLink}
                            btnLabel={<React.Fragment>Copy link<LinkIcon /></React.Fragment>}
                            disabled={disabled} />
                        <ProgressRadio value="true" label={typo2} />
                    </div>
                </Grid>
            </Grid>) : (<Grid container>
                <Grid item xs={6}>
                    <ProgressRadio value="false" label={typo1} />
                    <CopyTextField
                        readOnly btnOnly
                        color="secondary"
                        style={{ marginLeft: 36 }}
                        fieldValue={registrationLink}
                        btnLabel={<React.Fragment>Copy link<LinkIcon /></React.Fragment>}
                        disabled={disabled} />
                </Grid>
                <Grid item xs={6}>
                    <ProgressRadio value="true" label={typo2} />
                </Grid>
            </Grid>)}
    </RadioGroup>);
};

enum Pages {
    eventDetails = 0,
    courseSetup = 1,
    registration = 2,
    scoring = 3,
}

type Props = {
    handleClose: (addedId?: string) => void;
} & WithUserId;

interface FacilitiesResult {
    loadResult?: string;
    loadStatus?: ResultStatus;
    facilities?: Array<Facility>;
}

interface State {
    event: Event;
    defaultEventName: string;
    lastBadge?: string;
    lastBanner?: string;
    currentStep: number;
    courseAddress?: string;
    searchAddress: string;
    foundAddress: string;
    tees: Array<Tee>;
    defaultMenTee?: string;
    defaultWomenTee?: string;
    selectedIn?: SimpleCourse;
    selectedOut?: SimpleCourse;
    showFacilities: boolean;
    selectedFacility?: Facility;
    nearbyResult: FacilitiesResult;
    foundResult: FacilitiesResult;
    recentResult: FacilitiesResult;
    teamSizeBackup: ScoringTeamSize;
    individualScoring: ScoringFormatIndividual | ScoringFormatSkins.skins_individual;
    teamScoringFormat: ScoringFormatTeams | ScoringFormatSkins.skins_team;
    scoringMode: ScoringMode;
    showPointSettings?: boolean;
    stablefordPoints?: Array<number>;
    handicapAllowances: Array<number>;
    editingTee?: Tee;
    coursesActivated?: boolean;
    teesLoadStatus?: ResultStatus;
    descriptionType: 'scoringFormat' | 'scoringMode' | 'scoringType';
    teeSelectionCourse?: SimpleCourse;
    notCombinedTees?: Array<Tee>;
    paymentSettings: EventPaymentSettings;
    userInfo?: UserInfo;
    pro?: boolean | undefined;
}

class NewEventDialogDetailed extends React.Component<Props, State> {
    state: State = {
        event: createEvent('', '', '', '', new Date().setHours(9, 0, 0, 0)),
        defaultEventName: '',
        currentStep: 0,
        searchAddress: '',
        foundAddress: '',
        nearbyResult: {},
        foundResult: {},
        recentResult: {},
        tees: [],
        showFacilities: false,
        teamSizeBackup: 2,
        individualScoring: ScoringFormatIndividual.strokeplay,
        teamScoringFormat: ScoringFormatTeams.best_ball,
        scoringMode: 'gross',
        handicapAllowances: [100],
        descriptionType: 'scoringFormat',
        paymentSettings: {
            enabled: DEFAULT_EVENT_PAYMENT_SETTINGS_ENABLED,
            platform: 'PayPal',
            feeCost: 0,
            payeeEmailAddress: '',
            currencyCode: ''
        }
    };

    private readonly coursesLoader: React.RefObject<AddressFacilitiesProvider>;
    private readonly teesLoader: React.RefObject<TeesProvider>;
    private readonly inputRef: React.RefObject<HTMLDivElement>;

    constructor(props: Props) {
        super(props);
        this.coursesLoader = React.createRef();
        this.teesLoader = React.createRef();
        this.inputRef = React.createRef();
    }

    componentDidMount() {
        this.loadInfo();
    }

    private loadInfo = async () => {
        const { userId } = this.props;
        const event = createEvent(userId, '', '', '', new Date().setHours(10, 0, 0, 0));
        const hideProgress = showProgress();
        const { adminName, adminEmail, lastBadge, lastBanner } = await getUserInfo(userId);
        const defaultEventName = Utils.upperFirstLetter(adminName.split(' ')[0]) + `'s tournament`;
        event.userEmail = adminEmail;
        event.userName = adminName;
        hideProgress();
        this.setState({ event, defaultEventName, lastBadge, lastBanner });
    }

    private handleClose = () => {
        Backend.trackEvent('new_event_canceled');
        this.props.handleClose();
    }

    private handleCloseX = (_uiEvent: string, reason: string) => {
        const { showFacilities } = this.state;
        if (showFacilities) {
            this.setState({ showFacilities: false });
            return;
        }
        if ("backdropClick" === reason) {
            return;
        }
        this.handleClose();
    }

    private addTees = (competition: Competition) => {
        const { tees, defaultMenTee, defaultWomenTee } = this.state;
        if (tees) {
            competition.tees = [];
            competition.tees[MENS_TEE] = tees.find(tee => tee.id === defaultMenTee) ?? null;
            competition.tees[WOMENS_TEE] = tees.find(tee => tee.id === defaultWomenTee) ?? null;
        }
        return competition;
    }

    private trySaveUserPaymentData = async () => {
        const { userInfo, event } = this.state;
        const paymentSettings = event.paymentSettings;
        await tryUpdateUserPayPalInfo(userInfo, paymentSettings, undefined);
    };

    private handleSave = async () => {
        const { event, defaultEventName, lastBadge, lastBanner, individualScoring, teamScoringFormat, scoringMode, stablefordPoints, handicapAllowances, paymentSettings } = this.state;
        const hideProgress = showProgress();
        try {
            const competition = this.addTees(createCompetition(event.teamSize, [], event.teamSize > 1 ? teamScoringFormat : individualScoring, scoringMode, handicapAllowances));
            competition.scoring.stablefordMode = individualScoring === ScoringFormatIndividual.stableford;
            if (stablefordPoints) {
                competition.scoring.mstablefordPoints = stablefordPoints;
                if (competition.scoring.format === ScoringFormatIndividual.stableford && stablefordPoints.length > 0 &&
                    !Utils.equalArrays(stablefordPoints, Scoring.defaultStablefordPoints)) {
                    competition.scoring.format = ScoringFormatIndividual.modified_stableford;
                }
            }
            event.name = event.name.trim() || defaultEventName;
            event.appCompetition = competition;
            if ((paymentSettings.payeeEmailAddress.length > 0 && paymentSettings.feeCost > 0) ||
                (!paymentSettings.enabled && (paymentSettings.payeeEmailAddress.length > 0 || paymentSettings.feeCost > 0))) {
                event.paymentSettings = paymentSettings;
            }
            const portal = await getPortalInfo(lastBadge, lastBanner)
            const id = await createNewEvent(event, portal, [competition]);
            await this.trySaveUserPaymentData();
            hideProgress();
            this.props.handleClose(id);
        } catch (err) {
            console.log(`handleSave error ${err}`);
        } finally {
            hideProgress()
        }
    }

    private handleNext = async () => {
        const { event } = this.state;
        let { currentStep } = this.state;
        if (currentStep < NEW_EVENT_STEPS.length - 1) {
            currentStep++;
            this.setState({ currentStep });
            if (currentStep === Pages.registration && !event.publicId) {
                const hideProgress = showProgress();
                event.publicId = await Backend.getFreePubId();
                this.setState({ event });
                hideProgress();
            } else if (currentStep === Pages.courseSetup) {
                this.setState({ coursesActivated: true });
            }
        } else {
            this.handleSave();
        }
    }

    private handleBack = () => {
        let { currentStep } = this.state;
        if (currentStep > 0) {
            currentStep--;
            this.setState({ currentStep });
        }
    }

    private setEventProp = (prop: keyof Event, val: any) => {
        const { event } = this.state;
        (event as any)[prop] = val;
        this.setState({ event });
    }

    private setEventName = (val: string) => this.setEventProp('name', Utils.toSafeString(val));

    private setEventDate = (date: Date) => {
        const { event } = this.state;
        event.date = date.getTime();
        event.dateUTC = getUTCMidnight(event.date);
        this.setState({ event });
    };

    private eventDetailsPage = () => {
        const { event, defaultEventName } = this.state;
        const endAdornment = event.name ?
            <InputAdornment position="end">
                <BkCloseButton onClick={() => this.setEventName('')} />
            </InputAdornment> : null;
        return (
            <React.Fragment>
                <FormControl variant="standard" margin="dense" fullWidth>
                    <TextField
                        variant="standard"
                        id="name"
                        label="Event name"
                        value={event.name}
                        placeholder={defaultEventName}
                        onChange={e => this.setEventName(e.target.value)}
                        onKeyDown={e => processEnterKey(e, this.handleNext)}
                        InputProps={{ endAdornment }}
                        InputLabelProps={{ shrink: true }}
                        style={{ width: '100%', maxWidth: NEW_EVENT_ELEM_WIDTH }}
                        autoFocus={true} />
                </FormControl>
                <Spacing />
                <MaterialDate
                    icon
                    enableUnderline
                    label="Event date"
                    value={event.date}
                    onChange={this.setEventDate}
                    format={EVENT_DATETIME_FORMAT_DLG}
                    autoFocus
                    popperPlacement={'bottom'}
                    style={{ width: '100%', maxWidth: NEW_EVENT_ELEM_WIDTH }} />
                <Spacing />
                <TimeTextField date={event.date} onTimeSelected={this.setEventDate} label="Event start time" />
                <Spacing />
                <FormControl variant="standard" margin="dense" fullWidth>
                    <Typography gutterBottom>Participants</Typography>
                    <RadioGroup value={event.eventGender}
                        style={{ flexDirection: 'row' }}
                        onChange={e => this.setEventProp('eventGender', e.target.value as EventGender)}>
                        <ProgressRadio value={'both'} label={EVENT_GENDER_LABELS[0]} />
                        <ProgressRadio value={'men'} label={EVENT_GENDER_LABELS[1]} />
                        <ProgressRadio value={'women'} label={EVENT_GENDER_LABELS[2]} />
                    </RadioGroup>
                </FormControl>
                <Spacing />
                <TextField
                    variant="standard"
                    id="description"
                    label="Event Description (Optional)"
                    value={event.description || ''}
                    placeholder="Describe your event"
                    onChange={e => this.setEventProp('description', Utils.toSafeString(e.target.value))}
                    onKeyDown={e => processEnterKey(e, this.handleNext)}
                    InputLabelProps={{ shrink: true }}
                    style={{ width: '100%', maxWidth: NEW_EVENT_ELEM_WIDTH }} />
            </React.Fragment>
        );
    }

    private onNearestResult = (loadStatus: ResultStatus, courseResponse?: CourseResponse, err?: any) => {
        const nearbyResult: FacilitiesResult = {
            loadStatus,
            loadResult: err ?? '',
            facilities: courseResponse?.facilities
        };
        this.setState({ nearbyResult });
    }

    private onFoundResult = (loadStatus: ResultStatus, courseResponse?: CourseResponse, err?: any) => {
        const foundAddress = courseResponse?.city.formattedString ?? '';
        const foundResult: FacilitiesResult = {
            loadStatus,
            loadResult: err ?? '',
            facilities: courseResponse?.facilities
        };
        this.setState({ foundResult, foundAddress });
    }

    private onRecentResult = (loadStatus: ResultStatus, recentFacilities?: Array<Facility>) => {
        const recentResult: FacilitiesResult = {
            loadStatus,
            loadResult: recentFacilities && recentFacilities.length > 0 ? '' : 'No recent courses yet',
            facilities: recentFacilities
        };
        this.setState({ recentResult });
    }

    private setAsOut = (course: SimpleCourse) => this.setState({ selectedOut: course });
    private setAsIn = (course: SimpleCourse) => this.setState({ selectedIn: course });
    private loadTees = () => this.teesLoader.current?.loadTees();

    private teesLoaded = (teesLoadStatus: ResultStatus, tees?: Array<Tee>, notCombinedTees?: Array<Tee>) => {
        this.setState({ tees: tees || [], teesLoadStatus });
        const { event, selectedFacility } = this.state;
        if (!event.course || !selectedFacility || !tees) {
            return;
        }
        if (tees.length === 0) {
            handleFacilityMissingTees(selectedFacility, event.course);
        } else {
            const selectedIn = iCourse(event.course);
            const selectedOut = oCourse(event.course);
            const defaultMenTee = tees.find(tee => getGender(tee) === 'male')?.id;
            const defaultWomenTee = tees.find(tee => getGender(tee) === 'female')?.id;
            event.holesType = getHolesType(selectedFacility, tees);
            this.setState({ event, tees, selectedOut, selectedIn, defaultMenTee, defaultWomenTee, notCombinedTees });
        }
    }

    private handleFacilitySelect = (selectedFacility?: Facility) => {
        const { userId } = this.props;
        const { event } = this.state;
        if (!selectedFacility) {
            return;
        }
        saveToRecent(selectedFacility, userId);
        event.course = getCourse(selectedFacility);
        delete event.holesType;
        this.setState({ event, showFacilities: false, selectedFacility, courseAddress: undefined, selectedOut: undefined, selectedIn: undefined, tees: [], defaultMenTee: undefined, defaultWomenTee: undefined });
        if (event.course) {
            const courseAddress = selectedFacility.city?.formattedString;
            this.setState({ courseAddress }, this.loadTees);
        }
    }

    private handleHolesChange = (val: string) => {
        const { event } = this.state;
        event.holesType = toHolesType(val);
        this.setState({ event, selectedIn: undefined, selectedOut: undefined });
    }

    private handleCourseSearch = (_?: React.MouseEvent<any>) => {
        this.setState({ showFacilities: true });
        this.coursesLoader.current?.load();
    }

    private handleOpenFacilities = () => {
        this.setState({ showFacilities: true });
    }

    private handleCloseEditTee = (changed: boolean, id: string, gender: Gender) => {
        this.setState({ editingTee: undefined, teeSelectionCourse: undefined });
        if (changed) {
            if (gender === 'female') {
                this.setState({ defaultWomenTee: id });
            } else {
                this.setState({ defaultMenTee: id });
            }
            this.loadTees();
        }
    };

    private setEditingTee = (gender: Gender, simpleCourse?: SimpleCourse) =>
        this.setState({
            editingTee: {
                name: '', gender,
                facilityId: simpleCourse?.id,
                facilityName: simpleCourse?.name
            } as Tee,
            teeSelectionCourse: simpleCourse
        });

    private addTeeInfoLater = 'Add tee info later';
    private teesNotFound = 'Tees not found';
    private blueText: SxProps = {
        color: AppColors.blue500,
        fontSize: '14px',
        lineHeight: '21px'
    };

    private courseSetupPage = () => {
        const { userId } = this.props;
        const {
            event, showFacilities, nearbyResult, recentResult, foundResult, searchAddress, foundAddress, courseAddress,
            defaultMenTee, defaultWomenTee, editingTee, teesLoadStatus, selectedFacility, selectedOut, selectedIn,
            teeSelectionCourse, notCombinedTees
        } = this.state;
        let { tees } = this.state;
        const id = showFacilities ? 'courses-popper' : undefined;
        const endAdornment = (onClk: (e: React.MouseEvent<any>) => void) => {
            return (
                <InputAdornment position="end">
                    <IconButton tabIndex={-1} onClick={onClk} size="large"><SearchIcon /></IconButton>
                </InputAdornment>
            );
        }
        const isCompound = isCompoundFacility(selectedFacility);
        const compoundShort = isCompound && event.holesType !== HOLES_18;
        const orderSimple = ['North', 'South', 'East'];
        const orderTemp = orderSimple.reduce((c, v, i) => Object.assign(c, { [v]: i }), {} as any);
        const isNineCourse = !isCompound && (event.holesType === HOLES_9 || event.holesType === HOLES_9_9);
        const holes = isNineCourse ? HOLES_TYPES_NINES : isCompound ? HOLES_TYPES_COMPOUND : HOLES_TYPES;
        const hasMen = event.eventGender === 'both' || event.eventGender === 'men';
        const hasWomen = event.eventGender === 'both' || event.eventGender === 'women';
        tees = event.holesType === HOLES_9 && isCompound && notCombinedTees ? notCombinedTees : tees;
        const menTees = tees.filter(tee => getGender(tee) === 'male').sort(compareTee);
        const womenTees = tees.filter(tee => getGender(tee) === 'female').sort(compareTee);
        const disabled = !event.course || teesLoadStatus !== 'ok';
        const facilitiesGroups: Array<FacilitiesGroup> = [];
        const errorMen = menTees.length > 0 ? '' : this.teesNotFound;
        const errorWomen = womenTees.length > 0 ? '' : this.teesNotFound;
        const menTeesError = !disabled && Boolean(errorMen);
        const womenTeesError = !disabled && Boolean(errorWomen);
        if (showFacilities) {
            facilitiesGroups.push({ name: foundAddress, ...foundResult });
            if (!foundResult.facilities?.length) {
                facilitiesGroups.push({ name: 'Previous courses', ...recentResult });
                if (nearbyResult.loadStatus !== 'error') {
                    facilitiesGroups.push({ name: 'Near you', ...nearbyResult });
                }
            }
        }
        const courseName = getFacilityName(selectedFacility, selectedOut, selectedIn);
        const singleFacility = event.course && !isCompoundCourse(event.course) ? event.course :
            (selectedIn && selectedIn.id === selectedOut?.id) ? selectedIn : undefined;
        const errorAndNotDisabled = !disabled && (errorMen || errorWomen);
        return (
            <React.Fragment>
                <TextField
                    variant="standard"
                    id="course"
                    ref={this.inputRef}
                    label="Select course"
                    value={showFacilities ? searchAddress : courseName}
                    helperText={showFacilities ? undefined : courseAddress}
                    placeholder="Search by city or course name"
                    style={{ width: '100%', maxWidth: NEW_EVENT_ELEM_WIDTH }}
                    onKeyDown={e => processEnterKey(e, this.handleCourseSearch)}
                    onChange={e => this.setState({ searchAddress: e.target.value })}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        autoComplete: 'off',
                        endAdornment: endAdornment(e => {
                            e.stopPropagation();
                            return showFacilities ? this.handleCourseSearch() : this.handleOpenFacilities();
                        }),
                        onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                            if (!showFacilities) {
                                this.handleOpenFacilities();
                            } else {
                                e.stopPropagation();
                            }
                        }
                    }} />
                <Spacing />
                <Typography gutterBottom>Holes</Typography>
                <FormControl
                    variant="standard"
                    fullWidth
                    style={{ flexDirection: 'column' }}
                    disabled={disabled}>
                    <RadioGroup value={String(event.holesType)} style={{ flexDirection: 'row' }} onChange={e => this.handleHolesChange(e.target.value)}>
                        {holes.map(ht => <ProgressRadio key={ht} value={String(ht)} label={HOLES_NAMES[ht]} />)}
                    </RadioGroup>
                    {isCompound && <div>
                        <Spacing />
                        {compoundShort ? <CourseViewHeaderShort /> : <CourseViewHeaderLong />}
                        {compoundShort ?
                            selectedFacility!.courses.sort((a, b) => orderTemp[a.name] - orderTemp[b.name]).map(course =>
                                <CourseViewShort key={course.id} course={course}
                                    outNine={(selectedOut?.id ?? '') === course.id}
                                    inNine={(selectedIn?.id ?? '') === course.id}
                                    setAsOut={this.setAsOut} setAsIn={this.setAsIn} />) :
                            selectedFacility!.courses.sort((a, b) => orderTemp[a.name] - orderTemp[b.name]).map(course =>
                                <CourseViewLong key={course.id} course={course}
                                    outNine={(selectedOut?.id ?? '') === course.id}
                                    inNine={(selectedIn?.id ?? '') === course.id}
                                    setAsOut={this.setAsOut} setAsIn={this.setAsIn} />)}
                    </div>}
                </FormControl>
                <Spacing />
                <Typography>Default tees
                    <InfoIconTooltip style={{ marginLeft: '5px' }} title={defaultTeeExplanation} placement="right"
                        offset1={-8} offset2={1} leaveTouchDelay={4000} />
                </Typography>
                <Spacing />
                {hasMen && <FormControl
                    variant="standard"
                    disabled={disabled || Boolean(errorMen)}
                    style={{ width: '45%', minWidth: 90, maxWidth: NEW_EVENT_ELEM_WIDTH / 2 - 8, marginRight: 8 }}>
                    <InputLabel id="select-tees-men-label" shrink>Men</InputLabel>
                    <Select
                        IconComponent={menTeesError ? () => null : undefined}
                        variant="standard"
                        labelId="select-tees-men-label"
                        id="select-tees-men"
                        label="men"
                        value={menTeesError ? this.addTeeInfoLater : defaultMenTee ?? ''}
                        onChange={e => {
                            if (e.target.value) {
                                this.setState({ defaultMenTee: e.target.value as string });
                            }
                        }}>
                        {menTees.map(tee => <MenuItem key={tee.id} value={tee.id}>{getTeeName(tee, event.holesType)}</MenuItem>)}
                        <Divider variant="middle" />
                        {singleFacility && <MenuItem sx={this.blueText} value="" onClick={() => this.setEditingTee('male', singleFacility)}>+ Add tee</MenuItem>}
                        {selectedIn && selectedIn.id !== selectedOut?.id && <MenuItem
                            sx={this.blueText} value="" onClick={() => this.setEditingTee('male', selectedIn)}>
                            + Add tee{` (${selectedIn.name})`}
                        </MenuItem>}
                        {selectedOut && selectedOut.id !== selectedIn?.id && <MenuItem
                            sx={this.blueText} value="" onClick={() => this.setEditingTee('male', selectedOut)}>
                            + Add tee{` (${selectedOut.name})`}
                        </MenuItem>}
                        {menTeesError && <MenuItem sx={this.blueText} value={this.addTeeInfoLater}>{this.addTeeInfoLater}</MenuItem>}
                    </Select>
                    {menTeesError && <FormHelperText error={true}>{errorMen}</FormHelperText>}
                </FormControl>}
                {hasWomen && <FormControl
                    variant="standard"
                    disabled={disabled || Boolean(errorWomen)}
                    style={{ width: '45%', minWidth: 90, maxWidth: NEW_EVENT_ELEM_WIDTH / 2 }}>
                    <InputLabel id="select-tees-women-label" shrink>Women</InputLabel>
                    <Select
                        IconComponent={womenTeesError ? () => null : undefined}
                        variant="standard"
                        labelId="select-tees-women-label"
                        id="select-tees-women"
                        label="women"
                        value={womenTeesError ? this.addTeeInfoLater : defaultWomenTee ?? ''}
                        onChange={e => {
                            if (e.target.value) {
                                this.setState({ defaultWomenTee: e.target.value as string });
                            }
                        }}>
                        {womenTees.map(tee => <MenuItem key={tee.id} value={tee.id}>{getTeeName(tee, event.holesType)}</MenuItem>)}
                        <Divider variant="middle" />
                        {singleFacility && <MenuItem sx={this.blueText} value="" onClick={() => this.setEditingTee('female', singleFacility)}>+ Add tee</MenuItem>}
                        {selectedIn && selectedIn.id !== selectedOut?.id && <MenuItem
                            sx={this.blueText} value="" onClick={() => this.setEditingTee('female', selectedIn)}>
                            + Add tee{` (${selectedIn.name})`}
                        </MenuItem>}
                        {selectedOut && selectedOut.id !== selectedIn?.id && <MenuItem
                            sx={this.blueText} value="" onClick={() => this.setEditingTee('female', selectedOut)}>
                            + Add tee{` (${selectedOut.name})`}
                        </MenuItem>}
                        {womenTeesError && <MenuItem sx={this.blueText} value={this.addTeeInfoLater}>{this.addTeeInfoLater}</MenuItem>}
                    </Select>
                    {womenTeesError && <FormHelperText error={true}>{errorWomen}</FormHelperText>}
                </FormControl>}
                {errorAndNotDisabled && (singleFacility ||
                    (event.holesType === HOLES_9 && isCompound && (selectedIn || selectedOut))) &&
                    <FormControl
                        variant="standard"
                        style={{ width: '45%', minWidth: 90, maxWidth: NEW_EVENT_ELEM_WIDTH * 3 / 8 }}>
                        <this.EditTeeButton btnStyle={{ marginTop: 16 }}
                            facility={(singleFacility ?? selectedIn ?? selectedOut)!}
                            isCompound={isCompound} />
                    </FormControl>}
                {errorAndNotDisabled && selectedIn && selectedOut && selectedIn.id !== selectedOut.id &&
                    <FormControl
                        variant="standard"
                        style={{ display: 'flex', flexDirection: 'row', width: '80%' }}>
                        <this.EditTeeButton btnStyle={{ marginTop: 4, maxWidth: NEW_EVENT_ELEM_WIDTH / 2 }}
                            facility={selectedIn} isCompound={isCompound} />
                        <this.EditTeeButton facility={selectedOut} isCompound={isCompound}
                            btnStyle={{ marginTop: 4, marginLeft: 10, maxWidth: NEW_EVENT_ELEM_WIDTH }} />
                    </FormControl>}
                <Popper id={id} open={showFacilities && Boolean(this.inputRef.current)} anchorEl={this.inputRef.current}
                    sx={{ zIndex: 1500 }}>
                    <FacilityListWrapped onSelect={this.handleFacilitySelect} facilitiesGroups={facilitiesGroups}
                        userId={userId}
                        onClickAway={() => this.setState({ showFacilities: false })} />
                </Popper>
                {editingTee && teeSelectionCourse &&
                    <EditTeeDialog
                        noGenderRequired tee={editingTee} facility={teeSelectionCourse} showFacilityName={isCompound}
                        event={event} tees={(isCompound && notCombinedTees?.length) ? notCombinedTees : tees}
                        isNine={isCompound} close={this.handleCloseEditTee} genderFixed={Boolean(editingTee.gender)}
                    />}
            </React.Fragment>
        );
    }

    private setPaymentSettingsEnableStatus = (e: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
        e.stopPropagation();
        const { paymentSettings } = this.state;
        paymentSettings.enabled = value;
        this.setState({ paymentSettings });
    };

    private setPayeeEmail = (newEmail: string) => {
        const { paymentSettings } = this.state;
        paymentSettings.payeeEmailAddress = newEmail;
        this.setState({ paymentSettings });
    };

    private setFeeCostWithDescription = (newFeeCostStr: string, description?: string) => {
        const { paymentSettings } = this.state;
        paymentSettings.feeCost = parseFloat(newFeeCostStr) ?? 0;
        if (description) {
            paymentSettings.feeDescription = description;
        }
        this.setState({ paymentSettings });
    };

    private incorrectPaymentSettings = () => {
        const { paymentSettings } = this.state;
        return paymentSettings.enabled && !(paymentSettings.feeCost > 0 && paymentSettings.payeeEmailAddress.length > 0);
    };

    public EditTeeButton = (props: { facility: SimpleCourse, isCompound: boolean, btnStyle: React.CSSProperties }) => {
        const { facility, isCompound, btnStyle } = props;
        return (
            <AppButton color="secondary" style={btnStyle} onClick={() => this.setEditingTee('', facility)}>
                <AddSharpIcon />
                <Typography sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    Add tee{`${isCompound && facility.name ? ` (${facility.name})` : ''}`}
                </Typography>
            </AppButton>
        );
    };

    private updatePaymentSettings = (newPayPalEmailAddress: string, newPayPalCurrencyCode: string) => {
        const paymentSettings = this.state.paymentSettings;
        if (newPayPalEmailAddress) {
            paymentSettings.payeeEmailAddress = newPayPalEmailAddress.trim();
        }
        if (newPayPalCurrencyCode) {
            paymentSettings.currencyCode = newPayPalCurrencyCode.trim();
        }
        this.setState({ paymentSettings });
    };

    private registrationPage = () => {
        const { event, paymentSettings, pro } = this.state;
        if (!event.publicId) {
            return null;
        }
        const lessThan2DaysBeforeEvent = (event.dateUTC - roundDate(Date.now())) < 2 * DAY_MS;
        const registeringByAdmin = (event.registrationByAdmin === undefined && lessThan2DaysBeforeEvent) ||
            Boolean(event.registrationByAdmin);
        const disabled = registeringByAdmin;
        const text1 = 'Organizers can always edit registered golfers, and the shareable registration link is always available in the Event settings.';
        const text2 = 'Add participants yourself in the golfers section after creating an event.';
        const text3 = 'Receive an email confirmation when golfers self-register via the registration page.';
        const typo1 = <Typography>Anyone with the event link <InfoIconTooltip title={text1} placement={'bottom-start'} leaveTouchDelay={5000} /></Typography>;
        const typo2 = <Typography>Only you <InfoIconTooltip title={text2} offset1={-2} placement={'bottom'} leaveTouchDelay={3500} /></Typography>;
        const typo3 = <Typography>Notify me via email when a new golfer registers <InfoIconTooltip offset1={-2} title={text3} leaveTouchDelay={3500} placement={'bottom'} disabled={disabled} /></Typography>;
        const typo4 = <Typography>Automatically add new golfers to the schedule</Typography>;
        const registrationLink = Urls.makeAboutLink(event.publicId);
        const lastEventMs = event.date + DAY_MS - 1;
        const pastEventCreation = lastEventMs < Date.now();
        return (
            <React.Fragment>
                <FormControl variant="standard" margin="dense" fullWidth>
                    <Typography>Who can add golfers to an event</Typography>
                    <RegistrationRadioGroup typo1={typo1} typo2={typo2}
                        onOptionChanged={e => this.setEventProp('registrationByAdmin',
                            e.target.value === 'true')}
                        registeringByAdmin={registeringByAdmin}
                        disabled={disabled} registrationLink={registrationLink} />
                    <Spacing />
                    <MaterialDate enableUnderline icon label="Registration deadline﻿ - through"
                        shouldDisableDate={date => pastEventCreation ? date.isAfter(lastEventMs, "day") :
                            date.isAfter(lastEventMs) || date.isBefore(moment(new Date()), "day")
                        }
                        disabled={disabled}
                        value={pastEventCreation ? getRegistrationDate(event) : event.registrationDeadline ?? Date.now()}
                        onChange={date => this.setEventProp('registrationDeadline', date.getTime())}
                        style={{ maxWidth: NEW_EVENT_ELEM_WIDTH }} />
                    <Spacing />
                    <FormControlLabel
                        disabled={disabled}
                        style={{ marginLeft: 4, color: disabled ? 'rgba(0, 0, 0, 0.38)' : '' }}
                        label={typo3}
                        control={<Checkbox
                            sx={{ alignSelf: 'start' }}
                            color="secondary"
                            disabled={disabled}
                            onChange={(e, v) => { e.stopPropagation(); this.setEventProp('registrationEmailNotify', v); }}
                            checked={Utils.isTrue(event.registrationEmailNotify, DEFAULT_REGISTRATION_EMAIL_NOTIFY)} />}
                    />
                    <Spacing />
                    <ParticipantsNumberField maxGolfers={event.maxGolfers} disabled={disabled} maxWidth={NEW_EVENT_ELEM_WIDTH} pro={pro}
                        inputRef={this.inputRef} onChange={(e, maxGolfers) => this.setEventProp('maxGolfers', Utils.parseInt(e.target.value, 1, maxGolfers) || undefined)} />
                    <PayPalPaymentSettings
                        style={{ paddingLeft: 4, paddingTop: 8 }}
                        disabled={disabled}
                        onPayPalDialogSave={(newPayPalEmailAddress: string, newPayPalCurrencyCode: string) =>
                            this.updatePaymentSettings(newPayPalEmailAddress, newPayPalCurrencyCode)}
                        paymentSettings={paymentSettings}
                        setPaymentSettingsEnableStatus={this.setPaymentSettingsEnableStatus}
                        setPayeeEmail={this.setPayeeEmail}
                        setFeeCostWithDescription={this.setFeeCostWithDescription} />
                    <FormControlLabel
                        style={{ marginLeft: 4, marginTop: 12 }}
                        label={typo4}
                        disabled={disabled}
                        control={<Checkbox
                            sx={{ alignSelf: 'start' }}
                            color="secondary"
                            onChange={(e, v) => { e.stopPropagation(); this.setEventProp('autoSchedule', v ? 'ON' : 'OFF'); }}
                            checked={event.autoSchedule === 'ON'} />}
                    />
                </FormControl>
                <Spacing />
            </React.Fragment>
        );
    };

    private setTeamSize = (teamSize: ScoringTeamSize, withBackUp: boolean = false) => {
        let { event, teamScoringFormat } = this.state;
        if (event.teamSize !== teamSize) {
            event.teamSize = teamSize;
            const handicapAllowances = this.getHandicapAllowances(teamSize, teamScoringFormat);
            if (withBackUp) {
                this.setState({ event, teamSizeBackup: teamSize, handicapAllowances });
            } else {
                this.setState({ event, handicapAllowances });
            }
        }
    };

    private handleHandicapPercentsChange = (num: number, val: number) => {
        let { handicapAllowances, event, teamScoringFormat } = this.state;
        if (handicapAllowances[num] !== val) {
            handicapAllowances = this.getHandicapAllowances(event.teamSize, teamScoringFormat);
            handicapAllowances[num] = val;
            this.setState({ handicapAllowances });
        }
    };

    private getHandicapAllowances = (teamSize: ScoringTeamSize, teamScoringFormat: ScoringFormat) => {
        const { handicapAllowances } = this.state;
        if (!handicapAllowances || handicapAllowances.length !== teamSize) {
            if (teamScoringFormat === ScoringFormatTeams.best_ball) {
                return fillEventHandicaps(teamSize, 100);
            } else {
                return fillProportionalHandicaps(teamSize);
            }
        }
        return handicapAllowances;
    };

    private onTeamScoringFormatChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { event } = this.state;
        const teamScoringFormat = e.target.value as ScoringFormatTeams | ScoringFormatSkins.skins_team;
        const handicapAllowances = this.getHandicapAllowances(event.teamSize, teamScoringFormat);
        const descriptionType = 'scoringFormat';
        this.setState({ teamScoringFormat, handicapAllowances, descriptionType });
    };

    private onIndividualScoringFormatChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { event } = this.state;
        const teamMode = event.teamSize > 1;
        const individualScoring = e.target.value as ScoringFormatIndividual | ScoringFormatSkins.skins_individual;
        const descriptionType = teamMode ? 'scoringType' : 'scoringFormat';
        this.setState({ individualScoring, descriptionType });
    };

    private onScoringModeChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const scoringMode = e.target.value as ScoringMode;
        const descriptionType = 'scoringMode';
        this.setState({ scoringMode, descriptionType })
    };

    private showPointSettings = () => this.setState({ showPointSettings: true });

    private scoringPage = () => {
        const { event, teamSizeBackup, individualScoring, teamScoringFormat, scoringMode, showPointSettings,
            stablefordPoints, handicapAllowances, descriptionType } = this.state;
        const teamMode = event.teamSize > 1;
        const textNote = 'You can add multiple competitions and configure details in the Scoring tab after the event is created.';
        const scoringFormat = teamMode ? teamScoringFormat : individualScoring;
        let description: Array<string> | undefined;
        let scoringTitle: ScoringFormatIndividual | ScoringFormatTeams | ScoringFormatSkins | ScoringMode | ScoringType | undefined;
        if (descriptionType === 'scoringFormat') {
            description = scoringFormatDescriptions.get(scoringFormat);
            scoringTitle = scoringFormat;
        }
        if (descriptionType === 'scoringMode') {
            description = scoringModeDescriptions.get(scoringMode);
            scoringTitle = scoringMode;
        }
        if (descriptionType === 'scoringType') {
            scoringTitle = individualScoring === ScoringFormatIndividual.strokeplay ?
                ScoringFormatIndividual.strokeplay : ScoringFormatIndividual.stableford;
            description = scoringTypeDescriptions.get(scoringTitle);
        }
        const isNet = isNetMode(scoringMode);
        const handicapUserNum = isNet ? teamMode ? event.teamSize : 1 : 0;
        return (
            <React.Fragment>
                <ButtonGroup size="small">
                    <AppButton
                        variant="contained"
                        color={!teamMode ? 'secondary' : 'info'}
                        style={{ width: 150 }}
                        onClick={() => this.setTeamSize(1)}>
                        Individual
                    </AppButton>
                    <AppButton
                        variant="contained"
                        color={teamMode ? 'secondary' : 'info'}
                        style={{ width: 150 }}
                        onClick={() => this.setTeamSize(teamSizeBackup)}>
                        Team
                    </AppButton>
                </ButtonGroup>
                <Grid container>
                    <Grid item xs={7}>
                        {teamMode && <FormControl variant="standard" margin="dense" fullWidth>
                            <Typography gutterBottom>Team format</Typography>
                            <RadioGroup value={teamScoringFormat}
                                style={{ flexDirection: 'row' }}
                                onChange={this.onTeamScoringFormatChanged}>
                                {TEAM_SCORINGS_AND_SKINS.map(scoringFormat =>
                                    <ScoringFormatRadio key={scoringFormat}
                                        width={160}
                                        teamMode={teamMode}
                                        scoringFormat={scoringFormat}
                                        selectedScoringFormat={teamScoringFormat} />)}
                            </RadioGroup>
                        </FormControl>}
                        <FormControl variant="standard" margin="dense" fullWidth>
                            <Typography gutterBottom>Scoring</Typography>
                            <RadioGroup value={individualScoring}
                                style={{ flexDirection: teamMode ? 'row' : 'column' }}
                                onChange={this.onIndividualScoringFormatChanged}>
                                {(teamMode ? INDIVIDUAL_SCORINGS : INDIVIDUAL_SCORINGS_AND_SKINS).map(scoringFormat =>
                                    <ScoringFormatRadio key={scoringFormat}
                                        teamMode={teamMode}
                                        scoringFormat={scoringFormat}
                                        selectedScoringFormat={individualScoring}
                                        onClick={this.showPointSettings} />)}
                            </RadioGroup>
                        </FormControl>
                        {teamMode && <FormControl variant="standard" margin="dense" fullWidth>
                            <Typography gutterBottom>Number of players in a team</Typography>
                            <RadioGroup value={String(event.teamSize)}
                                style={{ flexDirection: 'row' }}
                                onChange={e => this.setTeamSize(Number.parseInt(e.target.value) as ScoringTeamSize, true)}>
                                {SCORING_TEAM_SIZES.map(teamSize => <ProgressRadio key={String(teamSize)}
                                    width={110}
                                    value={String(teamSize)}
                                    label={`${teamSize} people`} />)}
                            </RadioGroup>
                        </FormControl>}
                        <FormControl variant="standard" margin="dense" fullWidth>
                            <Typography gutterBottom>Handicaps</Typography>
                            <RadioGroup value={scoringMode}
                                style={{ flexDirection: 'row' }}
                                onChange={this.onScoringModeChanged}>
                                {ScoringModes.map(scoringMode => <ProgressRadio key={scoringMode}
                                    width={100}
                                    value={scoringMode}
                                    label={Utils.makeFriendlyString(scoringMode, true)} />)}
                            </RadioGroup>
                        </FormControl>
                        {isNet && <React.Fragment>
                            {handicapUserNum > 0 && <HandicapPercentSelector value={handicapAllowances[0]} index={0} handlePercentsChange={this.handleHandicapPercentsChange} teamSize={event.teamSize} individual={handicapUserNum === 1} />}
                            {handicapUserNum > 1 && <HandicapPercentSelector value={handicapAllowances[1]} index={1} handlePercentsChange={this.handleHandicapPercentsChange} teamSize={event.teamSize} />}
                            {handicapUserNum > 2 && <HandicapPercentSelector value={handicapAllowances[2]} index={2} handlePercentsChange={this.handleHandicapPercentsChange} teamSize={event.teamSize} />}
                            {handicapUserNum > 3 && <HandicapPercentSelector value={handicapAllowances[3]} index={3} handlePercentsChange={this.handleHandicapPercentsChange} teamSize={event.teamSize} />}
                        </React.Fragment>}
                        {showPointSettings && <StablefordPointSettingsDialog
                            open={true}
                            event={event}
                            points={(stablefordPoints || Scoring.defaultStablefordPoints).slice()}
                            savePoints={stablefordPoints => this.setState({ showPointSettings: false, stablefordPoints })}
                            onClose={() => this.setState({ showPointSettings: false })} />}
                    </Grid>
                    <Grid item xs={5}>
                        {description?.length && scoringTitle &&
                            <div style={{ backgroundColor: AppColors.descriptionBkColor, padding: 8, marginTop: 8 }}>
                                <Typography variant="subtitle2">{Utils.makeFriendlyString(scoringTitle, true)}</Typography>
                                {description.map((text, idx) =>
                                    <Typography key={idx} sx={{ fontSize: 12, lineHeight: '18px' }}
                                        variant="caption">{text}{idx < description!.length - 1 ? <br /> : ''}
                                    </Typography>)}
                            </div>}
                        <Typography sx={{ fontSize: 12, lineHeight: '18px', color: AppColors.webGray }} variant="caption">
                            {textNote}
                        </Typography>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }

    private pages = [
        this.eventDetailsPage,
        this.courseSetupPage,
        this.registrationPage,
        this.scoringPage,
    ];

    private courseIsMissing = () => {
        const { event, selectedFacility, selectedOut, selectedIn } = this.state;
        const isCompound = isCompoundFacility(selectedFacility);
        return !event.course ||
            (isCompound && event.holesType !== HOLES_18 && !selectedIn) ||
            (isCompound && event.holesType === HOLES_18 && !(selectedOut && selectedIn));
    };

    private userRemoteChanged = (userInfo: UserInfo) => {
        const paymentSettings = this.state.paymentSettings;
        paymentSettings.payeeEmailAddress = userInfo.payPalEmailAddress ?? '';
        paymentSettings.currencyCode = userInfo.payPalCurrencyCode ?? '';
        this.setState({ paymentSettings, userInfo });
    };

    private setIsPro = (pro: Pro) => this.setState({ pro: Boolean(new Date().getTime() < pro.exp) });

    render() {
        const { userId } = this.props;
        const { currentStep, coursesActivated, event, searchAddress } = this.state;
        const currentPage = this.pages[currentStep]();
        const nextDisabled = (currentStep === Pages.courseSetup && this.courseIsMissing()) ||
            (currentStep === Pages.registration && this.incorrectPaymentSettings());
        const nextBtnLabel = currentStep < NEW_EVENT_STEPS.length - 2 ? 'Next' : currentStep === NEW_EVENT_STEPS.length - 2 ? 'Last step' : 'Create event';
        return (
            <SMMobileDialog open={true} onClose={this.handleCloseX} maxWidth={'md'} fullWidth>
                <DialogAppBar bar close={this.handleClose} label="Create new event" toolbarBarStyle={{ padding: 0, marginLeft: 16, marginRight: 16 }} />
                <DialogAppBar><ProgressBar selectedStep={currentStep} /></DialogAppBar>
                <DialogContent style={{ minHeight: 380 }}>{currentPage}</DialogContent>
                <DialogActions>
                    {currentStep > 0 && <AppButton color="info" onClick={this.handleBack}>Back</AppButton>}
                    <span style={{ flex: '1 1 0%' }} />
                    <AppButton onClick={this.handleNext} color="secondary" disabled={nextDisabled}>{nextBtnLabel}</AppButton>
                </DialogActions>
                {coursesActivated && <React.Fragment>
                    <LocationFacilitiesProvider withTeesOnly={false} onResult={this.onNearestResult} />
                    <AddressFacilitiesProvider withTeesOnly={false} ref={this.coursesLoader} address={searchAddress} onResult={this.onFoundResult} />
                    <RecentProvider userId={userId} onResult={this.onRecentResult} />
                    {event.course && <TeesProvider includeNotCombined event={event} course={event.course} onResult={this.teesLoaded} ref={this.teesLoader} />}
                </React.Fragment>}
                <FirebaseUserDataComponent onData={this.userRemoteChanged} />
                {firebaseAuth.currentUser?.uid && <FirebaseDocComponent docReference={Backend.proExpirationDoc(firebaseAuth.currentUser.uid)}
                                                                       onDoc={doc => this.setIsPro(Backend.fromEntity<Pro>(doc))} />}
            </SMMobileDialog>
        );
    }
}

export default withUserId(NewEventDialogDetailed);
