import * as React from 'react';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { FormControl, DialogActions, DialogContent } from '@mui/material';
import { AutoSchedule } from '../../../types/EventTypes';
import { autoScheduleName } from '../../Event';
import { processEnterKey } from '../../../util/react_utils';
import { XSMobileDialog } from '../../../common/dialog/MobileDialog';
import DialogAppBar from '../../../common/dialog/DialogAppBar';
import AppButton from '../../../common/components/AppButton';
import TypedFormRadioLabel from '../../../common/form/TypedFormRadioLabel';
import { styles } from '../../../styles';

type Props = WithStyles<typeof styles> & {
    close: () => void;
    save: (autoSchedule: AutoSchedule) => void;
    autoSchedule?: AutoSchedule;
    open: boolean;
};

type State = {
    autoSchedule: AutoSchedule;
};

class SelectAutoScheduleDialog extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = { autoSchedule: props.autoSchedule || 'OFF' };
    }

    private handleChange = (autoSchedule: AutoSchedule) => this.setState({ autoSchedule });
    private handleSave = () => this.props.save(this.state.autoSchedule);

    render() {
        const { classes, open, close } = this.props;
        const autoSchedule = this.state.autoSchedule;
        return (
            <XSMobileDialog open={open} onClose={close}>
                <DialogAppBar label={'Auto schedule'} close={close} />
                <DialogContent onKeyDown={uiEvent => processEnterKey(uiEvent, () => this.handleSave)} >
                    <FormControl
                        variant="standard"
                        margin="dense"
                        fullWidth
                        style={{ flexDirection: 'column' }}>
                        <TypedFormRadioLabel
                            value={'ON'}
                            label={autoScheduleName('ON')}
                            currentValue={autoSchedule}
                            handleChange={this.handleChange}
                            className={classes.formSelector} />
                        <TypedFormRadioLabel
                            value={'OFF'}
                            label={autoScheduleName('OFF')}
                            currentValue={autoSchedule}
                            handleChange={this.handleChange}
                            className={classes.formSelector} />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <AppButton color="info" onClick={close}>Cancel</AppButton>
                    <AppButton color="secondary" onClick={this.handleSave}>Save</AppButton>
                </DialogActions>
            </XSMobileDialog>
        );
    }
}

export default withStyles(styles)(SelectAutoScheduleDialog);
