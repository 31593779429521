import * as React from 'react';
import { List, ListItem, Hidden, Typography } from '@mui/material';
import { GridSize } from '@mui/material/Grid';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import PersonIcon from '@mui/icons-material/Person';
import * as Backend from '../util/firebase';
import { Event, Contact, GolferGroup, Team, teeTimeName } from '../types/EventTypes';
import { fullName, golferTeamFullNames, getSameNameGolfersIds } from '../contact/Contact';
import { FirebaseDataComponent } from '../common/WithData';
import { getSchedule, ScheduleItem } from '../event/TeeTimes';
import { Container, Item, ItemS, VertDivider, HorzDivider, Label } from '../common/Misc';
import { styles } from '../styles';

type User = { name: string, last?: boolean };
type Teams = Array<User>;

interface ItemProps {
    tee: string;
    teams: Teams;
    teamSize: number;
    groupSize: number;
}

const UserElem = withStyles(styles)((props: { user: User, height: number, xs?: GridSize, sm?: GridSize, md?: GridSize, lg?: GridSize, className?: string } & WithStyles<typeof styles>) => {
    const { classes, user, height, xs, sm, md, lg, className } = props;
    return (
        <ItemS xs={xs} sm={sm} md={md} lg={lg} neg height={height}>
            <PersonIcon className={classes.textIcon + (user.name ? '' : ' ' + classes.invisible)} />
            <Label backgroundColor="inherit" className={className} paddingLeft={0}>
                {user.name}
            </Label>
        </ItemS>
    );
});

const GroupElem = withStyles(styles)((props: { user: User, height: number, teamSize: number, groupSize: number } & WithStyles<typeof styles>) => {
    const { classes, user, height, teamSize, groupSize } = props;
    if (teamSize === 1) {
        if (groupSize === 1) {
            return <UserElem user={user} height={height} xs={12} />;
        } else if (groupSize === 2) {
            return <UserElem user={user} height={height} xs={12} sm={6} />;
        } else if (groupSize === 3) {
            return <UserElem user={user} height={height} xs={12} sm={6} md={4} />;
        } else {
            return <UserElem user={user} height={height} xs={12} sm={6} md={4} lg={3} />;
        }
    } else if (teamSize === 2 && groupSize === 4) {
        return (
            <React.Fragment>
                <Hidden lgDown>
                    <React.Fragment>
                        <UserElem user={user} height={height} xs={3} className={classes.paddingLeftRight} />
                        {user.last && <VertDivider height={height - 10} padding={5} />}
                    </React.Fragment>
                </Hidden>
                <Hidden lgUp>
                    <React.Fragment>
                        <UserElem user={user} height={height} xs={12} sm={6} />
                        {user.last && <HorzDivider />}
                    </React.Fragment>
                </Hidden>
            </React.Fragment>
        );
    } else if (teamSize === 2) {
        return (
            <React.Fragment>
                <UserElem user={user} height={height} xs={12} sm={6} />
                {user.last && <HorzDivider />}
            </React.Fragment>
        );
    } else if (teamSize === 3) {
        return (
            <React.Fragment>
                <UserElem user={user} height={height} xs={12} sm={6} md={4} />
                {user.last && <HorzDivider />}
            </React.Fragment>
        );
    }
    return (
        <React.Fragment>
            <UserElem user={user} height={height} xs={12} sm={6} md={4} lg={3} />
            {user.last && <HorzDivider />}
        </React.Fragment>
    );
});

const RowElem = withStyles(styles)((props: { teams: Teams, height: number, teamSize: number, groupSize: number } & WithStyles<typeof styles>) => {
    const { teams, height, teamSize, groupSize } = props;
    return (
        <React.Fragment>
            <Container>
                {teams.map((user, idx) => <GroupElem key={idx} user={user} height={height} teamSize={teamSize} groupSize={groupSize} />)}
            </Container>
        </React.Fragment>
    );
});

const ScheduleRow = withStyles(styles)((props: ItemProps & WithStyles<typeof styles>) => {
    const { classes, teams, teamSize, groupSize } = props;
    const height = 42;
    return (
        <ListItem className={classes.listItemZebra + ' ' + classes.padding0} dense>
            <Container>
                <Item xs={3} sm={2} height={height} paddingLeft="1em">{props.tee}</Item>
                <ItemS xs={9} sm={10}>
                    <RowElem teams={teams} height={height} teamSize={teamSize} groupSize={groupSize} />
                </ItemS>
            </Container>
        </ListItem>
    );
});

type ScheduleRows = Array<{ time: string, teams: Teams }>;

const ScheduleHeader = withStyles(styles)((props: { tee: string } & WithStyles<typeof styles>) => {
    const { classes } = props;
    const height = 42;
    return (
        <ListItem className={classes.listItemHeader + ' ' + classes.padding0}>
            <Container>
                <Item xs={3} sm={2} height={height} paddingLeft="1em">{props.tee}</Item>
                <Item xs={9} sm={10} height={height} paddingLeft="1em">Golfers</Item>
            </Container>
        </ListItem>
    );
});

interface State {
    schedule?: ScheduleItem[];
    golfers: Map<string, Contact>;
    teams: Map<string, Team>;
    groups: Array<GolferGroup>;
    groupsLoaded: boolean;
    golfersLoaded: boolean;
    teamsLoaded: boolean;
}

interface ScheduleProperties {
    event: Event;
}

type Props = ScheduleProperties & WithStyles<typeof styles>;

class Schedule extends React.Component<Props, State> {
    state: State = {
        golfers: new Map<string, Contact>(),
        teams: new Map<string, Team>(),
        groups: [],
        groupsLoaded: false,
        golfersLoaded: false,
        teamsLoaded: false
    };

    private onGroups = (groups: Array<GolferGroup>) => this.setState({ groups, groupsLoaded: true });
    private onGolfers = (golfers: Map<string, Contact>) => this.setState({ golfers, golfersLoaded: true });
    private onTeams = (teams: Map<string, Team>) => this.setState({ teams, teamsLoaded: true });

    render() {
        const { event, classes } = this.props;
        const { groups, golfers, teams, groupsLoaded, golfersLoaded, teamsLoaded } = this.state;
        const isRegular = event.teeTime.mode === 'regular';
        let scheduleRows: ScheduleRows = [];
        const schedule: ScheduleItem[] = getSchedule(event, groups, golfers, teams);
        let groupSize = 0;
        const sameNameGolfersIdsSet: Set<string> = getSameNameGolfersIds(Array.from(golfers.values()));
        if (schedule && event.teamSize === 1) {
            schedule.forEach(s => {
                if (s.golfers) {
                    if (groupSize < s.golfers.length) {
                        groupSize = s.golfers.length;
                    }
                    const rowTeams: Teams = [];
                    for (let i = 0; i < s.golfers.length; i++) {
                        if (s.golfers[i]) {
                            const golfer: Contact = s.golfers[i];
                            rowTeams.push({ name: fullName(golfer).concat(sameNameGolfersIdsSet.has(golfer.id) && golfer.homeCourseOrCity ? ` (${golfer.homeCourseOrCity})` : '') });
                        }
                    }
                    if (rowTeams.length > 0) {
                        scheduleRows.push({ time: teeTimeName(s.time), teams: rowTeams });
                    }
                }
            });
        } else if (schedule && event.teamSize > 1) {
            groupSize = event.teeTime.golfersPerGroup;
            schedule.forEach(s => {
                if (s.teams) {
                    const rowTeams: Teams = [];
                    for (let i = 0; i < s.teams.length; i++) {
                        const contacts = s.teams[i] ? golferTeamFullNames(s.teams[i], golfers, sameNameGolfersIdsSet) : [];
                        const contactsNum = contacts.length;
                        while (contacts.length < event.teamSize) {
                            contacts.push('');
                        }
                        contacts.forEach((contact, idx) => rowTeams.push({ name: contact, last: idx === contacts.length - 1 && contactsNum > 0 && i < s.teams.length - 1 }));
                    }
                    if (rowTeams.length > 0) {
                        scheduleRows.push({ time: teeTimeName(s.time), teams: rowTeams });
                    }
                }
            });
        }
        return (
            <React.Fragment>
                {scheduleRows.length > 0 && <List disablePadding>
                    <ScheduleHeader tee={isRegular ? 'Time' : 'Hole'} />
                    {scheduleRows.map(row => <ScheduleRow key={row.time} tee={teeTimeName(row.time)} teams={row.teams} teamSize={event.teamSize} groupSize={groupSize} />)}
                </List>}
                {!scheduleRows.length && groupsLoaded && golfersLoaded && teamsLoaded &&
                    <Typography className={classes.portalInfoNotSet}>The event schedule will be visible here, once it is set by the tournament organizer.</Typography>}
                <FirebaseDataComponent query={Backend.query(Backend.golferDb(event.id), Backend.where('hidden', '==', false))} onMap={this.onGolfers} />
                <FirebaseDataComponent query={Backend.golferTeamDb(event.id)} onMap={this.onTeams} />
                <FirebaseDataComponent query={Backend.query(Backend.golferGroupDb(event.id), Backend.orderBy('order'))} onData={this.onGroups} />
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(Schedule);
