import * as React from 'react';
import NewEventDialog from './NewEventDialogDetailed';
import { List, Typography, ListItemButton, useMediaQuery, useTheme, Box, IconButton } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { withCookies, ReactCookieProps } from 'react-cookie';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { LocationIcon, PlusInCircleIcon } from '../../common/Icons';
import { Event, Portal, getCourseName } from '../../types/EventTypes';
import { eventBadgeUrl, formatDateUniversal, hasEmptyTees } from '../Event';
import { FirebasePaginatedDataProvider, PaginationProps } from '../../common/WithData';
import * as Backend from '../../util/firebase';
import { pushUrl, showProgress } from '../../redux/ReduxConfig';
import { UserAware, WithUserId, withUserId, userProviderContextTypes } from '../../auth/Auth';
import { formatDate, getUserToday, DAY_MILLIS } from '../../util/utility';
import AppButton from '../../common/components/AppButton';
import CompleteEventSetupDialog from './CompleteEventSetupDialog';
import { ListTitle, FlexCenter, IconLinkElement, Flex } from '../../common/Misc';
import ArcStepper from './ArcStepper';
import { EVENT_DATETIME_FORMAT, Urls, dbgLog } from '../../util/config';
import { greyBorderImg, styles } from '../../styles';
import { AppColors } from "../../main/Theme";
import { ForwardedRef, useState } from "react";
import { errMsg } from "../../util/firebase";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type EventItemProps = {
    event: Event,
    portal: Portal,
    portalLoaded: boolean,
    stepsTotal: number,
    stepsCompleted: number,
    participantsOk: boolean;
    scheduleOk: boolean;
    teesOk: boolean;
    clickHandler: (event: Event) => void
};

const PortalLink = (props: { event: Event; click?: (e: React.MouseEvent) => void; }) => {
    const { event, click } = props;
    return (
        <IconLinkElement href={`/event/${event.publicId}`} target="_blank" rel="noopener noreferrer" click={click}>Event site</IconLinkElement>
    );
};

const TileEventItem = withStyles(styles)((props: EventItemProps & WithStyles<typeof styles>) => {
    const { classes, event, portal, portalLoaded, stepsTotal, stepsCompleted, participantsOk, scheduleOk, teesOk } = props;
    const [completeSetup, setCompleteSetup] = React.useState(0);
    let buttonClicked = false;
    const aclick = () => {
        buttonClicked = true;
        setTimeout(() => buttonClicked = false);
    };
    const click = () => {
        if (buttonClicked) {
            return;
        }
        props.clickHandler(event);
    };
    const clickComplete = (e: React.MouseEvent) => {
        e.preventDefault();
        buttonClicked = true;
        setCompleteSetup(1);
        setTimeout(() => buttonClicked = false);
    };
    const clickCustomize = (e: React.MouseEvent) => {
        e.preventDefault();
        buttonClicked = true;
        setCompleteSetup(2);
        setTimeout(() => buttonClicked = false);
    };
    const clickStandings = (e: React.MouseEvent) => {
        e.preventDefault();
        buttonClicked = true;
        pushUrl(`/event/${event.publicId}/standings`);
    };
    const eventSetupClosed = (target?: string) => {
        setCompleteSetup(0);
        if (target) {
            pushUrl(target);
        }
    };
    return (
        <Grid>
            <ListItemButton className={classes.tileBox} onClick={click}>
                <Grid container spacing={2} alignItems="center">
                    <Grid xs={3}>
                        <ArcStepper badgeUrl={portalLoaded ? eventBadgeUrl(event, portal) : ''} stepsTotal={stepsTotal} stepsCompleted={stepsCompleted} size={'100%'} />
                    </Grid>
                    <Grid xs={9}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid>
                                <Typography noWrap className={classes.tileEventTitle}>{event.name}</Typography>
                            </Grid>
                            <Grid>
                                <Typography className={classes.link + ' ' + classes.tileEventText} noWrap><PortalLink event={event} click={aclick} /></Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} direction="column" className={classes.tileEventText}>
                            <Grid>
                                <Typography noWrap>
                                    <Flex>
                                        <DateRangeIcon className={classes.textIcon} />
                                        {formatDateUniversal(event.date, EVENT_DATETIME_FORMAT)}
                                    </Flex>
                                </Typography>
                            </Grid>
                            <Grid xs={12}>
                                <Typography>
                                    <Flex>
                                        <LocationIcon className={classes.textIcon} />
                                        {getCourseName(event.course)}
                                    </Flex>
                                </Typography>
                            </Grid>
                            {portalLoaded && <Grid xs={12}>
                                {stepsCompleted < stepsTotal &&
                                    <AppButton color="secondary" onClick={clickComplete}>Complete event setup</AppButton>}
                                {stepsCompleted > stepsTotal &&
                                    <AppButton color="secondary" onClick={clickStandings}>Standings</AppButton>}
                                {stepsCompleted === stepsTotal &&
                                    <AppButton color="info" onClick={clickCustomize}>Customize event</AppButton>}
                            </Grid>}
                        </Grid>
                    </Grid>
                </Grid>
            </ListItemButton>
            {completeSetup > 0 && <CompleteEventSetupDialog event={event} participantsOk={participantsOk} scheduleOk={scheduleOk} teesOk={teesOk} handleClose={eventSetupClosed} customize={completeSetup === 2} />}
        </Grid>
    );
});

type AddNewEventItemProps = {
    taller: boolean;
    onClick: () => void
} & WithStyles<typeof styles>;

const AddNewEventItem = withStyles(styles)(({ onClick, classes, taller }: AddNewEventItemProps) => {
    const [hover, setHover] = useState(false);
    const lessThanSm = useMediaQuery(useTheme().breakpoints.down('sm'));
    return (
        <Grid>
            <ListItemButton
                onClick={onClick}
                className={classes.tileNewEvent}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                sx={{
                    minHeight: lessThanSm ? 100 : (taller ? 200 : 160),
                    color: hover ? AppColors.blue500 : AppColors.bluish,
                    backgroundImage: hover ? 'none' : `url(${greyBorderImg})`,
                    backgroundSize: 'contain'
                }}>
                <Grid container spacing={0} alignItems="center">
                    <Grid xs={12}>
                        <FlexCenter>
                            <Typography sx={{ fontWeight: 600, fontSize: 16, lineHeight: '24px', margin: '4px', width: 400 }}>
                                Add new event
                            </Typography>
                        </FlexCenter>
                        <FlexCenter>
                            <PlusInCircleIcon hover={hover} />
                        </FlexCenter>
                    </Grid>
                </Grid>
            </ListItemButton>
        </Grid>
    );
});

type ScheduleSessionProps = {
    onClose: () => void
} & WithStyles<typeof styles>;

const ScheduleSessionItem = withStyles(styles)(({ classes, onClose }: ScheduleSessionProps) => {
    const moreThanSm = useMediaQuery(useTheme().breakpoints.up('sm'));
    const openSessionSchedule = () => window.open(Urls.eventsUserSession, '_blank');
    return (
        <Grid minHeight={moreThanSm ? 200 : 160}>
            <ListItemButton className={classes.tileScheduleSession} sx={{ backgroundColor: AppColors.blue100 }}>
                <Grid container spacing={0} alignItems="center">
                    {moreThanSm && <Grid xs={2}>
                        <FlexCenter><img src={Urls.scheduleOnlineSessionImg} style={{ width: '100%', maxWidth: 120 }} alt="" /></FlexCenter>
                    </Grid>}
                    <Grid xs={moreThanSm ? 10 : 12}>
                        <IconButton color="secondary" sx={{ float: 'right' }} onClick={onClose}><CloseIcon /></IconButton>
                        <Typography sx={{ fontWeight: 600, fontSize: 16, lineHeight: '24px', margin: '4px' }}>
                            Schedule an online session
                        </Typography>
                        <Typography sx={{ fontWeight: 400, fontSize: 14, lineHeight: '21px', margin: '4px' }}>
                            The Golf Pad team can be your on-hand resource as you create your golf tournament from start to finish. Seeing your experience and learning about your tournament needs will improve the Events platform.
                        </Typography>
                        <Typography sx={{ fontWeight: 500, fontSize: 14, lineHeight: '21px', margin: '4px' }}>
                            Schedule a video call with the Golf Pad Events team today!
                        </Typography>
                        <AppButton color="secondary" onClick={openSessionSchedule}><DateRangeIcon className={classes.textIcon} /> Book session</AppButton>
                    </Grid>
                </Grid>
            </ListItemButton>
        </Grid>
    );
});

type EventData = {
    stepsCompleted: number;
    portalLoaded: boolean;
    teesOk: boolean;
    portal: Portal;
    stepsTotal: number;
    participantsOk: boolean;
    scheduleOk: boolean;
};

type PastEventsListProps = {
    allPastEvents?: number;
    eventsToRender: Event[];
    showMore: boolean;
    firstPastEventsPortion: number;
    maxPastEventsShown: number;
    onShowMoreOrLessClick: () => void;
    eventData: (event: Event, today?: boolean) => EventData;
    handleItemClick: (event: Event) => void;
    onLastElementScroll: () => void;
};

const PastEventsList = withStyles(styles)((props: PastEventsListProps & WithStyles<typeof styles>) => {
    const {
        allPastEvents, eventsToRender, showMore, firstPastEventsPortion, maxPastEventsShown,
        onShowMoreOrLessClick, eventData, handleItemClick, onLastElementScroll, classes
    } = props;
    const lastItemRef = React.createRef<HTMLDivElement>();
    const actionInSight = (entries: IntersectionObserverEntry[]) => {
        if (entries[0].isIntersecting && eventsToRender.length < (allPastEvents ?? 0)) {
            onLastElementScroll();
        }
    };
    const observerLoader = React.useRef<IntersectionObserver>();
    React.useEffect(() => {
        if (observerLoader.current) {
            observerLoader.current.disconnect();
        }
        observerLoader.current = new IntersectionObserver(actionInSight);
        if (lastItemRef.current) {
            observerLoader.current.observe(lastItemRef.current);
        }
    }, [lastItemRef]);
    const showMoreVisible = (allPastEvents ?? 0) > 1;
    return (
        <React.Fragment>
            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                <ListTitle text="Past" />
                {allPastEvents && <Typography style={{
                    color: '#949494', lineHeight: '21px',
                    fontFamily: 'poppins, sans-serif', paddingRight: 32, paddingTop: 16
                }}>
                    {allPastEvents} events
                </Typography>}
            </Box>
            <List className={classes.listRoot1}>
                {eventsToRender.slice(0, firstPastEventsPortion).map(item =>
                    <RowEventItem key={item.id} event={item} {...eventData(item)} clickHandler={handleItemClick} />)}
                {showMoreVisible && <div style={{ width: '100%', height: 50, display: 'flex', alignItems: 'center' }}>
                    <Typography color="info" onClick={onShowMoreOrLessClick} style={{
                        cursor: 'pointer', fontSize: 14, lineHeight: '21px', marginLeft: 4,
                        color: AppColors.blue500, display: 'flex', justifyContent: 'left',
                        alignItems: 'center', fontFamily: 'poppins, sans-serif'
                    }}>
                        {`Show ${showMore ? 'less' : 'more'}`}
                        <ExpandMoreIcon style={{ transform: showMore ? 'rotate(180deg)' : 'none' }} />
                    </Typography>
                </div>}
                {showMore && eventsToRender.slice(firstPastEventsPortion, maxPastEventsShown).map(
                    (item, idx, arr) => {
                        if (idx === arr.length - 1) {
                            return <RowEventItem key={item.id} event={item} {...eventData(item)}
                                clickHandler={handleItemClick} ref={lastItemRef} />;
                        }
                        return <RowEventItem key={item.id} event={item} {...eventData(item)}
                            clickHandler={handleItemClick} />;
                    })}
            </List>
        </React.Fragment>
    );
});

const RowEventItem = withStyles(styles)(React.forwardRef(((props: EventItemProps & WithStyles<typeof styles>, ref: ForwardedRef<HTMLDivElement>) => {
    const { classes, event } = props;
    const { portal, portalLoaded } = props;
    const click = () => props.clickHandler(event);
    const lessThanSm = useMediaQuery(useTheme().breakpoints.down('sm'));
    return (
        <ListItemButton className={classes.listItem1} ref={ref}>
            <span style={{ paddingRight: 6 }}>
                <ArcStepper badgeUrl={portalLoaded ? eventBadgeUrl(event, portal) : ''} stepsTotal={0} stepsCompleted={0} size={60} />
            </span>
            {lessThanSm ? (<Box onClick={click} display="flex" flexDirection="column" width="75%">
                <Typography variant="body1" className={classes.boldText}>{event.name}</Typography>
                <Typography variant="body2" noWrap className={classes.link} style={{}}>
                    <PortalLink event={event} />
                </Typography>
                <Typography variant="body2" noWrap style={{ width: '130px', minWidth: '130px' }}>
                    <Flex>
                        <DateRangeIcon className={classes.textIcon + ' ' + classes.secondaryText} />
                        {formatDate(event.date)}
                    </Flex>
                </Typography>
                <Typography variant="body2" className={classes.secondaryText} noWrap>
                    {!!event.course && <LocationIcon className={classes.textIcon} />}{getCourseName(event.course)}
                </Typography>
            </Box>) : (<Grid container spacing={1} sx={{ width: '100%' }}>
                <Grid onClick={click} xs={9}>
                    <Typography variant="body1" className={classes.boldText}>{event.name}</Typography>
                </Grid>
                <Grid xs={3}>
                    <Typography variant="body2" noWrap className={classes.link} style={{ float: 'right', marginRight: 10 }}>
                        <PortalLink event={event} />
                    </Typography>
                </Grid>
                <Grid onClick={click} xs={12}>
                    <span style={{ display: 'flex' }}>
                        <Typography variant="body2" noWrap style={{ width: '130px', minWidth: '130px' }}>
                            <Flex>
                                <DateRangeIcon className={classes.textIcon + ' ' + classes.secondaryText} />
                                {formatDate(event.date)}
                            </Flex>
                        </Typography>
                        <Typography variant="body2" className={classes.secondaryText} noWrap>
                            <Flex>
                                {!!event.course && <LocationIcon className={classes.textIcon} />}
                                {getCourseName(event.course)}
                            </Flex>
                        </Typography>
                    </span>
                </Grid>
            </Grid>)}
        </ListItemButton>
    );
})));

type Props = WithUserId & WithStyles<typeof styles> & ReactCookieProps;

type State = {
    addDialogOpened: boolean;
    events: Array<Event>;
    eventPortals: Map<string, Portal>;
    eventStatGolfers: Map<string, number>;
    eventStatGroups: Map<string, number>;
    loading: boolean;
    maxPastEventsShown: number;
    showMore: boolean;
    allEventsAmount: number;
    deletedEventsAmount: number;
};

class EventList extends React.Component<Props, State> {
    private pastEventsPortion = 30;
    private firstPastEventsPortion = 1;

    state: State = {
        addDialogOpened: false,
        loading: true,
        events: [],
        eventPortals: new Map<string, Portal>(),
        eventStatGolfers: new Map<string, number>(),
        eventStatGroups: new Map<string, number>(),
        maxPastEventsShown: this.firstPastEventsPortion,
        showMore: false,
        allEventsAmount: 0,
        deletedEventsAmount: 0
    };

    private readonly eventsProvider: FirebasePaginatedDataProvider<Event>;
    static contextTypes = userProviderContextTypes;
    context!: UserAware;

    private loadEventsAmounts(props: Props) {
        Backend.getCountFromServer(
            Backend.query(
                Backend.eventsDb,
                Backend.where('userId', '==', props.userId),
                Backend.where('deleted', '==', true)
            )
        )
            .then(val => this.setState({ deletedEventsAmount: val.data().count }))
            .catch(err => console.log(errMsg(err)));
        Backend.getCountFromServer(
            Backend.query(Backend.eventsDb, Backend.where('userId', '==', props.userId))
        )
            .then(val => {
                const allEventsAmount = val.data().count;
                dbgLog(`${allEventsAmount}`);
                this.setState({ allEventsAmount });
            })
            .catch(err => console.log(errMsg(err)));
    }

    constructor(props: Props) {
        super(props);
        this.loadEventsAmounts(props);
        dbgLog(`load events ${props.userId} - ${props}`)
        const paginationProps: PaginationProps<Event> = {
            collection: Backend.eventsDb,
            queryConstraints: [
                Backend.where('userId', '==', props.userId),
                Backend.orderBy('date', 'desc')
            ],
            onData: this.onMoreEvents,
            loadLimit: this.pastEventsPortion
        };
        this.eventsProvider = new FirebasePaginatedDataProvider(paginationProps);
        this.eventsProvider.loadNextPortion().catch(err => console.log(errMsg(err)));
    }

    componentDidMount() {
        Backend.trackEvent('view_events');
    }

    private handleAddClick = () => this.setState({ addDialogOpened: true });
    private handleItemClick = (event: Event) => this.openEvent(event.id);
    private onMoreEvents = (otherEvents: Array<Event>) => this.loadEventsData(otherEvents);

    private openEvent = (id: string) => {
        dbgLog(`openEvent ${id} - ${this.context.hasUpdated}`)
        if (this.context.hasUpdated) {
            window.location.replace(`/events/${id}`)
        } else {
            pushUrl(`/events/${id}`);
        }
    }

    private eventData = (event: Event, today?: boolean) => {
        const { eventPortals, eventStatGolfers, eventStatGroups } = this.state;
        let stepsTotal = 0;
        let stepsCompleted = 0;
        let participantsOk = false;
        let scheduleOk = false;
        let teesOk = false;
        if (!eventPortals.has(event.id)) {
            stepsTotal = -1;
            stepsCompleted = -1;
        } else if (eventStatGolfers.has(event.id)) {
            stepsTotal = 3;
            if (eventStatGolfers.get(event.id)! > 0) {
                stepsCompleted++;
                participantsOk = true;
            }
            if (eventStatGroups.get(event.id)! > 0) {
                stepsCompleted++;
                scheduleOk = true;
            }
            if (!hasEmptyTees(event)) {
                stepsCompleted++;
                teesOk = true;
            }
            if (today && stepsCompleted === stepsTotal) {
                stepsCompleted++;
            }
        }
        return {
            portal: eventPortals.get(event.id) ?? {} as Portal,
            portalLoaded: eventPortals.has(event.id),
            stepsCompleted,
            stepsTotal,
            participantsOk,
            scheduleOk,
            teesOk
        };
    }

    private loadEventsData = async (eventsForExtraction: Array<Event>) => {
        const { events, eventPortals, eventStatGolfers, eventStatGroups, allEventsAmount } = this.state;
        dbgLog(eventsForExtraction);
        dbgLog(events);
        const hideProgress = showProgress();
        const ids = eventsForExtraction.map(e => e.id);
        const portals = ids.length > 0 ? await Backend.getEntities<Portal>(
            Backend.portalInfoDb,
            Backend.where(Backend.documentId(), 'in', ids)
        ) : [];
        portals.forEach(portal => eventPortals.set(portal.id, portal));
        const today = getUserToday();
        const currentEvents = eventsForExtraction.filter(event => !event.deleted && event.date >= today);
        const golferCounts = await Promise.all(currentEvents.map(currentEvent => Backend.getCountFromServer(Backend.query(Backend.golferDb(currentEvent.id), Backend.where('hidden', '==', false)))));
        const groupCounts = await Promise.all(currentEvents.map(currentEvent => Backend.getCountFromServer(Backend.query(Backend.golferGroupDb(currentEvent.id), Backend.where('contactIds', '!=', [])))));
        for (let i = 0; i < currentEvents.length; i++) {
            eventStatGolfers.set(currentEvents[i].id, golferCounts[i].data().count);
            eventStatGroups.set(currentEvents[i].id, groupCounts[i].data().count);
        }
        events.push(...eventsForExtraction);
        hideProgress();
        let loading = false;
        const pastNotDeletedEventsAmount = eventsForExtraction.filter(event =>
            !event.deleted && event.date  < today
        ).length;
        if (pastNotDeletedEventsAmount < this.firstPastEventsPortion && events.length < allEventsAmount) {
            loading = true;
            await this.eventsProvider.loadNextPortion();
        }
        this.setState({ events, eventPortals, eventStatGolfers, eventStatGroups, loading: loading });
    }

    private handleCloseDialog = (addedId?: string) => {
        this.setState({ addDialogOpened: false });
        if (addedId) {
            this.context.setEventId(addedId);
            this.openEvent(addedId);
        }
    }

    render() {
        const { classes } = this.props;
        const { addDialogOpened, events, allEventsAmount, deletedEventsAmount } = this.state;
        const { effectiveUserId } = this.context;
        const today = getUserToday();
        const deletedEvents: Array<Event> = [];
        const todayEvents: Array<Event> = [];
        const pastEvents: Array<Event> = [];
        const upcomingEvents: Array<Event> = [];
        events.forEach(event => {
            const eventDate = event.date;
            if (event.deleted) {
                deletedEvents.push(event);
            } else if (eventDate < today) {
                pastEvents.push(event);
            } else if (today <= eventDate && eventDate < today + DAY_MILLIS) {
                todayEvents.push(event);
            } else {
                upcomingEvents.splice(0, 0, event);
            }
        });
        const totalEvents = todayEvents.length + upcomingEvents.length + pastEvents.length;
        const allPastEvents = allEventsAmount > 0 ? (allEventsAmount - (todayEvents.length + upcomingEvents.length +
            deletedEventsAmount)) : 0;
        return (
            <React.Fragment>
                {!navigator.onLine ? this.renderNoInternet() :
                    totalEvents === 0 && (deletedEvents.length === 0 || !effectiveUserId) ? this.renderNoEvents() :
                        <div className={classes.root} style={{ paddingTop: 0 }}>
                            {this.renderEvents(todayEvents, 'Today', true, true)}
                            {this.renderEvents(upcomingEvents, 'Upcoming Events', true)}
                            {this.renderEvents(pastEvents, 'Past', false, false, allPastEvents)}
                            {!!effectiveUserId && this.renderEvents(deletedEvents, 'Deleted')}
                        </div>}
                {addDialogOpened && <NewEventDialog handleClose={this.handleCloseDialog} />}
            </React.Fragment>
        );
    }

    private renderEvents(events: Event[], title: 'Today' | 'Upcoming Events' | 'Past' | 'Deleted', tiles?: boolean, today?: boolean, allPastEvents?: number) {
        if (!events.length && title !== 'Upcoming Events') {
            return null;
        }
        const { classes, cookies } = this.props;
        if (tiles) {
            const closeScheduleTile = () => {
                cookies?.set('scheduleBookClosed', true, { path: '/' });
            }
            const showAddNew = !today;
            const showSchedule = !today && events.length === 0 && !cookies?.get('scheduleBookClosed');
            return (
                <React.Fragment>
                    <ListTitle text={title} onClick={() => title === 'Upcoming Events' && cookies?.remove('scheduleBookClosed')} />
                    <Grid container className={classes.listRoot1}>
                        {events.map(item => <TileEventItem key={item.id} event={item} {...this.eventData(item, today)} clickHandler={this.handleItemClick} />)}
                        {showSchedule && <ScheduleSessionItem onClose={closeScheduleTile} />}
                        {showAddNew && <AddNewEventItem onClick={this.handleAddClick} taller={showSchedule} />}
                    </Grid>
                </React.Fragment>
            );
        } else {
            const { maxPastEventsShown, showMore } = this.state;
            const eventsToRender = title === 'Past' ? events.slice(0, maxPastEventsShown) : events;
            return (title === 'Past' ?
                <PastEventsList eventsToRender={eventsToRender}
                    showMore={showMore}
                    firstPastEventsPortion={this.firstPastEventsPortion}
                    allPastEvents={allPastEvents}
                    maxPastEventsShown={maxPastEventsShown}
                    onShowMoreOrLessClick={this.onShowMoreOrLessClick}
                    eventData={this.eventData}
                    handleItemClick={this.handleItemClick}
                    onLastElementScroll={this.onLastElementScroll}
                /> : <React.Fragment>
                    <ListTitle text={title} />
                    <List className={classes.listRoot1}>
                        {events.map(item => <RowEventItem key={item.id} event={item} {...this.eventData(item)}
                            clickHandler={this.handleItemClick} />)}
                    </List>
                </React.Fragment>
            );
        }
    }

    private onShowMoreOrLessClick = async () => {
        const { showMore } = this.state;
        if (showMore) {
            this.setState({ showMore: false });
        } else {
            await this.loadMorePastEvents(true);
        }
    };

    private loadMorePastEvents = async (setShowMoreClicked: boolean = false) => {
        let { maxPastEventsShown, showMore, events } = this.state;
        const shouldLoadMore = events.length <= this.pastEventsPortion;
        if (shouldLoadMore) {
            await this.eventsProvider.loadNextPortion();
        }
        maxPastEventsShown = shouldLoadMore ? maxPastEventsShown + this.pastEventsPortion : maxPastEventsShown;
        this.setState({ maxPastEventsShown, showMore: showMore || setShowMoreClicked });
    };

    private onLastElementScroll = async () => {
        let { maxPastEventsShown } = this.state;
        await this.eventsProvider.loadNextPortion();
        maxPastEventsShown = maxPastEventsShown + this.pastEventsPortion;
        this.setState({ maxPastEventsShown });
    };

    private renderNoEvents() {
        const { classes } = this.props;
        const { loading } = this.state;
        if (loading) {
            return null;
        }
        return (
            <Grid container justifyContent="center">
                <Grid xs={12} style={{ paddingLeft: 16, paddingRight: 16 }}>
                    <FlexCenter>
                        <div className={classes.welcomeHeader1}>Welcome to Golf Pad Events</div>
                    </FlexCenter>
                    <FlexCenter>
                        <Typography className={classes.welcomeHeader2}>Steps to creating your first golf tournament</Typography>
                    </FlexCenter>
                </Grid>
                <Grid xs={12} style={{ paddingTop: 24, paddingBottom: 24 }}>
                    <div className={classes.centerOuter}>
                        <div className={classes.centerInner}>
                            <Grid container>
                                <Grid sm={12} lg={4} style={{ padding: 30 }}>
                                    <FlexCenter><img src={Urls.welcomeCreateEventImg} style={{ height: 110, paddingBottom: 20 }} alt="" /></FlexCenter>
                                    <FlexCenter><Typography variant="h6">1. Create your event</Typography></FlexCenter>
                                    <FlexCenter><Typography variant="subtitle1" alignContent="center">Select the course, format, date and set up registration.</Typography></FlexCenter>
                                </Grid>
                                <Grid sm={12} lg={4} style={{ padding: 30 }}>
                                    <FlexCenter><img src={Urls.welcomeAddGolfersImg} style={{ height: 110, paddingBottom: 20 }} alt="" /></FlexCenter>
                                    <FlexCenter><Typography variant="h6">2. Add golfers</Typography></FlexCenter>
                                    <FlexCenter><Typography variant="subtitle1">Add, import or share registration link with golfers.</Typography></FlexCenter>
                                </Grid>
                                <Grid sm={12} lg={4} style={{ padding: 30 }}>
                                    <FlexCenter><img src={Urls.welcomeViewScoringImg} style={{ height: 110, paddingBottom: 20 }} alt="" /></FlexCenter>
                                    <FlexCenter><Typography variant="h6">3. Set up scoring</Typography></FlexCenter>
                                    <FlexCenter><Typography variant="subtitle1">Get scores from players' apps or enter  yourself and share live standings.</Typography></FlexCenter>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
                <Grid xs={12}>
                    <FlexCenter className={classes.welcomeNormalButton}>
                        <AppButton style={{ height: 40 }} color="secondary" onClick={this.handleAddClick}>
                            <AddIcon /> Create new event
                        </AppButton>
                    </FlexCenter>
                    <FlexCenter>
                        <Typography sx={{ fontWeight: 600, fontSize: 16, lineHeight: '24px', padding: '4px' }}>
                            Need help with getting started?
                        </Typography>
                    </FlexCenter>
                    <FlexCenter>
                        <Typography sx={{ fontWeight: 500, fontSize: 16, lineHeight: '24px', padding: '4px' }}>
                            <a href={Urls.helpURL} target="help" className={classes.link}>Visit FAQ</a>
                        </Typography>
                    </FlexCenter>
                    <FlexCenter>
                        or
                    </FlexCenter>
                    <FlexCenter>
                        <Typography sx={{ fontWeight: 500, fontSize: 16, lineHeight: '24px', padding: '4px' }}>
                            <a href={Urls.eventsUserSession} target="_blank" className={classes.link}>Schedule an online session</a>
                        </Typography>
                    </FlexCenter>
                    <FlexCenter>
                        <Typography sx={{ fontWeight: 400, fontSize: 14, lineHeight: '21px', padding: '4px', maxWidth: 580 }}>
                            The Golf Pad team can be your on-hand resource as you create your golf tournament from start to finish. Seeing your experience and learning about your tournament needs will improve the Events platform.
                        </Typography>
                    </FlexCenter>
                    <FlexCenter>
                        <Typography sx={{ fontWeight: 500, fontSize: 14, lineHeight: '21px', padding: '4px', maxWidth: 580 }}>
                            Schedule a video call with the Golf Pad Events team today!
                        </Typography>
                    </FlexCenter>
                </Grid>
                <div className={classes.welcomeFooterButton}>
                    <FlexCenter padding={16}>
                        <AppButton fullWidth style={{ height: 48 }} color="secondary" onClick={this.handleAddClick}><AddIcon /> Create new event</AppButton>
                    </FlexCenter>
                </div>
            </Grid>
        );
    }

    private renderNoInternet() {
        const { classes } = this.props;
        const { loading } = this.state;
        const status = 'No Internet connection.';
        if (loading) {
            return null;
        }
        return (
            <div className={classes.centerOuter}>
                <div className={classes.centerInner}>
                    <div className={classes.childrenCentered}>
                        <div className={classes.margin20}>
                            <img src={Urls.golfImage} style={{ height: 170 }} alt="" />
                        </div>
                        <div className={classes.margin20}>
                            <Typography variant="h5" className={classes.boldText}>{status}</Typography>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withUserId(withStyles(styles)(withCookies(EventList)));
