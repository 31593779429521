import * as React from 'react';
import { WithStyles } from '@mui/styles';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { pushUrl } from '../../../redux/ReduxConfig';
import { styles } from "../../../styles";
import { Box } from "@mui/material";
import { AppColors } from "../../../main/Theme";
import styled from "styled-components";

const ScrollContainer = styled.div`
  overflow-x: hidden;
  display: flex;
  scroll-behavior: smooth;
  width: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
`;

interface LinkTabProps<T> {
    label: React.ReactNode;
    active: boolean;
    value: T;
    pub?: boolean;
    getIcon?: (active: boolean) => React.ReactNode;
    onSelected: (tab: T) => void;
    index: number;
}

const LinkTab = function <T>(props: LinkTabProps<T> & WithStyles<typeof styles>) {
    const { active, classes, value, label, pub, index, getIcon, onSelected } = props;
    const cls = pub ?
        (classes.tabPub + ' ' + (active ? classes.activePub : classes.passivePub)) :
        (classes.tab + ' ' + (active ? classes.active : classes.passive));
    const icon = getIcon?.(active);
    const onClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        if (pub) {
            const element = document.getElementById(`item-${index}`);
            element?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        }
        onSelected(value);
    };
    return (
        <a href="/" className={cls} onClick={onClick} id={`item-${index}`} style={{
            cursor: 'pointer', whiteSpace: 'nowrap', minWidth: pub ? 150 : 'unset',
            borderBottom: pub ? active ? `2px solid ${AppColors.bluish}` : `1px solid ${AppColors.webGray200}` : 'unset'
        }}>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                {icon}
                {icon && <Box width={8} />}
                {label}
            </Box>
        </a>
    );
};

export interface Tab<T extends string> {
    id: T;
    label: React.ReactNode;
    node?: React.ReactNode;
    getIcon?: (active: boolean) => React.ReactNode;
}

type TabChanger<T> = (tab: T) => void;
type TabCreator<T extends string> = (changeTab: TabChanger<T>) => Tab<T>;

interface SettingsProperties<T extends string> {
    tabs: Array<Tab<T> | TabCreator<T>>;
    initial: T;
    maxWidth?: number;
    pub?: boolean;
    sticky?: boolean;
    onSelectedTab: (tab: T) => void;
}

type PropsInner<T extends string> = SettingsProperties<T> & { tabId: T, parentUrl: string };

class TabsInner<T extends string> extends React.Component<PropsInner<T> & WithStyles<typeof styles>> {
    private tabs: Array<Tab<T>> = [];
    private readonly withShadow: string;
    private readonly withoutShadow: string;

    constructor(props: PropsInner<T> & WithStyles<typeof styles>) {
        super(props);
        this.updateTabs(this.props.tabs);
        const sticky = this.props.sticky ? this.props.classes.stickyTop : '';
        this.withShadow = (this.props.pub ? this.props.classes.shadowTabRootPub : this.props.classes.tabRoot) + ' ' + sticky;
        this.withoutShadow = (this.props.pub ? this.props.classes.tabRootPub : this.props.classes.tabRoot) + ' ' + sticky;
    }

    scrollEventListener = () => {
        const elem: Element | null = document.getElementById('tabsDiv');
        if (elem) {
            if (elem.getBoundingClientRect().top < 5) {
                elem.className = this.withShadow;
            } else {
                elem.className = this.withoutShadow;
            }
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.scrollEventListener);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollEventListener);
    }

    shouldComponentUpdate(nextProps: PropsInner<T>) {
        if (nextProps.tabs !== this.props.tabs) {
            this.updateTabs(nextProps.tabs);
            return true;
        }
        return false;
    }

    private updateTabs(tabs: Array<Tab<T> | TabCreator<T>>) {
        this.tabs = tabs.map(t => {
            if (typeof t === 'function') {
                return t(this.changeTab);
            } else {
                return t;
            }
        });
    }

    private changeTab = (value: T) => {
        const { parentUrl, onSelectedTab } = this.props;
        onSelectedTab(value);
        pushUrl(`${parentUrl}/${value}`);
    };

    render() {
        const { classes, maxWidth, pub, tabId } = this.props;
        const selectedTab = this.tabs.filter((t) => t.id === tabId)[0];
        const className = this.withoutShadow;
        return (
            <div id={'tabsDiv'} className={className} style={{ maxWidth }}>
                <ScrollContainer>
                    {this.tabs.map((t, i) => <LinkTab index={i} key={t.id} label={t.label} pub={pub} active={tabId === t.id} value={t.id} getIcon={t.getIcon} onSelected={this.changeTab} classes={classes} />)}
                </ScrollContainer>
                {selectedTab && selectedTab.node}
            </div>
        );
    }
}

type Props<T extends string> = SettingsProperties<T> & RouteComponentProps<{ tab: T }> & WithStyles<typeof styles>;

function LinkTabs<T extends string>(props: Props<T>) {
    const { classes, match, location, history, staticContext, ...other } = props;
    const parentUrl = match.url;
    return (
        <Switch>
            <Route path={`${parentUrl}/:tab`}
                render={p => <TabsInner tabId={p.match.params.tab as T} parentUrl={parentUrl} classes={classes} {...other} />} />
            <Redirect to={`${parentUrl}/${props.initial}`} />
        </Switch>
    );
}

export default withRouter(LinkTabs);
