import React, { useRef, useState, useCallback, useImperativeHandle } from 'react';
import update from 'immutability-helper'
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { CSVLink } from 'react-csv';
import { Badge, List, ListItem } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { DragSource, DragSourceConnector, DragSourceMonitor, ConnectDragSource, DropTarget, DropTargetConnector, DropTargetMonitor, ConnectDropTarget } from 'react-dnd';
import PersonIcon from '@mui/icons-material/Person';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { FixedWidthChip, FixedWidthChipLong, FixedWidthChipLonger, FixedWidthChipLongest } from '../../../../common/components/FixedWidthChip';
import ButtonBar from '../../../../common/components/ButtonBar';
import AppButton from '../../../../common/components/AppButton';
import LabeledField from '../../../../common/form/LabeledField';
import { NoWrapOverflow } from '../../../../common/Misc';
import { WizardIcon } from '../../../../common/Icons';
import {
    Event, EventData, Contact, ContactDetails, Team, GolferGroup, TeeTimeSettings, AutoSchedule, HandicapMode,
    GenderMode, golfersOrTeamsPerGroup, getGroupFullness, makeNewGroups, teeTimeName, golfersOrTeams, getHolesRange,
    Score, ReportedScore, Distance
} from '../../../../types/EventTypes';
import { fullName, golferTeamName, golfersOfTeam, shortName, getSameNameGolfersIds } from '../../../../contact/Contact';
import { addGolferOrTeamToGroup, elog, autoScheduleName, deleteGolfersFromEvent, updateGolfersPlayingHandicap, saveContact, saveGroupsBatch, deleteInviteCode } from '../../../Event';
import * as Backend from '../../../../util/firebase';
import { teeSettingsName, getTeeTime, formatTeeTime } from '../../../TeeTimes';
import AutoScheduleDialog from '../../common/AutoScheduleDialog';
import SelectAutoScheduleDialog from '../../common/SelectAutoScheduleDialog';
import { EditTeeTimeDialog } from '../../settings/format/EditTeeTimeField';
import EditGroupsDialog from './EditGroupsDialog';
import EditContactDialog from '../../../../contact/EditContactDialog';
import * as Utils from '../../../../util/utility';
import { styles } from '../../../../styles';
import { showAlert, showProgress } from '../../../../redux/ReduxConfig';
import { EmailVariant, sendPaymentMessage } from "../../../../util/email_utils";

const DRAG_TYPE_GROUP = 'GROUP';

function showAdjustmentsAlert(reportedContactNames: Array<string>) {
    showAlert(`${reportedContactNames.join(', ')} can no longer be rescheduled, as they have already started scoring for this event. Schedule adjustments can be made prior to golfers starting the round and adding scores.`, [
        { title: 'Ok', color: 'secondary' }
    ]);
}

function reportedContacts(teamOrGolferId: string, golfers: Map<string, Contact>, teams: Map<string, Team>) {
    let reportedContactsName: Array<string> = [];
    const golfer = golfers.get(teamOrGolferId);
    if (golfer && golfer.reportedBy) {
        reportedContactsName = [fullName(golfer)];
    } else {
        const team = teams.get(teamOrGolferId);
        if (team) {
            reportedContactsName = golfersOfTeam(team, golfers).filter(g => g.reportedBy).map(g => fullName(g));
        }
    }
    return reportedContactsName;
}

export interface GroupWithContacts {
    group: GolferGroup;
    teams: Array<Team>;
    golfers: Array<Contact>;
    label: string;
}

interface GroupRowItem {
    id: string
    names: Array<string>
    homeCourseOrCities?: string[];
    hidden: boolean;
    isPlaceholder: boolean;
    isReported: boolean;
    version: number;
}

interface GroupRowProps {
    initialItems: GroupRowItem[];
    event: Event;
    groups: GolferGroup[];
    group: GroupWithContacts;
    teams: Map<string, Team>;
    golfers: Map<string, Contact>;
    groupFullness: number;
    addToGroup: (group: GroupWithContacts) => void;
    dropToGroup: (teamOrGolferId: string) => void;
    openGolfer: (id: string) => void;
    deleteFromGroup: (group: GroupWithContacts, teamOrGolferId: string) => void;
}

interface GroupItemProps {
    id: string;
    names: Array<string>;
    homeCourseOrCities?: string[];
    isPlaceholder?: boolean;
    isGroupOver: boolean;
    isReported: boolean;
    group: GroupWithContacts;
    teams: Map<string, Team>;
    golfers: Map<string, Contact>;
    groupSize: number;
    groupFullness: number;
    index: number;
    dragIndex: number;
    dragY?: number;
    moveChip: (chip: GroupRowItem, groupFrom: GroupWithContacts, draggedIndex: number, groupTo: GroupWithContacts, hoverIndex: number) => void;
    dropToGroup: (group: GroupWithContacts, golferId: string, index?: number) => void;
    onDelete: () => void;
    onOpen: (id: string) => void;
}

interface GroupItemTargetProps {
    connectDropTarget: ConnectDropTarget;
    canDrop: boolean;
    isOver: boolean;
    item: GroupItemProps;
}

interface GroupItemSourceProps {
    connectDragSource: ConnectDragSource;
    isDragging: boolean;
    item: GroupItemProps;
}

interface ItemInstance {
    getNode(): HTMLDivElement | null
}

const itemTarget = {
    hover(props: GroupItemProps, monitor: DropTargetMonitor, component: ItemInstance) {
        const item = monitor.getItem() as GroupItemProps;
        const node = component.getNode()
        if (!node) {
            return null
        }
        if (!item.dragY) {
            item.dragY = monitor.getInitialClientOffset()!.y;
        }
        if (item.group.group.id === props.group.group.id) {
            const chip: GroupRowItem = { id: item.id, names: item.names, isPlaceholder: false, isReported: item.isReported, hidden: false, version: -1 }
            if (item.dragY < node.getBoundingClientRect()!.y - 10 || item.dragY > node.getBoundingClientRect()!.y + 10) {
                item.dragIndex = props.index;
                item.dragY = node.getBoundingClientRect()!.y;
                item.moveChip(chip, item.group, item.index, props.group, props.index);
            } else if (item.dragIndex < props.index && (monitor.getClientOffset()!.x > node.getBoundingClientRect().x + node.getBoundingClientRect().width / 3)) {
                item.dragIndex = props.index;
                item.dragY = node.getBoundingClientRect()!.y;
                item.moveChip(chip, item.group, item.index, props.group, props.index);
            } else if (item.dragIndex > props.index && (monitor.getClientOffset()!.x < node.getBoundingClientRect().x + node.getBoundingClientRect().width / 3)) {
                item.dragIndex = props.index;
                item.dragY = node.getBoundingClientRect()!.y;
                item.moveChip(chip, item.group, item.index, props.group, props.index);
            }
        } else {
            const chip: GroupRowItem = { id: item.id, names: item.names, isPlaceholder: true, isReported: item.isReported, hidden: false, version: -1 }
            if (item.dragY < node.getBoundingClientRect()!.y - 10 || item.dragY > node.getBoundingClientRect()!.y + 10) {
                item.dragIndex = props.index;
                item.dragY = node.getBoundingClientRect()!.y;
                props.moveChip(chip, item.group, item.index, props.group, props.index);
            } else if ((item.dragIndex < props.index && (monitor.getClientOffset()!.x > node.getBoundingClientRect().x + node.getBoundingClientRect().width / 3))) {
                item.dragIndex = props.index;
                item.dragY = node.getBoundingClientRect()!.y;
                props.moveChip(chip, item.group, item.index, props.group, props.index);
            } else if (item.dragIndex >= props.index && (monitor.getClientOffset()!.x < node.getBoundingClientRect().x + node.getBoundingClientRect().width / 3)) {
                item.dragIndex = props.index;
                item.dragY = node.getBoundingClientRect()!.y;
                props.moveChip(chip, item.group, item.index, props.group, props.index);
            }
        }
        return null;
    },
    canDrop(props: GroupItemProps, monitor: DropTargetMonitor) {
        const item = monitor.getItem() as GroupItemProps;
        return props.groupFullness < 1 || (!!props.group.golfers.find(c => c.id === item.id) || !!props.group.teams.find(t => t.id === item.id));
    },
    drop(props: GroupItemProps, monitor: DropTargetMonitor) {
        const item = monitor.getItem() as GroupItemProps;
        const reportedContactNames = reportedContacts(item.id, props.golfers, props.teams);
        if (reportedContactNames.length > 0) {
            showAdjustmentsAlert(reportedContactNames);
        } else {
            if (item.group.group.id === props.group.group.id) {
                item.dropToGroup(props.group, item.id, props.index);
            } else {
                props.dropToGroup(props.group, item.id, props.index);
            }
        }
    }
};

const groupTarget = {
    canDrop(props: GroupRowProps, monitor: DropTargetMonitor) {
        const item = monitor.getItem() as GroupItemProps;
        return (props.groupFullness < 1 || !!props.group.golfers.find(c => c.id === item.id) || !!props.group.teams.find(t => t.id === item.id));
    },
    drop(props: GroupRowProps, monitor: DropTargetMonitor) {
        if (!monitor.didDrop()) {
            const item = monitor.getItem() as GroupItemProps;
            const reportedContactNames = reportedContacts(item.id, props.golfers, props.teams);
            if (reportedContactNames.length > 0) {
                showAdjustmentsAlert(reportedContactNames);
            } else {
                props.dropToGroup(item.id);
            }
        }
    }
};

const collectItemSource = (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
    item: monitor.getItem() as GroupItemProps,
});

const collectItemTarget = (connect: DropTargetConnector, monitor: DropTargetMonitor) => ({
    connectDropTarget: connect.dropTarget(),
    canDrop: monitor.canDrop(),
    isOver: monitor.isOver(),
    item: monitor.getItem() as GroupItemProps,
});

const collectGroupTarget = (connect: DropTargetConnector, monitor: DropTargetMonitor) => ({
    connectDropTarget: connect.dropTarget(),
    canDrop: monitor.canDrop(),
    isOver: monitor.isOver(),
    item: monitor.getItem() as GroupItemProps,
});

const GroupItemDnD = React.forwardRef<HTMLDivElement, GroupItemProps & GroupItemSourceProps & GroupItemTargetProps & WithStyles<typeof styles>>(
    ({ id, names, homeCourseOrCities, onOpen, onDelete, connectDragSource, connectDropTarget, isPlaceholder, isGroupOver, isReported, isOver, item, classes, golfers }, ref) => {
        const elementRef = useRef(null);
        connectDragSource(elementRef);
        connectDropTarget(elementRef);
        useImperativeHandle<{}, ItemInstance>(ref, () => ({
            getNode: () => elementRef.current,
        }));
        const label = (
            <NoWrapOverflow className={classes.chipPaper}>
                {names.map((name, idx) => <React.Fragment key={idx}><PersonIcon className={classes.textIcon} />{name}<span className={classes.homeCourseOrCity}>{homeCourseOrCities && homeCourseOrCities[idx] ? ` (${homeCourseOrCities[idx]})` : ''}</span></React.Fragment>)}
            </NoWrapOverflow>
        );
        return !isPlaceholder || (isGroupOver && id === item.id) ?
            <span style={{ height: '100%', borderRadius: 16 }}>
                {names.length === 1 && <FixedWidthChip ref={elementRef} label={label} onDelete={onDelete} color={isOver && !!item && item.id === id ? "secondary" : "default"} onDoubleClick={_e => onOpen(id)} />}
                {names.length === 2 && <FixedWidthChipLong ref={elementRef} label={label} onDelete={onDelete} color={isOver && !!item && item.id === id ? "secondary" : "default"} onDoubleClick={_e => onOpen(id)} />}
                {names.length === 3 && <FixedWidthChipLonger ref={elementRef} label={label} onDelete={onDelete} color={isOver && !!item && item.id === id ? "secondary" : "default"} onDoubleClick={_e => onOpen(id)} />}
                {names.length === 4 && <FixedWidthChipLongest ref={elementRef} label={label} onDelete={onDelete} color={isOver && !!item && item.id === id ? "secondary" : "default"} onDoubleClick={_e => onOpen(id)} />}
            </span> : <span></span>;
    },
);

const GroupItem = withStyles(styles)(DropTarget(DRAG_TYPE_GROUP, itemTarget, collectItemTarget)(DragSource(DRAG_TYPE_GROUP, {
    beginDrag: (props: GroupItemProps) => ({
        id: props.id,
        names: props.names,
        index: props.index,
        dragIndex: props.index,
        isPlaceholder: props.isPlaceholder,
        isGroupOver: props.isGroupOver,
        group: props.group,
        groupSize: props.groupSize,
        groupFullness: props.groupFullness,
        moveChip: props.moveChip,
        dropToGroup: props.dropToGroup,
        onDelete: props.onDelete,
        onOpen: props.onOpen,
    }),
}, collectItemSource)(GroupItemDnD)));

export interface GroupRowState {
    items: GroupRowItem[]
}

function gatherItems(event: Event, group: GroupWithContacts, golfers: Map<string, Contact>, version: number): GroupRowItem[] {
    const items: GroupRowItem[] = [];
    const sameNameGolfersIdsSet: Set<string> = getSameNameGolfersIds(Array.from(golfers.values()));
    if (event.teamSize > 1) {
        group.teams.forEach(team => {
            const teamMembers = golfersOfTeam(team, golfers);
            const coursesOrCities = teamMembers.filter(contact => sameNameGolfersIdsSet.has(contact.id) && contact.homeCourseOrCity).map(contact => contact.homeCourseOrCity!);
            items.push({
                id: team.id,
                names: teamMembers.map(golfer => shortName(golfer, undefined, 10)),
                homeCourseOrCities: coursesOrCities.length === 0 ? undefined : coursesOrCities,
                isPlaceholder: false,
                hidden: false,
                isReported: !!teamMembers.find(g => !!g.reportedBy),
                version
            })
        })
    } else {
        group.golfers.forEach(contact => items.push({
            id: contact.id,
            names: [fullName(contact)],
            homeCourseOrCities: sameNameGolfersIdsSet.has(contact.id) && contact.homeCourseOrCity ? [contact.homeCourseOrCity] : undefined,
            isPlaceholder: false,
            hidden: !golfers.has(contact.id),
            isReported: !!contact.reportedBy,
            version
        }));
    }
    return items;
}

const GroupRowDnD = React.forwardRef<HTMLDivElement, GroupRowProps & GroupItemTargetProps & WithStyles<typeof styles>>(
    ({ initialItems, event, groups, group, addToGroup, openGolfer, deleteFromGroup, classes, groupFullness, canDrop, isOver, item, connectDropTarget, golfers, teams }, _ref) => {
        const showIcon = groupFullness < 1 && !item;
        const decorations = classes.listItem;
        const [initialState, setInitialState] = useState(initialItems);
        const [items, setItems] = useState(initialState);
        if (initialState.length !== initialItems.length || (initialState.length > 0 && initialState[0].version !== initialItems[0].version)) {
            setInitialState(initialItems);
            setItems(initialItems);
        }
        const dropToGroup = (group: GroupWithContacts, teamOrGolferId: string, index?: number) => {
            addGolferOrTeamToGroup(teamOrGolferId, group.group, groups, event, index);
        }
        const moveChip = useCallback(
            (chip: GroupRowItem, groupFrom: GroupWithContacts, dragIndex: number, groupTo: GroupWithContacts, hoverIndex: number) => {
                const pos = items.findIndex(i => i.id === chip.id);
                if (groupFrom.group.id === groupTo.group.id) {
                    setItems(update(items, { $splice: [[dragIndex, 1], [hoverIndex, 0, chip],] }));
                } else if (groupFullness < 1) {
                    if (items.length === 0 || pos < 0) {
                        setItems(update(items, { $splice: [[hoverIndex, 0, chip],] }));
                    } else {
                        setItems(update(items, { $splice: [[pos, 1], [hoverIndex, 0, chip],] }));
                    }
                }
            },
            [items, groupFullness]);

        const contactsList = items.filter(i => !i.hidden).map((item: GroupRowItem, i: number) =>
            <GroupItem isPlaceholder={item.isPlaceholder} dragIndex={i} index={i} key={item.id} id={item.id} names={item.names} homeCourseOrCities={item.homeCourseOrCities}
                group={group} groupSize={event.teamSize} groupFullness={groupFullness} isGroupOver={isOver} isReported={item.isReported} moveChip={moveChip} golfers={golfers} teams={teams}
                onOpen={id => openGolfer(id)} onDelete={() => deleteFromGroup(group, item.id)} dropToGroup={(group: GroupWithContacts, golferId: string, index?: number) => dropToGroup(group, golferId, index)} />);
        if (!!item && canDrop && isOver && item.group.group.id !== group.group.id && items.findIndex(i => i.id === item.id) < 0) {
            if (item.names.length === 1) contactsList.push((<FixedWidthChip id={item.id} key={item.id} label={item.names} onDelete={() => { }} className={classes.dragActive} onDoubleClick={() => { }} />));
            if (item.names.length === 2) contactsList.push((<FixedWidthChipLong id={item.id} key={item.id} label={item.names} onDelete={() => { }} className={classes.dragActive} onDoubleClick={() => { }} />));
            if (item.names.length > 2) contactsList.push((<FixedWidthChipLonger id={item.id} key={item.id} label={item.names} onDelete={() => { }} className={classes.dragActive} onDoubleClick={() => { }} />));
        }
        const holesRange = getHolesRange(event.holesType);
        const teeTime = teeTimeName(getTeeTime(event.teeTime, holesRange, group.group.order));
        return connectDropTarget(
            <div>
                <ListItem className={decorations}>
                    <Grid container wrap="nowrap">
                        <Grid className={classes.centeredGrid + ' ' + (event.teeTime.mode === 'regular' ? classes.labelTeeRegular : classes.labelTeeShotgun)} item style={{ height: 52 }}>
                            <Typography variant="body2" noWrap color={'initial'}>{teeTime}</Typography>
                            {groupFullness >= 1 && <span style={{ color: 'red', fontSize: '0.65rem' }}>{Utils.withS(contactsList.length, event.teamSize === 1 ? 'golfer' : 'team')}</span>}
                        </Grid>
                        <Grid className={classes.centeredGrid} item>
                            <div>
                                {contactsList}
                                {showIcon && <IconButton
                                    className={classes.smallIconButton}
                                    onClick={() => addToGroup(group)}
                                    size="large"><AddCircleOutlineIcon /></IconButton>}
                            </div>
                        </Grid>
                    </Grid>
                </ListItem >
                <Divider />
            </div>
        );
    });

const GroupRow = withStyles(styles)(DropTarget(DRAG_TYPE_GROUP, groupTarget, collectGroupTarget)(GroupRowDnD));

interface State {
    doSchedule: boolean;
    editingTeeTimes: boolean;
    selectingAutoSchedule?: boolean;
    editingGroup: number;
    editedContact?: ContactDetails;
    handleExport: boolean;
}

type Props = { event: Event; eventData: EventData; } & WithStyles<typeof styles>;

class ScheduleList extends React.Component<Props, State> {

    state: State = {
        doSchedule: false,
        editingTeeTimes: false,
        editingGroup: -1,
        handleExport: false
    };

    componentDidMount() {
        Backend.trackEvent('view_schedule');
    }

    private handleExport = () => this.setState({ handleExport: true });
    private handleTeeTimeSettingsClose = () => this.setState({ editingTeeTimes: false });
    private handleAutoSchedule = () => this.setState({ doSchedule: true });
    private addToGroup = (group: GroupWithContacts) => this.setState({ editingGroup: group.group.order });

    private handleReset = () => {
        const { event } = this.props;
        const { golfers, groups } = this.props.eventData;
        let reportedGolfers: Array<Contact> = []
        groups.forEach(g => g.contactIds.forEach(id => {
            if (golfers.get(id)?.reportedBy) {
                reportedGolfers.push(golfers.get(id)!);
            }
        }));
        if (reportedGolfers.length === 0) {
            Backend.removeBatch(Backend.golferGroupDb(event.id), groups, deleteInviteCode)
                .then(() => elog(event, `Groups reset `, `${groups.length} removed`, ''));
        } else {
            const reportedContactNames = reportedGolfers.map(golfer => fullName(golfer));
            showAdjustmentsAlert(reportedContactNames);
        }
    }

    private setAutoScheduleSave = (autoSchedule: AutoSchedule) => {
        const { event } = this.props;
        this.setState({ selectingAutoSchedule: false }, () => Backend.update(Backend.eventsDb, { id: event.id, autoSchedule }).then(() => {
            if (autoSchedule === 'ON') {
                this.scheduleGroups('random', 'random', false);
            }
        }));
    }

    private saveTeeTime = (val: TeeTimeSettings, eventTime?: number) => {
        const { event } = this.props;
        const { golfers, teams, groups } = this.props.eventData;
        const groupCount = golfersOrTeamsPerGroup(event, val.golfersPerGroup);
        const updatedGroups = groups.filter(g => g.contactIds.length > groupCount);
        if (updatedGroups.length > 0) {
            let reportedContactNames: Array<string> = [];
            updatedGroups.forEach(g => g.contactIds.forEach(id => reportedContactNames = reportedContactNames.concat(reportedContacts(id, golfers, teams))))
            if (reportedContactNames.length > 0) {
                showAdjustmentsAlert(reportedContactNames);
            } else {
                showAlert("Reducing the group size will automatically remove some golfers from the existing groups. ", [
                    { title: 'Cancel' },
                    { title: 'Proceed', color: 'secondary', action: () => this.saveTeeTimeConfirmed(val, eventTime) }
                ]);
            }
        } else {
            this.saveTeeTimeConfirmed(val, eventTime);
        }
    }

    private saveTeeTimeConfirmed = async (val: TeeTimeSettings, eventTime?: number) => {
        const { event } = this.props;
        this.setState({ editingTeeTimes: false });
        const eventData = {
            id: event.id,
            exists: true,
            teeTime: val,
            date: eventTime ?? event.date
        };
        const hideProgress = showProgress();
        try {
            await Backend.updateOrAdd(Backend.eventsDb, eventData);
            await this.adjustGroupsSize(val.golfersPerGroup);
            await this.generateTeeTimes();
            elog(event, 'Tee times changed',
                `startTime: ${formatTeeTime(val)} mode: ${val.mode} groups size: ${val.golfersPerGroup} starting type: ${val.startingHolesType}`,
                `Id: ${event.id}`)
        } finally {
            hideProgress();
        }
    }

    private adjustGroupsSize = async (golfersPerGroup: number) => {
        const { event } = this.props;
        const { groups } = this.props.eventData;
        const groupCount = golfersOrTeamsPerGroup(event, golfersPerGroup);
        const updatedGroups = groups.filter(g => g.contactIds.length > groupCount);
        if (updatedGroups.length > 0) {
            const usedOrders = new Set();
            groups.filter(g => g.contactIds.length > 0).forEach(g => usedOrders.add(g.order));
            updatedGroups.forEach(g => g.contactIds.splice(groupCount, g.contactIds.length - groupCount));
            await Backend.updateOrAddBatch(Backend.golferGroupDb(event.id), updatedGroups);
        }
    }

    private deleteFromGroup = (groupc: GroupWithContacts, teamOrGolferId: string) => {
        const { event } = this.props;
        const { golfers, teams } = this.props.eventData;
        const reportedContactNames = reportedContacts(teamOrGolferId, golfers, teams);
        if (reportedContactNames.length > 0) {
            showAdjustmentsAlert(reportedContactNames);
        } else {
            const group = groupc.group;
            const idx = group.contactIds.indexOf(teamOrGolferId);
            group.contactIds.splice(idx, 1);
            Backend.updateOrAdd(Backend.golferGroupDb(event.id), group);
        }
    }

    private async generateTeeTimes() {
        const { genTeeTimes } = this.props.eventData;
        await genTeeTimes();
    }

    private groupWithTeams(group: GolferGroup): GroupWithContacts {
        const { teams } = this.props.eventData;
        const groupTeams: Array<Team> = [];
        group.contactIds.forEach(id => {
            const team = teams.get(id);
            if (team) {
                groupTeams.push(team);
            } else {
                groupTeams.push({ id, order: -1, contactIds: [] });
            }
        });
        return {
            group: group,
            teams: groupTeams,
            golfers: [],
            label: ''
        };
    }

    private groupWithGolfers(group: GolferGroup): GroupWithContacts {
        const { golfers } = this.props.eventData;
        const groupGolfers: Array<Contact> = [];
        let label = '';
        group.contactIds.forEach(id => {
            const golfer = golfers.get(id);
            if (golfer) {
                groupGolfers.push(golfer);
                label += golfer.lastName + ',';
            } else {
                groupGolfers.push({ id, lastName: 'id:' + id.substring(0, 6) + '..', gender: 'male', hidden: false });
            }
        });
        return {
            group: group,
            teams: [],
            golfers: groupGolfers,
            label
        };
    }

    private scheduleGroups = (genderMode: GenderMode, handicapMode: HandicapMode, keepGroups: boolean) => {
        const { event } = this.props;
        const { golfers, teams, groups, teeTimes } = this.props.eventData;
        const groupSize = golfersOrTeamsPerGroup(event, event.teeTime.golfersPerGroup);
        const groupsFrom = keepGroups ? groups : [{ id: '', contactIds: [], order: 0 }];
        const newGroups = makeNewGroups(groupsFrom, groupSize, teeTimes.length, false, golfersOrTeams(event.teamSize === 1 ? golfers : teams, groupsFrom, true), handicapMode, genderMode);
        const groupsToDel = keepGroups ? [] : groups.filter(g1 => g1.id && !newGroups.find(g2 => g2.id === g1.id));
        saveGroupsBatch(newGroups, genderMode, handicapMode, groupsToDel, event);
    }

    private handleScheduleGroups = (genderMode: GenderMode, handicapMode: HandicapMode, keepGroups: boolean) =>
        this.setState({ doSchedule: false }, () => this.scheduleGroups(genderMode, handicapMode, keepGroups));

    private openGolfer = (id: string) => this.setState({ editedContact: this.props.eventData.golfers.get(id) });

    private handleContactDeleted = (golfers: Array<Contact>) => {
        const { event } = this.props;
        deleteGolfersFromEvent(event, golfers, undefined)
            .then(() => updateGolfersPlayingHandicap(event));
    }

    private handleContactChanged = (contactDetails: ContactDetails) => {
        const { event } = this.props;
        saveContact(event, contactDetails, 'Golfer modified')
            .then(() => this.setState({ editedContact: undefined }));
    }

    private exportData = () => {
        const { event } = this.props;
        const { golfers, teams, groups } = this.props.eventData;
        const groupSize = Math.max(Math.floor(((event.teeTime && event.teeTime.golfersPerGroup) ? event.teeTime.golfersPerGroup : 4) / event.teamSize), 1);
        const exportData: string[][] = [];
        const exportHeader = ['Start'];
        if (exportData) {
            for (let i = 1; i <= groupSize; i += 1) {
                if (event.teamSize === 1) {
                    exportHeader.push('Golfer ' + i);
                } else {
                    exportHeader.push('Team ' + i);
                }
            }
            exportData.push(exportHeader);
            groups.forEach(group => {
                if (group.contactIds.length > 0) {
                    let exportRow: string[] = [];
                    const holesRange = getHolesRange(event.holesType);
                    const groupTime = teeTimeName(getTeeTime(event.teeTime, holesRange, group.order));
                    exportRow.push(groupTime);
                    group.contactIds.forEach(contactId => {
                        if (event.teamSize === 1) {
                            const contact = golfers.get(contactId);
                            if (contact) {
                                exportRow.push(fullName(contact!));
                            }
                        } else {
                            const team = teams.get(contactId);
                            if (team) {
                                exportRow.push(golferTeamName(team!, golfers));
                            }
                        }
                    });
                    exportData.push(exportRow);
                }
            });
        }
        return exportData;
    }

    private Groups = () => {
        const { event, classes } = this.props;
        const { golfers, teams, groups, loadedGolfers, loadedTeams, loadedGroups } = this.props.eventData;
        const { handleExport } = this.state;
        const moveGroup = (group: GolferGroup, teamOrGolferId: string) => addGolferOrTeamToGroup(teamOrGolferId, group, groups, event);
        const groupSize = Math.max(Math.floor((event.teeTime?.golfersPerGroup || 4) / event.teamSize), 1);
        const teeTimeInfo = (
            <React.Fragment>
                <Typography>{teeSettingsName(event)}</Typography>
                <Typography>{groupSize === 1 ? (event.teamSize > 1 ? '1 team per tee time' : '1 person per tee time') :
                    groupSize + (event.teamSize > 1 ? ' teams per tee time' : ' people per tee time')}</Typography>
            </React.Fragment>
        );
        const autoScheduleInfo = Utils.makeFriendlyString(autoScheduleName(event.autoSchedule).toLowerCase(), false);
        let unscheduledInfo = '';
        if (loadedGroups > 0) {
            const scheduled = new Set<string>();
            groups.forEach(group => group.contactIds.forEach(contactId => scheduled.add(contactId)));
            let unscheduled = 0;
            if (event.teamSize === 1 && loadedGolfers > 0) {
                golfers.forEach(golfer => unscheduled += scheduled.has(golfer.id) ? 0 : 1);
            } else if (event.teamSize > 1 && loadedTeams > 0) {
                teams.forEach(team => unscheduled += scheduled.has(team.id) ? 0 : 1);
            }
            if (unscheduled > 0) {
                unscheduledInfo = Utils.withS(unscheduled, event.teamSize === 1 ? 'golfer' : 'team') + ' not scheduled yet';
            }
        }
        const fileName = event.name.replace(' ', '-') + '-' + Utils.formatDate2(event.date);
        const exportFile = `${fileName}-schedule.csv`;
        const exportData = handleExport ? this.exportData() : '';
        return (
            <div className={classes.listRoot2}>
                <List disablePadding className={classes.listRoot2}>
                    <LabeledField label={'Tee times'} itemClass={classes.listItem} value={teeTimeInfo} edit={() => this.setState({ editingTeeTimes: true })} />
                    <Divider />
                    <LabeledField label={'Auto schedule'} itemClass={classes.listItem} value={autoScheduleInfo} edit={() => this.setState({ selectingAutoSchedule: true })} />
                </List>
                <List disablePadding className={classes.listRoot2}>
                    <ListItem className={classes.listItem}>
                        <ButtonBar margin>
                            <AppButton color="secondary" onClick={this.handleAutoSchedule}>
                                <WizardIcon className={classes.leftButtonIcon} />Schedule...
                            </AppButton>
                            {<CSVLink data={exportData} filename={exportFile} style={{ textDecoration: 'none' }} >
                                <AppButton color="info" onClick={this.handleExport}>Export</AppButton></CSVLink>}
                            <AppButton color="info" onClick={this.handleReset}>Reset all</AppButton>
                            {!!unscheduledInfo && <Typography variant="body1" style={{ marginLeft: 16 }}>
                                {unscheduledInfo}
                                <Badge sx={{ marginBottom: 2, marginLeft: 1 }} color="error" variant="dot" overlap="rectangular" />
                            </Typography>}
                        </ButtonBar>
                    </ListItem>
                </List>
                <List disablePadding className={classes.listRoot2}>
                    {groups.map((group, i) => {
                        const groupWithContacts: GroupWithContacts = event.teamSize === 1 ? this.groupWithGolfers(group) : this.groupWithTeams(group);
                        return <GroupRow
                            initialItems={gatherItems(event, groupWithContacts, golfers, loadedGroups * 1000000 + loadedTeams * 10000 + loadedGolfers)}
                            key={group.id + '-' + i}
                            event={event}
                            teams={teams}
                            golfers={golfers}
                            groups={groups}
                            group={groupWithContacts}
                            groupFullness={getGroupFullness(group, event, event.teeTime.golfersPerGroup)}
                            addToGroup={this.addToGroup}
                            dropToGroup={(teamOrGolferId: string) => moveGroup(group, teamOrGolferId)}
                            openGolfer={this.openGolfer}
                            deleteFromGroup={this.deleteFromGroup} />
                    })}
                </List>
            </div>
        );
    };

    private handleSendMessage = async (contactsToSend: Contact[], variant: EmailVariant) => {
        const { event, eventData } = this.props;
        await sendPaymentMessage(contactsToSend, event, eventData, new Map<string, Score>(), new Map<string, Score>(),
            new Map<string, ReportedScore>(), new Map<string, ReportedScore>(), new Map<string, Distance>(), variant);
    };

    render() {
        const { event, eventData } = this.props;
        const { golfers, teams, groups, teeTimes } = eventData;
        const { doSchedule, editingTeeTimes, editedContact, selectingAutoSchedule } = this.state;
        const teamsPerGroup = golfersOrTeamsPerGroup(event, event.teeTime.golfersPerGroup);
        return (
            <React.Fragment>
                <this.Groups />
                {!!editedContact && <EditContactDialog
                    open
                    event={event}
                    actionMode="edit"
                    initialContact={editedContact}
                    saveToEvent={this.handleContactChanged}
                    sendEmail={this.handleSendMessage}
                    deleteFromEvent={this.handleContactDeleted}
                    handleClose={() => this.setState({ editedContact: undefined })} />}
                {(teeTimes.length > 0 && doSchedule) && <AutoScheduleDialog
                    event={event}
                    open={doSchedule}
                    pairing={false}
                    close={() => this.setState({ doSchedule: false })}
                    save={this.handleScheduleGroups}
                    golfersOrTeams={golfersOrTeams(event.teamSize === 1 ? golfers : teams, groups, true)}
                    groups={groups}
                    count={teeTimes.length}
                    groupSize={teamsPerGroup} />}
                {editingTeeTimes && <EditTeeTimeDialog
                    event={event}
                    settings={event.teeTime}
                    saveSettings={this.saveTeeTime}
                    onClose={this.handleTeeTimeSettingsClose} />}
                {this.state.editingGroup >= 0 &&
                    <EditGroupsDialog event={event} editingGroup={this.state.editingGroup} handleClose={() => this.setState({ editingGroup: -1 })} />}
                {selectingAutoSchedule &&
                    <SelectAutoScheduleDialog open={true} autoSchedule={event.autoSchedule} close={() => this.setState({ selectingAutoSchedule: false })} save={this.setAutoScheduleSave} />}
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(ScheduleList);
