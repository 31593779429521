import * as React from 'react';
import Badge from '@mui/material/Badge';
import Paper from '@mui/material/Paper';
import { ButtonBase } from '@mui/material';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { DialogContent, DialogActions } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import ArrowOutlineIcon from '@mui/icons-material/ArrowDropDownCircle';
import { firebaseAuth } from '../../../../util/firebase';
import { XSMobileDialog } from '../../../../common/dialog/MobileDialog';
import { FirebaseDataComponent } from '../../../../common/WithData';
import DialogAppBar from '../../../../common/dialog/DialogAppBar';
import AppButton from '../../../../common/components/AppButton';
import ConfirmDialog from '../../../../common/dialog/ConfirmDialog';
import { ItemS, FlexGrid, NoWrap, Red } from '../../../../common/Misc';
import { withProgress } from '../../../../util/ProgressPromise';
import { Event, Contact, Team, GolferGroup, EventLog, teeTimeName, generateGroups, getHolesRange } from '../../../../types/EventTypes';
import { addGolferOrTeamToGroup, removeGolferOrTeamFromGroup, deleteInviteCode } from '../../../Event';
import { getTeeTime } from '../../../TeeTimes';
import { fullName, getSameNameGolfersIds, golferTeamFullNames, compareByNamesOrCity } from '../../../../contact/Contact';
import * as Backend from '../../../../util/firebase';
import * as Utils from '../../../../util/utility';
import { styles } from '../../../../styles';

function countOpenings(groupIndex: number, event: Event, groups: Array<GolferGroup>) {
    const openings = groupIndex < groups.length ? event.teeTime.golfersPerGroup - groups[groupIndex].contactIds.length * event.teamSize : 0;
    return Math.floor(openings / event.teamSize);
}

function nextCurGroup(curGroup: number, event: Event, groups: Array<GolferGroup>) {
    while (curGroup < groups.length) {
        const openings = countOpenings(curGroup, event, groups);
        if (openings > 0) {
            return curGroup;
        }
        curGroup++;
    }
    if (curGroup > groups.length - 1) {
        curGroup = groups.length - 1;
    }
    return curGroup;
}

interface TeamItemProps {
    event: Event;
    maxTeamSize: number;
    group?: GolferGroup;
    items: Array<string>;
    curGroup: number;
    select: () => void;
    courseOrCity?: string;
}

export class GroupItem extends React.Component<TeamItemProps & WithStyles<typeof styles>> {
    GroupBadge = () => {
        const { classes, maxTeamSize, items, select, courseOrCity } = this.props;
        const labelContent =
            maxTeamSize === 1 ? <Typography className={classes.labelOverflow}>{items}
                {courseOrCity && <span className={classes.homeCourseOrCity}>{` (${courseOrCity})`}</span>}
                </Typography>
                : maxTeamSize === 2 ?
            items.map(item => (<Typography key={item} className={classes.labelOverflow}>{item}</Typography>)) :
            (<Typography className={classes.labelOverflow2}>{items.join(' + ')}</Typography>);
        return (
            <ButtonBase className={classes.buttonBadge} onClick={() => select()}>
                <Paper className={maxTeamSize === 1 ? classes.teamPaper : classes.teamPaperShort}>{labelContent}</Paper>
            </ButtonBase>
        );
    }
    render() {
        const { classes, event, group, curGroup } = this.props;
        const holesRange = getHolesRange(event.holesType);
        const badge = group ? teeTimeName(getTeeTime(event.teeTime, holesRange, group.order)) : '';
        const isCur = group && curGroup === group.order;
        const badgeStyle = event.teamSize > 1 ?
            (isCur ? classes.groupBadge2 : classes.groupBadge2 + ' ' + classes.backgroundGray) :
            (isCur ? classes.groupBadge : classes.groupBadge + ' ' + classes.backgroundGray);
        return (
            <ItemS>
                {group ?
                    <Badge classes={{ badge: badgeStyle }} color="primary" badgeContent={badge}>
                        <this.GroupBadge />
                    </Badge> :
                    <this.GroupBadge />}
            </ItemS>
        );
    }
}

interface EditGroupsDialogProps {
    event: Event;
    editingGroup: number;
    handleClose: () => void;
    syncUpdate?: boolean;
}

interface EditGroupsDialogState {
    golfers: Map<string, Contact>;
    teams: Map<string, Team>;
    groups: Array<GolferGroup>;
    origGroups: Array<GolferGroup>;
    curGroup: number;
    changes: number;
    changesLog: Array<EventLog>;
    confirmingCancel?: () => void;
    loadedGolfers: number;
    loadedTeams: number;
    loadedGroups: number;
}

type Props = EditGroupsDialogProps & WithStyles<typeof styles>;

class EditGroupsDialog extends React.Component<Props, EditGroupsDialogState> {
    constructor(props: EditGroupsDialogProps & WithStyles<typeof styles>) {
        super(props);
        this.state = {
            golfers: new Map<string, Contact>(),
            teams: new Map<string, Team>(),
            groups: [],
            origGroups: [],
            curGroup: props.editingGroup,
            changes: 0,
            changesLog: [],
            loadedGolfers: 0,
            loadedTeams: 0,
            loadedGroups: 0
        };
    }

    private incCurGroup = () => {
        const { groups } = this.state;
        const { curGroup } = this.state;
        if (curGroup < groups.length - 1) {
            this.setState({ curGroup: curGroup + 1 });
        }
    }

    private decCurGroup = () => {
        const { curGroup } = this.state;
        if (curGroup > 0) {
            this.setState({ curGroup: curGroup - 1 });
        }
    }

    private select = (teamOrGolferId: string) => {
        const { event, syncUpdate } = this.props;
        const { groups, changesLog } = this.state;
        let { curGroup, changes } = this.state;
        const group = curGroup < groups.length ? groups[curGroup] : undefined;
        if (group) {
            if (group.contactIds.indexOf(teamOrGolferId) < 0) {
                if (syncUpdate) {
                    addGolferOrTeamToGroup(teamOrGolferId, group, groups, event).then(this.incCurGroupIfFull);
                } else if (group.contactIds.length * event.teamSize < event.teeTime.golfersPerGroup + 1) {
                    const fromGroup = groups.find(g => g.contactIds.indexOf(teamOrGolferId) >= 0);
                    if (fromGroup) {
                        if (fromGroup.order === group.order) {
                            return;
                        }
                        fromGroup.contactIds.splice(fromGroup.contactIds.indexOf(teamOrGolferId), 1);
                    }
                    group.contactIds.push(teamOrGolferId);
                    if (groups.length === 0 || groups[groups.length - 1].contactIds.length > 0) {
                        groups.push({ id: '', contactIds: [], order: groups.length });
                    }
                    curGroup = nextCurGroup(curGroup, event, groups);
                    changes++;
                    changesLog.push({
                        eventId: event.publicId,
                        datetime: Date.now(),
                        source: firebaseAuth.currentUser!.email ? firebaseAuth.currentUser!.email : '(email is missing)',
                        action: `${event.teamSize > 1 ? 'Team' : 'Golfer'} moved to group`,
                        specs: `Id: ${teamOrGolferId}`,
                        details: `Id#${teamOrGolferId} to Group ${group.order + 1}`
                    } as EventLog);
                    this.setState({ groups, changes, curGroup });
                }
            } else {
                if (syncUpdate) {
                    removeGolferOrTeamFromGroup(teamOrGolferId, event, group);
                } else {
                    group.contactIds.splice(group.contactIds.indexOf(teamOrGolferId), 1);
                    changes++;
                    changesLog.push({
                        eventId: event.publicId,
                        datetime: Date.now(),
                        source: firebaseAuth.currentUser!.email ? firebaseAuth.currentUser!.email : '(email is missing)',
                        action: `${event.teamSize > 1 ? 'Team' : 'Golfer'} removed from group`,
                        specs: `Id: ${teamOrGolferId}`,
                        details: `Id#${teamOrGolferId} from Group ${group.order + 1}`
                    } as EventLog);
                    this.setState({ groups, changes });
                }
            }
        }
    }

    private incCurGroupIfFull = () => this.setState({ curGroup: nextCurGroup(this.state.curGroup, this.props.event, this.state.groups) });

    private handleClose = () => {
        const { changes } = this.state;
        if (changes === 0) {
            this.props.handleClose();
        } else {
            this.setState({ confirmingCancel: () => this.handleSave() });
        }
    }

    private handleCloseX = (uiEvent: string, reason: string) => {
        if ("backdropClick" === reason) {
            return;
        }
        this.handleClose();
    }

    private generateTeeTimes(inputGroups?: Array<GolferGroup>) {
        const { event } = this.props;
        const { golfers, teams } = this.state;
        if (!inputGroups) {
            inputGroups = this.state.groups;
        } else {
            const origGroups = inputGroups.map(groop => Object.assign({}, groop));
            this.setState({ origGroups });
        }
        const groups = generateGroups(event, golfers, teams, inputGroups);
        this.setState({ groups });
    }

    private handleSave = () => {
        const { event } = this.props;
        const { origGroups, groups, changesLog } = this.state;
        withProgress(
            Backend.removeAddBatchPromise(Backend.golferGroupDb(event.id), origGroups, groups.filter(group => group.contactIds.length > 0), deleteInviteCode)
                .then(() => Backend.updateOrAddBatch(Backend.eventLogsDb(event.id), changesLog))
                .then(this.props.handleClose));
    }

    render() {
        const { event, classes, editingGroup, syncUpdate } = this.props;
        const { loadedGolfers, loadedTeams, loadedGroups, teams, golfers, groups, curGroup, changes } = this.state;
        const openings = countOpenings(curGroup, event, groups);
        const openingsText = openings < 0 ? <Red>You are exceeding group size per tee time setting</Red> : '(' + Utils.withS(openings, 'opening') + ')';
        const curGroupOrder = curGroup < groups.length ? groups[curGroup].order : -1;
        const holesRange = getHolesRange(event.holesType);
        const teeTime = teeTimeName(getTeeTime(event.teeTime, holesRange, curGroup));
        const open = editingGroup >= 0;
        const teamGroup = new Map<string, Team>();
        groups.forEach(group => group.contactIds.forEach(golferId => teamGroup.set(golferId, group)));
        const golfersArray = Array.from(golfers.values()).sort((c1, c2) => compareByNamesOrCity(c1, c2));
        const teamsArray = Array.from(teams.values());
        const emptyStatus = loadedGolfers > 0 && loadedTeams > 0 && loadedGroups > 0 ? (event.teamSize > 1 ? 'No teams yet' : 'No golfers yet') : 'Loading...';
        let maxTeamSize = event.teamSize as number;
        teams.forEach(team => maxTeamSize = Math.max(maxTeamSize, team.contactIds.length));
        const sameNameIds = getSameNameGolfersIds(golfersArray);
        return (
            <React.Fragment>
                <XSMobileDialog open={open} onClose={this.handleCloseX} maxWidth={'lg'}>
                    <DialogAppBar label={event.teamSize === 1 ? 'Select golfers' : 'Select teams'} close={this.handleClose} />
                    {event.teamSize === 1 &&
                        <DialogContent className={classes.noselect}>
                            <Typography className={classes.flex}><NoWrap>Assign tee time:&nbsp;&nbsp;&nbsp;</NoWrap>
                                <IconButton
                                    className={classes.colorSecondary}
                                    disabled={curGroup === 0}
                                    onClick={this.decCurGroup}
                                    size="large">
                                    <ArrowOutlineIcon className={classes.rotate90} /></IconButton>
                                <NoWrap className={classes.colorSecondary + ' ' + classes.thickText}>&nbsp;&nbsp;&nbsp;{teeTime}&nbsp;&nbsp;&nbsp;</NoWrap>
                                <IconButton
                                    className={classes.colorSecondary}
                                    disabled={curGroup === groups.length - 1}
                                    onClick={this.incCurGroup}
                                    size="large">
                                    <ArrowOutlineIcon className={classes.rotate270} /></IconButton>
                                <NoWrap>&nbsp;&nbsp;&nbsp;{openingsText}</NoWrap>
                            </Typography>
                            <FlexGrid spacing={2}>
                                {golfersArray.map(golfer => <GroupItem key={golfer.id} event={event}
                                    items={[fullName(golfer)]} group={teamGroup.get(golfer.id)} maxTeamSize={1}
                                    select={() => this.select(golfer.id)} curGroup={curGroupOrder} classes={classes}
                                    courseOrCity={sameNameIds.has(golfer.id) ? golfer.homeCourseOrCity : undefined} />)}
                            </FlexGrid>
                            {golfers.size === 0 && <Typography variant="body1" style={{ margin: 16 }}>{emptyStatus}</Typography>}
                        </DialogContent>}
                    {event.teamSize > 1 &&
                        <DialogContent className={classes.noselect}>
                            <Typography className={classes.flex}><NoWrap>Assign tee time:&nbsp;&nbsp;&nbsp;</NoWrap>
                                <IconButton
                                    className={classes.colorSecondary}
                                    disabled={curGroup === 0}
                                    onClick={this.decCurGroup}
                                    size="large">
                                    <ArrowOutlineIcon className={classes.rotate90} /></IconButton>
                                <NoWrap className={classes.colorSecondary + ' ' + classes.thickText}>&nbsp;&nbsp;&nbsp;{teeTime}&nbsp;&nbsp;&nbsp;</NoWrap>
                                <IconButton
                                    className={classes.colorSecondary}
                                    disabled={curGroup === groups.length - 1}
                                    onClick={this.incCurGroup}
                                    size="large">
                                    <ArrowOutlineIcon className={classes.rotate270} /></IconButton>
                                <NoWrap>&nbsp;&nbsp;&nbsp;{openingsText}</NoWrap>
                            </Typography>
                            <FlexGrid spacing={2}>
                                {teamsArray.map(team =>
                                    <GroupItem key={team.id}
                                        event={event}
                                        items={golferTeamFullNames(team, golfers)}
                                        group={teamGroup.get(team.id)}
                                        maxTeamSize={maxTeamSize}
                                        select={() => this.select(team.id)}
                                        curGroup={curGroupOrder}
                                        classes={classes} />)}
                            </FlexGrid>
                            {teams.size === 0 && <Typography variant="body1" style={{ margin: 16 }}>{emptyStatus}</Typography>}
                        </DialogContent>}
                    <DialogActions>
                        {syncUpdate && <AppButton color="secondary" onClick={this.handleClose}>{'Done'}</AppButton>}
                        {!syncUpdate && <AppButton color="info" onClick={this.handleClose}>{'Cancel'}</AppButton>}
                        {!syncUpdate && <AppButton color="secondary" onClick={this.handleSave} disabled={changes === 0}>{'Save'}</AppButton>}
                    </DialogActions>
                </XSMobileDialog>
                {!!this.state.confirmingCancel && <ConfirmDialog
                    open={true}
                    disableEscapeKeyDown
                    disableBackdropClick
                    onOk={this.state.confirmingCancel}
                    onCancel={this.props.handleClose}
                    content="Save teams updates?"
                    cancelLabel="Discard"
                    okLabel="Save" />}
                <FirebaseDataComponent query={Backend.query(Backend.golferDb(event.id), Backend.where('hidden', '==', false))}
                    name={'golfers ' + event.id}
                    onMap={(golfers: Map<string, Contact>) => this.setState({ golfers: golfers, loadedGolfers: this.state.loadedGolfers + 1 }, this.generateTeeTimes)} />
                <FirebaseDataComponent query={Backend.query(Backend.golferTeamDb(event.id), Backend.orderBy('order'))}
                    name={'teams ' + event.id}
                    onMap={(newTeams: Map<string, Team>) => { this.setState({ teams: newTeams, loadedTeams: loadedTeams + 1 }); this.generateTeeTimes(); }} />
                <FirebaseDataComponent query={Backend.query(Backend.golferGroupDb(event.id), Backend.orderBy('order'))}
                    name={'groups ' + event.id}
                    onData={(newGroups: Array<GolferGroup>) => { this.setState({ loadedGroups: loadedGroups + 1 }); this.generateTeeTimes(newGroups); }} />
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(EditGroupsDialog);
