import * as React from 'react';
import {
    Chip, Checkbox, Dialog, DialogActions, DialogContent, Divider, IconButton, FormControlLabel, FormGroup, TextField,
    Slide, Box, Typography
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import * as Backend from '../../../util/firebase';
import {
    Event, EventData, Contact, ContactDetails, Distance, Score, ReportedScore
} from '../../../types/EventTypes';
import { ContactGroup, Email } from '../../Event';
import { fullName, compareContacts, indexOfContact } from '../../../contact/Contact';
import { ScheduleItem } from '../../TeeTimes';
import ButtonBar from '../../../common/components/ButtonBar';
import AppButton from '../../../common/components/AppButton';
import AddGolfersDialog from './AddGolfersDialog';
import LabeledField from '../../../common/form/LabeledFieldSimple';
import RichTextQuill from '../../../common/form/richtext/RichTextQuill';
import { showProgress, showAlert } from '../../../redux/ReduxConfig';
import { FirebaseUserDataComponent, FirebaseDataComponent } from '../../../common/WithData';
import { firebaseAuth } from '../../../util/firebase';
import { styles } from '../../../styles';
import * as Utils from '../../../util/utility';
import { indexOfFile } from '../../../util/react_utils';
import { UserAware, userProviderContextTypes } from '../../../auth/Auth';
import {
    EmailVariant, initialEmailTemplate, paymentTemplate, SendEmailInfo, sendMail
} from "../../../util/email_utils";
import { AppColors } from "../../../main/Theme";
import { UploadIcon } from "../../../common/Icons";

const MAX_FILE_SIZE = 3 * 1024 * 1024;
const MAX_FILE_COUNT = 10;

interface NewEmailProps {
    open: boolean;
    event: Event;
    eventData: EventData;
    initialGolfers: ContactDetails[];
    emailVariant: EmailVariant;
    handleCancel: () => void;
    handleSent: () => void;
}

interface State {
    golferScores: Map<string, Score>;
    teamScores: Map<string, Score>;
    reportedScores: Map<string, ReportedScore>;
    reportedTeamScores: Map<string, ReportedScore>;
    distances: Map<string, Distance>;
    selectRecipientsOpen: boolean;
    schedule?: ScheduleItem[];
    newMail: Email;
    attachSchedule: boolean;
    attachResults: boolean;
    attachPlayers: boolean;
    files: Array<File>;
    adminName?: string;
    adminEmail?: string;
}

const Transition = (props: any) => <Slide direction="up" {...props} />;
type Props = NewEmailProps & WithStyles<typeof styles>;

function updateEventEmail(event: Event) {
    if (event.userName && event.userEmail) {
        const hideProgress = showProgress();
        Backend.update(Backend.eventsDb, { id: event.id, userName: event.userName, userEmail: event.userEmail })
            .then(() => hideProgress())
            .catch(err => hideProgress('Failed to update event: ' + err.message));
    }
}

class NewEmailDialog extends React.Component<Props, State> {
    static contextTypes = userProviderContextTypes;
    context!: UserAware;

    private readonly defaultText: string;

    constructor(props: Props) {
        super(props);
        const { event, emailVariant, initialGolfers } = this.props;
        this.defaultText = this.getDefaultText(emailVariant, event);
        this.state = {
            golferScores: new Map<string, Score>(),
            teamScores: new Map<string, Score>(),
            reportedScores: new Map<string, ReportedScore>(),
            reportedTeamScores: new Map<string, ReportedScore>(),
            distances: new Map<string, Distance>(),
            selectRecipientsOpen: false,
            newMail: {
                subject: 'Welcome!',
                replyTo: '',
                recipients: initialGolfers.filter(c => Boolean(c.email)),
                text: ''
            },
            attachSchedule: false,
            attachResults: false,
            attachPlayers: false,
            files: []
        };
    }

    private getDefaultText = (variant: EmailVariant, event: Event) => {
        return variant != EmailVariant.default ? paymentTemplate(event) : initialEmailTemplate(event);
    };

    private toList() {
        const { classes } = this.props;
        return (
            <div className={classes.chipContainer + ' ' + classes.scrollPanel}>
                {this.toListChips()}
                <IconButton
                    className={classes.smallIconButton}
                    onClick={this.handleOpenAddRecipients}
                    size="large"><AddCircleOutlineIcon /></IconButton>
            </div>
        );
    }

    private toListChips() {
        const { classes } = this.props;
        const { newMail } = this.state;
        return newMail.recipients.map(recipient => (
            <Chip key={recipient.id}
                label={fullName(recipient)}
                className={classes.chip}
                onDelete={() => this.handleDeleteRecipient(recipient)} />));
    }

    private handleClose = () => this.props.handleCancel();
    private handleOpenAddRecipients = () => this.setState({ selectRecipientsOpen: true });
    private handleRecipientsCancel = () => this.setState({ selectRecipientsOpen: false });
    private onDistances = (distances: Map<string, Distance>) => this.setState({ distances });
    private onTeamScores = (scores: Map<string, Score>) => this.setState({ teamScores: scores });
    private onGolferScores = (scores: Map<string, Score>) => this.setState({ golferScores: scores });
    private onReportedGolferScores = (reportedScores: Map<string, ReportedScore>) => this.setState({ reportedScores });
    private onReportedTeamScores = (reportedTeamScores: Map<string, ReportedScore>) => this.setState({ reportedTeamScores });

    public handleSend = (confirmBeforeSend: boolean) => {
        const { event } = this.props;
        if (((!event.userName || event.userName?.length === 0) && !firebaseAuth.currentUser!.displayName && !this.state.adminName)) {
            return this.warnNoAdminName;
        }
        return confirmBeforeSend ? this.confirmSendBlankEmail : this.handleSendEmail;
    }

    private handleChange = (name: keyof State) => (event: any) => {
        const prop = {} as any;
        prop[name] = event.target.checked;
        this.setState({ ...prop });
    }

    private handleDeleteFile = (file: File) => {
        const { files } = this.state;
        files.splice(indexOfFile(files, file.name), 1);
        this.setState({ files: files });
    }

    private handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const { files } = this.state;
            const fa = Array.from(e.target.files);
            const list = new Array<File>(...files);
            fa.forEach(f => {
                if (!list.find(f2 => f2.name === f.name)) {
                    list.push(f);
                }
            });
            if (list.length > MAX_FILE_COUNT) {
                showAlert('Maximum total attachments count (10 files) is exceeded. File(s) have not been added');
                return;
            }
            let fs = 0;
            list.forEach(f => fs += f.size);
            if (fs > MAX_FILE_SIZE) {
                showAlert('Maximum total attachments size (3MB) is exceeded. File(s) have not been added');
                return;
            }
            this.setState({ files: list });
        } else {
            this.setState({ files: [] });
        }
    }

    private renderFiles = () => {
        const { classes } = this.props;
        const { files } = this.state;
        if (!files.length) {
            return null;
        }
        return (
            <Box display="flex" flexDirection="column">
                {files.map(f => <Box key={f.name}>
                    <Chip
                        label={f.name + ' (' + Utils.formatSize(f.size) + ')'}
                        className={classes.chip}
                        onDelete={() => this.handleDeleteFile(f)} />
                    <br />
                </Box >)}
            </Box>
        );
    };

    private insertPaymentLink = () => {
        const { newMail } = this.state;
        newMail.text += `<br/><p><span>Your payment link: {paymentLink}</span></p><br/>`;
        this.setState({ newMail });
    };

    private attachments() {
        const { emailVariant, classes } = this.props;
        const { attachSchedule, attachResults, attachPlayers } = this.state;
        return (
            <FormGroup>
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                    <Box display="flex" flexDirection="row">
                        <FormControlLabel className={classes.checkBox} control={<Checkbox color="secondary" checked={attachSchedule} onChange={this.handleChange('attachSchedule')} />} label={'Schedule'} />
                        <FormControlLabel className={classes.checkBox} control={<Checkbox color="secondary" checked={attachResults} onChange={this.handleChange('attachResults')} />} label={'Results'} />
                        <FormControlLabel className={classes.checkBox} control={<Checkbox color="secondary" checked={attachPlayers} onChange={this.handleChange('attachPlayers')} />} label={'Golfers list'} />
                    </Box>
                    <input id="fileInput"
                           onChange={this.handleFileChange}
                           style={{ display: 'none' }}
                           type="file"
                           multiple />
                    <label htmlFor="fileInput">
                        <Box sx={{ borderColor: AppColors.blue500, cursor: 'pointer' }} borderRadius="4px" border={1}
                             display="flex" flexDirection="row" alignItems="center" padding="6px 16px">
                            <UploadIcon/>
                            <Box width={4}/>
                            <Typography style={{
                                textTransform: 'uppercase', alignItems: 'center', fontWeight: 600, lineHeight: '21px',
                                fontSize: 14, color: AppColors.blue500
                            }}>Upload file</Typography>
                        </Box>
                    </label>
                </Box>
                <Box display="flex" flexDirection="column">
                    <this.renderFiles />
                    {emailVariant === EmailVariant.default && <Box>
                        <Box height={8} />
                        <AppButton color="secondary" onClick={this.insertPaymentLink} style={{ maxWidth: 160 }}>
                            Insert payment link
                        </AppButton>
                    </Box>}
                </Box>
            </FormGroup>
        );
    };

    private subject() {
        return (
            <TextField
                variant="standard"
                fullWidth
                value={this.state.newMail.subject}
                onChange={e => this.handlePropChange('subject', e.target.value)} />
        );
    };

    private replyTo() {
        const user = Backend.firebaseAuth.currentUser;
        return (
            <TextField
                variant="standard"
                type="email"
                value={this.state.newMail.replyTo || user?.email || ''}
                fullWidth
                onChange={e => this.handlePropChange('replyTo', e.target.value)} />
        );
    };

    private handleContentChange = (value: any) => {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
        this.timeoutId = setTimeout(() => {
            if (!value) {
                return;
            }
            const newMail = this.state.newMail;
            const adminName = this.state.adminName || firebaseAuth.currentUser!.displayName || '';
            if (value && value.indexOf('{admin_name}') > 0 && adminName) {
                value = value.replace('{admin_name}', adminName);
            }
            newMail['text'] = value;
            this.setState({ newMail });
        }, 500);
    };

    private timeoutId?: any;

    private content() {
        return (
            <RichTextQuill value={this.state.newMail.text} onChange={val => this.handleContentChange(val)} />
        );
    }

    private confirmSendBlankEmail = () => {
        showAlert('You are about to send an email without any written content. Confirm if you would like to proceed with sending your email, or select cancel to return to your message.', [
            { title: 'Cancel' },
            { title: 'Confirm and Send', action: this.handleSendEmail }
        ]);
    }

    private warnNoAdminName = () => {
        const { classes } = this.props;
        const msgNode: React.ReactNode = React.createElement('div', null, [React.createElement('span', null, `Unable to create an email: sender name is not filled. `),
        React.createElement('a', { href: '/account', className: classes.linkBlue }, ` Edit in settings`)]);
        showAlert(msgNode, [{ title: 'Ok' }]);
    }

    private handleSendEmail = () => {
        if (Backend.firebaseAuth.currentUser) {
            const hideProgress = showProgress();
            const { attachSchedule, attachResults, attachPlayers, files, golferScores, teamScores, reportedScores,
                distances, reportedTeamScores, newMail
            } = this.state;
            const { event, eventData, emailVariant: variant } = this.props;
            const getSendEmailInfo = (firebaseToken: string): SendEmailInfo => {
                return {
                    event, eventData, attachSchedule, attachResults, attachPlayers, firebaseToken, files, golferScores,
                    teamScores, reportedScores, distances, reportedTeamScores, newMail, variant
                };
            };
            Backend.firebaseAuth.currentUser.getIdToken(false)
                .then(token => sendMail(getSendEmailInfo(token)))
                .then(() => {
                    hideProgress('E-mail has been sent');
                    const { event } = this.props;
                    const { effectiveUserId } = this.context;
                    if (!effectiveUserId && (
                        !event.userName || event.userName?.length === 0 || !event.userEmail || event.userEmail?.length === 0
                        || ((!!this.state.adminName && event.userName !== this.state.adminName) || (!this.state.adminName && !!firebaseAuth.currentUser!.displayName && firebaseAuth.currentUser!.displayName !== event.userName))
                        || ((!!this.state.adminEmail && event.userEmail !== this.state.adminEmail) || (!this.state.adminEmail && !!firebaseAuth.currentUser!.email && firebaseAuth.currentUser!.email !== event.userEmail)))) {
                        event.userName = this.state.adminName || firebaseAuth.currentUser!.displayName || undefined;
                        event.userEmail = this.state.adminEmail || firebaseAuth.currentUser!.email || undefined;
                        updateEventEmail(event);
                    }
                    this.props.handleSent();
                })
                .catch(err => hideProgress('Failed to send e-mail: ' + err.message));
        }
    };

    private handleDeleteRecipient = (recipient: Contact) => {
        const newMail = this.state.newMail;
        newMail.recipients.splice(indexOfContact(newMail.recipients, recipient.id), 1);
        this.setState({ newMail });
    }

    private handlePropChange = (prop: keyof Email, value: any) => {
        const newMail = this.state.newMail;
        newMail[prop] = value;
        this.setState({ newMail });
    }

    private handleRecipientsAdd = (contacts: Array<ContactGroup>) => {
        const newMail = this.state.newMail;
        newMail.recipients = contacts.filter(contactItem => contactItem.contact).map(contactItem => contactItem.contact!);
        newMail.recipients.sort(compareContacts);
        this.setState({ selectRecipientsOpen: false, newMail });
    }

    private onUserData = (newEmail: string, newName: string) => {
        const newMail = this.state.newMail;
        newMail.replyTo = newEmail;
        newMail.text = this.defaultText ?? '';
        this.setState({ selectRecipientsOpen: false, newMail, adminName: newName, adminEmail: newEmail });
    }

    render() {
        const { classes, event, eventData } = this.props;
        const { newMail, selectRecipientsOpen, files } = this.state;
        const selectedGolfersIds = newMail.recipients.map(contact => contact.id);
        const toTitle = 'To: (' + this.state.newMail.recipients.length + ' total)';
        const strippedDefault = this.defaultText.replace(/(<([^>]+)>)/gi, "");
        const strippedMail = newMail.text.replace(/(<([^>]+)>)/gi, "");
        const confirmBeforeSend = !strippedMail || strippedDefault.includes(strippedMail);
        return (
            <Dialog
                fullScreen
                maxWidth="xl"
                className={classes.emailDialog}
                open={this.props.open}
                onClose={this.handleClose}
                TransitionComponent={Transition}>
                {selectRecipientsOpen && <AddGolfersDialog
                    open={true}
                    label={'Select recipients'}
                    event={event}
                    eventData={eventData}
                    golferDB={'GOLFERS_EMAILS'}
                    handleAddGolfers={this.handleRecipientsAdd}
                    handleCancel={this.handleRecipientsCancel}
                    selectedGolfersIds={selectedGolfersIds} />}
                <DialogContent>
                    <LabeledField label={toTitle} value={this.toList()} />
                    <Divider />
                    <LabeledField label="Reply-To:" value={this.replyTo()} />
                    <Divider />
                    <LabeledField label="Subject:" value={this.subject()} />
                    <Divider />
                    <LabeledField label="Attachments:" value={this.attachments()} />
                    <Divider />
                    <LabeledField label="Content:" value={this.content()} />
                </DialogContent>
                <DialogActions>
                    <ButtonBar>
                        <AppButton color="info" onClick={this.handleClose}>Cancel</AppButton>
                        <AppButton color="secondary" onClick={this.handleSend(confirmBeforeSend)}
                            disabled={newMail.recipients.length === 0 || !newMail.subject.trim() || (files.length > MAX_FILE_COUNT || files.map(f => f.size).reduce((a, b) => a + b, 0) > MAX_FILE_SIZE)}>
                            <SendIcon className={classes.leftButtonIcon} /> Send</AppButton>
                    </ButtonBar>
                </DialogActions>
                <FirebaseUserDataComponent onData={data => this.onUserData(data.email, data.name)} />
                <FirebaseDataComponent query={Backend.golferTeamScoresDb(event.id)} onMap={this.onTeamScores} />
                <FirebaseDataComponent query={Backend.golferScoresDb(event.id)} onMap={this.onGolferScores} />
                <FirebaseDataComponent query={Backend.reportedGolferScoresDb(event.id)} onMap={this.onReportedGolferScores} />
                <FirebaseDataComponent query={Backend.reportedTeamScoresDb(event.id)} onMap={this.onReportedTeamScores} />
                <FirebaseDataComponent query={Backend.golferDistancesDb(event.id)} onMap={this.onDistances} />
            </Dialog>
        );
    }
}

export default withStyles(styles)(NewEmailDialog);
